<template>
  <FLegacyIframe
    :iframe-route="
      $route.fullPath +
      '?' +
      objectToQueryString($route.params) +
      '&isEmployeeProfile=true&code=' +
      employeeData.code +
      '&id=' +
      employeeData.id
    "
  />
</template>
<script lang="ts" setup>
import EMPLOYEES_ABILITIES from '@/modules/employees/constants/abilities';
import { objectToQueryString } from '@/modules/shared/core/utils/route';
import { useSingleEmployeeStore } from '@/modules/employees/stores/employee';
import CONTROL_TYPES from '@/modules/shared/core/constants/controlTypes';
import useEmployeeTimeManagementControlEdit from '@/modules/employees/composables/useEmployeeTimeManagementControlEdit';

definePage({
  meta: {
    ability: EMPLOYEES_ABILITIES.EMPLOYEE.TIME_MANAGEMENT.VACATION.ANY
  }
});

const singleEmployeeStore = useSingleEmployeeStore('list');
const { employeeData } = storeToRefs(singleEmployeeStore);
useEmployeeTimeManagementControlEdit(CONTROL_TYPES.VACATION);
</script>
