<template>
  <FHeaderPrimary
    :configuration-ability="ADVANCEMENT_ABILITIES.PERFORMANCES.ADMIN"
    :configuration-route="ADVANCEMENT_ROUTES.PERFORMANCES.ADMIN"
    :tabs="tabs"
    :title="t('title')"
  />
  <FLayoutContentForTabs />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import ADVANCEMENT_ABILITIES from '@/modules/advancement/constants/abilities';
import type { Tab } from '@/components/FTabs.types';
import ADVANCEMENT_ROUTES from '@/modules/advancement/constants/urls';

definePage({
  meta: {
    ability: ADVANCEMENT_ABILITIES.PERFORMANCES.ANY,
    bypassAbility: [ADVANCEMENT_ABILITIES.PERFORMANCES.ADMIN]
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Performances',
      summary: 'Résultats',
      tracking: 'Suivi des processus',
      analysis: 'Analyse détaillée (obsolète)'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Performances',
      summary: 'Results',
      tracking: 'Process monitoring',
      analysis: 'Detailed analysis (obsolete)'
    }
  }
});

const tabs = [
  {
    to: ADVANCEMENT_ROUTES.PERFORMANCES.TRACKING,
    ability: ADVANCEMENT_ABILITIES.PERFORMANCES.TRACKING,
    title: t('tracking')
  },
  {
    to: ADVANCEMENT_ROUTES.PERFORMANCES.SUMMARY,
    ability: ADVANCEMENT_ABILITIES.PERFORMANCES.SUMMARY,
    title: t('summary')
  },
  {
    to: ADVANCEMENT_ROUTES.PERFORMANCES.ANALYSIS,
    ability: ADVANCEMENT_ABILITIES.PERFORMANCES.ANALYSIS,
    title: t('analysis')
  }
] as Tab[];

useFirstTabRedirect(ADVANCEMENT_ROUTES.PERFORMANCES.ROOT, tabs);
</script>
