<template>
  <FAutocomplete
    ref="autocomplete"
    :clearable="false"
    :item-title="itemTitle"
    :item-value="itemValue"
    :items="items"
    :list-props="{
      theme: 'lightFolksTheme'
    }"
    :loading="loading"
    :placeholder="placeholder"
    :menu-arrow-icon="false"
    :value="value"
    :search="value"
    clear-on-select
    no-filter
    theme="darkFolksTheme"
    color="surface-container-high"
    @update:search="emit('update:search', $event)"
    @update:model-value="emit('update:selected', $event)"
    @click:clear="emit('click:clear', $event)"
  >
    <template #item="props">
      <FTopBarSearchEmployee
        :employee="props.item.raw"
        :search-value="value"
      />
    </template>
    <template #append-inner="{ focus }">
      <FIconMD
        v-if="value"
        v-dynamic-data-attr="{ all: 'f-top-bar-search_clear' }"
        icon="close"
        @click="emit('click:clear')"
      />
      <FIconMD
        v-else
        v-dynamic-data-attr="{ all: 'f-top-bar-search_search' }"
        icon="search"
        @click="focus()"
      />
    </template>
    <template
      v-if="items.length"
      #append-item
    >
      <hr />
      <!-- This is the see all results button that should be displayed at the bottom of the list -->
      <VListItem>
        <template #append>
          <VBtn
            v-dynamic-data-attr="{
              all: 'f-top-bar-search_see-all-results'
            }"
            variant="text"
            color="primary"
            @click="emit('click:see-all-results')"
          >
            <template #prepend>
              <FIconMD icon="subdirectory_arrow_left" />
            </template>
            {{ t('seeAllResults') }}
          </VBtn>
        </template>
      </VListItem>
    </template>
  </FAutocomplete>
</template>

<script setup lang="ts">
import type { FTopBarSearchAutocompleteProps } from './FTopBarSearchAutocomplete.types';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';

const emit = defineEmits([
  'update:selected',
  'update:search',
  'click:clear',
  'click:see-all-results'
]);

const autocomplete = ref();
defineExpose({
  blur: () => autocomplete.value?.blur()
});

const {
  placeholder = undefined,
  items,
  itemTitle = 'title',
  itemValue = 'value',
  loading = false,
  value
} = defineProps<FTopBarSearchAutocompleteProps>();

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      seeAllResults: 'Voir tous les résultats'
    },
    [SUPPORTED_LANGUAGES.en]: {
      seeAllResults: 'See all results'
    }
  }
});
</script>
