<template>
  <FHeaderPrimary :title="t('title')" />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import TIME_MANAGEMENT_ABILITIES from '@/modules/time-management/constants/abilities';
import CONTROL_TYPES from '@/modules/shared/core/constants/controlTypes';
import useTimeManagementControlEdit from '@/modules/time-management/composables/useTimeManagementControlEdit';

definePage({
  meta: {
    ability: TIME_MANAGEMENT_ABILITIES.CALENDAR.VIEW
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Calendrier des absences et des vacances'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Absences and vacations calendar'
    }
  }
});

useTimeManagementControlEdit(CONTROL_TYPES.VACATION);
useTimeManagementControlEdit(CONTROL_TYPES.ABSENCE);
useTimeManagementControlEdit(CONTROL_TYPES.OVERTIME);
</script>
<style lang="sass" scoped></style>
