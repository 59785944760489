import { createVuetify } from 'vuetify';
import DateFnsAdapter from '@date-io/date-fns';
import frCA from 'date-fns/locale/fr-CA';
import enUS from 'date-fns/locale/en-US';

import { md } from 'vuetify/iconsets/md';
import { aliases, mdi } from 'vuetify/iconsets/mdi';

import { darkFolksTheme, lightFolksTheme } from './vuetify-folks-themes';
import {
  darkFolksVariables,
  lightFolksVariables
} from '@/plugins/vuetify-folks-variables';
import folksDefaults from './vuetify-folks-defaults';
import m3Defaults from './vuetify-m3-defaults';

import { ObjectUtils } from '@sdk/modules/core';

import '@mdi/font/css/materialdesignicons.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'material-symbols/index.css';
import 'vuetify/styles';

import '../styles/variables.scss';
import '../styles/md3/index.scss';
import '../styles/folks/index.scss';
import '../styles/folksUtils.scss';

//IMPORTANT: Components must be imported after all styles
import { VFileUpload, VFileUploadItem } from 'vuetify/labs/VFileUpload';
import { VDateInput } from 'vuetify/labs/VDateInput';

export default createVuetify({
  date: {
    adapter: DateFnsAdapter,
    locale: {
      fr: frCA,
      en: enUS
    }
  },
  display: {
    mobileBreakpoint: 'sm'
  },
  theme: {
    defaultTheme: 'lightFolksTheme',
    themes: {
      lightFolksTheme: { ...lightFolksTheme, variables: lightFolksVariables },
      darkFolksTheme: { ...darkFolksTheme, variables: darkFolksVariables }
    },
    variations: {
      colors: ['primary', 'secondary', 'tertiary'],
      darken: 10,
      lighten: 10
    }
  },
  icons: {
    aliases,
    defaultSet: 'mdi',
    sets: {
      mdi,
      md
    }
  },
  defaults: {
    ...ObjectUtils.mergeDeep({}, m3Defaults, folksDefaults)
  },
  components: {
    VFileUpload,
    VFileUploadItem,
    VDateInput
  }
});
