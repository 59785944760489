<template>
  <VList>
    <template
      v-for="item in items"
      :key="item.text"
    >
      <VListSubheader
        v-dynamic-data-attr="{
          all: `f-top-bar-side-sheet-item_section-${item.text}`
        }"
        class="text-title-small pt-6"
      >
        {{ translate(item.text) }}
      </VListSubheader>
      <template
        v-for="children in item.children"
        :key="children.ability"
      >
        <VListItem
          v-dynamic-data-attr="{
            all: `f-top-bar-side-sheet-item_child-${children.text}`
          }"
          :href="children.href"
          :to="getRouteLocation(children.to)"
          :target="children.href ? '_blank' : undefined"
          variant="outlined"
          @click="emit('click:item', children)"
        >
          <template #prepend>
            <FIconMD :icon="children.icon" />
          </template>
          <template #default>
            <div class="text-body-large">
              {{ translate(children.text) }}
            </div>
            <div
              v-if="children.subtitle"
              class="text-body-medium text-on-surface-variant"
            >
              {{
                children.subtitleIsUrl
                  ? children.subtitle
                  : translate(children.subtitle)
              }}
            </div>
          </template>
        </VListItem>
      </template>
    </template>
  </VList>
</template>

<script lang="ts" setup>
import type { TopBarSideSheetItem } from '@/components/FTopBarSideSheet.types';
import type { RouteLocationRaw } from 'vue-router';

defineProps({
  items: {
    type: Array as () => TopBarSideSheetItem[],
    required: true
  },
  translate: {
    type: Function,
    required: true
  }
});

const getRouteLocation = (
  to: string | undefined
): RouteLocationRaw | undefined => {
  return to ? ({ name: to } as RouteLocationRaw) : undefined;
};

const emit = defineEmits(['click:item']);
</script>
<style lang="sass" scoped></style>
