import type SSOConfigurationServiceInterface from '@sdk/modules/authentication/interfaces/sso/SSOConfigurationServiceInterface';
import type { SSOConfiguration } from '@sdk/modules/authentication/interfaces/types/SSOConfiguration';
import type { CreateSSOConfigurationPayload } from '@sdk/modules/authentication/interfaces/types/Payloads/CreateSSOConfigurationPayload';
import { inject, injectable } from 'inversify';
import { API_BINDING_TYPES } from '@sdk/modules/API';
import type { APIProxyInterface } from '@sdk/modules/API/Interface/APIProxyInterface';

@injectable()
export default class SSOConfigurationService
  implements SSOConfigurationServiceInterface
{
  constructor(
    @inject(API_BINDING_TYPES.APIProxyInterface)
    private proxy: APIProxyInterface
  ) {}

  async fetch(): Promise<Array<SSOConfiguration>> {
    const {
      data: { data }
    } = await this.proxy.get<{ data: Array<SSOConfiguration> }>(
      'sso/configurations'
    );

    return data;
  }

  async show(id: number): Promise<SSOConfiguration> {
    const response = await this.proxy.get<SSOConfiguration>(
      `sso/configurations/${id}`
    );

    return response.data;
  }

  async create(
    payload: CreateSSOConfigurationPayload
  ): Promise<SSOConfiguration> {
    const response = await this.proxy.post<SSOConfiguration>(
      'sso/configurations',
      this.createFormDataFromPayload(payload),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    return response.data;
  }

  async delete(config: SSOConfiguration): Promise<void> {
    if (!('id' in config) || !config.id) {
      return;
    }

    await this.proxy.delete(`sso/configurations/${config.id}`);
  }

  async update(config: SSOConfiguration): Promise<SSOConfiguration> {
    const response = await this.proxy.post<SSOConfiguration, FormData>(
      `sso/configurations/${config.id}`,
      this.createFormDataFromPayload({ ...config, code: '' }),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    return response.data;
  }

  private createFormDataFromPayload(
    payload: Record<string, unknown>
  ): FormData {
    const output = new FormData();

    for (const key in payload) {
      if (
        typeof payload[key] === 'string' &&
        payload[key] &&
        key !== 'custom_image'
      ) {
        output.set(key, payload[key] as string);
      }

      if (payload[key] instanceof File && (payload[key] as File).size > 0) {
        output.append(key, payload[key] as File, (payload[key] as File).name);
      }
    }

    return output;
  }
}
