<template>
  <div
    v-dompurify-html="rawHtml"
    :class="classProps"
  ></div>
</template>

<script setup lang="ts">
import { buildVueDompurifyHTMLDirective } from 'vue-dompurify-html';
import type { FStringHighlightProps } from './FStringHighlight.types';

const { rawHtml, classProps } = defineProps<FStringHighlightProps>();

const vDompurifyHtml = buildVueDompurifyHTMLDirective({
  default: {
    ALLOWED_ATTR: ['class'],
    ALLOWED_TAGS: ['span']
  }
});
</script>
