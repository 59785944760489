import type { Program } from '@/modules/shared/core/utils/mapping/index.types';
import LEGACY_TYPE from '@/modules/shared/core/constants/legacyType';
import BASE_ROUTES from '@/constants/urls';
import BASE_ABILITIES from '@/constants/abilities';

const BASE_MAPPING = [
  {
    name: BASE_ABILITIES.HOME,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 100000,
    module: 'base',
    routeName: BASE_ROUTES.HOME,
    iframeUrl: BASE_ROUTES.HOME
  },
  {
    name: BASE_ABILITIES.EMPLOYEE_DIRECTORY,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 1000000,
    module: 'base',
    routeName: BASE_ROUTES.EMPLOYEE_DIRECTORY,
    iframeUrl: '/legacy/phonebook/'
  },
  {
    name: BASE_ABILITIES.USER_PARAMS,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 800000,
    module: 'base',
    routeName: BASE_ROUTES.USER_PARAMS
  }
] as Program[];

export default BASE_MAPPING;
