import type { Program } from '@/modules/shared/core/utils/mapping/index.types';
import LEGACY_TYPE from '@/modules/shared/core/constants/legacyType';
import HIRING_ABILITIES from '@/modules/hiring/constants/abilities';
import HIRING_ROUTES from '@/modules/hiring/constants/urls';

const REQUISITIONS_MAPPING = [
  {
    name: HIRING_ABILITIES.REQUISITIONS.LIST,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 201210,
    module: HIRING_ABILITIES.REQUISITIONS.ANY,
    routeName: HIRING_ROUTES.REQUISITIONS.ROOT
  },
  {
    name: HIRING_ABILITIES.REQUISITIONS.CONFIG.ANY,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505211,
    module: HIRING_ABILITIES.REQUISITIONS.ANY,
    routeName: HIRING_ROUTES.REQUISITIONS.CONFIG.ROOT
  },
  {
    name: HIRING_ABILITIES.REQUISITIONS.CONFIG.EDIT,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505210,
    module: HIRING_ABILITIES.REQUISITIONS.ANY,
    routeName: HIRING_ROUTES.REQUISITIONS.CONFIG.EDIT
  },
  {
    name: HIRING_ABILITIES.REQUISITIONS.REQUEST + ':edit',
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 406000,
    module: HIRING_ABILITIES.REQUISITIONS.ANY,
    routeName: HIRING_ROUTES.REQUISITIONS.EDIT
  },
  {
    name: HIRING_ABILITIES.REQUISITIONS.REQUEST + ':create',
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 406000,
    module: HIRING_ABILITIES.REQUISITIONS.ANY,
    routeName: HIRING_ROUTES.REQUISITIONS.CREATE
  }
] as Program[];

const HIRING_MAPPING = [...REQUISITIONS_MAPPING] as Program[];

export default HIRING_MAPPING;
