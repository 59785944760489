import type { AuthenticationRefreshTokenMutexInterface } from '@sdk/modules/authentication/interfaces/AuthenticationRefreshTokenMutexInterface';
import { Mutex } from 'async-mutex';
import type { AuthResponseSuccess } from '@sdk/modules/authentication/services/AuthenticationService.types';
import { injectable } from 'inversify';

@injectable()
export class AuthenticationRefreshTokenMutex
  implements AuthenticationRefreshTokenMutexInterface
{
  private mutex: Mutex;
  private mutexResultsValue: AuthResponseSuccess | null;

  constructor() {
    this.mutex = new Mutex();
    this.mutexResultsValue = null;
  }

  isLocked(): boolean {
    return this.mutex.isLocked();
  }

  runExclusive(
    callback: () => Promise<AuthResponseSuccess>
  ): Promise<AuthResponseSuccess> {
    return this.mutex.runExclusive<AuthResponseSuccess>(async () => {
      const result = await callback();
      this.mutexResultsValue = result;

      return result;
    });
  }

  async waitForUnlock(): Promise<AuthResponseSuccess> {
    await this.mutex.waitForUnlock();

    if (this.mutexResultsValue === null) {
      throw new Error('There was an error while refreshing access token');
    }

    return this.mutexResultsValue;
  }
}
