<template>
  <FHeaderPrimary
    :tabs="tabs"
    :title="t('title')"
    :back-route="EMPLOYEES_ROUTES.EMPLOYEE.ROOT"
  />
  <div class="align-self-start mx-6">
    <FAlert
      is-visible
      type="info"
      icon="warning"
      :message-text="t('alertMessage')"
      :closable="false"
      class="my-3"
    />
  </div>
  <FLayoutContentForTabs />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import type { TypeOfTab } from '@/components/FTabs.types';
import EMPLOYEES_ROUTES from '@/modules/employees/constants/urls';
import EMPLOYEES_ABILITIES from '@/modules/employees/constants/abilities';

definePage({
  meta: {
    ability: EMPLOYEES_ABILITIES.EMPLOYEE.ANY
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: "Configuration de l'employé",
      customField: 'Champs personnalisés',
      customFieldsPerso: 'Personnels',
      customFieldsCorpo: 'Corporatifs',
      projects: 'Accès aux projets',
      alertMessage:
        "N'oubliez pas de sauvegarder vos modifications avant de quitter la page."
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Employee Configuration',
      customField: 'Custom fields',
      customFieldsPerso: 'Personal',
      customFieldsCorpo: 'Corporate',
      projects: 'Access to projects',
      alertMessage: "Don't forget to save your changes before leaving the page."
    }
  }
});

const tabs = [
  {
    ability: EMPLOYEES_ABILITIES.EMPLOYEE.CONFIGURATION.ANY,
    title: t('customField'),
    type: 'parent',
    children: [
      {
        to: EMPLOYEES_ROUTES.EMPLOYEE.CONFIGURATION.CUSTOM_FIELD_PERSO,
        ability: EMPLOYEES_ABILITIES.EMPLOYEE.CONFIGURATION.CUSTOM_FIELD_PERSO,
        title: t('customFieldsPerso')
      },
      {
        to: EMPLOYEES_ROUTES.EMPLOYEE.CONFIGURATION.CUSTOM_FIELD_CORPO,
        ability: EMPLOYEES_ABILITIES.EMPLOYEE.CONFIGURATION.CUSTOM_FIELD_CORPO,
        title: t('customFieldsCorpo')
      }
    ]
  },
  {
    to: EMPLOYEES_ROUTES.EMPLOYEE.CONFIGURATION.PROJECTS,
    ability: EMPLOYEES_ABILITIES.EMPLOYEE.CONFIGURATION.PROJECTS,
    title: t('projects')
  }
] as TypeOfTab[];

useFirstTabRedirect(EMPLOYEES_ROUTES.EMPLOYEE.CONFIGURATION.ROOT, tabs);
</script>
