<template>
  <VList
    :opened="currentlyOpen"
    @update:opened="emit('update:opened-nav', $event)"
  >
    <VTooltip>
      <template #activator="{ props }">
        <VBtn
          v-dynamic-data-attr="{ all: `button-menu-home` }"
          :icon="true"
          to="/"
          v-bind="props"
          variant="flat"
          class="v-list-item--nav"
          @click="navigationRailStore.closeMobileNav"
        >
          <FIconMD icon="home" />
        </VBtn>
      </template>
      {{ t('home') }}
    </VTooltip>
    <template
      v-for="item in itemsToDisplay"
      :key="item.name"
    >
      <FNavigationGroup
        v-if="item.children"
        :item-data="item"
      />
      <VDivider
        v-else-if="item.sectionEnd"
        class="my-3 pl-n10"
        thickness="2"
      ></VDivider>
      <FNavigationListItem
        v-else
        :item-data="item"
      />
    </template>
  </VList>
</template>

<script lang="ts" setup>
import { useNavigationRailStore } from '@/stores/navigationRail';
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import type { FNavigationContentProps } from '@/components/FNavigationContent.types';

const emit = defineEmits(['update:opened-nav']);

const { itemsToDisplay, currentlyOpen } =
  defineProps<FNavigationContentProps>();

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      home: 'Accueil'
    },
    [SUPPORTED_LANGUAGES.en]: {
      home: 'Home'
    }
  }
});

const navigationRailStore = useNavigationRailStore();
</script>
