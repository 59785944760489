<template>
  <VList
    :opened="currentlyOpen"
    class="pt-0"
    @update:opened="emit('update:opened-nav', $event)"
  >
    <template
      v-for="item in itemsToDisplay"
      :key="item.name"
    >
      <template v-if="showSettingsMenu">
        <FNavigationGroup
          v-if="item.children"
          :item-data="item"
        />
        <FNavigationListItem
          v-else
          :item-data="item"
        />
      </template>
    </template>
    <VDivider
      class="my-3 pl-n10"
      thickness="2"
    />
    <FNavigationListItem
      v-if="!showSettingsMenu"
      :item-data="settingsItem"
      @click="emit('update:settings-menu', true)"
    />
    <FNavigationListItem
      v-else
      :item-data="returnToNavItem"
      @click="emit('update:settings-menu', false)"
    />
  </VList>
</template>

<script lang="ts" setup>
import { type Ref } from 'vue';
import type { MenuItem } from '@/components/FNavigation.types';
import type { FNavigationSettingsContentProps } from './FNavigationSettingsContent.types';

const emit = defineEmits(['update:opened-nav', 'update:settings-menu']);

const { itemsToDisplay, currentlyOpen, showSettingsMenu } =
  defineProps<FNavigationSettingsContentProps>();

const settingsItem: Ref<MenuItem> = ref({
  name: 'settings.show',
  text: 'settings',
  icon: 'settings'
});
const returnToNavItem: Ref<MenuItem> = ref({
  name: 'settings.back',
  text: 'back',
  icon: 'arrow_back'
});
</script>
