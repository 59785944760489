<template>
  <FIframeDialog
    v-if="isDialogShowed"
    :iframe-route="dialogRoute"
    :is-showed="isDialogShowed"
    :title="dialogTitle"
    @dialog:save="sendSaveEvent"
    @dialog:cancel="updateDialog(false)"
  >
    <template #card-actions> </template>
  </FIframeDialog>
  <FLegacyIframe :iframe-route="$route.fullPath" />
</template>
<script lang="ts" setup>
import useMessageListener from '@/modules/shared/core/composables/PostMessage/useMessageListener';
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import type { MessageEventData } from '@/modules/shared/core/objects/MessageEventData';
import {
  getProgramDataByID,
  getProgramDataByRoute
} from '@/modules/shared/core/utils/mapping';
import useMessageSender from '@/modules/shared/core/composables/PostMessage/useMessageSender';
import TIME_MANAGEMENT_ABILITIES from '@/modules/time-management/constants/abilities';

definePage({
  meta: {
    ability: TIME_MANAGEMENT_ABILITIES.TIMESHEET.CONFIG.PROJECTS
  }
});

const { showDialog, isDialogShowed, updateDialog } = useDialogLegacyIframe();
const dialogRoute = ref(``);
const dialogTitle = ref(``);

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      'time-management:timesheet:config:tasks': 'Gestion des tâches',
      'time-management:timesheet:config:project': 'Gestion des projets'
    },
    [SUPPORTED_LANGUAGES.en]: {
      'time-management:timesheet:config:tasks': 'Tasks management',
      'time-management:timesheet:config:project': 'Projects management'
    }
  }
});

const { addHandler } = useMessageListener({
  name: 'timesheet:config-projects'
});
addHandler('modalOpen', (event: MessageEventData) => {
  const queryParams = new URLSearchParams(event.data);
  const pageID = Number(queryParams.get('f'));
  if (isNaN(pageID)) {
    throw new Error('Invalid page ID');
  }
  const programData = getProgramDataByID(pageID);
  dialogRoute.value = programData.routeName;
  dialogTitle.value = t(programData.name);
  showDialog();
});

const { sendMessageToProgramFrame, buildMessageEventDataToSend } =
  useMessageSender();

const { path } = useRoute();
const sendSaveEvent = () => {
  const programData = getProgramDataByRoute(dialogRoute.value);
  sendMessageToProgramFrame(
    buildMessageEventDataToSend('form:submit', null, path),
    programData
  );
};
</script>
