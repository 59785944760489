<template>
  <FHeaderPrimary
    :add-summary-ability="EMPLOYEES_ABILITIES.LIST.SUMMARY"
    :add-summary-route="EMPLOYEES_ROUTES.SUMMARY"
    :add-summary-text="t('summary')"
    :configuration-ability="EMPLOYEES_ABILITIES.CONFIGURATION.ANY"
    :configuration-route="EMPLOYEES_ROUTES.CONFIGURATION.ROOT"
    :title="t('title')"
  />
  <FDatatable
    :headers="getVDatatableHeaders"
    :items="items"
    :items-length="itemsLength"
    :items-per-page="itemsPerPage"
    :loading="loading"
    :sort-by="sortBy"
    :search-value="search"
    @update:search="search = $event"
    @update:sort-by="sortBy = $event"
    @update:page="page = $event"
    @update:items-per-page="itemsPerPage = $event"
    @click:row="redirectToEmployee"
  >
    <template #quick_filters>
      <VChipGroup
        v-model="quickFilters"
        column
        multiple
      >
        <VChip
          :text="t('quickFilters.inactive')"
          class="rounded-lg"
          filter
          value="inactive"
          variant="outlined"
        />
        <VChip
          :text="t('quickFilters.myEmployees')"
          class="rounded-lg"
          filter
          value="colleagues"
          variant="outlined"
        />
        <VChip
          :text="t('quickFilters.recent')"
          class="rounded-lg"
          filter
          value="recent"
          variant="outlined"
        />
      </VChipGroup>
    </template>
    <template #first_name="{ item }: { item: Employee }">
      <FDatatableCellFullNameAndAvatar
        :full-name="getFullName(item)"
        :is-active="item.active"
        :image="item.image"
      />
    </template>
    <template #supervisor="{ item }">
      <FDataFullName :value="item.supervisor" />
    </template>
    <template #jobStatus="{ item }">
      <FDataLocalized :value="item.jobStatus" />
    </template>
    <template #position="{ item }">
      <FDataLocalized :value="item.position" />
    </template>
    <template #department="{ item }">
      <FDataLocalized :value="item.department" />
    </template>
  </FDatatable>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import EMPLOYEES_ABILITIES from '@/modules/employees/constants/abilities';
import EMPLOYEES_ROUTES from '@/modules/employees/constants/urls';
import type { RouteNamedMap } from 'vue-router/auto';
import { useEmployeesStore } from '@/modules/shared/core/stores/employees';
import type { Employee } from '@/modules/shared/core/services/employees.types';
import FDataFullName from '@/modules/shared/core/components/FDataFullName.vue';
import FDataLocalized from '@/modules/shared/core/components/FDataLocalized.vue';
import { getFullName } from '@/modules/shared/core/utils/dataFormatters';

definePage({
  meta: {
    ability: EMPLOYEES_ABILITIES.LIST.INDEX,
    bypassAbility: [
      EMPLOYEES_ABILITIES.CONFIGURATION.ANY,
      EMPLOYEES_ABILITIES.LIST.SUMMARY
    ]
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Liste des employés',
      summary: 'Voir le sommaire',
      headers: {
        employeeNumber: 'Numéro',
        employee: 'Employé',
        supervisor: 'Superviseur',
        jobStatus: 'Type d’emploi',
        position: 'Titre d’emploi',
        department: 'Département'
      },
      quickFilters: {
        inactive: 'Employés inactifs',
        recent: 'Nouveaux employés',
        myEmployees: 'Mes employés'
      }
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Employees List',
      summary: 'View summary',
      headers: {
        employeeNumber: 'Number',
        employee: 'Employee',
        supervisor: 'Supervisor',
        jobStatus: 'Job Status',
        position: 'Position',
        department: 'Department'
      },
      quickFilters: {
        inactive: 'Inactive Employees',
        recent: 'Recent Employees',
        myEmployees: 'My Employees'
      }
    }
  }
});

const { getVDatatableHeaders, getVisibleColumns } = useFDatatableColumns([
  {
    title: t('headers.employee'),
    key: 'first_name',
    sortable: true,
    mandatory: true,
    minWidth: '200',
    maxWidth: '300'
  },
  { title: t('headers.employeeNumber'), key: 'code', sortable: true },
  { title: t('headers.position'), key: 'position' },
  { title: t('headers.department'), key: 'department' },
  { title: t('headers.jobStatus'), key: 'jobStatus' },
  { title: t('headers.supervisor'), key: 'supervisor' }
]);

const employeesStore = useEmployeesStore('list');
const { items, itemsLength } = storeToRefs(employeesStore);
const {
  itemsPerPage,
  loading,
  search,
  sortBy,
  page,
  quickFilters,
  fetchNewEntries
} = useFDatatableItems(employeesStore.get, getVisibleColumns);

onMounted(() => {
  fetchNewEntries();
});

const router = useRouter();
const redirectToEmployee = ({ item }: { item: Employee; index: number }) => {
  const routeName = EMPLOYEES_ROUTES.EMPLOYEE.ROOT as keyof RouteNamedMap;
  router.push({ name: routeName, params: { employee_id: item.id } });
};
</script>
