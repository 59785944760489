import {
  leaveBugsnagBreadcrumbs,
  reportNetworkErrorToBugsnag,
  reportTimeoutErrorToBugsnag
} from '@/modules/shared/core/utils/apiFolks/bugsnagNetwork';
import type { AxiosResponse } from 'axios';
import HTTP_STATUS_CODES from '@/modules/shared/core/constants/httpStatusCodes';
import Bugsnag from '@bugsnag/js';
import { handleErrorCode } from '@/modules/shared/core/utils/apiFolks/proxyHandling';

export function interceptorResponse(response: AxiosResponse): any {
  const { data, config, status, statusText, headers } = response;
  switch (status) {
    // todo why 0
    case HTTP_STATUS_CODES.OK:
    case HTTP_STATUS_CODES.Created:
    case HTTP_STATUS_CODES.NoContent:
    case 0:
      leaveBugsnagBreadcrumbs(config, status);
      headers['x-total-count'] = headers['x-total-count'] || data.length;
      return {
        data,
        headers: headers
      };
  }

  return handleErrorCode(status, statusText, response, response.data);
  //todo what is this supposed to do
  return Promise.reject(
    'Success code verification failed:' +
      (JSON.stringify({ url: config.url, status, statusText }) || 'Error')
  );
}

export const interceptorError = (error: any) => {
  const {
    response: { data, status, request },
    message
  } = error;
  let errorHandled = false;
  const notNetworkErrorHttpStatusCodes: HTTP_STATUS_CODES[] = [
    HTTP_STATUS_CODES.Unauthorized,
    HTTP_STATUS_CODES.Forbidden,
    HTTP_STATUS_CODES.UnprocessableEntity
  ];

  if (!notNetworkErrorHttpStatusCodes.includes(status)) {
    reportNetworkErrorToBugsnag(error);
    errorHandled = true;
  }

  if (message.includes('timeout')) {
    reportTimeoutErrorToBugsnag(error);
    errorHandled = true;
  }

  if (data) {
    let handleCodeMessage = '';
    let responseData = data;

    // handle error message when requesting binary files.
    //todo test this if because im not sure if it works
    if (
      request.responseType === 'arraybuffer' &&
      responseData.toString() === '[object ArrayBuffer]'
    ) {
      const errorMessage = String.fromCharCode.apply(null, responseData);
      const errorMessageObject = JSON.parse(errorMessage);
      responseData = errorMessage;
      handleCodeMessage = errorMessageObject.message;
    } else if (typeof responseData === 'string') {
      handleCodeMessage = responseData;
    } else {
      handleCodeMessage = message;
    }

    return handleErrorCode(status, handleCodeMessage, error, responseData);
  }

  if (!errorHandled) {
    Bugsnag.notify(error);
  }
  return Promise.reject(error);
};
