<template>
  <VAutocomplete
    ref="autocomplete"
    theme="lightFolksTheme"
    auto-select-first
    :clearable="clearable"
    density="compact"
    :disabled="disabled"
    flat
    hide-details
    :color="color"
    :items="items"
    :item-title="itemTitle"
    :item-value="itemValue"
    :label="label"
    :list-props="listProps"
    :loading="loading"
    :menu-icon="menuIcon"
    :no-data-text="t('noResult')"
    :placeholder="placeholder"
    return-object
    variant="solo-filled"
    :value="value"
    @update:search="emit('update:search', $event)"
    @update:model-value="emit('update:selected', $event)"
    @click:clear="emit('click:clear', $event)"
  >
    <template #item="props">
      <VListItem
        v-bind="props.props"
        :title="undefined"
      >
        <slot
          name="item"
          v-bind="props"
        >
        </slot>
      </VListItem>
    </template>
    <template #append-inner="props">
      <slot
        name="append-inner"
        v-bind="props"
      >
      </slot>
    </template>
    <template #no-data>
      <VListItem>
        <template #prepend>
          <FIconMD icon="search" />
        </template>
        {{ t('noResult') }}
      </VListItem>
    </template>
    <template #append-item>
      <slot name="append-item"></slot>
    </template>
  </VAutocomplete>
</template>

<script setup lang="ts">
import SUPPORTED_LANGUAGES from '../constants/languages';
import type { FAutocompleteProps } from './FAutocomplete.types';

const emit = defineEmits(['update:selected', 'update:search', 'click:clear']);

// Expose the autocomplete ref to allow blur functionality
const autocomplete = useTemplateRef('autocomplete');
defineExpose({
  blur: () => autocomplete.value?.blur()
});

const {
  label,
  color,
  placeholder = undefined,
  items,
  itemTitle = 'title',
  itemValue = 'value',
  loading = false,
  value,
  clearable = false,
  disabled = false,
  menuArrowIcon = true,
  listProps
} = defineProps<FAutocompleteProps>();

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      noResult: 'Aucun résultat'
    },
    [SUPPORTED_LANGUAGES.en]: {
      noResult: 'No result'
    }
  }
});

const menuIcon = ref(menuArrowIcon ? '$dropdown' : '');
</script>
