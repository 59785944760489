import type { Ref } from 'vue';
import type { ApiListParams } from '@/modules/shared/core/utils/apiFolks/api.types';
import { useDebounceFn } from '@vueuse/core';

export default function useSearchBuffer(
  searchValue: Ref<ApiListParams['search']>,
  callback: () => Promise<any>,
  searchCondition?: Ref<boolean>
) {
  const shouldSearchNow = ref(searchCondition ?? true);
  const searchBufferForNoTyping = 500;

  const debouncedFn = useDebounceFn(async () => {
    await callback();
  }, searchBufferForNoTyping);

  watch([searchValue, shouldSearchNow], () => {
    if (shouldSearchNow.value) {
      debouncedFn();
    }
  });
}
