<template>
  <VContainer>
    <VRow>
      <VCol cols="6">
        <VCard variant="outlined">
          <VCardTitle class="text-title-large">
            {{ t('languageEmulator') }}
          </VCardTitle>
          <VCardSubtitle class="text-body-medium">
            {{ t('userLoggedInLanguage') }}
            {{ userStore.language }}
          </VCardSubtitle>
          <VCardText>
            <VSelect
              :items="languages"
              :label="t('selectLanguage')"
              :model-value="localeStored"
              variant="outlined"
              @update:model-value="setI18nLanguage($event)"
            />
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <VCard variant="outlined">
          <VCardTitle class="text-title-large">
            {{ t('abilityEmulator') }}
          </VCardTitle>
          <VCardSubtitle class="text-body-medium">
            {{ t('abilityDescription') }}
          </VCardSubtitle>

          <VCardText>
            <VContainer>
              <VRow>
                <VCol cols="6">
                  <VCardSubtitle class="text-body-medium">
                    {{ t('currentAbilities') }}
                  </VCardSubtitle>
                  <VDataTable
                    v-model="clonedPermissions"
                    v-model:page="currentPage"
                    :headers="[{ title: 'Ability', value: 'ability' }]"
                    :items="abilitiesForSimulator"
                    :items-per-page="-1"
                    density="compact"
                    hide-default-footer
                    item-value="ability"
                    show-select
                  />
                </VCol>
                <VCol cols="6">
                  <VCardSubtitle class="text-body-medium">
                    {{ t('simulatedAbilities') }}
                  </VCardSubtitle>
                  <VDataTable
                    v-model="permissions"
                    v-model:page="currentPage"
                    :headers="[{ title: 'Ability', value: 'ability' }]"
                    :items="abilitiesForSimulator"
                    :items-per-page="-1"
                    density="compact"
                    hide-default-footer
                    item-value="ability"
                    select-nones
                    show-select
                  />
                </VCol>
              </VRow>
            </VContainer>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </VContainer>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import { useUserStore } from '@/modules/shared/core/stores/user';
import { useLocalStorage } from '@vueuse/core';
import { setI18nLanguage } from '@/plugins/vueI18n';
import { usePermissionsStore } from '@/modules/shared/core/stores/permissions';
import { storeToRefs } from 'pinia';
import { concat } from 'lodash';
import { useSimulatorStore } from '@/stores/simulator';

definePage({
  meta: {
    needAuth: true,
    hideInProd: true
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      languageEmulator: 'Gestion de la langue',
      userLoggedInLanguage: "Langue de l'utilisateur connecté:",
      selectLanguage: 'Sélectionner une langue',
      abilityEmulator: 'Gestion des droits',
      abilityDescription:
        "Il est possible ici de simuler les droits de l'utilisateur connecté",
      currentAbilities: 'Droits actuels',
      simulatedAbilities: 'Droits simulés'
    },
    [SUPPORTED_LANGUAGES.en]: {
      languageEmulator: 'Language management',
      userLoggedInLanguage: 'User loggedIn language:',
      selectLanguage: 'Select a language',
      abilityEmulator: 'Ability management',
      abilityDescription:
        'It is possible here to simulate the rights of the connected user',
      currentAbilities: 'Current abilities',
      simulatedAbilities: 'Simulated abilities'
    }
  }
});

const userStore = useUserStore();
const languages = Object.values(SUPPORTED_LANGUAGES);
const localeStored = useLocalStorage<SUPPORTED_LANGUAGES>(
  'user-locale',
  SUPPORTED_LANGUAGES.fr
);

const currentPage = ref(1);
const { permissions } = storeToRefs(usePermissionsStore());
const clonedPermissions = ref(JSON.parse(JSON.stringify(permissions.value)));

const transformAbilitiesForSimulator = (abilities: any) => {
  let fullAbilitiesList: { ability: string }[] = [];
  for (const key in abilities) {
    if (typeof abilities[key] === 'object') {
      fullAbilitiesList = concat(
        fullAbilitiesList,
        transformAbilitiesForSimulator(abilities[key])
      );
    } else {
      fullAbilitiesList.push({ ability: abilities[key] });
    }
  }
  return fullAbilitiesList.sort((a, b) => a.ability.localeCompare(b.ability));
};
const { abilities } = storeToRefs(useSimulatorStore());
const abilitiesForSimulator = computed(() =>
  transformAbilitiesForSimulator(abilities.value)
);
</script>
