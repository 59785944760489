<template>
  <FHeaderPrimary
    :title="t('title')"
    :tabs="tabs"
  />
  <FLayoutContentForTabs />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import type { Tab } from '@/components/FTabs.types';
import INTEGRATIONS_ABILITIES from '@/modules/parameters/submodules/integrations/constants/abilities';
import INTEGRATIONS_ROUTES from '@/modules/parameters/submodules/integrations/constants/urls';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';

definePage({
  meta: {
    ability: INTEGRATIONS_ABILITIES.ANY
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Intégrations',
      sso: 'Authentification unique (SSO)',
      nethris: 'Configuration des comptes Nethris',
      api: "Gestion des clés d'API",
      payroll: 'Configuration des code de paies',
      payworks: 'Configuration des comptes Payworks'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Integrations',
      sso: 'Single Sign-On (SSO)',
      api: 'API key management',
      nethris: 'Configuring Nethris accounts',
      payroll: 'Payroll code configuration',
      payworks: 'Configuring Payworks accounts'
    }
  }
});

const tabs = [
  {
    to: INTEGRATIONS_ROUTES.SSO,
    ability: INTEGRATIONS_ABILITIES.SSO,
    title: t('sso')
  },
  {
    to: INTEGRATIONS_ROUTES.NETHRIS,
    ability: INTEGRATIONS_ABILITIES.NETHRIS,
    title: t('nethris')
  },
  {
    to: INTEGRATIONS_ROUTES.API,
    ability: INTEGRATIONS_ABILITIES.API,
    title: t('api')
  },
  {
    to: INTEGRATIONS_ROUTES.PAYROLL,
    ability: INTEGRATIONS_ABILITIES.PAYROLL,
    title: t('payroll')
  },
  {
    to: INTEGRATIONS_ROUTES.PAYWORKS,
    ability: INTEGRATIONS_ABILITIES.PAYWORKS,
    title: t('payworks')
  }
] as Tab[];

useFirstTabRedirect(INTEGRATIONS_ROUTES.ROOT, tabs);
</script>
