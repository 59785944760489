<template>
  <div class="d-flex">
    <FAvatar
      :full-name="fullName"
      :size="FAvatarSizes.xSmall"
      :src="employee.image"
      :disable="!employee.active"
      class="mr-3"
    />
    <div class="d-flex flex-column">
      <FStringHighlight
        :raw-html="highlithedFullName"
        class-props="text-body-large"
      />
      <FStringHighlight
        :raw-html="highlithedPosition"
        class-props="text-body-medium"
      />
      <FStringHighlight
        :raw-html="highlithedCode"
        class-props="text-body-medium"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  getFullName,
  getLocalizedData
} from '@/modules/shared/core/utils/dataFormatters';
import { FAvatarSizes } from '@/modules/shared/core/constants/FAvatarSizeEnum';
import type { FTopBarSearchEmployeeProps } from '@/components/FTopBarSearchEmployee.types';
import useSearchHighlither from '@/modules/shared/core/composables/useSearchHighlighter';

const { employee, searchValue } = defineProps<FTopBarSearchEmployeeProps>();

const fullName = computed(() => getFullName(employee));

const { highlightHTMLString } = useSearchHighlither(searchValue, {
  frontTag: '<span class="font-weight-bold">',
  backTag: '</span>'
});
const highlithedFullName = computed(() => highlightHTMLString(fullName.value));
const highlithedPosition = computed(() =>
  highlightHTMLString(getLocalizedData(employee.position))
);
const highlithedCode = computed(() => highlightHTMLString(employee.code));
</script>
