export default class ValidationError extends Error {
  constructor(
    message: string,
    private _validationErrors: { [key: string]: Array<string> }
  ) {
    super(message);
  }

  get validationErrors(): { [p: string]: Array<string> } {
    return this._validationErrors;
  }
}
