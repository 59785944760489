import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import type { MenuItem } from '@/components/FNavigation.types';
import useHookHandler from '@/modules/shared/core/composables/useHookHandler';

export const useNavigationStore = defineStore('navigation', () => {
  const menuItems: Ref<MenuItem[]> = ref([]);
  const {
    getSortedItems: getSortedMenuItems,
    register: registerMenu,
    unregister: unregisterMenu
  } = useHookHandler(menuItems, 'order');

  const settingItems: Ref<MenuItem[]> = ref([]);
  const {
    getSortedItems: getSortedSettingItems,
    register: registerSetting,
    unregister: unregisterSetting
  } = useHookHandler(settingItems, 'order');

  const settingStickyItems: Ref<MenuItem[]> = ref([]);
  const {
    getSortedItems: getSortedSettingStickyItems,
    register: registerSettingSticky,
    unregister: unregisterSettingSticky
  } = useHookHandler(settingStickyItems, 'order');

  return {
    menuItems,
    getSortedMenuItems,
    registerMenu,
    unregisterMenu,
    settingItems,
    getSortedSettingItems,
    registerSetting,
    unregisterSetting,
    settingStickyItems,
    getSortedSettingStickyItems,
    registerSettingSticky,
    unregisterSettingSticky
  };
});
