<template>
  <FHeaderPrimary
    :tabs="tabs"
    :title="t('title')"
  />
  <FLayoutContentForTabs />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import REPORTS_ABILITIES from '@/modules/reports/constants/abilities';
import REPORTS_ROUTES from '@/modules/reports/constants/urls';
import type { Tab } from '@/components/FTabs.types';

definePage({
  meta: {
    ability: REPORTS_ABILITIES.DASHBOARD.ANY
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Tableau de bord',
      dashboard: 'Tableau de bord',
      summary: 'Sommaire'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Dashboard',
      dashboard: 'Dashboard',
      summary: 'Summary'
    }
  }
});

const tabs = [
  {
    to: REPORTS_ROUTES.DASHBOARD.ANALYSIS,
    ability: REPORTS_ABILITIES.DASHBOARD.ANALYSIS,
    title: t('dashboard')
  },
  {
    to: REPORTS_ROUTES.DASHBOARD.SUMMARY,
    ability: REPORTS_ABILITIES.DASHBOARD.SUMMARY,
    title: t('summary')
  }
] as Tab[];

useFirstTabRedirect(REPORTS_ROUTES.DASHBOARD.ROOT, tabs);
</script>
