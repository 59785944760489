import type { Program } from '@/modules/shared/core/utils/mapping/index.types';
import LEGACY_TYPE from '@/modules/shared/core/constants/legacyType';
import INTEGRATIONS_ABILITIES from '@/modules/parameters/submodules/integrations/constants/abilities';
import INTEGRATIONS_ROUTES from '@/modules/parameters/submodules/integrations/constants/urls';

const INTEGRATIONS_MAPPING = [
  {
    name: INTEGRATIONS_ABILITIES.API,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 501090,
    module: INTEGRATIONS_ABILITIES.ANY,
    routeName: INTEGRATIONS_ROUTES.API,
    iframeUrl: '/legacy/admin/api-key-management/index'
  },
  {
    name: INTEGRATIONS_ABILITIES.NETHRIS,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 501051,
    module: INTEGRATIONS_ABILITIES.ANY,
    routeName: INTEGRATIONS_ROUTES.NETHRIS
  },
  {
    name: INTEGRATIONS_ABILITIES.PAYROLL,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 506610,
    module: INTEGRATIONS_ABILITIES.ANY,
    routeName: INTEGRATIONS_ROUTES.PAYROLL
  },
  {
    name: INTEGRATIONS_ABILITIES.PAYWORKS,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 501055,
    module: INTEGRATIONS_ABILITIES.ANY,
    routeName: INTEGRATIONS_ROUTES.PAYWORKS
  }
] as Program[];

export default INTEGRATIONS_MAPPING;
