import type { Program } from '@/modules/shared/core/utils/mapping/index.types';
import LEGACY_TYPE from '@/modules/shared/core/constants/legacyType';
import COMPANY_ROUTES from '@/modules/company/constants/urls';
import COMPANY_ABILITIES from '@/modules/company/constants/abilities';

const INFORMATIONS_MAPPING = [
  {
    name: COMPANY_ABILITIES.INFORMATIONS.ANY,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 501020,
    module: COMPANY_ABILITIES.INFORMATIONS.ANY,
    routeName: COMPANY_ROUTES.INFORMATIONS.ROOT
  }
] as Program[];

const STANDARDS_MAPPING = [
  {
    name: COMPANY_ABILITIES.STANDARDS.ORGANISATIONAL,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505010,
    module: COMPANY_ABILITIES.STANDARDS.ANY,
    routeName: COMPANY_ROUTES.STANDARDS.ORGANISATIONAL
  },
  {
    name: COMPANY_ABILITIES.STANDARDS.EMPLOYEES,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505020,
    module: COMPANY_ABILITIES.STANDARDS.ANY,
    routeName: COMPANY_ROUTES.STANDARDS.EMPLOYEES
  },
  {
    name: COMPANY_ABILITIES.STANDARDS.TRANSFER,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505025,
    module: COMPANY_ABILITIES.STANDARDS.ANY,
    routeName: COMPANY_ROUTES.STANDARDS.TRANSFER
  }
] as Program[];

const STRUCTURE_MAPPING = [
  {
    name: COMPANY_ABILITIES.STRUCTURE.WORKINGSITES,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 505038,
    module: COMPANY_ABILITIES.STRUCTURE.ANY,
    routeName: COMPANY_ROUTES.STRUCTURE.WORKINGSITES,
    iframeUrl: '/legacy/structures/working-sites'
  },
  {
    name: COMPANY_ABILITIES.STRUCTURE.DEPARTMENTS,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 505040,
    module: COMPANY_ABILITIES.STRUCTURE.ANY,
    routeName: COMPANY_ROUTES.STRUCTURE.DEPARTMENTS,
    iframeUrl: '/legacy/structures/department'
  },
  {
    name: COMPANY_ABILITIES.STRUCTURE.POSITIONS,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 505050,
    module: COMPANY_ABILITIES.STRUCTURE.ANY,
    routeName: COMPANY_ROUTES.STRUCTURE.POSITIONS,
    iframeUrl: '/legacy/structures/positions'
  },
  {
    name: COMPANY_ABILITIES.STRUCTURE.UNION,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 505070,
    module: COMPANY_ABILITIES.STRUCTURE.ANY,
    routeName: COMPANY_ROUTES.STRUCTURE.UNION,
    iframeUrl: '/legacy/structures/unions'
  },
  {
    name: COMPANY_ABILITIES.STRUCTURE.CHART,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 201001,
    module: COMPANY_ABILITIES.STRUCTURE.ANY,
    routeName: COMPANY_ROUTES.STRUCTURE.CHART
  },
  {
    name: COMPANY_ABILITIES.STRUCTURE.CHAIRS,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505072,
    module: COMPANY_ABILITIES.STRUCTURE.ANY,
    routeName: COMPANY_ROUTES.STRUCTURE.CHAIRS
  },
  {
    name: COMPANY_ABILITIES.STRUCTURE.POSITIONID,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505073,
    module: COMPANY_ABILITIES.STRUCTURE.ANY,
    routeName: COMPANY_ROUTES.STRUCTURE.POSITIONID
  }
] as Program[];

const SALARY_MAPPING = [
  {
    name: COMPANY_ABILITIES.SALARY.ANY,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505022,
    module: COMPANY_ABILITIES.SALARY.ANY,
    routeName: COMPANY_ROUTES.SALARY.ROOT
  },
  {
    name: COMPANY_ABILITIES.SALARY.RANGE,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505024,
    module: COMPANY_ABILITIES.SALARY.ANY,
    routeName: COMPANY_ROUTES.SALARY.RANGE
  }
] as Program[];

const COMPANY_MAPPING = [
  ...INFORMATIONS_MAPPING,
  ...STANDARDS_MAPPING,
  ...STRUCTURE_MAPPING,
  ...SALARY_MAPPING
] as Program[];

export default COMPANY_MAPPING;
