import { defineStore } from 'pinia';
import { usePermissionsStore } from '@/modules/shared/core/stores/permissions';
import type { Ref } from 'vue';
import type { TopBarSideSheetItem } from '@/components/FTopBarSideSheet.types';
import useHookHandler from '@/modules/shared/core/composables/useHookHandler';

export const useRequestSideSheetStore = defineStore('requestSideSheet', () => {
  const isOpened = ref(false);

  const items: Ref<TopBarSideSheetItem[]> = ref([]);
  const {
    getSortedItems,
    register: registerItem,
    unregister: unregisterItem
  } = useHookHandler(items, 'order');

  const permissionsToVerify: Ref<string[]> = ref([]);
  const registerPermissionToVerify = (permission: string) => {
    permissionsToVerify.value.push(permission);
  };
  const unregisterPermissionToVerify = (permission: string) => {
    const index = permissionsToVerify.value.indexOf(permission);
    if (index > -1) {
      permissionsToVerify.value.splice(index, 1);
    }
  };
  const { canAccess } = usePermissionsStore();
  const hasAnyPermission = computed(() => {
    return permissionsToVerify.value.some((permission) => {
      return canAccess(permission);
    });
  });

  return {
    isOpened,
    items,
    getSortedItems,
    registerItem,
    unregisterItem,
    registerPermissionToVerify,
    unregisterPermissionToVerify,
    hasAnyPermission
  };
});
