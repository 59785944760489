<template>
  <div class="f-top-bar-search">
    <FTopBarSearchAutocomplete
      ref="searchFieldRef"
      v-dynamic-data-attr="{ all: 'f-top-bar-search_textfield' }"
      :items="searchResults"
      item-title="firstName"
      item-value="id"
      :loading="employeesStore.loading"
      :placeholder="t('searchPlaceholder')"
      :value="searchField.value.value"
      @update:selected="handleItemClick"
      @update:search="handleSearch"
      @click:clear="handleClearSearch"
      @click:see-all-results="handleSeeAllResultsClick(searchField.value.value)"
    />
  </div>
</template>

<script lang="ts" setup>
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import { useField } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/zod';
import { string } from 'zod';
import { useEmployeesStore } from '@/modules/shared/core/stores/employees';
import EMPLOYEES_ROUTES from '@/modules/employees/constants/urls';
import type { RouteLocationRaw } from 'vue-router';
import type { Employee } from '@/modules/shared/core/services/employees.types';
import { arrayToUrlValues } from '@/modules/shared/core/utils/dataFormatters';

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      searchPlaceholder: 'Rechercher un employé'
    },
    [SUPPORTED_LANGUAGES.en]: {
      searchPlaceholder: 'Search for an employee'
    }
  }
});

const employeesStore = useEmployeesStore('search', ['position']);

const { items: searchResults } = storeToRefs(employeesStore);
const searchField = useField(
  'search',
  toTypedSchema(string().min(1, 'required'))
);

const handleEmployeeGet = async () => {
  employeesStore.get({
    itemsPerPage: 5,
    search: searchField.value.value,
    page: 1,
    sortBy: [],
    searchableKeys: ['position', 'first_name', 'last_name', 'code'],
    quickFilters: ['inactive']
  });
};

const canSendSearchRequest = computed(
  () =>
    searchField.meta.valid &&
    searchField.meta.dirty &&
    searchField.value.value !== ''
);

useSearchBuffer(searchField.value, handleEmployeeGet, canSendSearchRequest);

const handleClearSearch = () => {
  searchField.resetField();
  employeesStore.reset();
};

const itemWasJustSelected = ref(false);

const handleSearch = (newValue: string) => {
  if (itemWasJustSelected.value) {
    itemWasJustSelected.value = false;
  } else {
    searchField.value.value = newValue;
  }
};

const router = useRouter();

const searchFieldRef = ref();
const handleItemClick = async (item: Employee | null) => {
  if (!item) return;
  itemWasJustSelected.value = true;
  router.push({
    name: EMPLOYEES_ROUTES.EMPLOYEE.ROOT,
    params: { employee_id: item.id }
  } as RouteLocationRaw);
  handleClearSearch();
  searchFieldRef.value.blur();
};

const handleSeeAllResultsClick = (searchValue: string) => {
  router.push({
    name: EMPLOYEES_ROUTES.LIST,
    query: {
      search: searchValue,
      quickFilters: arrayToUrlValues(['inactive'])
    }
  } as RouteLocationRaw);
  handleClearSearch();
  searchFieldRef.value.blur();
};
</script>

<style lang="sass">
.f-top-bar-search
    width: 360px
    .v-field
        border-radius: 20px

div.v-overlay__content.v-autocomplete__content
    max-width: 360px!important
    width: 360px!important
    max-height: 450px!important

.v-menu
    .v-overlay__content
        border-radius: 20px
</style>
