<template>
  <FHeaderPrimary
    :add-summary-ability="TIME_MANAGEMENT_ABILITIES.TIMESHEET.SUMMARY.ANY"
    :add-summary-route="TIME_MANAGEMENT_ROUTES.TIMESHEETS.SUMMARY.ROOT"
    :add-summary-text="t('summary')"
    :configuration-ability="TIME_MANAGEMENT_ABILITIES.TIMESHEET.CONFIG.ANY"
    :configuration-route="TIME_MANAGEMENT_ROUTES.TIMESHEETS.CONFIG.ROOT"
    :title="t('title')"
  />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import TIME_MANAGEMENT_ABILITIES from '@/modules/time-management/constants/abilities';
import TIME_MANAGEMENT_ROUTES from '@/modules/time-management/constants/urls';

definePage({
  meta: {
    ability: TIME_MANAGEMENT_ABILITIES.TIMESHEET.VIEW,
    bypassAbility: [
      TIME_MANAGEMENT_ABILITIES.TIMESHEET.CONFIG.ANY,
      TIME_MANAGEMENT_ABILITIES.TIMESHEET.SUMMARY.ANY
    ]
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Feuille de temps',
      summary: 'Voir le sommaire'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Timesheets',
      summary: 'View summary'
    }
  }
});
</script>
<style lang="sass" scoped></style>
