import type { PostMessageHandlerInterface } from '@/modules/shared/core/composables/PostMessage/handlers/PostMessageHandlerInterface';
import type { MessageRespondFn } from '@/modules/shared/core/stores/messageHandlersStore';

export class RequestQueryParamsPostMessageHandler
  implements PostMessageHandlerInterface
{
  async handle(respond: MessageRespondFn) {
    respond(window.location.search);
  }
}

const request = new RequestQueryParamsPostMessageHandler();
request.handle(() => {});
