<template>
  <VContainer
    class="align-center justify-center fill-height text-center"
    max-width="750"
  >
    <VRow>
      <VCol cols="12">
        <div>
          <svg
            fill="none"
            height="166"
            viewBox="0 0 166 166"
            width="166"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="83"
              cy="83"
              fill="#F6F6F6"
              r="83"
            />
            <g filter="url(#filter0_d_3980_33527)">
              <path
                clip-rule="evenodd"
                d="M110.829 50.4321C108.772 50.4321 107.05 51.9928 106.848 54.0403L106.631 56.2539H37.5797C35.4729 56.2539 33.7273 57.888 33.5884 59.9902L29.2454 125.735C29.093 128.043 30.9239 129.998 33.2368 129.998H129.123C131.23 129.998 132.975 128.364 133.114 126.262L137.457 60.5176C137.61 58.2097 135.779 56.2539 133.466 56.2539H128.671L128.811 54.824C129.043 52.4713 127.195 50.4321 124.831 50.4321H110.829Z"
                fill="url(#paint0_linear_3980_33527)"
                fill-rule="evenodd"
              />
            </g>
            <path
              d="M91.8494 74.9701C96.3941 72.3717 68.3696 75.5199 63.2069 68.3227C58.0442 61.1256 87.1009 55.3602 86.3266 40.1176C86.1052 35.7593 83.1941 28.0794 74.649 29.2466C59.3243 31.34 64.357 63.3913 99.2365 40.1176"
              stroke="#939397"
              stroke-dasharray="2 5"
              stroke-linecap="round"
              stroke-width="1.5"
            />
            <g filter="url(#filter1_d_3980_33527)">
              <path
                d="M103.068 31.6908C103.796 32.2606 104.682 33.9727 104.072 34.7516C103.463 35.5306 101.588 35.0812 100.86 34.5115C100.132 33.9418 100.036 32.8486 100.646 32.0696C101.256 31.2907 102.34 31.1211 103.068 31.6908Z"
                fill="#C0C0C0"
              />
              <path
                d="M106.116 39.2918C105.441 38.738 104.67 37.0677 105.298 36.3031C105.925 35.5384 107.714 35.969 108.389 36.5228C109.063 37.0765 109.102 38.1453 108.474 38.9099C107.847 39.6745 106.791 39.8455 106.116 39.2918Z"
                fill="#C0C0C0"
              />
              <ellipse
                cx="104.562"
                cy="35.3373"
                fill="#343434"
                rx="1.88289"
                ry="2.51052"
                transform="rotate(41.631 104.562 35.3373)"
              />
            </g>
            <path
              d="M17.0992 72.8953C16.58 70.4107 18.4764 68.0771 21.0146 68.0771H117.047C118.941 68.0771 120.575 69.4052 120.962 71.259L132.162 124.858C132.681 127.343 130.785 129.676 128.247 129.676H32.2146C30.3208 129.676 28.6865 128.348 28.2992 126.494L17.0992 72.8953Z"
              fill="url(#paint1_linear_3980_33527)"
            />
            <defs>
              <filter
                id="filter0_d_3980_33527"
                color-interpolation-filters="sRGB"
                filterUnits="userSpaceOnUse"
                height="119.566"
                width="148.229"
                x="11.2366"
                y="32.4321"
              >
                <feFlood
                  flood-opacity="0"
                  result="BackgroundImageFix"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset
                  dx="2"
                  dy="2"
                />
                <feGaussianBlur stdDeviation="10" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.00392157 0 0 0 0 0 0 0 0 0 0.0627451 0 0 0 0.1 0"
                />
                <feBlend
                  in2="BackgroundImageFix"
                  mode="normal"
                  result="effect1_dropShadow_3980_33527"
                />
                <feBlend
                  in="SourceGraphic"
                  in2="effect1_dropShadow_3980_33527"
                  mode="normal"
                  result="shape"
                />
              </filter>
              <filter
                id="filter1_d_3980_33527"
                color-interpolation-filters="sRGB"
                filterUnits="userSpaceOnUse"
                height="28.2631"
                width="28.6763"
                x="88.2446"
                y="23.3537"
              >
                <feFlood
                  flood-opacity="0"
                  result="BackgroundImageFix"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset
                  dx="-2"
                  dy="2"
                />
                <feGaussianBlur stdDeviation="5" />
                <feComposite
                  in2="hardAlpha"
                  operator="out"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.00392157 0 0 0 0 0 0 0 0 0 0.0627451 0 0 0 0.1 0"
                />
                <feBlend
                  in2="BackgroundImageFix"
                  mode="normal"
                  result="effect1_dropShadow_3980_33527"
                />
                <feBlend
                  in="SourceGraphic"
                  in2="effect1_dropShadow_3980_33527"
                  mode="normal"
                  result="shape"
                />
              </filter>
              <linearGradient
                id="paint0_linear_3980_33527"
                gradientUnits="userSpaceOnUse"
                x1="75.3549"
                x2="119.98"
                y1="43.6528"
                y2="124.49"
              >
                <stop stop-color="#CCCCCC" />
                <stop
                  offset="0.713542"
                  stop-color="#A5A5A5"
                />
              </linearGradient>
              <linearGradient
                id="paint1_linear_3980_33527"
                gradientUnits="userSpaceOnUse"
                x1="66.1167"
                x2="94.3443"
                y1="62.8287"
                y2="133.153"
              >
                <stop stop-color="#DCDCDC" />
                <stop
                  offset="1"
                  stop-color="#B0B0B0"
                />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <h4 class="text-display-small pb-6 pt-5">
          {{ t('noPermissionTitle') }}
        </h4>
        <p class="text-body-large">{{ t('noPermissionText') }}</p>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      noPermissionTitle: 'C’est bien vide ici...',
      noPermissionText:
        "Il semble que vous n'avez pas les autorisations nécessaires pour accéder au contenu de cette page.\n Contactez votre administrateur."
    },
    [SUPPORTED_LANGUAGES.en]: {
      noPermissionTitle: 'It’s pretty empty here...',
      noPermissionText:
        'It seems you don’t have the necessary permissions to access the content of this page.\n Contact your administrator.'
    }
  }
});
</script>
<style lang="sass" scoped></style>
