<template>
  <FLegacyIframe :iframe-route="$route.fullPath" />
</template>
<script lang="ts" setup>
import IMPORT_EXPORT_ABILITIES from '@/modules/parameters/submodules/import-export/constants/abilities';

definePage({
  meta: {
    ability: IMPORT_EXPORT_ABILITIES.EXPORT
  }
});
</script>
