<template>
  <FLegacyIframe
    :iframe-route="
      $route.fullPath +
      '?' +
      objectToQueryString($route.params) +
      '&isEmployeeProfile=true&n&id=' +
      employeeData.id +
      '&code=' +
      employeeData.code
    "
  />
</template>
<script lang="ts" setup>
import EMPLOYEES_ABILITIES from '@/modules/employees/constants/abilities';
import { useSingleEmployeeStore } from '@/modules/employees/stores/employee';
import { objectToQueryString } from '@/modules/shared/core/utils/route';

definePage({
  meta: {
    ability: EMPLOYEES_ABILITIES.EMPLOYEE.EXPENSE_ACCOUNTS
  }
});

const singleEmployeeStore = useSingleEmployeeStore('list');
const { employeeData } = storeToRefs(singleEmployeeStore);
</script>
