import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type { GlobalErrorAlert } from '@/modules/shared/core/stores/globalErrorAlert.types';

export const useGlobalErrorAlertStore = defineStore(
  'globalErrorAlertStore',
  () => {
    const isVisible = ref(false);
    const messageTitle = ref<
      | {
          text: string;
          keys?: Record<string, unknown>;
        }
      | string
    >('');
    const messageText = ref<
      | {
          text: string;
          keys?: Record<string, unknown>;
        }
      | string
      | undefined
    >('');
    const isContactSupport = ref(false);

    const show = (options: Omit<GlobalErrorAlert, 'isVisible'>): void => {
      messageTitle.value = options.messageTitle ?? '';
      messageText.value = options.messageText ?? '';
      isContactSupport.value = options.isContactSupport ?? false;
      isVisible.value = true;
    };

    const dismiss = (): void => {
      isVisible.value = false;
    };

    const bindings = computed(() => ({
      isVisible: isVisible.value,
      messageTitle: messageTitle.value,
      messageText: messageText.value,
      icon: 'error',
      isContactSupport: isContactSupport.value
    }));

    return {
      show,
      dismiss,
      bindings
    };
  }
);
