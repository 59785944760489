<template>
  <VCard
    class="mx-auto"
    max-width="800"
  >
    <VCardTitle class="pt-0 text-headline-medium">
      {{ t('completingSSO') }}
    </VCardTitle>
    <VCardSubtitle
      v-if="!hasError"
      class="pb-4"
      style="white-space: wrap"
    >
      {{ t('pleaseWait') }}
    </VCardSubtitle>
    <VCardText>
      <VContainer class="pa-0">
        <VRow>
          <VCol
            v-if="hasError"
            cols="12"
          >
            <FAlertFormError
              v-if="errorMessage"
              v-dynamic-data-attr="{ all: `alert-error-message-sso` }"
              :is-visible="!!errorMessage"
              :message-text="errorMessage"
              :message-title="t('errorTitle')"
            />
          </VCol>
          <VCol
            v-else
            cols="12"
            align-self="center"
          >
            <VProgressLinear
              v-if="hasLoader"
              v-dynamic-data-attr="{ all: `sso-loader` }"
              class="mx-auto d-block"
              indeterminate
            />
          </VCol>
          <VCol
            cols="12"
            class="d-flex"
          >
            <VBtn
              v-dynamic-data-attr="{ all: `button-sso-back-to-pwd` }"
              color="primary"
              variant="text"
              class="mx-auto mt-6"
              :to="loginTo"
              :text="t('backToLogin')"
            />
          </VCol>
        </VRow>
      </VContainer>
    </VCardText>
  </VCard>
</template>

<script setup lang="ts">
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import { useAuthenticationStore } from '@/modules/authentication/store/authentication';
import AUTHENTICATION_ROUTES from '../../constants/urls';
import type { RouteLocationRaw } from 'vue-router';

definePage({
  meta: {
    isAuthPages: true
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      completingSSO: 'Authentification unique (SSO) en cours',
      pleaseWait: 'Veuillez patienter',
      backToLogin: 'Retour à la page de connexion',
      errorTitle: 'Une erreur est survenue:',
      errors: {
        user_not_found: 'Utilisateur non trouvé',
        sso_misconfiguration: 'Configuration SSO incorrecte'
      }
    },
    [SUPPORTED_LANGUAGES.en]: {
      completingSSO: 'Completing single sign-on (SSO)',
      pleaseWait: 'Please wait.',
      backToLogin: 'Go back to login',
      errorTitle: 'An error occurred:',
      errors: {
        user_not_found: 'User not found',
        sso_misconfiguration: 'SSO configuration invalid'
      }
    }
  }
});

const { query } = useRoute();
const responseError = ref<string | null>(null);

const hasError = computed(() => {
  if (responseError.value !== null) {
    return true;
  }

  return 'error_code' in query && 'error_message' in query;
});

const errorMessage = computed(() => {
  if (!hasError.value) {
    return null;
  }

  if (responseError.value !== null) {
    return responseError.value;
  }
  const errorCode = query?.error_code;

  return t(`errors.${errorCode}`);
});

const authenticationStore = useAuthenticationStore();
const router = useRouter();

const handleSSOCompletion = async () => {
  const urlAuthCode = query.code as string;
  const urlState = query.state as string;

  if (urlAuthCode && urlState) {
    try {
      await authenticationStore.completeSSO(urlAuthCode, urlState);

      await router.push({ name: '/' });
    } catch (error) {
      responseError.value = (error as Error).message;
      authenticationStore.clear();
    }
  }
};

const hasLoader = ref(true);

const loginTo = computed(
  () =>
    ({
      name: AUTHENTICATION_ROUTES.LOGIN
    }) as RouteLocationRaw
);

onMounted(async () => {
  if (!hasError.value) {
    await handleSSOCompletion();
  } else {
    hasLoader.value = false;
  }
});
</script>

<style scoped lang="sass"></style>
