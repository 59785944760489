<template>
  <VContainer fluid>
    <VRow>
      <VCol cols="auto">
        <h2 class="text-headline-small">{{ t('title') }}</h2>
      </VCol>
      <VSpacer />
      <VCol cols="auto">
        <VBtn
          v-if="!isLoading && configurations.length === 0"
          v-dynamic-data-attr="{ all: `f-header-primary_add-action` }"
          :text="t('actions.add')"
          class="mr-2"
          @click="() => (isVisible = true)"
        >
          <template #prepend>
            <FIconMD icon="add" />
          </template>
        </VBtn>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <p class="text-readable-max-w mb-3">{{ t('description') }}</p>
        <p class="text-readable-max-w">
          {{ t('moreInfo')
          }}<VBtn
            v-dynamic-data-attr="{ all: `f-header-primary_help-center-link` }"
            :href="t('helpCenterLink')"
            color="text"
            target="_blank"
            variant="plain"
            class="pl-2"
          >
            {{ t('helpCenterText') }}
            <template #append>
              <FIconMD icon="open_in_new" />
            </template>
          </VBtn>
        </p>
      </VCol>
    </VRow>
    <VRow>
      <VCol
        cols="12"
        lg="6"
      >
        <VSkeletonLoader
          :loading="isLoading"
          type="card"
          class="w-100 h-100 d-flex ga-3"
        >
          <FCardSSOConfig
            v-for="config in configurations"
            :key="config.code"
            :config="config"
            @delete="handleConfirmDeleteClick"
          />
        </VSkeletonLoader>
      </VCol>
    </VRow>
    <FDialogSSOConfig
      :is-visible="isVisible"
      @cancel="isVisible = false"
    />
  </VContainer>
</template>

<script lang="ts" setup>
import { useSSOConfigurationsStore } from '@/modules/authentication/store/ssoConfigurations';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import type { SSOConfiguration } from '@sdk/modules/authentication/interfaces/types/SSOConfiguration';
import INTEGRATIONS_ABILITIES from '@/modules/parameters/submodules/integrations/constants/abilities';
import { useSnackbarStore } from '@/modules/shared/core/stores/snackbar';

definePage({
  meta: {
    ability: INTEGRATIONS_ABILITIES.SSO
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Configuration SSO',
      description:
        'Le SSO (Single Sign-On) permet aux utilisateurs de se connecter à la plateforme Folks en utilisant leurs identifiants professionnels existants, simplifiant ainsi l’accès tout en renforçant la sécurité. La page d’ajout de SSO vous permet de configurer et activer l’intégration avec votre fournisseur d’authentification d’entreprise.',
      moreInfo:
        'Pour plus d’informations, lire la documentation sur le sujet dans notre ',
      helpCenterText: "Centre d'aide",
      helpCenterLink:
        'https://support.folkshr.com/hc/fr-ca/articles/35114299286548',
      actions: {
        add: 'Ajouter'
      },
      messages: {
        deleteSuccess: 'La suppression a été effectuée avec succès.',
        deleteError:
          'La suppression n’a pas pu être complétée. Veuillez réessayer.'
      }
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'SSO Configuration',
      description:
        'SSO (Single Sign-On) enables users to log on to the Folks platform using their existing professional credentials, simplifying access while reinforcing security. The Add SSO page lets you configure and activate integration with your enterprise authentication provider.',
      moreInfo:
        'For more information, read the documentation on the subject in our ',
      helpCenterText: 'Help Center',
      helpCenterLink:
        'https://support.folkshr.com/hc/en-ca/articles/35114299286548',
      actions: {
        add: 'Add'
      },
      messages: {
        deleteSuccess: 'The deletion was successful.',
        deleteError: 'The deletion could not be completed. Please try again.'
      }
    }
  }
});

const isLoading = ref(false);

const { isVisible } = useDialog();

const ssoConfigurationsStore = useSSOConfigurationsStore();

const { configurations } = storeToRefs(ssoConfigurationsStore);

const { show } = useSnackbarStore();

const handleConfirmDeleteClick = async (config: SSOConfiguration) => {
  if (config) {
    isLoading.value = true;
    await ssoConfigurationsStore.delete(config);
    if (ssoConfigurationsStore.backendErrors.value?.length > 0) {
      show({
        message: t('messages.deleteError')
      });
    } else {
      show({
        message: t('messages.deleteSuccess')
      });
    }
    isLoading.value = false;
  }
};

const handleDataFetch = async () => {
  isLoading.value = true;
  await ssoConfigurationsStore.fetch();
  isLoading.value = false;
};

handleDataFetch();
</script>
