<template>
  <VNavigationDrawer
    v-if="mobile"
    :model-value="mobileNavOpen"
    class="f-navigation"
    color="surface-container-low"
    width="360"
    @update:model-value="mobileNavOpen = $event"
  >
    <FNavigationContent
      :currently-open="currentlyOpen"
      :items-to-display="itemsToDisplay"
      @update:opened-nav="updateOpenedNav"
    />
    <template
      v-if="
        filteredSettingsMenuItems.length || filteredSettingStickyItems.length
      "
      #append
    >
      <FNavigationSettingsContent
        :currently-open="currentlyOpen"
        :show-settings-menu="showSettingsMenu"
        :items-to-display="filteredSettingStickyItems"
        @update:opened-nav="updateOpenedNav"
        @update:settings-menu="changeSettingsMenu"
      />
    </template>
  </VNavigationDrawer>
  <VNavigationDrawer
    v-if="!mobile"
    :rail="railIsOpenByLockOrClick"
    class="f-navigation"
    color="surface-container-low"
    rail-width="80"
    width="360"
  >
    <template #prepend>
      <VList class="pb-0">
        <FNavToggler
          v-dynamic-data-attr="{
            all: 'button-open-navigation-sidebar'
          }"
          :nav-is-open="!isInRail"
          @toggle-rail="handleClickIsInRail"
        />
      </VList>
    </template>
    <FNavigationContent
      :currently-open="currentlyOpen"
      :items-to-display="itemsToDisplay"
      @mouseleave="handleMouseLeave"
      @update:opened-nav="updateOpenedNav"
    />
    <template
      v-if="
        filteredSettingsMenuItems.length || filteredSettingStickyItems.length
      "
      #append
    >
      <FNavigationSettingsContent
        :currently-open="currentlyOpen"
        :show-settings-menu="showSettingsMenu"
        :items-to-display="filteredSettingStickyItems"
        @mouseleave="handleMouseLeave"
        @update:opened-nav="updateOpenedNav"
        @update:settings-menu="changeSettingsMenu"
      />
    </template>
  </VNavigationDrawer>
</template>

<script lang="ts" setup>
import { useNavigationRailStore } from '@/stores/navigationRail';
import { storeToRefs } from 'pinia';
import { useDisplay } from 'vuetify';
import FNavigationContent from '@/components/FNavigationContent.vue';
import type { MenuItem } from '@/components/FNavigation.types';
import { useNavigationStore } from '@/stores/navigation';
import { usePermissionsStore } from '@/modules/shared/core/stores/permissions';

const { mobile } = useDisplay();
const navigationRailStore = useNavigationRailStore();
const { isInRail, mobileNavOpen, webNavOpenByClick } =
  storeToRefs(navigationRailStore);

const railIsOpenByLockOrClick = computed(() => {
  return isInRail.value && !webNavOpenByClick.value;
});

const handleMouseLeave = () => {
  webNavOpenByClick.value = false;
};

const currentlyOpen = ref([]) as Ref<string[]>;
const keptOpenedRef = ref([]) as Ref<string[]>;

const updateOpenedNav = (event: string[]) => {
  keptOpenedRef.value = event;
  if (mobile.value) {
    currentlyOpen.value = keptOpenedRef.value;
  } else {
    if (webNavOpenByClick.value || !isInRail.value) {
      currentlyOpen.value = keptOpenedRef.value;
    }
  }
};
watch(isInRail, (value) => {
  currentlyOpen.value = !value ? keptOpenedRef.value : [];
  if (value && webNavOpenByClick.value) {
    webNavOpenByClick.value = false;
  }
});
watch(webNavOpenByClick, (value) => {
  if (!isInRail.value) return;
  currentlyOpen.value = value ? keptOpenedRef.value : [];
});

const {
  getSortedMenuItems,
  getSortedSettingItems,
  getSortedSettingStickyItems
} = storeToRefs(useNavigationStore());

const { canAccess } = usePermissionsStore();

const canAccessMenuItem = (item: MenuItem) => {
  return item.ability ? canAccess(item.ability) : true;
};

const parseMenuData = (menuData: MenuItem[]) => {
  const newMenuItems: MenuItem[] = [];
  if (!menuData) return newMenuItems;
  menuData.forEach((item) => {
    const clonedItem = { ...item };
    if (canAccessMenuItem(clonedItem)) {
      const filteredChildren: MenuItem[] = [];
      if (clonedItem.children) {
        clonedItem.children.forEach((child) => {
          if (canAccessMenuItem(child)) {
            filteredChildren.push(child);
          }
        });
        clonedItem.children = filteredChildren;
      }
      newMenuItems.push(clonedItem);
    }
  });
  return newMenuItems;
};

const filteredMenuItems = computed(() => {
  return parseMenuData(getSortedMenuItems.value);
});

const filteredSettingsMenuItems = computed(() => {
  return parseMenuData(getSortedSettingItems.value);
});

const filteredSettingStickyItems = computed(() => {
  return parseMenuData(getSortedSettingStickyItems.value);
});

const showSettingsMenu = ref(false);
const changeSettingsMenu = (value: boolean) => {
  showSettingsMenu.value = value;
};

const itemsToDisplay = computed(() => {
  return showSettingsMenu.value
    ? filteredSettingsMenuItems.value
    : filteredMenuItems.value;
});

const handleClickIsInRail = () => {
  isInRail.value = !isInRail.value;
};
</script>
