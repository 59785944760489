const BASE_ROUTE_TIME_MANAGEMENT = '/time-management';
const BASE_ROUTE_ABSENCES = `${BASE_ROUTE_TIME_MANAGEMENT}/absences`;
const BASE_ROUTE_VACATIONS = `${BASE_ROUTE_TIME_MANAGEMENT}/vacations`;
const BASE_ROUTE_OVERTIMES = `${BASE_ROUTE_TIME_MANAGEMENT}/overtimes`;
const BASE_ROUTE_TIMESHEETS = `${BASE_ROUTE_TIME_MANAGEMENT}/timesheet`;
const BASE_ROUTE_TIMESHEETS_CONFIG = `${BASE_ROUTE_TIMESHEETS}.configurations`;
const BASE_ROUTE_CALENDAR = `${BASE_ROUTE_TIME_MANAGEMENT}/calendar`;
const BASE_ROUTE_ABSENCES_SUMMARY = `${BASE_ROUTE_ABSENCES}.summary`;

const TIME_MANAGEMENT_ROUTES = {
  ROOT: BASE_ROUTE_TIME_MANAGEMENT,
  ABSENCE: {
    ROOT: BASE_ROUTE_ABSENCES,
    CONTROL: `${BASE_ROUTE_ABSENCES}/control`,
    EDIT: `${BASE_ROUTE_ABSENCES}/:id`,
    CREATE: `${BASE_ROUTE_ABSENCES}/create`,
    SUMMARY: {
      ROOT: `${BASE_ROUTE_ABSENCES_SUMMARY}`,
      ANNUAL: `${BASE_ROUTE_ABSENCES_SUMMARY}/annual`,
      WEEKLY: `${BASE_ROUTE_ABSENCES_SUMMARY}/weekly`
    }
  },
  VACATION: {
    ROOT: BASE_ROUTE_VACATIONS,
    CONTROL: `${BASE_ROUTE_VACATIONS}/control`,
    EDIT: `${BASE_ROUTE_VACATIONS}/:id`,
    CREATE: `${BASE_ROUTE_VACATIONS}/create`,
    SUMMARY: {
      ROOT: `${BASE_ROUTE_VACATIONS}.summary`
    }
  },
  OVERTIME: {
    ROOT: BASE_ROUTE_OVERTIMES,
    CONTROL: `${BASE_ROUTE_OVERTIMES}/control`,
    EDIT: `${BASE_ROUTE_OVERTIMES}/:id`,
    CREATE: `${BASE_ROUTE_OVERTIMES}/create`,
    SUMMARY: {
      ROOT: `${BASE_ROUTE_OVERTIMES}.summary`
    }
  },
  TIMESHEETS: {
    ROOT: BASE_ROUTE_TIMESHEETS,
    SUMMARY: {
      ROOT: `${BASE_ROUTE_TIMESHEETS}.summary`
    },
    CONFIG: {
      ROOT: BASE_ROUTE_TIMESHEETS_CONFIG,
      CALCULATION: `${BASE_ROUTE_TIMESHEETS_CONFIG}/calculations`,
      LEGEND: `${BASE_ROUTE_TIMESHEETS_CONFIG}/legend`,
      HOLIDAY_SETTINGS: `${BASE_ROUTE_TIMESHEETS_CONFIG}/holidays`,
      PROJECTS: `${BASE_ROUTE_TIMESHEETS_CONFIG}/projects`,
      PROJECT: `${BASE_ROUTE_TIMESHEETS_CONFIG}/projects/management`,
      PROJECT_TASKS: `${BASE_ROUTE_TIMESHEETS_CONFIG}/projects/tasks`,
      IMPORTATION: `${BASE_ROUTE_TIMESHEETS_CONFIG}/importation`
    }
  },
  CALENDAR: {
    ROOT: BASE_ROUTE_CALENDAR
  }
};

export default TIME_MANAGEMENT_ROUTES;
