import type CryptographyStrategyInterface from '@sdk/modules/core/interfaces/CryptographyStrategyInterface';

export default class WindowCryptographyStrategy
  implements CryptographyStrategyInterface
{
  constructor(private cryptoSubtle: Window['crypto']) {}

  digest(alg: string, payload: ArrayBuffer): Promise<ArrayBuffer> {
    return this.cryptoSubtle.subtle.digest(alg, payload);
  }
}
