import { useI18n } from 'vue-i18n';

export default function useMessage() {
  const { t } = useI18n();

  const getMessage = (
    message: { text: string; keys?: Record<string, unknown> } | string
  ) => {
    if (!message) return;
    return typeof message === 'string'
      ? message
      : message.keys === undefined
        ? t(message.text)
        : t(message.text, message.keys);
  };

  return {
    getMessage
  };
}
