<template>
  <router-view />
</template>
<script lang="ts" setup>
import EMPLOYEES_ABILITIES from '@/modules/employees/constants/abilities';

definePage({
  meta: {
    needAuth: true,
    ability: EMPLOYEES_ABILITIES.ANY
  }
});
</script>
<style lang="sass" scoped></style>
