import type { Program } from '@/modules/shared/core/utils/mapping/index.types';
import LEGACY_TYPE from '@/modules/shared/core/constants/legacyType';
import REPORTS_ABILITIES from '@/modules/reports/constants/abilities';
import REPORTS_ROUTES from '@/modules/reports/constants/urls';

const REPORT_MAPPING = [
  {
    name: REPORTS_ABILITIES.DASHBOARD.ANALYSIS,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 200000,
    module: REPORTS_ABILITIES.DASHBOARD.ANY,
    routeName: REPORTS_ROUTES.DASHBOARD.ANALYSIS
  },
  {
    name: REPORTS_ABILITIES.DASHBOARD.SUMMARY,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 200001,
    module: REPORTS_ABILITIES.DASHBOARD.ANY,
    routeName: REPORTS_ROUTES.DASHBOARD.SUMMARY
  },
  {
    name: REPORTS_ABILITIES.KPI,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 200005,
    module: REPORTS_ABILITIES.ANY,
    routeName: REPORTS_ROUTES.KPI
  }
] as Program[];

const DISCIPLINE_MAPPING = [
  {
    name: REPORTS_ABILITIES.DISCIPLINE.ANY,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 204020,
    module: REPORTS_ABILITIES.DISCIPLINE.ANY,
    routeName: REPORTS_ROUTES.DISCIPLINE.ROOT,
    iframeUrl: '/legacy/report-discipline/analysis'
  },
  {
    name: REPORTS_ABILITIES.DISCIPLINE.CONTROL,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 204030,
    module: REPORTS_ABILITIES.DISCIPLINE.ANY,
    routeName: REPORTS_ROUTES.DISCIPLINE.CONTROL,
    iframeUrl: '/legacy/report-discipline/control'
  },
  {
    name: REPORTS_ABILITIES.DISCIPLINE.CONTROL + ':edit',
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 204030,
    module: REPORTS_ABILITIES.DISCIPLINE.ANY,
    routeName: REPORTS_ROUTES.DISCIPLINE.EDIT,
    iframeUrl: '/legacy/report-discipline/:id/edit'
  }
] as Program[];

const REPORTS_MAPPING = [...REPORT_MAPPING, ...DISCIPLINE_MAPPING] as Program[];

export default REPORTS_MAPPING;
