<template>
  <VApp>
    <FSnackbar
      v-bind="bindings"
      @close="dismiss"
    />
    <FControlDialog
      v-if="isDialogShowed"
      :is-showed="isDialogShowed"
      :route="route"
      :route-ability="routeAbility"
      :title="title"
      :btn-text="btnText"
      :type="type"
      @dialog:update="fControlDialogStore.updateDialog($event)"
    />
    <FTopBarRequestSideSheet />
    <FTopBarToolsSideSheet />
    <FTopBar />
    <FNavigation />
    <VMain>
      <FGlobalErrorAlert />
      <VContainer
        class="pa-0 d-flex flex-column fill-height"
        fluid
      >
        <slot />
      </VContainer>
      <div id="bottom-sheet-form-save-teleport"></div>
    </VMain>
  </VApp>
</template>

<script lang="ts" setup>
import useMessageListener from '@/modules/shared/core/composables/PostMessage/useMessageListener';
import type { MessageEventData } from '@/modules/shared/core/objects/MessageEventData';
import { useFControlDialog } from '@/modules/shared/core/stores/fControlDialog';
import { useSnackbarStore } from '@/modules/shared/core/stores/snackbar';

const fControlDialogStore = useFControlDialog();
const { routeAbility, route, type, title, btnText, isDialogShowed } =
  storeToRefs(fControlDialogStore);

const snackbarStore = useSnackbarStore();
const { bindings } = storeToRefs(snackbarStore);
const { show, dismiss } = snackbarStore;

const { addHandler } = useMessageListener({ name: 'layout-default' });

addHandler('toast', (eventData: MessageEventData) => {
  show({ message: eventData.data.message });
});
</script>
