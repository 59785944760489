import { ref } from 'vue';

export default function useDialogLegacyIframe() {
  const isDialogShowed = ref(false);
  const showDialog = () => {
    isDialogShowed.value = true;
  };
  const updateDialog = (value: boolean) => {
    isDialogShowed.value = value;
  };
  return {
    isDialogShowed,
    showDialog,
    updateDialog
  };
}
