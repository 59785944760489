import type { AuthenticationServiceInterface } from '@sdk/modules/authentication/interfaces/AuthenticationServiceInterface';
import { AxiosError, type AxiosResponse } from 'axios';
import type { AuthenticationResponseInterface } from '@sdk/modules/authentication/interfaces/AuthenticationResponseInterface';
import { inject, injectable } from 'inversify';
import { AuthenticationSuccessfulResponse } from '@sdk/modules/authentication/objects/AuthenticationSuccessfulResponse';
import { API_BINDING_TYPES } from '@sdk/modules/API/FolksSDKAPIModuleBindTypes';
import type { APIProxyInterface } from '@sdk/modules/API/Interface/APIProxyInterface';
import type { AuthenticationSuccessfulResponsePayload } from '@sdk/modules/authentication/interfaces/types/responses/AuthenticationSuccessfulResponsePayload';
import { Authentication2faResponse } from '@sdk/modules/authentication/objects/Authentication2faResponse';
import {
  Authentication2faResponseCode,
  type Authentication2faResponsePayload
} from '@sdk/modules/authentication/interfaces/types/responses/Authentication2faResponsePayload';
import { AuthenticationErrorResponse } from '@sdk/modules/authentication/objects/AuthenticationErrorResponse';
import type { AuthenticationErrorResponsePayload } from '@sdk/modules/authentication/interfaces/types/responses/AuthenticationErrorResponsePayload';
import { MissingTwoFactorChallengeException } from '@sdk/modules/authentication/exceptions/MissingTwoFactorChallengeException';
import { InvalidTwoFactorChallengeException } from '@sdk/modules/authentication/exceptions/InvalidTwoFactorChallengeException';
import { InvalidTokenException } from '@sdk/modules/authentication/exceptions/InvalidTokenException';
import type { AuthenticationCredentialsStorageInterface } from '@sdk/modules/API/Interface/AuthenticationCredentialsStorageInterface';
import HTTP_STATUS_CODES from '@sdk/modules/API/Enums/httpStatusCodes';
import type { AuthenticationRefreshTokenMutexInterface } from '@sdk/modules/authentication/interfaces/AuthenticationRefreshTokenMutexInterface';
import type { AuthResponseSuccess } from '@sdk/modules/authentication/services/AuthenticationService.types';
import { AUTHENTICATION_BINDING_TYPES } from '@sdk/modules/authentication/FolksSDKAuthenticationModuleTypes';

@injectable()
export class AuthenticationService implements AuthenticationServiceInterface {
  static readonly BASE_URL = 'authorization';
  static readonly SPA = 'spa';

  private onRefreshTokenFailCallback: (error: AxiosError) => Promise<void>;

  constructor(
    @inject(API_BINDING_TYPES.APIProxyInterface)
    protected apiProxy: APIProxyInterface,
    @inject(API_BINDING_TYPES.AuthenticationCredentialsStorage)
    protected credentialsStorage: AuthenticationCredentialsStorageInterface,
    @inject(
      AUTHENTICATION_BINDING_TYPES.AuthenticationRefreshTokenMutexInterface
    )
    protected refreshTokenMutex: AuthenticationRefreshTokenMutexInterface
  ) {
    this.onRefreshTokenFailCallback = async (error: AxiosError) => {
      console.log(
        'onRefreshTokenFail was not defined, yet there was an error: ',
        error
      );

      throw new AuthenticationErrorResponse(
        error.response?.data as AuthenticationErrorResponsePayload
      );
    };
  }

  async authenticate(
    email: string,
    password: string,
    authenticationCode?: string | null
  ): Promise<AuthenticationResponseInterface> {
    try {
      const payload: {
        email: string;
        password: string;
        with_legacy_session_token: boolean;
        two_factor_challenge_response?: string;
      } = {
        email,
        password,
        with_legacy_session_token: true
      };

      if (authenticationCode) {
        payload['two_factor_challenge_response'] = authenticationCode;
      }

      const axiosResponse = await this.apiProxy.post<
        | AuthenticationSuccessfulResponsePayload
        | Authentication2faResponsePayload
      >(`${AuthenticationService.BASE_URL}/give`, payload, {
        withBearerAuth: false,
        withErrorHandling: false
      });

      if (this.isAwaitingTwoFactorResponse(axiosResponse)) {
        return new Authentication2faResponse(
          axiosResponse.data as Authentication2faResponsePayload
        );
      }

      const responseData =
        axiosResponse.data as AuthenticationSuccessfulResponsePayload;

      await this.credentialsStorage.save({
        accessToken: responseData.access_token,
        refreshToken: responseData.refresh_token
      });

      return new AuthenticationSuccessfulResponse(responseData);
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response &&
        error.response.status === HTTP_STATUS_CODES.Unauthorized
      ) {
        throw this.handleAuthenticationError(error);
      }

      throw error;
    }
  }

  async forgot(email: string): Promise<AuthenticationResponseInterface> {
    try {
      const payload: {
        email: string;
      } = {
        email
      };
      const axiosResponse = await this.apiProxy.post<
        | AuthenticationSuccessfulResponsePayload
        | Authentication2faResponsePayload
      >(`spa/password/forgot`, payload, {
        withBearerAuth: false,
        withErrorHandling: false,
        baseURL: import.meta.env.VITE_APP_API_URL_ORIGIN + '/api/'
      });

      const responseData =
        axiosResponse.data as AuthenticationSuccessfulResponsePayload;

      await this.credentialsStorage.save({
        accessToken: responseData.access_token,
        refreshToken: responseData.refresh_token
      });

      return new AuthenticationSuccessfulResponse(responseData);
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response &&
        error.response.status === HTTP_STATUS_CODES.Unauthorized
      ) {
        throw this.handleAuthenticationError(error);
      }

      throw error;
    }
  }

  async verifyToken(token: string): Promise<AuthenticationResponseInterface> {
    try {
      const payload: {
        token: string;
      } = {
        token
      };
      const axiosResponse = await this.apiProxy.post<
        | AuthenticationSuccessfulResponsePayload
        | Authentication2faResponsePayload
      >(`spa/password/verify/token`, payload, {
        withBearerAuth: false,
        withErrorHandling: false,
        baseURL: import.meta.env.VITE_APP_API_URL_ORIGIN + '/api/'
      });

      const responseData =
        axiosResponse.data as AuthenticationSuccessfulResponsePayload;

      await this.credentialsStorage.save({
        accessToken: responseData.access_token,
        refreshToken: responseData.refresh_token
      });

      return new AuthenticationSuccessfulResponse(responseData);
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response &&
        error.response.status === HTTP_STATUS_CODES.Unauthorized
      ) {
        throw this.handleAuthenticationError(error);
      }

      throw error;
    }
  }

  async resetPassword(
    email: string,
    token: string,
    password: string,
    password_confirmation: string
  ): Promise<AuthenticationResponseInterface> {
    try {
      const payload: {
        email: string;
        token: string;
        password: string;
        password_confirmation: string;
      } = {
        email,
        token,
        password,
        password_confirmation
      };
      const axiosResponse = await this.apiProxy.post<
        | AuthenticationSuccessfulResponsePayload
        | Authentication2faResponsePayload
      >(`spa/password/reset`, payload, {
        withBearerAuth: false,
        withErrorHandling: false,
        baseURL: import.meta.env.VITE_APP_API_URL_ORIGIN + '/api/'
      });

      const responseData =
        axiosResponse.data as AuthenticationSuccessfulResponsePayload;

      await this.credentialsStorage.save({
        accessToken: responseData.access_token,
        refreshToken: responseData.refresh_token
      });

      return new AuthenticationSuccessfulResponse(responseData);
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response &&
        error.response.status === HTTP_STATUS_CODES.Unauthorized
      ) {
        throw this.handleAuthenticationError(error);
      }

      throw error;
    }
  }

  async resendChallenge(
    email: string,
    password: string
  ): Promise<AuthenticationResponseInterface> {
    try {
      const axiosResponse = await this.apiProxy.post(
        `${AuthenticationService.BASE_URL}/challenge/resend`,
        {
          email,
          password
        },
        { withBearerAuth: false, withErrorHandling: false }
      );

      return new Authentication2faResponse(
        axiosResponse.data as Authentication2faResponsePayload
      );
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response &&
        error.response.status === HTTP_STATUS_CODES.Unauthorized
      ) {
        throw new AuthenticationErrorResponse(
          error.response.data as AuthenticationErrorResponsePayload
        );
      }

      throw error;
    }
  }

  async refresh(): Promise<AuthResponseSuccess> {
    if (this.refreshTokenMutex.isLocked()) {
      return this.refreshTokenMutex.waitForUnlock();
    }

    return this.refreshTokenMutex.runExclusive(async () => {
      return this.makeRefreshTokenCall();
    });
  }

  async logout(): Promise<AxiosResponse> {
    return Promise.resolve({} as AxiosResponse);
  }

  private isAwaitingTwoFactorResponse(response: AxiosResponse) {
    return (
      response.status === HTTP_STATUS_CODES.Accepted &&
      'code' in response.data &&
      response.data.code === Authentication2faResponseCode
    );
  }

  private handleAuthenticationError(
    error: AxiosError<AuthenticationErrorResponsePayload>
  ) {
    const errorCode = error.response?.data.errors?.error;

    if (errorCode === 'invalid_request') {
      return new InvalidTokenException();
    }

    if (errorCode === 'missing_two_factor_challenge') {
      return new MissingTwoFactorChallengeException();
    }

    if (errorCode === 'invalid_two_factor_token') {
      return new InvalidTwoFactorChallengeException();
    }

    return new AuthenticationErrorResponse(
      error.response?.data as AuthenticationErrorResponsePayload
    );
  }

  private async makeRefreshTokenCall(): Promise<
    AuthenticationResponseInterface<AuthenticationSuccessfulResponsePayload>
  > {
    try {
      const refreshToken = await this.credentialsStorage.get('refreshToken');

      const payload: {
        refresh_token: string;
        with_legacy_session_token: true;
      } = {
        refresh_token: refreshToken,
        with_legacy_session_token: true
      };

      const axiosResponse =
        await this.apiProxy.post<AuthenticationSuccessfulResponsePayload>(
          `${AuthenticationService.BASE_URL}/refresh`,
          payload,
          { withBearerAuth: false, withErrorHandling: false }
        );

      const responseData =
        axiosResponse.data as AuthenticationSuccessfulResponsePayload;

      await this.credentialsStorage.save({
        accessToken: responseData.access_token,
        refreshToken: responseData.refresh_token
      });

      return new AuthenticationSuccessfulResponse(responseData);
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response &&
        [HTTP_STATUS_CODES.Unauthorized, HTTP_STATUS_CODES.BadRequest].includes(
          error.response.status
        )
      ) {
        await this.onRefreshTokenFailCallback(error);
      }

      throw error;
    }
  }

  onRefreshTokenFail(callback: (error: AxiosError) => Promise<void>): void {
    this.onRefreshTokenFailCallback = callback;
  }
}
