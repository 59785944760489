import { logout as authLogout } from '../services/authentification';
import { defineStore } from 'pinia';
import localStorageWithExpiration from '@/modules/shared/core/utils/localStorageWithExpiration';
import router from '@/plugins/vueRouter';
import type { AuthenticationSuccessfulResponsePayload } from '@sdk/modules/authentication/interfaces/types/responses/AuthenticationSuccessfulResponsePayload';
import useSDKContainer from '@/modules/shared/core/composables/useSDKContainer';
import type { AuthenticationServiceInterface } from '@sdk/modules/authentication/interfaces/AuthenticationServiceInterface';
import { AUTHENTICATION_BINDING_TYPES } from '@sdk/modules/authentication/FolksSDKAuthenticationModuleTypes';
import proxy from '@/modules/shared/core/utils/apiFolks/proxy';
import { AuthenticationService } from '@sdk/modules/authentication';
import AUTHENTICATION_ROUTES from '@/modules/authentication/constants/urls';
import type { SSOConfiguration } from '@sdk/modules/authentication/interfaces/types/SSOConfiguration';
import type SSOAuthenticationServiceInterface from '@sdk/modules/authentication/interfaces/sso/SSOAuthenticationServiceInterface';
import { AuthenticationErrorResponse } from '@sdk/modules/authentication/objects/AuthenticationErrorResponse';
import type { RouteLocationRaw } from 'vue-router';

const SSO_STORAGE_KEY = 'sso_configuration';

export const useAuthenticationStore = defineStore('authentication', {
  state: () => ({
    authData: {
      access_token: '',
      session_token: '',
      refresh_token: '',
      expires_in: 0
    } as AuthenticationSuccessfulResponsePayload,
    sso: null as SSOConfiguration | null
  }),
  actions: {
    async login(
      email: string,
      password: string,
      authenticationCode: string | null = null
    ) {
      const { resolve } = useSDKContainer();
      const authenticationService = resolve<AuthenticationServiceInterface>(
        AUTHENTICATION_BINDING_TYPES.AuthenticationServiceInterface
      );

      return await authenticationService.authenticate(
        email,
        password,
        authenticationCode
      );
    },
    async saveCredentials(
      authenticationPayload: AuthenticationSuccessfulResponsePayload
    ) {
      this.authData = authenticationPayload;
      localStorageWithExpiration.setItem(
        'authData',
        this.authData,
        this.authData.expires_in * 1000
      );

      // todo-ndamours: remove when we only use SDK's axios.
      proxy.defaults.headers['Authorization'] =
        `Bearer ${this.authData.access_token}`;
    },
    async resend(email: string, password: string) {
      const { resolve } = useSDKContainer();
      const authenticationService = resolve<AuthenticationServiceInterface>(
        AUTHENTICATION_BINDING_TYPES.AuthenticationServiceInterface
      );

      return await authenticationService.resendChallenge(email, password);
    },
    async refreshToken() {
      const { resolve } = useSDKContainer();
      const authenticationService = resolve<AuthenticationService>(
        AUTHENTICATION_BINDING_TYPES.AuthenticationServiceInterface
      );

      const response = await authenticationService.refresh();
      this.authData = response.getPayload();

      localStorageWithExpiration.setItem(
        'authData',
        this.authData,
        this.authData.expires_in * 1000
      );
    },
    getFromLocalStorage() {
      const authData = localStorageWithExpiration.getItem('authData');
      const sso = localStorage.getItem(SSO_STORAGE_KEY);
      if (sso) {
        this.sso = JSON.parse(sso);
      }
      if (authData) {
        this.authData = authData;
      }
    },
    clear() {
      this.authData = {
        access_token: '',
        session_token: '',
        refresh_token: '',
        expires_in: 0
      };
      localStorageWithExpiration.removeItem('authData');
    },
    async logout() {
      await authLogout();
      this.authData = {
        access_token: '',
        session_token: '',
        refresh_token: '',
        expires_in: 0
      };
      localStorageWithExpiration.removeItem('authData');
      await router.push({
        name: AUTHENTICATION_ROUTES.LOGIN
      } as RouteLocationRaw);
    },
    async forgot(email: string) {
      const { resolve } = useSDKContainer();
      const authenticationService = resolve<AuthenticationServiceInterface>(
        AUTHENTICATION_BINDING_TYPES.AuthenticationServiceInterface
      );

      return await authenticationService.forgot(email);
    },
    async verifyToken(token: string) {
      const { resolve } = useSDKContainer();
      const authenticationService = resolve<AuthenticationServiceInterface>(
        AUTHENTICATION_BINDING_TYPES.AuthenticationServiceInterface
      );

      return await authenticationService.verifyToken(token);
    },
    async resetPassword(
      email: string,
      token: string,
      password: string,
      password_confirmation: string
    ) {
      const { resolve } = useSDKContainer();
      const authenticationService = resolve<AuthenticationServiceInterface>(
        AUTHENTICATION_BINDING_TYPES.AuthenticationServiceInterface
      );

      return await authenticationService.resetPassword(
        email,
        token,
        password,
        password_confirmation
      );
    },
    async initiateSSO(ssoCode: string, persist: boolean = false) {
      const { resolve } = useSDKContainer();

      const ssoAuthenticationService =
        resolve<SSOAuthenticationServiceInterface>(
          AUTHENTICATION_BINDING_TYPES.SSOAuthenticationServiceInterface
        );

      const ssoResponse = await ssoAuthenticationService.init(ssoCode);
      if (persist) {
        this.sso = {
          code: ssoCode,
          type: ssoResponse.type,
          custom_name: ssoResponse.custom_name,
          custom_image: ssoResponse.custom_image
        };
        this.saveSSOToLocalStorage();
      }

      return ssoResponse.redirect_url;
    },
    async completeSSO(authorizationCode: string, authorizationState: string) {
      try {
        const { resolve } = useSDKContainer();

        const ssoAuthenticationService =
          resolve<SSOAuthenticationServiceInterface>(
            AUTHENTICATION_BINDING_TYPES.SSOAuthenticationServiceInterface
          );
        const response = await ssoAuthenticationService.complete(
          authorizationCode,
          authorizationState
        );

        await this.saveCredentials(response.getPayload());
      } catch (error) {
        if (error instanceof AuthenticationErrorResponse) {
          throw new Error(error.getPayload().message);
        }

        throw error;
      }
    },
    saveSSOToLocalStorage() {
      if (this.sso) {
        localStorage.setItem(SSO_STORAGE_KEY, JSON.stringify(this.sso));
      }
    },
    loadSSOFromLocalStorage() {
      const storedSSO = localStorage.getItem(SSO_STORAGE_KEY);
      if (storedSSO) {
        try {
          this.sso = JSON.parse(storedSSO);
        } catch (e) {
          console.error('Failed to parse SSO config from localStorage');
        }
      }
    },
    clearSSO() {
      this.sso = null;
      localStorage.removeItem(SSO_STORAGE_KEY);
    }
  }
});
