<template>
  <router-view />
</template>
<script lang="ts" setup>
import PARAMETERS_ABILITIES_ROOT from '@/modules/parameters/constants/abilities';

definePage({
  meta: {
    needAuth: true,
    ability: PARAMETERS_ABILITIES_ROOT
  }
});
</script>
<style lang="sass" scoped></style>
