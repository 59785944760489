<template>
  <FHeaderPrimary
    :back-route="USERS_ROUTES.ROOT"
    :title="t('title')"
  />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import USERS_ABILITIES from '@/modules/parameters/submodules/users/constants/abilities';
import USERS_ROUTES from '@/modules/parameters/submodules/users/constants/urls';

definePage({
  meta: {
    ability: USERS_ABILITIES.EDIT
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Modification un utilisateur'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Edit user'
    }
  }
});
</script>
