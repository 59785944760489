import axios, { type AxiosRequestConfig } from 'axios';
import SETTINGS from '@/modules/shared/core/constants/settings';
import {
  interceptorError,
  interceptorResponse
} from '@/modules/shared/core/utils/apiFolks/axiosInterceptors';
import localStorageWithExpiration from '@/modules/shared/core/utils/localStorageWithExpiration';

const authData = localStorageWithExpiration.getItem('authData');
const axiosConfig: AxiosRequestConfig = {
  baseURL: SETTINGS.apiBaseURL,
  withCredentials: true,
  headers: {
    Accept: 'application/json;charset=UTF-8',
    'Content-Type': 'application/json;charset=UTF-8'
  },
  timeout: 60000
};
if (authData && axiosConfig.headers) {
  axiosConfig.headers.Authorization = `Bearer ${authData.access_token}`;
}
const proxy = axios.create(axiosConfig);

proxy.interceptors.response.use(interceptorResponse, interceptorError);

export default proxy;
