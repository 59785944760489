import type { FolksSDKModuleInterface } from '@sdk/interfaces/FolksSDKModuleInterface';
import { ContainerModule } from 'inversify';
import type { AuthenticationServiceInterface } from '@sdk/modules/authentication/interfaces/AuthenticationServiceInterface';
import { AUTHENTICATION_BINDING_TYPES } from '@sdk/modules/authentication/FolksSDKAuthenticationModuleTypes';
import { AuthenticationService } from '@sdk/modules/authentication/services/AuthenticationService';
import type SSOAuthenticationServiceInterface from '@sdk/modules/authentication/interfaces/sso/SSOAuthenticationServiceInterface';
import SSOAuthenticationService from '@sdk/modules/authentication/services/sso/SSOAuthenticationService';
import type AuthenticationCodeFactoryInterface from '@sdk/modules/authentication/interfaces/AuthenticationCodeFactoryInterface';
import { AuthenticationCodeFactory } from '@sdk/modules/authentication/factories/AuthenticationCodeFactory';
import type SSOAuthenticationStateRepositoryInterface from './interfaces/sso/SSOAuthenticationStateRepositoryInterface';
import SSOAuthenticationStateRepository from '@sdk/modules/authentication/repositories/SSOAuthenticationStateRepository';
import { APIProxy } from '@sdk/modules/API/proxies/APIProxy';
import { API_BINDING_TYPES } from '@sdk/modules/API';
import type SSOConfigurationServiceInterface from '@sdk/modules/authentication/interfaces/sso/SSOConfigurationServiceInterface';
import SSOConfigurationService from '@sdk/modules/authentication/services/sso/SSOConfigurationService';
import type { AuthenticationRefreshTokenMutexInterface } from '@sdk/modules/authentication/interfaces/AuthenticationRefreshTokenMutexInterface';
import { AuthenticationRefreshTokenMutex } from '@sdk/modules/authentication/services/AuthenticationRefreshTokenMutex';
import UnauthenticatedErrorHandler from '@sdk/modules/authentication/handlers/unauthenticatedErrorHandler';

export class FolksSDKAuthenticationModule implements FolksSDKModuleInterface {
  getContainerModule(): ContainerModule {
    return new ContainerModule(
      (bind, unbind, isBound, rebind, unbindAsync, onActivation) => {
        bind<AuthenticationRefreshTokenMutexInterface>(
          AUTHENTICATION_BINDING_TYPES.AuthenticationRefreshTokenMutexInterface
        )
          .to(AuthenticationRefreshTokenMutex)
          .inSingletonScope();

        bind<AuthenticationServiceInterface>(
          AUTHENTICATION_BINDING_TYPES.AuthenticationServiceInterface
        )
          .to(AuthenticationService)
          .inSingletonScope();

        bind<AuthenticationCodeFactoryInterface>(
          AUTHENTICATION_BINDING_TYPES.AuthenticationCodeFactoryInterface
        ).to(AuthenticationCodeFactory);

        // sso specific bindings
        bind<SSOAuthenticationServiceInterface>(
          AUTHENTICATION_BINDING_TYPES.SSOAuthenticationServiceInterface
        ).to(SSOAuthenticationService);
        bind<SSOAuthenticationStateRepositoryInterface>(
          AUTHENTICATION_BINDING_TYPES.SSOAuthenticationStateRepositoryInterface
        ).to(SSOAuthenticationStateRepository);
        bind<SSOConfigurationServiceInterface>(
          AUTHENTICATION_BINDING_TYPES.SSOConfigurationServiceInterface
        ).to(SSOConfigurationService);

        // add an error handler to the APIProxy to handle refreshing the token
        onActivation<APIProxy>(
          API_BINDING_TYPES.APIProxyInterface,
          (context, apiProxy) => {
            apiProxy.addErrorHandler(UnauthenticatedErrorHandler, 401);

            return apiProxy;
          }
        );
      }
    );
  }
}
