import type StorageStrategyInterface from '@sdk/modules/core/interfaces/StorageStrategyInterface';
export default class LocalStorageStrategy
  implements StorageStrategyInterface<string>
{
  constructor(private readonly localStorage: Window['localStorage']) {}
  async setItem(key: string, value: string): Promise<void> {
    this.localStorage.setItem(key, value);
  }
  async getItem(key: string): Promise<string | null> {
    return this.localStorage.getItem(key);
  }
  async hasItem(key: string): Promise<boolean> {
    return (await this.getItem(key)) !== null;
  }
}
