<template>
  <div>
    <VNavigationDrawer
      :model-value="isOpened"
      class="f-side-sheet"
      color="surface"
      disable-resize-watcher
      location="right"
      mobile
      width="320"
      @update:model-value="emit('update:isOpened', $event)"
    >
      <VToolbar color="transparent">
        <VToolbarTitle class="text-title-large">{{ title }}</VToolbarTitle>
        <template #append>
          <VBtn
            v-dynamic-data-attr="{ all: 'f-top-bar-side-sheet_close-action' }"
            :icon="true"
            color="primary"
            variant="text"
            @click="emit('update:isOpened', false)"
          >
            <FIconMD icon="close" />
          </VBtn>
        </template>
      </VToolbar>
      <VForm>
        <VContainer class="px-6 py-0">
          <VRow no-gutters>
            <VCol cols="12">
              <p class="text-body-medium">{{ description }}</p>
            </VCol>
          </VRow>
          <VRow no-gutters>
            <VCol cols="12">
              <FTopBarSideSheetItem
                :items="items"
                :translate="translate"
                @click:item="
                  emit('update:isOpened', false);
                  emit('click:item', $event);
                "
              />
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VNavigationDrawer>
  </div>
</template>

<script lang="ts" setup>
import type { TopBarSideSheetItem } from '@/components/FTopBarSideSheet.types';

defineProps({
  title: {
    type: String,
    required: true
  },
  description: {
    type: String,
    required: true
  },
  translate: {
    type: Function,
    required: true
  },
  items: {
    type: Array as () => TopBarSideSheetItem[],
    required: true
  },
  isOpened: {
    type: Boolean,
    required: true
  }
});

const emit = defineEmits(['update:isOpened', 'click:item']);
</script>
<style lang="sass" scoped></style>
