import proxy from '@/modules/shared/core/utils/apiFolks/proxy';
import type {
  ApiEmployee,
  Employee
} from '@/modules/shared/core/services/employees.types';
import { apiEmployeeToEmployee } from '@/modules/shared/core/services/employeesEntity';
import type { ApiListParams } from '@/modules/shared/core/utils/apiFolks/api.types';
import type { ApiListMeta } from '@/modules/shared/core/services/api.types';
import UrlUtils from '@/modules/shared/core/utils/apiFolks/url';

const baseEndpoint = `spa/employees`;

export async function get(
  {
    sortBy,
    search,
    page,
    itemsPerPage,
    quickFilters,
    searchableKeys
  }: ApiListParams,
  embeddedResources: string[],
  additionalParams: any = {}
): Promise<{
  data: Employee[];
  meta: ApiListMeta;
}> {
  const urlParams: any = {};
  const firstSortBy = sortBy && sortBy[0] ? sortBy[0] : null;
  if (firstSortBy) {
    urlParams.sortBy = firstSortBy.key;
    urlParams.order = firstSortBy.order;
  }
  if (search) {
    urlParams.all = search;
  }
  if (itemsPerPage) {
    urlParams.limit = itemsPerPage;
  }
  if (page) {
    urlParams.page = page;
  }
  if (quickFilters.length > 0) {
    quickFilters.forEach((filter) => {
      urlParams[filter] = 1;
    });
  }

  let outputUrl: string = UrlUtils.appendQueryParamsString(
    baseEndpoint,
    UrlUtils.buildEmbeddedResourcesString(embeddedResources)
  );

  outputUrl = UrlUtils.appendQueryParamsString(
    outputUrl,
    UrlUtils.buildSearchColumnsResourcesString(searchableKeys)
  );

  outputUrl = UrlUtils.appendQueryParamsString(
    outputUrl,
    UrlUtils.buildQueryParametersString({ ...urlParams, ...additionalParams })
  );

  const { data: response } = await proxy.get(outputUrl);
  const itemConverted = response.data.map((employee: ApiEmployee.Base) => {
    return apiEmployeeToEmployee(employee);
  });

  return {
    data: itemConverted,
    meta: response.meta as ApiListMeta
  };
}
