import {
  AUTHENTICATION_BINDING_TYPES,
  AuthenticationService
} from '@sdk/modules/authentication';
import { FolksSDK } from '@sdk/FolksSDK';

const UnauthenticatedErrorHandler = async (): Promise<boolean> => {
  const authenticationService =
    FolksSDK.getContainer().get<AuthenticationService>(
      AUTHENTICATION_BINDING_TYPES.AuthenticationServiceInterface
    );

  await authenticationService.refresh();

  return true;
};

export default UnauthenticatedErrorHandler;
