import type { EmployeeNode } from '@/modules/shared/core/services/employees.types';
import type { LocalizedData } from '@/modules/shared/core/services/api.types';
import type { SupportedLanguageKeys } from '@/modules/shared/core/constants/languages.types';

export function getFullName(employeeData: EmployeeNode | null): string {
  return employeeData && employeeData.firstName && employeeData.lastName
    ? getFullNameSimple(employeeData.firstName, employeeData.lastName)
    : '';
}

export function getFullNameSimple(
  firstName?: string,
  lastName?: string
): string {
  if (!firstName || !lastName) {
    return '';
  }
  return `${firstName} ${lastName}`;
}

export function getLocalizedData(data: LocalizedData | null): string {
  const { locale } = useI18n();
  const localizedKey = locale.value as SupportedLanguageKeys;
  return data && localizedKey in data ? data[localizedKey] : '';
}

/**
 * Converts an array of strings into a comma-separated string for URL usage
 * @param arrayValue - Array of strings to be joined
 * @returns A string with array values separated by commas and spaces
 */
export function arrayToUrlValues(arrayValue: string[]): string {
  return arrayValue.map((value) => value.trim()).join(',');
}

/**
 * Converts a comma-separated string into an array of strings
 * @param stringValue - String containing comma-separated values
 * @returns An array of strings with whitespace trimmed from each value
 */
export function urlValuesToArray(stringValue: string): string[] {
  return stringValue.split(',').map((value) => value.trim());
}

const prefixEmployeeCode = '#';

export function getEmployeeCode(code: string): string {
  return code ? `${prefixEmployeeCode}${code}` : '';
}

export function getPhone(number: string, ext?: string): string {
  if (!number) {
    return '';
  }
  return ext ? `${number} ext. ${ext}` : number;
}
