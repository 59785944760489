import type { Program } from '@/modules/shared/core/utils/mapping/index.types';
import LEGACY_TYPE from '@/modules/shared/core/constants/legacyType';
import DOCUMENTS_ABILITIES from '@/modules/documents/constants/abilities';
import DOCUMENTS_ROUTES from '@/modules/documents/constants/urls';

const VAULT_MAPPING = [
  {
    name: DOCUMENTS_ABILITIES.VAULT.ANY,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 700000,
    module: DOCUMENTS_ABILITIES.ANY,
    routeName: DOCUMENTS_ROUTES.VAULT.ROOT,
    iframeUrl: '/legacy/my-portal/'
  },
  {
    name: DOCUMENTS_ABILITIES.VAULT.CONFIG.ANY,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 506100,
    module: DOCUMENTS_ABILITIES.ANY,
    routeName: DOCUMENTS_ROUTES.VAULT.CONFIG.ROOT,
    iframeUrl: '/legacy/admin/portal/'
  }
] as Program[];

const E_SIGN_MAPPING = [
  {
    name: DOCUMENTS_ABILITIES.E_SIGN.ANY,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 900000,
    module: DOCUMENTS_ABILITIES.E_SIGN.ANY,
    routeName: DOCUMENTS_ROUTES.E_SIGN.ROOT,
    iframeUrl: '/e-sign/documents'
  },
  {
    name: DOCUMENTS_ABILITIES.E_SIGN.CONFIG.ANY,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 900001,
    module: DOCUMENTS_ABILITIES.E_SIGN.ANY,
    routeName: DOCUMENTS_ROUTES.E_SIGN.CONFIG.ROOT,
    iframeUrl: '/e-sign/templates'
  }
];

const DOCUMENTS_MAPPING = [...VAULT_MAPPING, ...E_SIGN_MAPPING] as Program[];

export default DOCUMENTS_MAPPING;
