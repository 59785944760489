import type AuthenticationCodeFactoryInterface from '@sdk/modules/authentication/interfaces/AuthenticationCodeFactoryInterface';
import { inject, injectable } from 'inversify';
import { CORE_TYPES } from '@sdk/modules/core/FolksSDKCoreModuleTypes';
import type CryptographyStrategyInterface from '@sdk/modules/core/interfaces/CryptographyStrategyInterface';

@injectable()
export class AuthenticationCodeFactory
  implements AuthenticationCodeFactoryInterface
{
  constructor(
    @inject(CORE_TYPES.CryptographyStrategyInterface)
    private cryptoStrategy: CryptographyStrategyInterface
  ) {}

  async getCodeChallenge(codeVerifier: string): Promise<string> {
    const verifierBytes = new TextEncoder().encode(codeVerifier);

    const resultingBytes = await this.cryptoStrategy.digest(
      'SHA-256',
      verifierBytes
    );
    const resultingString = String.fromCharCode(
      ...new Uint8Array(resultingBytes)
    );

    return btoa(resultingString)
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  }

  getCodeVerifier(): string {
    const min = 43;
    const max = 128;
    const length = Math.floor(Math.random() * (max - min + 1) + min);

    return this.getRandomString(length);
  }

  getState(): string {
    return this.getRandomString(40);
  }

  private getRandomString(length: number): string {
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789-._~';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }
}
