<template>
  <FHeaderPrimary
    :add-summary-ability="TIME_MANAGEMENT_ABILITIES.VACATION.SUMMARY.ANY"
    :add-summary-route="TIME_MANAGEMENT_ROUTES.VACATION.SUMMARY.ROOT"
    :add-summary-text="t('summary')"
    :add-ability="TIME_MANAGEMENT_ABILITIES.VACATION.CONTROL"
    :title="t('title')"
    @add:click="handleAddClick"
  />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import TIME_MANAGEMENT_ABILITIES from '@/modules/time-management/constants/abilities';
import TIME_MANAGEMENT_ROUTES from '@/modules/time-management/constants/urls';
import CONTROL_TYPES from '@/modules/shared/core/constants/controlTypes';

definePage({
  meta: {
    ability: TIME_MANAGEMENT_ABILITIES.VACATION.LIST,
    bypassAbility: [
      TIME_MANAGEMENT_ABILITIES.VACATION.CONTROL,
      TIME_MANAGEMENT_ABILITIES.VACATION.SUMMARY.ANY
    ]
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Vacances',
      summary: 'Voir le sommaire'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Vacations',
      summary: 'View summary'
    }
  }
});

const { handleAddClick } = useTimeManagementList(CONTROL_TYPES.VACATION);
</script>
<style lang="sass" scoped></style>
