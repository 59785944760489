<template>
  <FHeaderPrimary
    :tabs="tabs"
    :back-route="backRoute"
    :title="t('title')"
  />
  <FLayoutContentForTabs />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import EMPLOYEES_ROUTES from '@/modules/employees/constants/urls';
import EMPLOYEES_ABILITIES from '@/modules/employees/constants/abilities';
import type { Tab } from '@/components/FTabs.types';

definePage({
  meta: {
    ability: EMPLOYEES_ABILITIES.CONFIGURATION.PROFIL_CUSTOMIZATION
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Configuration du profil des employés',
      profileCustomization: 'Personnalisation du profil'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Employees profile configuration',
      profileCustomization: 'Profile customization'
    }
  }
});

const tabs = [
  {
    to: EMPLOYEES_ROUTES.CONFIGURATION.PROFIL_CUSTOMIZATION,
    ability: EMPLOYEES_ABILITIES.CONFIGURATION.PROFIL_CUSTOMIZATION,
    title: t('profileCustomization')
  }
] as Tab[];

const backRoute = EMPLOYEES_ROUTES.LIST;

useFirstTabRedirect(EMPLOYEES_ROUTES.CONFIGURATION.ROOT, tabs);
</script>
