import type { FSnackbarProps } from '@/modules/shared/core/components/FSnackbar.types';
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

export const useSnackbarStore = defineStore('snackbarStore', () => {
  const isVisible = ref(false);
  const text = ref<
    | string
    | {
        text: string;
        keys?: Record<string, unknown>;
      }
  >('');
  const timeout = ref(3500);
  const multiline = ref(false);
  const vertical = ref(false);
  const closable = ref(true);

  const show = (options: Omit<FSnackbarProps, 'isVisible'>): void => {
    text.value = options.message ?? '';
    if (options.timeout !== undefined) timeout.value = options.timeout;
    if (options.multiline !== undefined) multiline.value = options.multiline;
    if (options.vertical !== undefined) vertical.value = options.vertical;
    if (options.closable !== undefined) closable.value = options.closable;
    isVisible.value = true;
  };

  const dismiss = (): void => {
    isVisible.value = false;
  };

  const bindings = computed(() => ({
    isVisible: isVisible.value,
    message: text.value,
    timeout: timeout.value,
    multiline: multiline.value,
    vertical: vertical.value,
    closable: closable.value
  }));

  return {
    show,
    dismiss,
    bindings
  };
});
