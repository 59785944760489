import type {
  ApiEmployee,
  EmergencyContact,
  Employee,
  EmployeeCorporate,
  EmployeeNode,
  EmployeePersonal
} from '@/modules/shared/core/services/employees.types';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import { getUnixTime } from 'date-fns';

export function apiEmployeeToEmployee(employee: ApiEmployee.Base): Employee {
  let supervisor: Employee['supervisor'] = null;
  if (employee.supervisor) {
    let supervisor_position: EmployeeNode['position'] = null;
    if (employee.supervisor.position) {
      supervisor_position = {
        [SUPPORTED_LANGUAGES.fr]: employee.supervisor.position.description_fr,
        [SUPPORTED_LANGUAGES.en]: employee.supervisor.position.description_en
      };
    }
    supervisor = {
      id: employee.supervisor.id,
      code: employee.supervisor.code,
      lastName: employee.supervisor.last_name,
      firstName: employee.supervisor.first_name,
      image: employee.supervisor.image,
      active: employee.supervisor.active,
      position: supervisor_position
    };
  }
  let department: Employee['department'] = null;
  if (employee.department) {
    department = {
      [SUPPORTED_LANGUAGES.fr]: employee.department.description_fr,
      [SUPPORTED_LANGUAGES.en]: employee.department.description_en
    };
  }

  let workingSite: Employee['workingSite'] = null;
  if (employee.working_site) {
    workingSite = {
      [SUPPORTED_LANGUAGES.fr]: employee.working_site.description_fr,
      [SUPPORTED_LANGUAGES.en]: employee.working_site.description_en
    };
  }
  let position: Employee['position'] = null;
  if (employee.position) {
    position = {
      [SUPPORTED_LANGUAGES.fr]: employee.position.description_fr,
      [SUPPORTED_LANGUAGES.en]: employee.position.description_en
    };
  }
  let jobStatus: Employee['jobStatus'] = null;
  if (employee.job_status) {
    jobStatus = {
      [SUPPORTED_LANGUAGES.fr]: employee.job_status.text_fr,
      [SUPPORTED_LANGUAGES.en]: employee.job_status.text
    };
  }

  let spokenLanguages: Employee['spokenLanguages'] = [];
  if (employee.languages) {
    spokenLanguages = employee.languages.map((language) => {
      if (!language.name) {
        return {
          [SUPPORTED_LANGUAGES.fr]: '',
          [SUPPORTED_LANGUAGES.en]: ''
        };
      }
      return {
        [SUPPORTED_LANGUAGES.fr]: language.name.fr,
        [SUPPORTED_LANGUAGES.en]: language.name.en
      };
    });
  }

  return {
    id: employee.id,
    permissionBit: employee.permissions ? employee.permissions.value : 0,
    active: employee.active,
    image: employee.image,
    code: employee.code,
    firstName: employee.first_name,
    lastName: employee.last_name,
    corporateEmail: employee.corporate_email ? employee.corporate_email : '',
    corporatePhone: employee.work_phone ? employee.work_phone : '',
    corporatePhoneExt: employee.work_phone_ext ? employee.work_phone_ext : '',
    spokenLanguages,
    supervisor,
    department,
    workingSite,
    position,
    jobStatus
  };
}

export function apiEmployeePersonalToEmployeePersonal(
  employeePersonalData: ApiEmployee.Personal
): EmployeePersonal {
  let country: EmployeePersonal['address']['country'] = null;
  if (employeePersonalData.country) {
    country = {
      [SUPPORTED_LANGUAGES.fr]: employeePersonalData.country.name_fr,
      [SUPPORTED_LANGUAGES.en]: employeePersonalData.country.name_en
    };
  }
  let province: EmployeePersonal['address']['province'] = null;
  if (employeePersonalData.province) {
    province = {
      [SUPPORTED_LANGUAGES.fr]: employeePersonalData.province.name_fr,
      [SUPPORTED_LANGUAGES.en]: employeePersonalData.province.name_en
    };
  }

  let customFields: EmployeePersonal['customFields'] = [];
  if (employeePersonalData.custom_fields) {
    customFields = employeePersonalData.custom_fields.map((customField) => {
      return {
        category: customField.category,
        type: customField.type,
        is_salary: customField.is_salary,
        options: customField.options,
        values: customField.values,
        name: {
          [SUPPORTED_LANGUAGES.fr]: customField.name_fr,
          [SUPPORTED_LANGUAGES.en]: customField.name_en
        },
        id: customField.id
      };
    });
  }

  let emergencyContacts: EmployeePersonal['emergencyContacts'] = [];
  if (employeePersonalData.emergency_contacts) {
    emergencyContacts = employeePersonalData.emergency_contacts.map(
      (emergencyContact) => {
        let link: EmergencyContact['link'] = null;
        if (emergencyContact.link) {
          link = {
            [SUPPORTED_LANGUAGES.fr]: emergencyContact.link.fr,
            [SUPPORTED_LANGUAGES.en]: emergencyContact.link.en
          };
        }
        return {
          name: emergencyContact.name,
          phone: emergencyContact.phone,
          link
        };
      }
    );
  }

  let languages: EmployeePersonal['languages'] = [];
  if (employeePersonalData.languages) {
    languages = employeePersonalData.languages.map((language) => {
      return {
        [SUPPORTED_LANGUAGES.fr]: language.name.fr,
        [SUPPORTED_LANGUAGES.en]: language.name.en
      };
    });
  }

  return {
    address: {
      city: employeePersonalData.city,
      country: country,
      line1: employeePersonalData.address,
      line2: employeePersonalData.address_line2,
      line3: employeePersonalData.address_line3,
      province: province,
      zipCode: employeePersonalData.zip_code
    },
    birthday: employeePersonalData.birthday,
    customFields: customFields,
    email: employeePersonalData.personal_email,
    emergencyContacts: emergencyContacts,
    firstName: employeePersonalData.first_name,
    image: employeePersonalData.image,
    languages: languages,
    lastName: employeePersonalData.last_name,
    phonePrimary: employeePersonalData.phone,
    phoneSecondary: employeePersonalData.second_phone,
    socialInsuranceNumber: employeePersonalData.social_insurance_number,
    sex: employeePersonalData.sex
  };
}

export function apiEmployeeCorporateToEmployeeCorporate(
  employeeCorporateData: ApiEmployee.Corporate
): EmployeeCorporate {
  let customFields: EmployeeCorporate['customFields'] = [];
  if (employeeCorporateData.custom_fields) {
    customFields = employeeCorporateData.custom_fields.map((customField) => {
      return {
        category: customField.category,
        type: customField.type,
        is_salary: customField.is_salary,
        options: customField.options,
        values: customField.values,
        name: {
          [SUPPORTED_LANGUAGES.fr]: customField.name_fr,
          [SUPPORTED_LANGUAGES.en]: customField.name_en
        },
        id: customField.id
      };
    });
  }
  let department: EmployeeCorporate['department'] = null;
  if (employeeCorporateData.department) {
    department = {
      [SUPPORTED_LANGUAGES.fr]: employeeCorporateData.department.description_fr,
      [SUPPORTED_LANGUAGES.en]: employeeCorporateData.department.description_en
    };
  }
  let jobStatus: EmployeeCorporate['jobStatus'] = null;
  if (employeeCorporateData.job_status) {
    jobStatus = {
      [SUPPORTED_LANGUAGES.fr]: employeeCorporateData.job_status.text_fr,
      [SUPPORTED_LANGUAGES.en]: employeeCorporateData.job_status.text
    };
  }
  let position: EmployeeCorporate['position'] = null;
  if (employeeCorporateData.position) {
    position = {
      [SUPPORTED_LANGUAGES.fr]: employeeCorporateData.position.description_fr,
      [SUPPORTED_LANGUAGES.en]: employeeCorporateData.position.description_en
    };
  }
  let startDate: number = getUnixTime(new Date());
  if (employeeCorporateData.start_date) {
    startDate = getUnixTime(new Date(employeeCorporateData.start_date));
  }

  let supervisor: EmployeeCorporate['supervisor'] = null;
  if (employeeCorporateData.supervisor) {
    let supervisor_position: EmployeeNode['position'] = null;
    if (employeeCorporateData.supervisor.position) {
      supervisor_position = {
        [SUPPORTED_LANGUAGES.fr]:
          employeeCorporateData.supervisor.position.description_fr,
        [SUPPORTED_LANGUAGES.en]:
          employeeCorporateData.supervisor.position.description_en
      };
    }
    supervisor = {
      id: employeeCorporateData.supervisor.id,
      code: employeeCorporateData.supervisor.code,
      lastName: employeeCorporateData.supervisor.last_name,
      firstName: employeeCorporateData.supervisor.first_name,
      image: employeeCorporateData.supervisor.image,
      active: employeeCorporateData.supervisor.active,
      position: supervisor_position
    };
  }

  let union: EmployeeCorporate['union'] = null;
  if (employeeCorporateData.union) {
    union = {
      [SUPPORTED_LANGUAGES.fr]: employeeCorporateData.union.name_en,
      [SUPPORTED_LANGUAGES.en]: employeeCorporateData.union.name_fr
    };
  }

  let workingSite: Employee['workingSite'] = null;
  if (employeeCorporateData.working_site) {
    workingSite = {
      [SUPPORTED_LANGUAGES.fr]:
        employeeCorporateData.working_site.description_fr,
      [SUPPORTED_LANGUAGES.en]:
        employeeCorporateData.working_site.description_en
    };
  }

  return {
    code: employeeCorporateData.code,
    corporateEmail: employeeCorporateData.corporate_email,
    customFields: customFields,
    department: department,
    jobStatus: jobStatus,
    notes: employeeCorporateData.notes,
    payrolls: employeeCorporateData.payrolls,
    position: position,
    salaryData: employeeCorporateData.salary_data,
    seniority: employeeCorporateData.seniority,
    startDate: startDate,
    supervisor: supervisor,
    union: union,
    workPhone: employeeCorporateData.work_phone,
    workPhoneExt: employeeCorporateData.work_phone_ext,
    workingSite: workingSite
  };
}

export function employeePersonalToApiEmployeePersonalPut() {
  return {
    first_name: 'John',
    last_name: 'Doe',
    birthday: '1990-01-01',
    sex: 'F',
    languages: ['string'],
    social_insurance_number: '123 456 789',
    phone: '(438) 456 789',
    second_phone: '(438) 456 789',
    personal_email: 'email@email.com',
    address: '1234 Street',
    address_line2: 'Apt 123',
    address_line3: 'Building 123',
    country_code: 'CA',
    zip_code: 'H0H 0H0',
    province_code: 'QC',
    city: 'Montreal',
    emergency_link: 1,
    emergency_phone: '(438) 456 789',
    emergency_contact: 'Solène Dupont',
    second_emergency_link: 1,
    second_emergency_phone: '(438) 456 789',
    second_emergency_contact: 'Solène Dupont'
  };
}
