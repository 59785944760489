import { defineStore } from 'pinia';
import { getUserPermissions } from '@/modules/shared/core/services/permissions';

export const usePermissionsStore = defineStore('permissions', {
  state: (): {
    permissions: string[];
  } => ({
    permissions: []
  }),
  getters: {
    canAccess: (state) => (ability: string) => {
      return ability === '' ? false : state.permissions.includes(ability);
    }
  },
  actions: {
    async getPermissions() {
      try {
        const response = await getUserPermissions();
        if (!response || !response.data) {
          console.error(
            'Failed to fetch permissions: Invalid response',
            response
          );
          this.permissions = []; // Fallback to default permissions
          return;
        }
        this.permissions = response.data.permissions.sort();
      } catch (error) {
        console.error('Error fetching permissions:', error);
        this.permissions = []; // Fallback in case of error
      }
    }
  }
});
