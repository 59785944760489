export default function useDebugLogging() {
  const shouldDisplayDebug = () => {
    return import.meta.env.VITE_APP_DEBUG_MODE;
  };

  const debugModeLog = (message: string, ...args: any[]) => {
    if (shouldDisplayDebug()) {
      console.log(`${getCurrentAppDebugPrefix()} ${message}`, ...args);
    }
  };

  const debugModeInfo = (message: string, ...args: any[]) => {
    if (shouldDisplayDebug()) {
      console.info(`${getCurrentAppDebugPrefix()} ${message}`, ...args);
    }
  };

  const debugModeError = (message: string, ...args: any[]) => {
    if (shouldDisplayDebug()) {
      console.error(`${getCurrentAppDebugPrefix()} ${message}`, ...args);
    }
  };

  const getCurrentAppDebugPrefix = () => {
    return '[FOLKS-SPA] - Debug - ';
  };

  return {
    debugModeLog,
    debugModeInfo,
    debugModeError
  };
}
