import type { Ref } from 'vue';
import * as employeesService from '@/modules/shared/core/services/employees';
import type { ApiListParams } from '@/modules/shared/core/utils/apiFolks/api.types';
import type { Employee } from '@/modules/shared/core/services/employees.types';

export const useEmployeesStore = (
  uniqueName: string,
  embeddedResources: string[] = [
    'supervisor',
    'jobStatus',
    'position',
    'department',
    'workingSite',
    'languages',
    'country'
  ],
  additionalParams: any = {}
) => {
  const store = defineStore(`employeesStore/${uniqueName}`, () => {
    const items: Ref<Employee[]> = ref([]);
    const itemsLength = ref(0);
    const loading = ref(false);

    const get = async (params: ApiListParams): Promise<void> => {
      loading.value = true;
      const { data, meta } = await employeesService.get(
        params,
        embeddedResources,
        additionalParams
      );
      items.value = data;
      itemsLength.value = meta.total;
      loading.value = false;
    };

    const reset = (): void => {
      items.value = [];
      itemsLength.value = 0;
    };

    return {
      items,
      itemsLength,
      loading,
      get,
      reset
    };
  });
  return store();
};
