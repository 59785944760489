const UrlUtils = {
  appendQueryParamsString(originalString: string, queryParamString = '') {
    const queryParamJoinCharacter =
      originalString.indexOf('?') !== -1 ? '&' : '?';
    let queryParamsOutput = '';
    if (queryParamString !== '') {
      queryParamsOutput = queryParamJoinCharacter + queryParamString;
    }
    return originalString + queryParamsOutput;
  },
  /**
   * Generate the query string based on parameters.
   * The key of the params car contain a [] if we have the same multiple times
   * It also means that the param is a array so we have to map them
   */
  buildQueryParametersString(parameters: Record<string, any>) {
    //todo encodeparams like the 2 other method?
    return Object.keys(parameters)
      .filter(
        (key) => parameters[key] !== null && parameters[key] !== undefined
      )
      .map((key) =>
        key.includes('[]')
          ? parameters[key]
              .map((subKey: string) => `${key}=${subKey}`)
              .join('&')
          : `${key}=${parameters[key]}`
      )
      .filter(Boolean)
      .join('&');
  },
  /**
   * An easier way of doing that will probably be to just use buildQueryParametersString above and pass params like this
   * "keyName[]": array
   */
  encodeUrlParam(column: string, value: string) {
    return [encodeURIComponent(column), encodeURIComponent(value)].join('=');
  },
  buildMultiGetParams(values: string[], key: string) {
    return values
      .map((resource) => this.encodeUrlParam(`${key}[]`, resource))
      .join('&');
  },
  buildEmbeddedResourcesString(embeddedResources: string[]) {
    return this.buildMultiGetParams(embeddedResources, '_embed');
  },
  buildSearchColumnsResourcesString(searchableColumn: string[]) {
    return this.buildMultiGetParams(
      searchableColumn,
      '_optional_search_columns'
    );
  }
};

export default UrlUtils;
