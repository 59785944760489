<template>
  <Component :is="layoutToRender">
    <router-view />
  </Component>
</template>

<script lang="ts" setup>
import { LogoutPostMessageHandler } from '@/modules/shared/core/composables/PostMessage/handlers/LogoutPostMessageHandler';
import useMessageListener from '@/modules/shared/core/composables/PostMessage/useMessageListener';
import { useAuthenticationStore } from '@/modules/authentication/store/authentication';
import FLayoutDefault from './modules/shared/core/layouts/FLayoutDefault.vue';
import FLayoutAuth from './modules/shared/core/layouts/FLayoutAuth.vue';

const authenticationStore = useAuthenticationStore();
const { addHandler } = useMessageListener({ name: 'app' });
addHandler('logout', new LogoutPostMessageHandler(authenticationStore));

const { t, locale } = useI18n();
watch(
  locale,
  () => {
    document.title = t('appTitle');
  },
  { immediate: true }
);

const route = useRoute();
const layoutToRender = computed(() => {
  if (route.name === undefined) {
    return null;
  }
  return route.meta.needAuth ? FLayoutDefault : FLayoutAuth;
});
</script>

<style lang="sass">
html
  scroll-behavior: smooth
</style>
