import { defineStore } from 'pinia';
import { usePermissionsStore } from '@/modules/shared/core/stores/permissions';

export const useToolsSideSheetStore = defineStore('toolsSideSheet', () => {
  const { canAccess } = usePermissionsStore();

  const isOpened = ref(false);

  const hasAnyToolEnabled = computed(() => {
    const permissionsToVerify: string[] = [];

    return permissionsToVerify.some((permission) => {
      return canAccess(permission);
    });
  });

  return {
    isOpened,
    hasAnyToolEnabled
  };
});
