const BASE_ROUTE_EMPLOYEES = '/employees';
const BASE_ROUTE_EMPLOYEES_CONFIGURATION = `${BASE_ROUTE_EMPLOYEES}/configuration`;
const BASE_ROUTE_EMPLOYEE = `${BASE_ROUTE_EMPLOYEES}/[employee_id]`;
const BASE_ROUTE_TIME_MANAGEMENT = `${BASE_ROUTE_EMPLOYEE}/time-management`;
const BASE_ROUTE_EMPLOYEE_CONFIGURATION = `${BASE_ROUTE_EMPLOYEE}.configuration`;
const BASE_ROUTE_DISCIPLINE = `${BASE_ROUTE_EMPLOYEE}/discipline`;
const BASE_ROUTE_PERFORMANCES = `${BASE_ROUTE_EMPLOYEE}/performances`;
const BASE_ROUTE_HISTORY = `${BASE_ROUTE_EMPLOYEE}.history`;

const BASE_ROUTE_EMPLOYEES_HISTORY = `${BASE_ROUTE_EMPLOYEES}/history`;

const BASE_ROUTE_DISCIPLINE_MODAL = `${BASE_ROUTE_EMPLOYEES}/discipline`;

const BASE_ROUTE_CLIMATE = `${BASE_ROUTE_EMPLOYEES}/climate`;
const BASE_ROUTE_INCIDENTS = `${BASE_ROUTE_EMPLOYEES}/incidents`;
const BASE_ROUTE_EXPENSE_ACCOUNTS = `${BASE_ROUTE_EMPLOYEES}/expense-accounts`;
const BASE_ROUTE_EXPENSE_ACCOUNT_CONFIG = `${BASE_ROUTE_EXPENSE_ACCOUNTS}.configurations`;

const EMPLOYEES_ROUTES = {
  ROOT: BASE_ROUTE_EMPLOYEES,
  LIST: `${BASE_ROUTE_EMPLOYEES}/list`,
  SUMMARY: `${BASE_ROUTE_EMPLOYEES}/summary`,
  CONFIGURATION: {
    ROOT: `${BASE_ROUTE_EMPLOYEES_CONFIGURATION}`,
    PROFIL_CUSTOMIZATION: `${BASE_ROUTE_EMPLOYEES_CONFIGURATION}/profil-customization`
  },
  EMPLOYEE: {
    ROOT: BASE_ROUTE_EMPLOYEE,
    NOTE: `${BASE_ROUTE_EMPLOYEE}/note`,
    INFORMATIONS: {
      ROOT: `${BASE_ROUTE_EMPLOYEE}/informations`,
      PERSONAL: `${BASE_ROUTE_EMPLOYEE}/informations/personal`,
      CORPORATE: `${BASE_ROUTE_EMPLOYEE}/informations/corporate`
    },
    ONBOARDING: `${BASE_ROUTE_EMPLOYEE}/onboarding`,
    DOCUMENTS: `${BASE_ROUTE_EMPLOYEE}/documents`,
    TIME_MANAGEMENT: {
      ANY: BASE_ROUTE_TIME_MANAGEMENT,
      ABSENCE: {
        ROOT: `${BASE_ROUTE_TIME_MANAGEMENT}/absence`,
        CONTROL: `${BASE_ROUTE_TIME_MANAGEMENT}/absence/control`,
        EDIT: `${BASE_ROUTE_TIME_MANAGEMENT}/absence/:id`
      },
      VACATION: {
        ROOT: `${BASE_ROUTE_TIME_MANAGEMENT}/vacation`,
        CONTROL: `${BASE_ROUTE_TIME_MANAGEMENT}/vacation/control`,
        EDIT: `${BASE_ROUTE_TIME_MANAGEMENT}/vacation/:id`
      },
      OVERTIME: {
        ROOT: `${BASE_ROUTE_TIME_MANAGEMENT}/overtime`,
        CONTROL: `${BASE_ROUTE_TIME_MANAGEMENT}/overtime/control`,
        EDIT: `${BASE_ROUTE_TIME_MANAGEMENT}/overtime/:id`
      },
      TIMESHEET: `${BASE_ROUTE_TIME_MANAGEMENT}/timesheet`
    },
    EXPENSE_ACCOUNTS: `${BASE_ROUTE_EMPLOYEE}/expense-account`,
    SURVEYS: `${BASE_ROUTE_EMPLOYEE}/surveys`,
    CONFIGURATION: {
      ROOT: BASE_ROUTE_EMPLOYEE_CONFIGURATION,
      CUSTOM_FIELD_PERSO: `${BASE_ROUTE_EMPLOYEE_CONFIGURATION}/custom-field-perso`,
      CUSTOM_FIELD_CORPO: `${BASE_ROUTE_EMPLOYEE_CONFIGURATION}/custom-field-corpo`,
      PROJECTS: `${BASE_ROUTE_EMPLOYEE_CONFIGURATION}/projects`
    },
    DISCIPLINE: {
      ROOT: BASE_ROUTE_DISCIPLINE,
      CONTROL: `${BASE_ROUTE_DISCIPLINE_MODAL}/:code/control`,
      EDIT: `${BASE_ROUTE_DISCIPLINE_MODAL}/:id`
    },
    PERFORMANCES: {
      ROOT: BASE_ROUTE_PERFORMANCES,
      TRAINING: `${BASE_ROUTE_PERFORMANCES}/training`,
      CAREERS: `${BASE_ROUTE_PERFORMANCES}/careers`,
      CERTIFICATES: `${BASE_ROUTE_PERFORMANCES}/certificates`,
      ANALYSIS: `${BASE_ROUTE_PERFORMANCES}/analysis`
    },
    HISTORY: {
      ROOT: BASE_ROUTE_HISTORY,
      CORPORATE: `${BASE_ROUTE_HISTORY}/corporate`,
      PERSONAL: `${BASE_ROUTE_HISTORY}/personal`
    },
    STATISTICS: `${BASE_ROUTE_EMPLOYEE}/statistics`,
    WORK_ACCIDENTS: `${BASE_ROUTE_EMPLOYEE}/work-accidents`
  },
  HISTORY: {
    ROOT: BASE_ROUTE_EMPLOYEES_HISTORY,
    CORPORATE: `${BASE_ROUTE_EMPLOYEES_HISTORY}/corporate`,
    PERSONAL: `${BASE_ROUTE_EMPLOYEES_HISTORY}/personal`
  },
  EXPENSE_ACCOUNTS: {
    ROOT: BASE_ROUTE_EXPENSE_ACCOUNTS,
    CREATE: `${BASE_ROUTE_EXPENSE_ACCOUNTS}.create`,
    EDIT: `${BASE_ROUTE_EXPENSE_ACCOUNTS}.edit`,
    CONFIG: {
      ROOT: BASE_ROUTE_EXPENSE_ACCOUNT_CONFIG
    }
  },
  INCIDENTS: {
    ROOT: BASE_ROUTE_INCIDENTS
  },
  CLIMATE: {
    ROOT: BASE_ROUTE_CLIMATE,
    ANNUAL: `${BASE_ROUTE_CLIMATE}/annual`,
    ANALYSIS: `${BASE_ROUTE_CLIMATE}/analysis`,
    COMMENTS: `${BASE_ROUTE_CLIMATE}/comments`
  }
};

export default EMPLOYEES_ROUTES;
