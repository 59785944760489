<template>
  <FHeaderPrimary :title="t('title')" />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import ADVANCEMENT_ABILITIES from '@/modules/advancement/constants/abilities';

definePage({
  meta: {
    ability: ADVANCEMENT_ABILITIES.TRAINING.REQUEST
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Soumettre une demande de formation'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Submit a training request'
    }
  }
});
</script>
