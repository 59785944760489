<template>
  <FHeaderPrimary
    :add-ability="DOCUMENTS_ABILITIES.VAULT.CONFIG.ANY"
    :back-route="DOCUMENTS_ROUTES.VAULT.ROOT"
    :title="t('title')"
    @add:click="sendCreateEventToIframe()"
  />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import DOCUMENTS_ROUTES from '@/modules/documents/constants/urls';
import DOCUMENTS_ABILITIES from '@/modules/documents/constants/abilities';
import useMessageSender from '@/modules/shared/core/composables/PostMessage/useMessageSender';
import { getProgramDataByRoute } from '@/modules/shared/core/utils/mapping';

definePage({
  meta: {
    needAuth: true
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Configuration ressources documentaires'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Documentary resources configuration'
    }
  }
});

const { sendMessageToProgramFrame, buildMessageEventDataToSend } =
  useMessageSender();

const { path } = useRoute();
const programData = getProgramDataByRoute(path);

const sendCreateEventToIframe = () => {
  sendMessageToProgramFrame(
    buildMessageEventDataToSend('documents:vault:add', null, path),
    programData
  );
};
</script>
