import { useUserStore } from '@/modules/shared/core/stores/user';
import { useCompanyStore } from '@/modules/shared/core/stores/compagny';

export const fillPendo = () => {
  const userStore = useUserStore();
  const { company, companyWhiteLabels, industry } = useCompanyStore();
  if ((<any>window).pendo) {
    (<any>window).pendo.initialize({
      disableGuides: import.meta.env.VITE_APP_DISABLE_PENDO_GUIDES == '1',
      visitor: {
        id: userStore.id,
        email: userStore.email,
        full_name: `${userStore.first_name} ${userStore.last_name}`,
        white_label: companyWhiteLabels.code,
        tags: [{ role: [userStore.role] }],
        is_support: userStore.is_support
      },
      account: {
        id: company.id,
        name: company.name,
        white_label: companyWhiteLabels.code,
        tags: [{ industry: [industry.name_fr] }]
      }
    });
  }
};
