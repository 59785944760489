import type { PostMessageHandlerInterface } from '@/modules/shared/core/composables/PostMessage/handlers/PostMessageHandlerInterface';
import type { MessageRespondFn } from '@/modules/shared/core/stores/messageHandlersStore';
import type { useAuthenticationStore } from '@/modules/authentication/store/authentication';

export class RefreshTokenPostMessageHandler
  implements PostMessageHandlerInterface
{
  constructor(
    private readonly authenticationStore: ReturnType<
      typeof useAuthenticationStore
    >
  ) {}

  async handle(respond: MessageRespondFn): Promise<void> {
    await this.authenticationStore.refreshToken();

    respond(this.authenticationStore.authData.access_token);
  }
}
