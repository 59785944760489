<template>
  <VTooltip :disabled="isTooltipDisabled">
    <template #activator="{ props }">
      <VListGroup
        v-if="itemData.children && itemData.children.length"
        :value="itemData.text"
      >
        <template #activator="{ props: listGroupProps }">
          <VListItem
            v-dynamic-data-attr="{ all: `navigation-group-${itemData.name}` }"
            class="text-no-wrap"
            v-bind="{ ...listGroupProps, ...props }"
            @click="triggerOpeningNavIfNotRail"
          >
            <template
              v-if="itemData.icon"
              #prepend
            >
              <FIconMD :icon="itemData.icon" />
            </template>
            {{ t(itemData.text) }}
          </VListItem>
        </template>
        <FNavigationListItem
          v-for="child in itemData.children"
          :key="child.name"
          :item-data="child"
        />
      </VListGroup>
    </template>
    {{ t(itemData.text) }}
  </VTooltip>
</template>

<script lang="ts" setup>
import type { MenuItem } from '@/components/FNavigation.types';
import { useNavigationRailStore } from '@/stores/navigationRail';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

defineProps({
  itemData: {
    type: Object as () => MenuItem,
    required: true
  }
});

const { t } = useI18n({
  useScope: 'global'
});

const navigationRailStore = useNavigationRailStore();
const { webNavOpenByClick, isInRail } = storeToRefs(navigationRailStore);
const triggerOpeningNavIfNotRail = () => {
  if (isInRail.value && !webNavOpenByClick.value) {
    webNavOpenByClick.value = true;
  }
};

const { isTooltipDisabled } = storeToRefs(useNavigationRailStore());
</script>

<style scoped></style>
