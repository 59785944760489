<template>
  <FLegacyIframe :iframe-route="$route.fullPath" />
</template>
<script lang="ts" setup>
import REPORTS_ABILITIES from '@/modules/reports/constants/abilities';

definePage({
  meta: {
    ability: REPORTS_ABILITIES.DASHBOARD.SUMMARY
  }
});
</script>
