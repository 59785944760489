const BASE_ROUTE_DOCUMENTS = '/documents';

const BASE_ROUTE_VAULT = `${BASE_ROUTE_DOCUMENTS}/vault`;
const BASE_ROUTE_VAULT_CONFIG = `${BASE_ROUTE_VAULT}.configurations`;
const BASE_ROUTE_E_SIGN = `${BASE_ROUTE_DOCUMENTS}/e-sign`;

const DOCUMENTS_ROUTES = {
  ROOT: BASE_ROUTE_DOCUMENTS,
  VAULT: {
    ROOT: BASE_ROUTE_VAULT,
    CONFIG: {
      ROOT: BASE_ROUTE_VAULT_CONFIG
    }
  },
  E_SIGN: {
    ROOT: BASE_ROUTE_E_SIGN,
    CONFIG: {
      ROOT: `${BASE_ROUTE_E_SIGN}.templates`
    }
  }
};

export default DOCUMENTS_ROUTES;
