/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */
import { DataLoaderPlugin } from 'unplugin-vue-router/data-loaders';

// Plugins
import vuetify from './vuetify';
import pinia from './pinia';
import { PiniaColada } from '@pinia/colada';
import router from './vueRouter';
import i18n from './vueI18n';
import { bugsnagPerformanceStart, bugsnagStartAndGetVue } from './bugsnag';
import datadog from './datadog';

// Types
import type { App } from 'vue';

export function registerPlugins(app: App) {
  app
    .use(vuetify)
    .use(DataLoaderPlugin, { router })
    .use(router)
    .use(pinia)
    .use(PiniaColada)
    .use(i18n)
    .use(bugsnagStartAndGetVue())
    .use(datadog);
  bugsnagPerformanceStart(router);
}
