<template>
  <FTopBarSideSheet
    v-if="hasAnyPermission"
    v-dynamic-data-attr="{ all: 'f-top-bar-request-side-sheet' }"
    :description="t('description')"
    :is-opened="isOpened"
    :items="itemsToDisplay"
    :title="t('title')"
    :translate="t"
    @update:is-opened="isOpened = $event"
    @click:item="handleItemClick"
  />
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import { useRequestSideSheetStore } from '@/stores/requestSideSheet';
import { usePermissionsStore } from '@/modules/shared/core/stores/permissions';
import type {
  TopBarSideSheetChildItem,
  TopBarSideSheetItem
} from '@/components/FTopBarSideSheet.types';
import { useFControlDialog } from '@/modules/shared/core/stores/fControlDialog';

const { t } = useI18n({
  useScope: 'global',
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Demandes',
      description:
        'Un courriel vous sera envoyé lorsque votre demande sera traitée.'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Requests',
      description:
        'An email will be sent to you when your request is processed.'
    }
  }
});

const requestSideSheetStore = useRequestSideSheetStore();

const { isOpened, hasAnyPermission, getSortedItems } = storeToRefs(
  requestSideSheetStore
);

const { canAccess } = usePermissionsStore();
const filterChildren = (children: TopBarSideSheetChildItem[]) => {
  return children.filter((child) => {
    return canAccess(child.ability);
  });
};

const itemsToDisplay = computed(() => {
  return getSortedItems.value.reduce<TopBarSideSheetItem[]>(
    (newItems, item) => {
      const children = filterChildren(item.children);
      if (children.length > 0) {
        newItems.push({ ...item, children });
      }
      return newItems;
    },
    []
  );
});

const { handleRequestDialog } = useFControlDialog();
const handleItemClick = (item: TopBarSideSheetChildItem) => {
  if (item.requestType) {
    handleRequestDialog(item.requestType);
  }
};
</script>
<style lang="sass" scoped></style>
