<template>
  <FHeaderPrimary
    :back-route="backButtonSelectedName"
    :title="t('title')"
  />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import TIME_MANAGEMENT_ABILITIES from '@/modules/time-management/constants/abilities';
import TIME_MANAGEMENT_ROUTES from '@/modules/time-management/constants/urls';
import REPORTS_ROUTES from '@/modules/reports/constants/urls';
import useBackPreviousButton from '@/modules/shared/core/composables/useBackPreviousButton';

definePage({
  meta: {
    ability: TIME_MANAGEMENT_ABILITIES.TIMESHEET.SUMMARY.ANY
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Statistiques de feuille de temps par projet'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Timesheet statistics by project'
    }
  }
});

const { backButtonSelectedName } = useBackPreviousButton([
  TIME_MANAGEMENT_ROUTES.TIMESHEETS.ROOT,
  REPORTS_ROUTES.SUMMARY
]);
</script>
