import type { Program } from '@/modules/shared/core/utils/mapping/index.types';
import LEGACY_TYPE from '@/modules/shared/core/constants/legacyType';
import TIME_MANAGEMENT_ROUTES from '@/modules/time-management/constants/urls';
import TIME_MANAGEMENT_ABILITIES from '@/modules/time-management/constants/abilities';

const ABSENCES_MAPPING = [
  {
    name: TIME_MANAGEMENT_ABILITIES.ABSENCE.LIST,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 201520,
    module: TIME_MANAGEMENT_ABILITIES.ABSENCE.ANY,
    routeName: TIME_MANAGEMENT_ROUTES.ABSENCE.ROOT,
    iframeUrl: '/legacy/absence/analysis'
  },
  {
    name: TIME_MANAGEMENT_ABILITIES.ABSENCE.CONTROL,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 201530,
    module: TIME_MANAGEMENT_ABILITIES.ABSENCE.ANY,
    routeName: TIME_MANAGEMENT_ROUTES.ABSENCE.CONTROL,
    iframeUrl: '/legacy/absence/control'
  },
  {
    name: TIME_MANAGEMENT_ABILITIES.ABSENCE.REQUEST + ':create',
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 402000,
    module: TIME_MANAGEMENT_ABILITIES.ABSENCE.ANY,
    routeName: TIME_MANAGEMENT_ROUTES.ABSENCE.CREATE,
    iframeUrl: '/legacy/absence/create'
  },
  {
    name: TIME_MANAGEMENT_ABILITIES.ABSENCE.SUMMARY.ANNUAL,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 201510,
    module: TIME_MANAGEMENT_ABILITIES.ABSENCE.ANY,
    routeName: TIME_MANAGEMENT_ROUTES.ABSENCE.SUMMARY.ANNUAL,
    iframeUrl: '/legacy/absence/summary'
  },
  {
    name: TIME_MANAGEMENT_ABILITIES.ABSENCE.SUMMARY.WEEKLY,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 201525,
    module: TIME_MANAGEMENT_ABILITIES.ABSENCE.ANY,
    routeName: TIME_MANAGEMENT_ROUTES.ABSENCE.SUMMARY.WEEKLY,
    iframeUrl: '/legacy/absence/weekly-analysis'
  },
  {
    name: TIME_MANAGEMENT_ABILITIES.ABSENCE.REQUEST + ':edit',
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 201530,
    module: TIME_MANAGEMENT_ABILITIES.ABSENCE.ANY,
    routeName: TIME_MANAGEMENT_ROUTES.ABSENCE.EDIT,
    iframeUrl: '/legacy/absence/:id/edit'
  }
] as Program[];

const VACATIONS_MAPPING = [
  {
    name: TIME_MANAGEMENT_ABILITIES.VACATION.LIST,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 206025,
    module: TIME_MANAGEMENT_ABILITIES.VACATION.ANY,
    routeName: TIME_MANAGEMENT_ROUTES.VACATION.ROOT,
    iframeUrl: '/legacy/vacation/analysis'
  },
  {
    name: TIME_MANAGEMENT_ABILITIES.VACATION.CONTROL,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 206030,
    module: TIME_MANAGEMENT_ABILITIES.VACATION.ANY,
    routeName: TIME_MANAGEMENT_ROUTES.VACATION.CONTROL,
    iframeUrl: '/legacy/vacation/control'
  },
  {
    name: TIME_MANAGEMENT_ABILITIES.VACATION.SUMMARY.ANY,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 206010,
    module: TIME_MANAGEMENT_ABILITIES.VACATION.ANY,
    routeName: TIME_MANAGEMENT_ROUTES.VACATION.SUMMARY.ROOT,
    iframeUrl: '/legacy/vacation/summary'
  },
  {
    name: TIME_MANAGEMENT_ABILITIES.VACATION.REQUEST + ':create',
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 401000,
    module: TIME_MANAGEMENT_ABILITIES.VACATION.ANY,
    routeName: TIME_MANAGEMENT_ROUTES.VACATION.CREATE,
    iframeUrl: '/legacy/vacation/create'
  },
  {
    name: TIME_MANAGEMENT_ABILITIES.VACATION.REQUEST + ':edit',
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 206030,
    module: TIME_MANAGEMENT_ABILITIES.VACATION.ANY,
    routeName: TIME_MANAGEMENT_ROUTES.VACATION.EDIT,
    iframeUrl: '/legacy/vacation/:id/edit'
  }
] as Program[];

const OVERTIMES_MAPPING = [
  {
    name: TIME_MANAGEMENT_ABILITIES.OVERTIME.LIST,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 207030,
    module: TIME_MANAGEMENT_ABILITIES.OVERTIME.ANY,
    routeName: TIME_MANAGEMENT_ROUTES.OVERTIME.ROOT,
    iframeUrl: '/legacy/overtime/analysis'
  },
  {
    name: TIME_MANAGEMENT_ABILITIES.OVERTIME.CONTROL,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 207040,
    module: TIME_MANAGEMENT_ABILITIES.OVERTIME.ANY,
    routeName: TIME_MANAGEMENT_ROUTES.OVERTIME.CONTROL,
    iframeUrl: '/legacy/overtime/control'
  },
  {
    name: TIME_MANAGEMENT_ABILITIES.OVERTIME.SUMMARY.ANY,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 207020,
    module: TIME_MANAGEMENT_ABILITIES.OVERTIME.ANY,
    routeName: TIME_MANAGEMENT_ROUTES.OVERTIME.SUMMARY.ROOT,
    iframeUrl: '/legacy/overtime/summary'
  },
  {
    name: TIME_MANAGEMENT_ABILITIES.OVERTIME.REQUEST + ':create',
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 403000,
    module: TIME_MANAGEMENT_ABILITIES.OVERTIME.ANY,
    routeName: TIME_MANAGEMENT_ROUTES.OVERTIME.CREATE,
    iframeUrl: '/legacy/overtime/create'
  },
  {
    name: TIME_MANAGEMENT_ABILITIES.OVERTIME.REQUEST + ':edit',
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 207040,
    module: TIME_MANAGEMENT_ABILITIES.OVERTIME.ANY,
    routeName: TIME_MANAGEMENT_ROUTES.OVERTIME.EDIT,
    iframeUrl: '/legacy/overtime/:id/edit'
  }
] as Program[];

const TIMESHEET_MAPPING = [
  {
    name: TIME_MANAGEMENT_ABILITIES.TIMESHEET.VIEW,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 250000,
    module: TIME_MANAGEMENT_ABILITIES.TIMESHEET.ANY,
    routeName: TIME_MANAGEMENT_ROUTES.TIMESHEETS.ROOT
  },
  {
    name: TIME_MANAGEMENT_ABILITIES.TIMESHEET.SUMMARY.ANY,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 250010,
    module: TIME_MANAGEMENT_ABILITIES.TIMESHEET.ANY,
    routeName: TIME_MANAGEMENT_ROUTES.TIMESHEETS.SUMMARY.ROOT
  },
  {
    name: TIME_MANAGEMENT_ABILITIES.TIMESHEET.CONFIG.LEGEND,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505085,
    module: TIME_MANAGEMENT_ABILITIES.TIMESHEET.ANY,
    routeName: TIME_MANAGEMENT_ROUTES.TIMESHEETS.CONFIG.LEGEND
  },
  {
    name: TIME_MANAGEMENT_ABILITIES.TIMESHEET.CONFIG.CALCULATION,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505084,
    module: TIME_MANAGEMENT_ABILITIES.TIMESHEET.ANY,
    routeName: TIME_MANAGEMENT_ROUTES.TIMESHEETS.CONFIG.CALCULATION
  },
  {
    name: TIME_MANAGEMENT_ABILITIES.TIMESHEET.CONFIG.HOLIDAY_SETTINGS,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505087,
    module: TIME_MANAGEMENT_ABILITIES.TIMESHEET.ANY,
    routeName: TIME_MANAGEMENT_ROUTES.TIMESHEETS.CONFIG.HOLIDAY_SETTINGS
  },
  {
    name: TIME_MANAGEMENT_ABILITIES.TIMESHEET.CONFIG.PROJECT,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505088,
    module: TIME_MANAGEMENT_ABILITIES.TIMESHEET.ANY,
    routeName: TIME_MANAGEMENT_ROUTES.TIMESHEETS.CONFIG.PROJECT
  },
  {
    name: TIME_MANAGEMENT_ABILITIES.TIMESHEET.CONFIG.PROJECT_TASKS,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505086,
    module: TIME_MANAGEMENT_ABILITIES.TIMESHEET.ANY,
    routeName: TIME_MANAGEMENT_ROUTES.TIMESHEETS.CONFIG.PROJECT_TASKS
  },
  {
    name: TIME_MANAGEMENT_ABILITIES.TIMESHEET.CONFIG.PROJECTS,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505089,
    module: TIME_MANAGEMENT_ABILITIES.TIMESHEET.ANY,
    routeName: TIME_MANAGEMENT_ROUTES.TIMESHEETS.CONFIG.PROJECTS
  },
  {
    name: TIME_MANAGEMENT_ABILITIES.TIMESHEET.CONFIG.IMPORTATION,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505093,
    module: TIME_MANAGEMENT_ABILITIES.TIMESHEET.ANY,
    routeName: TIME_MANAGEMENT_ROUTES.TIMESHEETS.CONFIG.IMPORTATION
  }
] as Program[];

const CALENDAR_MAPPING = [
  {
    name: TIME_MANAGEMENT_ABILITIES.CALENDAR.VIEW,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 206100,
    module: TIME_MANAGEMENT_ABILITIES.CALENDAR.ANY,
    routeName: TIME_MANAGEMENT_ROUTES.CALENDAR.ROOT
  }
] as Program[];

const TIMEOFF_MAPPING = [
  ...ABSENCES_MAPPING,
  ...VACATIONS_MAPPING,
  ...OVERTIMES_MAPPING,
  ...TIMESHEET_MAPPING,
  ...CALENDAR_MAPPING
] as Program[];

export default TIMEOFF_MAPPING;
