const REPORTS_ABILITIES_ROOT = 'reports';
const DASHBOARD_ABILITIES_ROOT = `${REPORTS_ABILITIES_ROOT}:dashboard`;
const REPORTS_DISCIPLINE_ROOT = `${REPORTS_ABILITIES_ROOT}:discipline`;

const REPORTS_ABILITIES = {
  ANY: REPORTS_ABILITIES_ROOT,
  SUMMARY: `${REPORTS_ABILITIES_ROOT}:summary:index`,
  DASHBOARD: {
    ANY: DASHBOARD_ABILITIES_ROOT,
    ANALYSIS: `${DASHBOARD_ABILITIES_ROOT}:analysis`,
    SUMMARY: `${DASHBOARD_ABILITIES_ROOT}:summary`
  },
  KPI: `${REPORTS_ABILITIES_ROOT}:kpi:index`,
  DISCIPLINE: {
    ANY: `${REPORTS_DISCIPLINE_ROOT}:analysis`,
    CONTROL: `${REPORTS_DISCIPLINE_ROOT}:control`
  }
};

export default REPORTS_ABILITIES;
