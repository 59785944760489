import { defineStore } from 'pinia';
import type {
  Company,
  CompanyParameters,
  Industry,
  IndustryGetter,
  Whitelabel,
  WhitelabelGetter
} from '@/modules/shared/core/stores/compagny.types';

const formatLocaleToSimple = (locale: string): 'fr' | 'en' => {
  return locale.split('-')[0].toLowerCase() as 'fr' | 'en';
};

export const useCompanyStore = defineStore('company', {
  state: () => ({
    company: {
      id: null,
      name: '',
      initials: '',
      logo: '',
      description: '',
      website: '',
      notes: '',
      created_at: '',
      updated_at: '',
      ats_url: ''
    } as Company,
    companyParameters: {
      id: null,
      using_union: false,
      using_portal: false,
      holiday_period_start_at: '',
      has_access_to_send_overtime_to_timesheet: false,
      bilingual_organizational_structures: false,
      hr_period_start_at: '',
      has_standard_hr_year: false,
      whitelabel: {
        id: null,
        code: '',
        link_fr: '',
        link_en: '',
        name_fr: '',
        name_en: '',
        ext_name_fr: '',
        ext_name_en: ''
      },
      vacation_unit: '',
      absence_unit: '',
      has_union: false,
      allow_employee_requests: false
    } as CompanyParameters,
    industry: {
      id: null,
      name_en: '',
      name_fr: ''
    } as Industry,
    companyWhiteLabels: {
      id: null,
      code: '',
      link_fr: '',
      link_en: '',
      name_fr: '',
      name_en: '',
      ext_name_fr: '',
      ext_name_en: ''
    } as Whitelabel
  }),
  getters: {
    getLocalizedIndustry: (state) => (locale: string) => {
      const industry = state.industry;
      return {
        id: industry.id,
        name: industry[`name_${formatLocaleToSimple(locale)}`]
      } as IndustryGetter;
    },
    getLocalizedCompanyWhiteLabels: (state) => (locale: string) => {
      const companyWhiteLabels = state.companyWhiteLabels;
      return {
        id: companyWhiteLabels.id,
        code: companyWhiteLabels.code,
        link: companyWhiteLabels[`link_${formatLocaleToSimple(locale)}`],
        name: companyWhiteLabels[`name_${formatLocaleToSimple(locale)}`],
        ext_name: companyWhiteLabels[`ext_name_${formatLocaleToSimple(locale)}`]
      } as WhitelabelGetter;
    }
  },
  actions: {
    formatCompany(companyData: Company) {
      this.company = {
        id: companyData.id,
        name: companyData.name,
        initials: companyData.name
          .split(' ')
          .map((n) => n[0])
          .join('')
          .toUpperCase(),
        logo: companyData.logo,
        description: companyData.description,
        website: companyData.website,
        notes: companyData.notes,
        created_at: companyData.created_at,
        updated_at: companyData.updated_at,
        ats_url: companyData.ats_url
      };

      if (companyData.companyParameters) {
        this.companyParameters = {
          id: companyData.companyParameters.id,
          using_union: companyData.companyParameters.using_union,
          using_portal: companyData.companyParameters.using_portal,
          holiday_period_start_at:
            companyData.companyParameters.holiday_period_start_at,
          has_access_to_send_overtime_to_timesheet:
            companyData.companyParameters
              .has_access_to_send_overtime_to_timesheet,
          bilingual_organizational_structures:
            companyData.companyParameters.bilingual_organizational_structures,
          hr_period_start_at: companyData.companyParameters.hr_period_start_at,
          has_standard_hr_year:
            companyData.companyParameters.has_standard_hr_year,
          vacation_unit: companyData.companyParameters.vacation_unit,
          absence_unit: companyData.companyParameters.absence_unit,
          has_union: companyData.companyParameters.has_union,
          allow_employee_requests:
            companyData.companyParameters.allow_employee_requests
        };
      }

      if (companyData.companyParameters?.whitelabel) {
        this.companyWhiteLabels = {
          id: companyData.companyParameters.whitelabel.id,
          code: companyData.companyParameters.whitelabel.code,
          link_fr: companyData.companyParameters.whitelabel.link_fr,
          link_en: companyData.companyParameters.whitelabel.link_en,
          name_fr: companyData.companyParameters.whitelabel.name_fr,
          name_en: companyData.companyParameters.whitelabel.name_en,
          ext_name_fr: companyData.companyParameters.whitelabel.ext_name_fr,
          ext_name_en: companyData.companyParameters.whitelabel.ext_name_en
        };
      }

      if (companyData.industry) {
        this.industry = {
          id: companyData.industry.id,
          name_en: companyData.industry.name_en,
          name_fr: companyData.industry.name_fr
        };
      }
    }
  }
});
