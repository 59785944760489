<template>
  <FHeaderPrimary :title="t('title')" />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import COMPANY_ABILITIES from '@/modules/company/constants/abilities';

definePage({
  meta: {
    ability: COMPANY_ABILITIES.INFORMATIONS.ANY
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Informations'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Information'
    }
  }
});
</script>
