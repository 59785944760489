<template>
  <FHeaderPrimary
    :back-route="DOCUMENTS_ROUTES.E_SIGN.ROOT"
    :title="t('title')"
  />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import DOCUMENTS_ROUTES from '@/modules/documents/constants/urls';
import DOCUMENTS_ABILITIES from '@/modules/documents/constants/abilities';

definePage({
  meta: {
    ability: DOCUMENTS_ABILITIES.E_SIGN.CONFIG.ANY
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Gestion des gabarits'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Templates management'
    }
  }
});
</script>
