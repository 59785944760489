<template>
  <VTooltip :disabled="isTooltipDisabled">
    <template #activator="{ props }">
      <VListItem
        v-dynamic-data-attr="{ all: `navigation-item-${itemData.name}` }"
        class="text-no-wrap"
        v-bind="{ ...props, ...getListItemData(itemData) }"
        @click="
          navigationRailStore.closeMobileNav();
          $emit('click', itemData);
        "
      >
        <template
          v-if="itemData.icon"
          #prepend
        >
          <FIconMD :icon="itemData.icon" />
        </template>
        {{ t(itemData.text) }}
        <template
          v-if="itemData.href"
          #append
        >
          <FIconMD icon="open_in_new" />
        </template>
      </VListItem>
    </template>
    {{ t(itemData.text) }}
  </VTooltip>
</template>

<script lang="ts" setup>
import type { ListItem, MenuItem } from '@/components/FNavigation.types';
import { storeToRefs } from 'pinia';
import { useNavigationRailStore } from '@/stores/navigationRail';
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';

defineProps({
  itemData: {
    type: Object as () => MenuItem,
    required: true
  }
});

defineEmits(['click']);

const { t } = useI18n({
  useScope: 'global',
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      back: 'Retour',
      settings: 'Paramètres'
    },
    [SUPPORTED_LANGUAGES.en]: {
      back: 'Back',
      settings: 'Settings'
    }
  }
});

const { isTooltipDisabled } = storeToRefs(useNavigationRailStore());
const navigationRailStore = useNavigationRailStore();

const getListItemData = (item: MenuItem) => {
  const listItem: ListItem = {
    value: item.name
  };

  if (item.href) {
    listItem.href = item.href;
    listItem.target = '_blank';
  } else if (item.to) {
    listItem.to = item.to;
  }

  return listItem;
};
</script>

<style scoped></style>
