const EMPLOYEES_ROOT = 'employees';
const CONFIG_ROOT = 'config';

const EMPLOYEES_CONFIGURATION_ROOT = `${EMPLOYEES_ROOT}:list:config`;
const HISTORY_ROOT = `${EMPLOYEES_ROOT}:history`;
const CLIMATE_ROOT = `${EMPLOYEES_ROOT}:climate`;
const INCIDENTS_ROOT = `${EMPLOYEES_ROOT}:incidents`;

const EXPENSE_ACCOUNTS_ROOT = `${EMPLOYEES_ROOT}:expense-account`;
const EXPENSE_ACCOUNTS_CONFIG_ROOT = `${EXPENSE_ACCOUNTS_ROOT}:${CONFIG_ROOT}`;

const EMPLOYEE_ROOT = `${EMPLOYEES_ROOT}:employee`;
const EMPLOYEE_CONFIGURATION_ROOT = `${EMPLOYEE_ROOT}:configuration`;
const EMPLOYEE_TIME_MANAGEMENT_ROOT = `${EMPLOYEE_ROOT}:time-management`;
const EMPLOYEE_DISCIPLINE_ROOT = `${EMPLOYEE_ROOT}:discipline`;
const EMPLOYEE_PERFORMANCES_ROOT = `${EMPLOYEE_ROOT}:performances`;
const EMPLOYEE_DOCUMENTS_ROOT = `${EMPLOYEE_ROOT}:documents`;

const EMPLOYEES_ABILITIES = {
  ANY: `${EMPLOYEES_ROOT}`,
  LIST: {
    ANY: `${EMPLOYEES_ROOT}:list`,
    INDEX: `${EMPLOYEES_ROOT}:list:index`,
    SUMMARY: `${EMPLOYEES_ROOT}:list:summary`
  },
  CONFIGURATION: {
    ANY: `${EMPLOYEES_CONFIGURATION_ROOT}`,
    PROFIL_CUSTOMIZATION: `${EMPLOYEES_CONFIGURATION_ROOT}:profile-customization`
  },
  EMPLOYEE: {
    ANY: EMPLOYEE_ROOT,
    INFORMATIONS: `${EMPLOYEE_ROOT}:informations`,
    NOTE: `${EMPLOYEE_ROOT}:note`,
    ONBOARDING: `${EMPLOYEE_ROOT}:onboarding`,
    DOCUMENTS: {
      ANY: `${EMPLOYEE_DOCUMENTS_ROOT}`,
      VIEW: `${EMPLOYEE_DOCUMENTS_ROOT}:view`,
      READ: `${EMPLOYEE_DOCUMENTS_ROOT}:read`,
      WRITE: `${EMPLOYEE_DOCUMENTS_ROOT}:write`
    },
    TIME_MANAGEMENT: {
      ANY: EMPLOYEE_TIME_MANAGEMENT_ROOT,
      ABSENCE: {
        ANY: `${EMPLOYEE_TIME_MANAGEMENT_ROOT}:absence`,
        CONTROL: `${EMPLOYEE_TIME_MANAGEMENT_ROOT}:absence:control`,
        REQUEST: `${EMPLOYEE_TIME_MANAGEMENT_ROOT}:absence:request`
      },
      VACATION: {
        ANY: `${EMPLOYEE_TIME_MANAGEMENT_ROOT}:vacation`,
        CONTROL: `${EMPLOYEE_TIME_MANAGEMENT_ROOT}:vacation:control`,
        REQUEST: `${EMPLOYEE_TIME_MANAGEMENT_ROOT}:vacation:request`
      },
      OVERTIME: {
        ANY: `${EMPLOYEE_TIME_MANAGEMENT_ROOT}:overtime`,
        CONTROL: `${EMPLOYEE_TIME_MANAGEMENT_ROOT}:overtime:control`,
        REQUEST: `${EMPLOYEE_TIME_MANAGEMENT_ROOT}:overtime:request`
      },
      TIMESHEET: `${EMPLOYEE_TIME_MANAGEMENT_ROOT}:timesheet`
    },
    EXPENSE_ACCOUNTS: `${EMPLOYEE_ROOT}:expense-account`,
    SURVEYS: `${EMPLOYEE_ROOT}:surveys`,
    CONFIGURATION: {
      ANY: EMPLOYEE_CONFIGURATION_ROOT,
      CUSTOM_FIELD_PERSO: `${EMPLOYEE_CONFIGURATION_ROOT}:custom-field-perso`,
      CUSTOM_FIELD_CORPO: `${EMPLOYEE_CONFIGURATION_ROOT}:custom-field-corpo`,
      PROJECTS: `${EMPLOYEE_CONFIGURATION_ROOT}:projects`
    },
    DISCIPLINE: {
      ANY: `${EMPLOYEE_DISCIPLINE_ROOT}:analysis`,
      CONTROL: `${EMPLOYEE_DISCIPLINE_ROOT}:control`
    },
    PERFORMANCES: {
      ANY: EMPLOYEE_PERFORMANCES_ROOT,
      TRAINING: `${EMPLOYEE_PERFORMANCES_ROOT}:training`,
      CAREERS: `${EMPLOYEE_PERFORMANCES_ROOT}:careers`,
      CERTIFICATES: `${EMPLOYEE_PERFORMANCES_ROOT}:certificates`,
      ANALYSIS: `${EMPLOYEE_PERFORMANCES_ROOT}:analysis`
    },
    STATISTICS: `${EMPLOYEE_ROOT}:statistics:index`,
    WORK_ACCIDENTS: `${EMPLOYEE_ROOT}:work-accidents`
  },
  HISTORY: {
    ANY: HISTORY_ROOT,
    CORPORATE: `${HISTORY_ROOT}:corporate`,
    PERSONAL: `${HISTORY_ROOT}:personal`
  },
  EXPENSE_ACCOUNTS: {
    ANY: EXPENSE_ACCOUNTS_ROOT,
    LIST: `${EXPENSE_ACCOUNTS_ROOT}:analysis`,
    REQUEST: `${EXPENSE_ACCOUNTS_ROOT}:request`,
    CONFIG: {
      ANY: EXPENSE_ACCOUNTS_CONFIG_ROOT,
      ADMISSIBLE: `${EXPENSE_ACCOUNTS_CONFIG_ROOT}:admissible`
    }
  },
  INCIDENTS: {
    ANY: `${INCIDENTS_ROOT}:index`
  },
  CLIMATE: {
    ANY: CLIMATE_ROOT,
    ANNUAL: `${CLIMATE_ROOT}:annual`,
    ANALYSIS: `${CLIMATE_ROOT}:analysis`,
    COMMENTS: `${CLIMATE_ROOT}:comments`
  }
};

export default EMPLOYEES_ABILITIES;
