import type { Hook, Module } from '@/modules/helpers.types';

// Utility function to load and validate modules/hooks
async function loadAndValidate<
  T extends { initialize?: Function; bind?: Function }
>(item: () => Promise<unknown>, type: 'module' | 'hook'): Promise<T> {
  const result = (await item()) as T;
  const methodName = type === 'module' ? 'initialize' : 'bind';
  if (typeof result[methodName] !== 'function') {
    throw new Error(`${type} does not have a ${methodName} function`);
  }
  return result;
}

export async function registerModules(
  modules: Record<string, () => Promise<unknown>>
): Promise<void> {
  for (const path in modules) {
    try {
      const module = await loadAndValidate<Module>(modules[path], 'module');
      module.initialize();
    } catch (error) {
      // Handle specific error if needed or rethrow
      console.error(`Error registering module ${path}: ${error}`);
      throw error;
    }
  }
}

export async function registerHooks(
  hooks: Record<string, () => Promise<unknown>>
): Promise<void> {
  for (const path in hooks) {
    try {
      const hook = await loadAndValidate<Hook>(hooks[path], 'hook');
      hook.bind();
    } catch (error) {
      // Handle specific error if needed or rethrow
      console.error(`Error registering hook ${path}: ${error}`);
      throw error;
    }
  }
}
