import { getProgramDataByID } from '@/modules/shared/core/utils/mapping';
import type { MessageEventData } from '@/modules/shared/core/objects/MessageEventData';
import {
  extractQueryParams,
  flattenObject,
  replaceParamsInRoute
} from '@/modules/shared/core/utils/route';
import router from '@/plugins/vueRouter';
import type { RouteLocationRaw } from 'vue-router';

const getAllSearchWithoutSomeParams = (
  searchParams: URLSearchParams,
  paramsToIgnore: string[]
) => {
  const newSearchParams = new URLSearchParams();
  searchParams.forEach((value, key) => {
    if (!paramsToIgnore.includes(key)) {
      newSearchParams.append(key, value);
    }
  });
  return newSearchParams;
};

const hasSearchParams = (searchParams: URLSearchParams): boolean => {
  return [...searchParams.entries()].length > 0;
};

export function legacyToNewRoute(messageEventData: MessageEventData) {
  const requestedRoute = messageEventData.data;

  const searchParams = new URLSearchParams(requestedRoute.split('?')[1]);

  let programData;
  try {
    programData = getProgramDataByID(Number(searchParams.get('f')));
  } catch (error) {
    throw new Error(`Invalid f parameter ${messageEventData.data}`);
  }
  const extractedParams = extractQueryParams(requestedRoute);
  const flattenedParams = flattenObject(extractedParams);
  const paramsToIgnore: string[] = ['f', 'frameless'];
  const otherSearchParams = getAllSearchWithoutSomeParams(
    searchParams,
    paramsToIgnore
  );
  const routeRaw = router.resolve({
    name: programData.routeName
  } as RouteLocationRaw);
  let returnedRoute = `${replaceParamsInRoute(routeRaw.fullPath, flattenedParams)}`;
  if (hasSearchParams(otherSearchParams)) {
    returnedRoute += `?${otherSearchParams.toString()}`;
  }
  return returnedRoute;
}

export function legacyToSPARoute(messageEventData: MessageEventData) {
  const requestedRoute = messageEventData.data;

  const searchParams = new URLSearchParams(requestedRoute.split('?')[1]);

  const employeeId = searchParams.get('em_id');
  if (!employeeId) {
    throw new Error('em_id parameter is required for f=201020');
  }
  let returnedRoute = `/employees/${employeeId}/informations`;

  const paramsToIgnore: string[] = ['f', 'em_id', 'frameless'];
  const otherSearchParams = getAllSearchWithoutSomeParams(
    searchParams,
    paramsToIgnore
  );
  if (hasSearchParams(otherSearchParams)) {
    returnedRoute += `?${otherSearchParams.toString()}`;
  }
  return returnedRoute;
}
