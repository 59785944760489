<template>
  <FHeaderPrimary
    :back-route="selectedBackUrl"
    :title="t('title')"
  />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import EMPLOYEES_ABILITIES from '@/modules/employees/constants/abilities';
import EMPLOYEES_ROUTES from '@/modules/employees/constants/urls';

definePage({
  meta: {
    ability: EMPLOYEES_ABILITIES.EXPENSE_ACCOUNTS.LIST
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Modification de compte de dépense'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Expense account'
    }
  }
});

const route = useRoute();
const employeeId = route.query.em_id as string;

const { backButtonSelectedName } = useBackPreviousButton([
  EMPLOYEES_ROUTES.EXPENSE_ACCOUNTS.ROOT,
  EMPLOYEES_ROUTES.EMPLOYEE.EXPENSE_ACCOUNTS
]);

let selectedBackUrl;

if (backButtonSelectedName === EMPLOYEES_ROUTES.EMPLOYEE.EXPENSE_ACCOUNTS) {
  selectedBackUrl = {
    name: EMPLOYEES_ROUTES.EMPLOYEE.EXPENSE_ACCOUNTS,
    query: { employee_id: employeeId }
  };
} else {
  selectedBackUrl = backButtonSelectedName;
}
</script>
