import { defineStore } from 'pinia';
import CONTROL_TYPES from '@/modules/shared/core/constants/controlTypes';
import type { SetValuesAndOpenDialogParams } from '@/modules/shared/core/stores/fControlDialog.types';

export const useFControlDialog = defineStore('fControlDialog', () => {
  const route = ref('');
  const routeAbility = ref('');
  const title = ref('');
  const type = ref('');
  const btnText = ref('');

  const { isDialogShowed, updateDialog } = useDialogLegacyIframe();
  const setValuesAndOpenDialog = ({
    routeValue,
    routeAbilityValue,
    titleValue,
    typeValue,
    btnTextValue
  }: SetValuesAndOpenDialogParams) => {
    route.value = routeValue;
    routeAbility.value = routeAbilityValue;
    title.value = titleValue;
    type.value = typeValue;
    isDialogShowed.value = true;
    btnText.value = btnTextValue ?? '';
  };

  const requestDialogHooks = ref<Function[]>([]);
  const addRequestDialogHook = (hook: Function) => {
    requestDialogHooks.value.push(hook);
  };
  const removeRequestDialogHook = (hook: Function) => {
    requestDialogHooks.value = requestDialogHooks.value.filter(
      (h) => h !== hook
    );
  };
  const handleRequestDialog = (type: CONTROL_TYPES) => {
    requestDialogHooks.value.forEach((hook) =>
      hook(setValuesAndOpenDialog, type)
    );
  };

  return {
    route,
    routeAbility,
    title,
    type,
    btnText,
    isDialogShowed,
    updateDialog,
    setValuesAndOpenDialog,
    addRequestDialogHook,
    removeRequestDialogHook,
    handleRequestDialog
  };
});
