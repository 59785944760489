<template>
  <VAlert
    v-model="alert"
    :closable="false"
    :variant="variant"
    :color="color"
    :type="type"
    :class="alertClass"
    class="relative"
    @update:model-value="$emit('close')"
  >
    <template #prepend>
      <slot name="prepend">
        <FIconMD
          v-if="icon"
          weight="400"
          :size="iconSize"
          :icon="icon"
        />
      </slot>
    </template>
    <template
      v-if="getTitle"
      #title
    >
      <slot name="title">
        <h3
          v-dynamic-data-attr="{ all: 'f-alert-title' }"
          class="text-title-small mb-1"
        >
          {{ getTitle }}
        </h3>
      </slot>
    </template>
    <template #text>
      <slot name="text">
        <p
          v-if="getText"
          class="text-body-medium"
        >
          {{ getText }}
        </p>
      </slot>
    </template>
    <template
      v-if="closable"
      #close
    >
      <VBtn
        v-dynamic-data-attr="{ all: 'f-alert-close' }"
        :color="getButtonColor"
        size="small"
        class="icon-btn-absolute"
        variant="text"
        @click="$emit('close')"
      >
        <FIconMD
          icon="close"
          variant="text"
          weight="400"
        />
      </VBtn>
    </template>
  </VAlert>
</template>

<script lang="ts" setup>
import { defineProps } from 'vue';
import type { FAlertProps } from '@/modules/shared/core/components/FAlert.types';

const {
  isVisible,
  messageTitle,
  messageText,
  variant,
  closable = false,
  type,
  iconSize = '20'
} = defineProps<FAlertProps>();
defineEmits(['close']);

const alert = computed(() => isVisible);

const getButtonColor = computed(() => {
  switch (type) {
    case 'error':
      return variant === 'flat' ? 'white' : 'error';
    case 'success':
      return 'success';
    case 'warning':
      return 'warning';
    case 'info':
      return 'info';
    default:
      return '';
  }
});

const { getMessage } = useGetMessage();

const getText = computed(() => (messageText ? getMessage(messageText) : ''));
const getTitle = computed(() => (messageTitle ? getMessage(messageTitle) : ''));
</script>
<style lang="scss" scoped>
.icon-btn-absolute {
  position: absolute;
  top: 0;
  right: 4px;
  transform: translateY(10%);
}
</style>
