<template>
  <FTopBarSideSheet
    v-if="hasAnyToolEnabled"
    v-dynamic-data-attr="{ all: 'f-top-bar-tools-side-sheet' }"
    :description="t('description')"
    :is-opened="isOpened"
    :items="itemsToDisplay"
    :title="t('title')"
    :translate="t"
    @update:is-opened="isOpened = $event"
  />
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import { type Ref, ref } from 'vue';
import { usePermissionsStore } from '@/modules/shared/core/stores/permissions';
import { useToolsSideSheetStore } from '@/stores/toolsSideSheet';
import type {
  TopBarSideSheetChildItem,
  TopBarSideSheetItem
} from '@/components/FTopBarSideSheet.types';

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Outils',
      description: 'Accéder rapidement aux contenus importants'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Tools',
      description: 'Quickly access important content'
    }
  }
});

const { isOpened, hasAnyToolEnabled } = storeToRefs(useToolsSideSheetStore());
const items = ref([]) as Ref<TopBarSideSheetItem[]>;

const { canAccess } = usePermissionsStore();
const filterChildren = (children: TopBarSideSheetChildItem[]) => {
  return children.filter((child) => {
    return canAccess(child.ability);
  });
};

const itemsToDisplay = computed(() => {
  const newItems: TopBarSideSheetItem[] = [];
  items.value.map((item) => {
    const children = filterChildren(item.children);
    if (children.length > 0) {
      newItems.push({ ...item, children });
    }
  });

  return newItems;
});
</script>
<style lang="sass" scoped></style>
