import BASE_ROUTE_PARAMETERS from '@/modules/parameters/constants/urls';

const BASE_ROUTE_USERS = `${BASE_ROUTE_PARAMETERS}/users`;

const USERS_ROUTES = {
  ROOT: BASE_ROUTE_USERS,
  EDIT: `${BASE_ROUTE_USERS}.edit`,
  CREATE: `${BASE_ROUTE_USERS}/create`,
  BULK: `${BASE_ROUTE_USERS}/bulk`
};

export default USERS_ROUTES;
