<template>
  <FEmployeeInformationsPrivateSection v-if="hasAccessToPrivateProfile" />
  <FEmployeeInformationsPublicSection v-else />
</template>
<script lang="ts" setup>
import EMPLOYEES_ABILITIES from '@/modules/employees/constants/abilities';
import FEmployeeInformationsPrivateSection from '@/modules/employees/pages_sections/FEmployeeInformationsPrivateSection.vue';
import FEmployeeInformationsPublicSection from '@/modules/employees/pages_sections/FEmployeeInformationsPublicSection.vue';
import EMPLOYEE_PERMISSIONS from '@/modules/shared/core/enums/employeePermissions';
import { useSingleEmployeeStore } from '@/modules/employees/stores/employee';

definePage({
  meta: {
    ability: EMPLOYEES_ABILITIES.EMPLOYEE.INFORMATIONS
  }
});

const singleEmployeeStore = useSingleEmployeeStore('list');
const { employeeData } = storeToRefs(singleEmployeeStore);

const hasAccessToPrivateProfile = computed(() => {
  return EMPLOYEE_PERMISSIONS.can(
    employeeData.value.permissionBit,
    EMPLOYEE_PERMISSIONS.VALUES.READ
  );
});
</script>
<style lang="scss">
@import '@/styles/variables.scss';

.anchor-negative-employee-informations {
  position: absolute;
  top: calc(
    0px - var(--v-layout-top) - $tabs-height - $rounded-base - $f-primary-height
  );
}

.employee-info-anchors-menu__sticky {
  position: sticky;
  top: calc(
    var(--v-layout-top) + $tabs-height + $container-spacing + $f-primary-height
  );
  z-index: 1;
}

.employee-info-tabs__sticky {
  position: sticky;
  top: calc(var(--v-layout-top) + $f-primary-height);
  z-index: 1;
}
</style>
