import BASE_ROUTE_PARAMETERS from '@/modules/parameters/constants/urls';

const BASE_ROUTE_INTEGRATIONS = `${BASE_ROUTE_PARAMETERS}/integrations`;

const INTEGRATIONS_ROUTES = {
  ROOT: BASE_ROUTE_INTEGRATIONS,
  SSO: `${BASE_ROUTE_INTEGRATIONS}/sso`,
  API: `${BASE_ROUTE_INTEGRATIONS}/api`,
  NETHRIS: `${BASE_ROUTE_INTEGRATIONS}/nethris`,
  PAYROLL: `${BASE_ROUTE_INTEGRATIONS}/payroll`,
  PAYWORKS: `${BASE_ROUTE_INTEGRATIONS}/payworks`
};

export default INTEGRATIONS_ROUTES;
