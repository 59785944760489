<template>
  <div>
    <VTooltip>
      <template #activator="{ props }">
        <VBtn
          v-dynamic-data-attr="{ all: 'f-nav-toggler' }"
          :icon="true"
          color="on-surface-variant"
          v-bind="props"
          variant="text"
          @click.stop="$emit('toggle-rail')"
        >
          <FIconMD :icon="railIcon" />
        </VBtn>
      </template>
      {{ t(railTooltipText) }}
    </VTooltip>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';

const componentProps = defineProps({
  navIsOpen: {
    type: Boolean,
    default: false
  }
});

defineEmits(['toggle-rail']);

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      open: 'Ouvrir',
      close: 'Fermer'
    },
    [SUPPORTED_LANGUAGES.en]: {
      open: 'Open',
      close: 'Close'
    }
  }
});

const railIcon = computed(() =>
  componentProps.navIsOpen ? 'menu_open' : 'menu'
);
const railTooltipText = computed(() =>
  componentProps.navIsOpen ? 'close' : 'open'
);
</script>
