const TestIdDirective = {
  beforeMount: function (el: Element, { value }: { value: string }) {
    if (import.meta.env.VITE_APP_DISPLAY_TEST_IDS === '1') {
      const valueAsArray = Array.isArray(value) ? value : [value];

      valueAsArray.forEach((val) => {
        el.setAttribute('data-test', val);
      });
    }
  }
};

export default TestIdDirective;
