import type { PostMessageHandlerInterface } from '@/modules/shared/core/composables/PostMessage/handlers/PostMessageHandlerInterface';
import type { MessageEventData } from '@/modules/shared/core/objects/MessageEventData';
import type { MessageRespondFn } from '@/modules/shared/core/stores/messageHandlersStore';
import type { Router } from 'vue-router';
import { legacyToSPARoute } from '@/modules/shared/core/composables/PostMessage/utils/legacy';

export class NavigateToSPAPostMessageHandler
  implements PostMessageHandlerInterface
{
  constructor(private readonly router: Router) {}

  async handle(
    respond: MessageRespondFn,
    messageEventData: MessageEventData
  ): Promise<void> {
    await this.router.push(legacyToSPARoute(messageEventData));
  }
}
