<template>
  <VIcon
    :icon="`md:${icon}`"
    :style="fontVariationSettings"
    class="material-symbols-outlined"
  />
</template>

<script lang="ts" setup>
import type { FIconMDProps } from './FIconMD.types';

const {
  fill = '0',
  weight = '300',
  opticalSize = '24'
} = defineProps<FIconMDProps>();

const fontVariationSettings = computed(() => {
  return `font-variation-settings: 'FILL' ${fill}, 'wght' ${weight}, 'GRAD' -25, 'opsz' ${opticalSize}`;
});
</script>
