<template>
  <FHeaderPrimary
    :add-ability="ADVANCEMENT_ABILITIES.TRAINING.CONTROL"
    :add-route="ADVANCEMENT_ROUTES.TRAINING.CONTROL"
    :title="t('title')"
  />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import ADVANCEMENT_ABILITIES from '@/modules/advancement/constants/abilities';
import ADVANCEMENT_ROUTES from '@/modules/advancement/constants/urls';

definePage({
  meta: {
    ability: ADVANCEMENT_ABILITIES.TRAINING.LIST,
    bypassAbility: [ADVANCEMENT_ABILITIES.TRAINING.CONTROL]
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Formation'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Training'
    }
  }
});
</script>
