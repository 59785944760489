<template>
  <FHeaderPrimary
    :back-route="COMPANY_ROUTES.SALARY.ROOT"
    :title="t('title')"
  />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import COMPANY_ABILITIES from '@/modules/company/constants/abilities';
import COMPANY_ROUTES from '@/modules/company/constants/urls';

definePage({
  meta: {
    ability: COMPANY_ABILITIES.SALARY.RANGE
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Ajouter une échelle'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Add a range'
    }
  }
});
</script>
