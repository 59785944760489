const BASE_ROUTE_COMPANY = '/company';

const BASE_ROUTE_INFORMATIONS = `${BASE_ROUTE_COMPANY}/informations`;
const BASE_ROUTE_STANDARDS = `${BASE_ROUTE_COMPANY}/standards`;
const BASE_ROUTE_STRUCTURE = `${BASE_ROUTE_COMPANY}/structure`;
const BASE_ROUTE_SALARY = `${BASE_ROUTE_COMPANY}/salary`;

const COMPANY_ROUTES = {
  ROOT: BASE_ROUTE_COMPANY,
  INFORMATIONS: {
    ROOT: BASE_ROUTE_INFORMATIONS
  },
  STANDARDS: {
    ROOT: BASE_ROUTE_STANDARDS,
    EMPLOYEES: `${BASE_ROUTE_STANDARDS}/employees`,
    ORGANISATIONAL: `${BASE_ROUTE_STANDARDS}/organisational`,
    TRANSFER: `${BASE_ROUTE_STANDARDS}/bank-transfer`
  },
  STRUCTURE: {
    ROOT: BASE_ROUTE_STRUCTURE,
    WORKINGSITES: `${BASE_ROUTE_STRUCTURE}/working-sites`,
    DEPARTMENTS: `${BASE_ROUTE_STRUCTURE}/departments`,
    POSITIONS: `${BASE_ROUTE_STRUCTURE}/positions`,
    UNION: `${BASE_ROUTE_STRUCTURE}/union`,
    CHART: `${BASE_ROUTE_STRUCTURE}/chart`,
    CHAIRS: `${BASE_ROUTE_STRUCTURE}/chairs`,
    POSITIONID: `${BASE_ROUTE_STRUCTURE}/chairs.position-id`
  },
  SALARY: {
    ROOT: BASE_ROUTE_SALARY,
    RANGE: `${BASE_ROUTE_SALARY}.range`
  }
};

export default COMPANY_ROUTES;
