<template>
  <FHeaderPrimary
    :add-ability="TIME_MANAGEMENT_ABILITIES.ABSENCE.CONTROL"
    :add-summary-ability="TIME_MANAGEMENT_ABILITIES.ABSENCE.SUMMARY.ANY"
    :add-summary-route="TIME_MANAGEMENT_ROUTES.ABSENCE.SUMMARY.ROOT"
    :add-summary-text="t('summary')"
    :title="t('title')"
    @add:click="handleAddClick"
  />
  <FLayoutContentForIframe />
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import TIME_MANAGEMENT_ABILITIES from '@/modules/time-management/constants/abilities';
import TIME_MANAGEMENT_ROUTES from '@/modules/time-management/constants/urls';
import CONTROL_TYPES from '@/modules/shared/core/constants/controlTypes';

definePage({
  meta: {
    ability: TIME_MANAGEMENT_ABILITIES.ABSENCE.LIST,
    bypassAbility: [
      TIME_MANAGEMENT_ABILITIES.ABSENCE.CONTROL,
      TIME_MANAGEMENT_ABILITIES.ABSENCE.SUMMARY.ANY
    ]
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Absences',
      summary: 'Voir les sommaires'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Absences',
      summary: 'View summaries'
    }
  }
});

const { handleAddClick } = useTimeManagementList(CONTROL_TYPES.ABSENCE);
</script>
<style lang="sass" scoped></style>
