const BASE_ROUTE_REPORTS = '/reports';
const BASE_DASHBOARD_ROUTE = `${BASE_ROUTE_REPORTS}/dashboard`;
const BASE_ROUTE_DISCIPLINE = `${BASE_ROUTE_REPORTS}/discipline`;

const REPORTS_ROUTES = {
  ROOT: BASE_ROUTE_REPORTS,
  SUMMARY: `${BASE_ROUTE_REPORTS}/summary`,
  DASHBOARD: {
    ROOT: BASE_DASHBOARD_ROUTE,
    ANALYSIS: `${BASE_DASHBOARD_ROUTE}/analysis`,
    SUMMARY: `${BASE_DASHBOARD_ROUTE}/summary`
  },
  KPI: `${BASE_ROUTE_REPORTS}/kpi`,
  DISCIPLINE: {
    ROOT: BASE_ROUTE_DISCIPLINE,
    CONTROL: `${BASE_ROUTE_DISCIPLINE}/control`,
    EDIT: `${BASE_ROUTE_DISCIPLINE}/:id`
  }
};

export default REPORTS_ROUTES;
