import PARAMETERS_ABILITIES_ROOT from '@/modules/parameters/constants/abilities';

const INTEGRATIONS_ROOT = `${PARAMETERS_ABILITIES_ROOT}:integrations`;

const INTEGRATIONS_ABILITIES = {
  ANY: `${INTEGRATIONS_ROOT}:index`,
  SSO: `${INTEGRATIONS_ROOT}:sso`,
  API: `${INTEGRATIONS_ROOT}:api`,
  NETHRIS: `${INTEGRATIONS_ROOT}:nethris`,
  PAYROLL: `${INTEGRATIONS_ROOT}:payroll`,
  PAYWORKS: `${INTEGRATIONS_ROOT}:payworks`
};

export default INTEGRATIONS_ABILITIES;
