<template>
  <router-view />
</template>
<script lang="ts" setup>
import COMPANY_ABILITIES from '@/modules/company/constants/abilities';

definePage({
  meta: {
    needAuth: true,
    ability: COMPANY_ABILITIES.ANY
  }
});
</script>
<style lang="sass" scoped></style>
