<template>
  <FHeaderPrimary
    :title="t('title')"
    :back-route="SYSTEM_MANAGEMENT_ROUTES.COMPANIES.LIST"
  />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import SYSTEM_MANAGEMENT_ABILITIES from '@/modules/parameters/submodules/system-management/constants/abilities';
import SYSTEM_MANAGEMENT_ROUTES from '@/modules/parameters/submodules/system-management/constants/urls';

definePage({
  meta: {
    ability: SYSTEM_MANAGEMENT_ABILITIES.COMPANIES.EDIT
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Modifier une compagnie'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Edit a company'
    }
  }
});
</script>
