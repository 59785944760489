import type { Program } from '@/modules/shared/core/utils/mapping/index.types';
import LEGACY_TYPE from '@/modules/shared/core/constants/legacyType';
import ONBOARDING_ABILITIES from '@/modules/onboarding/constants/abilities';
import ONBOARDING_ROUTES from '@/modules/onboarding/constants/urls';

const ARRIVALS_MAPPING = [
  {
    name: ONBOARDING_ABILITIES.ARRIVALS.LIST,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 201040,
    module: ONBOARDING_ABILITIES.ARRIVALS.ANY,
    routeName: ONBOARDING_ROUTES.ARRIVALS.ROOT
  },
  {
    name: ONBOARDING_ABILITIES.ARRIVALS.CONFIG.TASKS,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505090,
    module: ONBOARDING_ABILITIES.ARRIVALS.ANY,
    routeName: ONBOARDING_ROUTES.ARRIVALS.CONFIG.TASKS
  },
  {
    name: ONBOARDING_ABILITIES.ARRIVALS.CONFIG.TEMPLATES,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505091,
    module: ONBOARDING_ABILITIES.ARRIVALS.ANY,
    routeName: ONBOARDING_ROUTES.ARRIVALS.CONFIG.TEMPLATES
  },
  {
    name: ONBOARDING_ABILITIES.ARRIVALS.CONFIG.TITLES,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505092,
    module: ONBOARDING_ABILITIES.ARRIVALS.ANY,
    routeName: ONBOARDING_ROUTES.ARRIVALS.CONFIG.TITLES
  }
] as Program[];

const DEPARTURES_MAPPING = [
  {
    name: ONBOARDING_ABILITIES.DEPARTURES.LIST,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 208010,
    module: ONBOARDING_ABILITIES.DEPARTURES.ANY,
    routeName: ONBOARDING_ROUTES.DEPARTURES.ROOT
  },
  {
    name: ONBOARDING_ABILITIES.DEPARTURES.CONTROL,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 208020,
    module: ONBOARDING_ABILITIES.DEPARTURES.ANY,
    routeName: ONBOARDING_ROUTES.DEPARTURES.CONTROL
  },
  {
    name: ONBOARDING_ABILITIES.DEPARTURES.CONFIG.TASKS,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505090,
    module: ONBOARDING_ABILITIES.DEPARTURES.ANY,
    routeName: ONBOARDING_ROUTES.DEPARTURES.CONFIG.TASKS
  },
  {
    name: ONBOARDING_ABILITIES.DEPARTURES.CONFIG.TEMPLATES,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505091,
    module: ONBOARDING_ABILITIES.DEPARTURES.ANY,
    routeName: ONBOARDING_ROUTES.DEPARTURES.CONFIG.TEMPLATES
  },
  {
    name: ONBOARDING_ABILITIES.DEPARTURES.CONFIG.TITLES,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505092,
    module: ONBOARDING_ABILITIES.DEPARTURES.ANY,
    routeName: ONBOARDING_ROUTES.DEPARTURES.CONFIG.TITLES
  }
] as Program[];

const ONBOARDING_MAPPING = [
  ...ARRIVALS_MAPPING,
  ...DEPARTURES_MAPPING
] as Program[];

export default ONBOARDING_MAPPING;
