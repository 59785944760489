<template>
  <FHeaderPrimary :title="t('title')" />
  <VContainer fluid>
    <VRow>
      <VCol cols="8">
        <FAlert
          :is-visible="true"
          icon="info"
          :message-text="t('alertDescription')"
          :message-title="t('alertTitle')"
        />
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="10">
        <VExpansionPanels v-if="filteredEmployeesSummaryItems.length > 0">
          <VExpansionPanel
            rounded="lg"
            elevation="0"
          >
            <VExpansionPanelTitle
              v-dynamic-data-attr="{ all: `f-vExpansionPanel-openPanel-title` }"
            >
              <VList>
                <VListItem class="pr-0 pl-0">
                  <VListItemTitle class="text-title-small">{{
                    t('employeeTitle')
                  }}</VListItemTitle>
                  <VListItemSubtitle class="text-body-small">{{
                    t('employeeSubtitle')
                  }}</VListItemSubtitle>
                </VListItem>
              </VList>
            </VExpansionPanelTitle>
            <VExpansionPanelText class="hasList">
              <VList>
                <VListItem
                  v-for="item in filteredEmployeesSummaryItems"
                  :key="item.title"
                  :to="item.to"
                  class="pr-6 pl-6"
                >
                  <VListItemTitle class="text-body-medium">{{
                    t(item.title)
                  }}</VListItemTitle>
                  <VListItemSubtitle class="text-body-small">{{
                    t(item.subTitle)
                  }}</VListItemSubtitle>
                </VListItem>
              </VList>
            </VExpansionPanelText>
          </VExpansionPanel>
        </VExpansionPanels>

        <VExpansionPanels v-if="filteredTimesheetSummaryItems.length > 0">
          <VExpansionPanel
            rounded="lg"
            elevation="0"
            class="mt-6"
          >
            <VExpansionPanelTitle
              v-dynamic-data-attr="{ all: `f-vExpansionPanel-openPanel-title` }"
            >
              <VList>
                <VListItem class="pr-0 pl-0">
                  <VListItemTitle class="text-title-small">{{
                    t('timeTitle')
                  }}</VListItemTitle>
                  <VListItemSubtitle class="text-body-small">{{
                    t('timeSubtitle')
                  }}</VListItemSubtitle>
                </VListItem>
              </VList>
            </VExpansionPanelTitle>
            <VExpansionPanelText class="hasList">
              <VList>
                <VListItem
                  v-for="item in filteredTimesheetSummaryItems"
                  :key="item.title"
                  :to="item.to"
                  class="pr-6 pl-6"
                >
                  <VListItemTitle class="text-body-medium">{{
                    t(item.title)
                  }}</VListItemTitle>
                  <VListItemSubtitle class="text-body-small">{{
                    t(item.subTitle)
                  }}</VListItemSubtitle>
                </VListItem>
              </VList>
            </VExpansionPanelText>
          </VExpansionPanel>
        </VExpansionPanels>

        <VExpansionPanels v-if="filteredSafetySummaryItems.length > 0">
          <VExpansionPanel
            rounded="lg"
            elevation="0"
            variant="accordion"
            class="mt-6"
          >
            <VExpansionPanelTitle
              v-dynamic-data-attr="{ all: `f-vExpansionPanel-openPanel-title` }"
            >
              <VList>
                <VListItem class="pr-0 pl-0">
                  <VListItemTitle class="text-title-small">{{
                    t('safetyTitle')
                  }}</VListItemTitle>
                  <VListItemSubtitle class="text-body-small">{{
                    t('safetySubtitle')
                  }}</VListItemSubtitle>
                </VListItem>
              </VList>
            </VExpansionPanelTitle>
            <VExpansionPanelText class="hasList">
              <VList>
                <VListItem
                  v-for="item in filteredSafetySummaryItems"
                  :key="item.title"
                  :to="item.to"
                  class="pr-6 pl-6"
                >
                  <VListItemTitle class="text-body-medium">{{
                    t(item.title)
                  }}</VListItemTitle>
                  <VListItemSubtitle class="text-body-small">{{
                    t(item.subTitle)
                  }}</VListItemSubtitle>
                </VListItem>
              </VList>
            </VExpansionPanelText>
          </VExpansionPanel>
        </VExpansionPanels>
      </VCol>
    </VRow>
  </VContainer>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import REPORTS_ABILITIES from '@/modules/reports/constants/abilities';
import FHeaderPrimary from '@/components/FHeaderPrimary.vue';
import { useReportSummariesStore } from '@/modules/reports/stores/reportSummaries';

definePage({
  meta: {
    ability: REPORTS_ABILITIES.SUMMARY
  }
});

const { t } = useI18n({
  useScope: 'global',
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Liste des sommaires',
      alertTitle: 'Tous vos sommaires, réunis au même endroit!',
      alertDescription:
        'Retrouvez les sommaires des différentes pages centralisés dans le module de rapports. Accédez facilement à toutes vos données en un seul clic!',
      employeeTitle: 'Employés',
      employeeSubtitle:
        "Sommaires et rapports sur les informations personnelles, historiques, incidents disciplinaires, relations de travail et sondages de l'employé",
      timeTitle: 'Temps',
      timeSubtitle:
        'Sommaires et rapports des modules de gestion du temps: Absences, Vacances, Temps supplémentaire et Feuille de temps',
      safetyTitle: 'Santé et sécurité',
      safetySubtitle: "Sommaires et rapports du module d'accident de travail"
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'List of summaries',
      alertTitle: 'All your summaries, gathered in one place!',
      alertDescription:
        'Find the summaries of the different pages centralized in the reports module. Easily access all your data with just one click!',
      employeeTitle: 'Employees',
      employeeSubtitle:
        'Summaries and reports on employee personal information, history, disciplinary incidents, labor relations and surveys',
      timeTitle: 'Time',
      timeSubtitle:
        'Summaries and reports of the time management modules: Absences, Vacation, Overtime and Timesheet',
      safetyTitle: 'Health and safety',
      safetySubtitle: 'Summaries and reports from the Work Accident module'
    }
  }
});

const reportSummaryStore = useReportSummariesStore();

const {
  filteredEmployeesSummaryItems,
  filteredTimesheetSummaryItems,
  filteredSafetySummaryItems
} = storeToRefs(reportSummaryStore);
</script>
