import { defineStore } from 'pinia';
import type {
  ApiEmployee,
  Employee
} from '@/modules/shared/core/services/employees.types';
import { useI18n } from 'vue-i18n';
import { apiEmployeeToEmployee } from '@/modules/shared/core/services/employeesEntity';
import type { Ref } from 'vue';
import type { SupportedLanguageKeys } from '@/modules/shared/core/constants/languages.types';

export const useEmployeeStore = defineStore('employee', () => {
  const employee: Ref<Employee> = ref({} as Employee);

  const getEmployeePositionLocalized = computed(() => {
    const { locale } = useI18n();
    if (!employee.value.position) {
      return '';
    }
    const localeKey = locale.value as SupportedLanguageKeys;
    return employee.value.position[localeKey];
  });

  const formatEmployee = (employeeData: ApiEmployee.Base | null) => {
    if (employeeData) {
      employee.value = apiEmployeeToEmployee(employeeData);
    }
  };

  return {
    employee,
    getEmployeePositionLocalized,
    formatEmployee
  };
});
