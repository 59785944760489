<template>
  <FHeaderPrimary
    :tabs="tabs"
    :back-route="backButtonSelectedName"
    :title="t('title')"
  />
  <FLayoutContentForTabs />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import type { Tab } from '@/components/FTabs.types';
import TIME_MANAGEMENT_ABILITIES from '@/modules/time-management/constants/abilities';
import TIME_MANAGEMENT_ROUTES from '@/modules/time-management/constants/urls';
import REPORTS_ROUTES from '@/modules/reports/constants/urls';
import useBackPreviousButton from '@/modules/shared/core/composables/useBackPreviousButton';

definePage({
  meta: {
    ability: TIME_MANAGEMENT_ABILITIES.ABSENCE.SUMMARY.ANY
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Sommaires des absences',
      annual: 'Annuel',
      weekly: 'Hebdomadaire'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Absences summaries',
      annual: 'Annual',
      weekly: 'Weekly'
    }
  }
});

const tabs = [
  {
    to: TIME_MANAGEMENT_ROUTES.ABSENCE.SUMMARY.ANNUAL,
    ability: TIME_MANAGEMENT_ABILITIES.ABSENCE.SUMMARY.ANNUAL,
    title: t('annual')
  },
  {
    to: TIME_MANAGEMENT_ROUTES.ABSENCE.SUMMARY.WEEKLY,
    ability: TIME_MANAGEMENT_ABILITIES.ABSENCE.SUMMARY.WEEKLY,
    title: t('weekly')
  }
] as Tab[];

const { backButtonSelectedName } = useBackPreviousButton([
  TIME_MANAGEMENT_ROUTES.ABSENCE.ROOT,
  REPORTS_ROUTES.SUMMARY
]);

useFirstTabRedirect(TIME_MANAGEMENT_ROUTES.ABSENCE.SUMMARY.ROOT, tabs);
</script>
