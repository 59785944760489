import type { PostMessageHandlerInterface } from '@/modules/shared/core/composables/PostMessage/handlers/PostMessageHandlerInterface';
import type { Router } from 'vue-router';

export class BackPostMessageHandler implements PostMessageHandlerInterface {
  constructor(private readonly router: Router) {}

  async handle(): Promise<void> {
    this.router.back();
  }
}
