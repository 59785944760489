<template>
  <VApp>
    <VMain>
      <VRow class="fill-height w-100 ma-0">
        <VCol
          class="d-none d-md-block pa-0 fill-height"
          cols="6"
        >
          <iframe
            v-if="isMarketingReady"
            width="100%"
            height="100%"
            :src="marketingIFrameURL"
          ></iframe>
          <VImg
            v-else
            class="fill-height"
            cover
            src="@/assets/folks_logging.png"
          />
        </VCol>
        <VCol
          class="pa-0 fill-height"
          md="6"
          sm="12"
        >
          <VContainer
            class="pa-md-10 flex-column fill-height justify-space-between"
          >
            <VToolbar
              class="bg-transparent pb-md-10 pd-5"
              density="compact"
            >
              <VImg
                class="mr-3"
                inline
                src="@/assets/folks-logo-new.svg"
                width="69"
              />
              <template
                v-for="(lang, key) in SUPPORTED_LANGUAGES_UNILANG_TEXT"
                :key="key"
              >
                <VBtn
                  :class="key === SUPPORTED_LANGUAGES.en ? 'mr-2' : ''"
                  :active="localeStored === key"
                  :text="lang"
                  color="primary"
                  variant="text"
                  @click="setI18nLanguage(key)"
                />
              </template>
            </VToolbar>
            <slot name="default" />
            <VFooter
              class="flex-0-0 flex-row justify-center text-label-medium pa-0 pt-md-10 pt-md-5 mx-auto"
            >
              <span class="mr-3">
                {{ t('footer.copyRight', { year: currentYear }) }}
              </span>
              <a
                :href="policyUrl"
                class="text-secondary text-decoration-none"
                target="_blank"
              >
                {{ t('footer.privacyPolicy') }}
              </a>
            </VFooter>
          </VContainer>
        </VCol>
      </VRow>
    </VMain>
  </VApp>
</template>
<script lang="ts" setup>
import SUPPORTED_LANGUAGES, {
  SUPPORTED_LANGUAGES_UNILANG_TEXT
} from '@/modules/shared/core/constants/languages';
import { setI18nLanguage } from '@/plugins/vueI18n';
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useLocalStorage } from '@vueuse/core';

let isMarketingReady = ref(false);

const currentYear = new Date().getFullYear();
const marketingIFrameURL = computed(() => {
  if (!isMarketingReady.value) return '';

  return locale.value === SUPPORTED_LANGUAGES.en
    ? import.meta.env.VITE_LOGIN_IFRAME_URL_EN
    : import.meta.env.VITE_LOGIN_IFRAME_URL_FR;
});

const { t, locale } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      footer: {
        copyRight: '© Folks {year}',
        privacyPolicy: 'Politique de confidentialité'
      }
    },
    [SUPPORTED_LANGUAGES.en]: {
      footer: {
        copyRight: '© Folks {year}',
        privacyPolicy: 'Privacy Policy'
      }
    }
  }
});

onBeforeMount(() => {
  if (import.meta.env.VITE_IS_MARKETING_READY == 1) {
    isMarketingReady.value = true;
  }
});

const policyUrl = computed(() => {
  return locale.value === SUPPORTED_LANGUAGES.en
    ? 'https://folksrh.com/en/privacy-policy/'
    : 'https://folksrh.com/politique-vie-privee/';
});

const localeStored = useLocalStorage<SUPPORTED_LANGUAGES>(
  'user-locale',
  SUPPORTED_LANGUAGES.fr
);
</script>
