import LEGACY_TYPE from '@/modules/shared/core/constants/legacyType';
import type { Program } from '@/modules/shared/core/utils/mapping/index.types';
import SYSTEM_MANAGEMENT_ABILITIES from '@/modules/parameters/submodules/system-management/constants/abilities';
import SYSTEM_MANAGEMENT_ROUTES from '@/modules/parameters/submodules/system-management/constants/urls';

const COMPANIES_MAPPING = [
  {
    name: SYSTEM_MANAGEMENT_ABILITIES.COMPANIES.LIST,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 501010,
    module: SYSTEM_MANAGEMENT_ABILITIES.COMPANIES.ANY,
    routeName: SYSTEM_MANAGEMENT_ROUTES.COMPANIES.LIST
  },
  {
    name: SYSTEM_MANAGEMENT_ABILITIES.COMPANIES.EDIT,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 501020,
    editKey: 'edit_field',
    module: SYSTEM_MANAGEMENT_ABILITIES.COMPANIES.ANY,
    routeName: SYSTEM_MANAGEMENT_ROUTES.COMPANIES.EDIT
  },
  {
    name: SYSTEM_MANAGEMENT_ABILITIES.COMPANIES.CREATE,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 501020,
    module: SYSTEM_MANAGEMENT_ABILITIES.COMPANIES.ANY,
    routeName: SYSTEM_MANAGEMENT_ROUTES.COMPANIES.CREATE
  },
  {
    name: SYSTEM_MANAGEMENT_ABILITIES.COMPANIES.CONFIG,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 501030,
    module: SYSTEM_MANAGEMENT_ABILITIES.COMPANIES.ANY,
    routeName: SYSTEM_MANAGEMENT_ROUTES.COMPANIES.CONFIG
  }
] as Program[];

const ACTIVE_EMPLOYEES_MAPPING = [
  {
    name: SYSTEM_MANAGEMENT_ABILITIES.ACTIVE_EMPLOYEES.ANY,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 501040,
    module: SYSTEM_MANAGEMENT_ABILITIES.ACTIVE_EMPLOYEES.ANY,
    routeName: SYSTEM_MANAGEMENT_ROUTES.ACTIVE_EMPLOYEES.ROOT
  }
] as Program[];

const PW_NUMBERS_MAPPING = [
  {
    name: SYSTEM_MANAGEMENT_ABILITIES.PW_NUMBERS.ANY,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 501050,
    module: SYSTEM_MANAGEMENT_ABILITIES.PW_NUMBERS.ANY,
    routeName: SYSTEM_MANAGEMENT_ROUTES.PW_NUMBERS.ROOT
  }
] as Program[];

const TIME_MANAGEMENT_MAPPING = [
  {
    name: SYSTEM_MANAGEMENT_ABILITIES.TIME_MANAGEMENT.ABSENCES_REASONS,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 501080,
    module: SYSTEM_MANAGEMENT_ABILITIES.TIME_MANAGEMENT.ANY,
    routeName: SYSTEM_MANAGEMENT_ROUTES.TIME_MANAGEMENT.ABSENCES_REASONS,
    iframeUrl: '/legacy/admin/time-management-settings/absence'
  },
  {
    name: SYSTEM_MANAGEMENT_ABILITIES.TIME_MANAGEMENT.VACATION_TYPES,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 501080,
    module: SYSTEM_MANAGEMENT_ABILITIES.TIME_MANAGEMENT.ANY,
    routeName: SYSTEM_MANAGEMENT_ROUTES.TIME_MANAGEMENT.VACATION_TYPES,
    iframeUrl: '/legacy/admin/time-management-settings/vacation'
  },
  {
    name: SYSTEM_MANAGEMENT_ABILITIES.TIME_MANAGEMENT.OVERTIME_TYPES,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 501080,
    module: SYSTEM_MANAGEMENT_ABILITIES.TIME_MANAGEMENT.ANY,
    routeName: SYSTEM_MANAGEMENT_ROUTES.TIME_MANAGEMENT.OVERTIME_TYPES,
    iframeUrl: '/legacy/admin/time-management-settings/overtimeType'
  },
  {
    name: SYSTEM_MANAGEMENT_ABILITIES.TIME_MANAGEMENT.OVERTIME_VALUES,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 501080,
    module: SYSTEM_MANAGEMENT_ABILITIES.TIME_MANAGEMENT.ANY,
    routeName: SYSTEM_MANAGEMENT_ROUTES.TIME_MANAGEMENT.OVERTIME_VALUES,
    iframeUrl: '/legacy/admin/time-management-settings/overtimeMultiplier'
  }
] as Program[];

const SYSTEM_MANAGEMENT_MAPPING = [
  ...COMPANIES_MAPPING,
  ...ACTIVE_EMPLOYEES_MAPPING,
  ...PW_NUMBERS_MAPPING,
  ...TIME_MANAGEMENT_MAPPING
] as Program[];

export default SYSTEM_MANAGEMENT_MAPPING;
