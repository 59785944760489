import { datadogRum } from '@datadog/browser-rum';

export default {
  install: () => {
    if (import.meta.env.VITE_APP_DATADOG_ACTIVE === 'true') {
      datadogRum.init({
        applicationId: import.meta.env.VITE_APP_DATADOG_RUM_APP_ID,
        clientToken: import.meta.env.VITE_APP_DATADOG_RUM_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'folkshr/hris/spa',
        env: import.meta.env.MODE,
        version: import.meta.env.VITE_APP_VERSION,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        telemetrySampleRate: 0,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingUrls: [
          import.meta.env.VITE_APP_API_URL_ORIGIN,
          import.meta.env.VITE_APP_LEGACY_URL_ORIGIN
        ],
        beforeSend: (event) => {
          if (event.type === 'resource') {
            event.resource.url = event.resource.url.replace(
              /sessionToken=[^&]*/,
              'sessionToken=REDACTED'
            );
          }

          return true;
        },
        useSecureSessionCookie: true,
        trackSessionAcrossSubdomains: true,
        usePartitionedCrossSiteSessionCookie: true
      });
    }
  }
};
