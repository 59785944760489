import type { Program } from '@/modules/shared/core/utils/mapping/index.types';
import LEGACY_TYPE from '@/modules/shared/core/constants/legacyType';
import IMPORT_EXPORT_ABILITIES from '@/modules/parameters/submodules/import-export/constants/abilities';
import IMPORT_EXPORT_ROUTES from '@/modules/parameters/submodules/import-export/constants/urls';

const IMPORT_EXPORT_MAPPING = [
  {
    name: IMPORT_EXPORT_ABILITIES.IMPORT,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 506530,
    module: IMPORT_EXPORT_ABILITIES.ANY,
    routeName: IMPORT_EXPORT_ROUTES.IMPORT
  },
  {
    name: IMPORT_EXPORT_ABILITIES.EXPORT,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 506532,
    module: IMPORT_EXPORT_ABILITIES.ANY,
    routeName: IMPORT_EXPORT_ROUTES.EXPORT
  }
] as Program[];

export default IMPORT_EXPORT_MAPPING;
