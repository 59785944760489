<template>
  <VAppBar
    v-dynamic-data-attr="{ all: `f-top-bar` }"
    app
    color="surface"
    theme="darkFolksTheme"
    class="topBar"
  >
    <template #prepend>
      <FNavToggler
        v-if="mobile"
        :nav-is-open="mobileNavOpen"
        @toggle-rail="mobileNavOpen = !mobileNavOpen"
      />
      <div v-if="!mobile">
        <VAvatar
          :rounded="false"
          class="rounded-lg text-title-small"
          color="tertiary-fixed"
          size="small"
        >
          {{ companyStore.company.initials[0] }}
        </VAvatar>
        <span class="ml-3 text-title-medium">
          {{ companyStore.company.name }}
        </span>
      </div>
    </template>
    <template
      v-if="!mobile"
      #default
    >
      <FTopBarSearch />
    </template>
    <template #append>
      <VTooltip
        :theme="themeName"
        location="bottom"
      >
        <template #activator="{ props }">
          <VBtn
            v-if="isEnabledRequestSideSheet"
            v-dynamic-data-attr="{ all: `f-btn-requests` }"
            :icon="true"
            theme="darkFolksTheme"
            v-bind="props"
            variant="flat"
            @click="isOpenedRequestSideSheet = true"
          >
            <FIconMD icon="maps_ugc" />
          </VBtn>
        </template>
        {{ t('request') }}
      </VTooltip>
      <VTooltip
        v-if="false"
        :theme="themeName"
        location="bottom"
      >
        <!-- Hide Till we need -->
        <template #activator="{ props }">
          <VBtn
            v-if="hasAnyToolEnabled"
            v-dynamic-data-attr="{ all: `f-btn-tools` }"
            :icon="true"
            theme="darkFolksTheme"
            v-bind="props"
            variant="flat"
            @click="isOpenedToolsSideSheet = true"
          >
            <FIconMD icon="apps" />
          </VBtn>
        </template>
        {{ t('tools') }}
      </VTooltip>
      <VMenu theme="lightFolksThemes">
        <template #activator="{ props }">
          <VBtn
            v-dynamic-data-attr="{ all: `f-btn-account` }"
            icon="true"
            v-bind="props"
          >
            <FIconMD
              fill="1"
              icon="account_circle"
            />
          </VBtn>
        </template>
        <VList v-dynamic-data-attr="{ all: `f-account-menu` }">
          <VListItem
            :subtitle="getEmployeePositionLocalized"
            :title="`${userStore.first_name} ${userStore.last_name}`"
            class="py-2 pb-4"
          >
            <template #prepend>
              <VAvatar
                color="primary"
                size="small"
              >
                {{ userStore.initials[0] }}
              </VAvatar>
            </template>
          </VListItem>
          <VListItem
            v-dynamic-data-attr="{
              all: `f-account-menu_btn-profile`
            }"
            to="/user-params"
          >
            {{ t('userMenu.userSettings') }}
          </VListItem>
          <VDivider class="my-2" />
          <VListItem
            v-dynamic-data-attr="{ all: `f-account-menu_btn-logout` }"
            rounded
            @click="logout()"
          >
            <template #prepend>
              <FIconMD icon="logout" />
            </template>
            {{ t('userMenu.logout') }}
          </VListItem>
        </VList>
      </VMenu>
      <VTooltip
        location="bottom"
        theme="lightFolksThemes"
      >
        <template #activator="{ props }">
          <VBtn
            v-if="hasSimulatorActivated"
            v-dynamic-data-attr="{ all: `f-top-bar_btn-simulator` }"
            icon="true"
            theme="darkFolksTheme"
            to="/simulator"
            v-bind="props"
          >
            <FIconMD
              fill="1"
              icon="ADB"
            />
          </VBtn>
        </template>
        <span>{{ t('simulator') }}</span>
      </VTooltip>
    </template>
  </VAppBar>
</template>

<script lang="ts" setup>
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import { useAuthenticationStore } from '@/modules/authentication/store/authentication';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/modules/shared/core/stores/user';
import { useCompanyStore } from '@/modules/shared/core/stores/compagny';
import { useEmployeeStore } from '@/modules/shared/core/stores/employee';
import { useDisplay, useTheme } from 'vuetify';
import { useNavigationRailStore } from '@/stores/navigationRail';
import { storeToRefs } from 'pinia';
import { useRequestSideSheetStore } from '@/stores/requestSideSheet';
import { useToolsSideSheetStore } from '@/stores/toolsSideSheet';

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      searchPlaceholder: 'Rechercher un employé',
      request: 'Faire une demande',
      tools: 'Outils',
      userMenu: {
        userSettings: 'Gestion du compte',
        logout: 'Déconnexion'
      },
      simulator: 'Menu Développeur'
    },
    [SUPPORTED_LANGUAGES.en]: {
      searchPlaceholder: 'Search for an employee',
      request: 'Make a request',
      tools: 'Tools',
      userMenu: {
        userSettings: 'Account management',
        logout: 'Logout'
      },
      simulator: 'Developer Menu'
    }
  }
});

const hasSimulatorActivated = import.meta.env.VITE_APP_SPA_SIMULATOR;
const { name: themeName } = useTheme();

const { mobile } = useDisplay();
const { mobileNavOpen } = storeToRefs(useNavigationRailStore());

const { logout } = useAuthenticationStore();
const userStore = useUserStore();
const companyStore = useCompanyStore();
const { getEmployeePositionLocalized } = storeToRefs(useEmployeeStore());
const {
  isOpened: isOpenedRequestSideSheet,
  hasAnyPermission: isEnabledRequestSideSheet
} = storeToRefs(useRequestSideSheetStore());
const { isOpened: isOpenedToolsSideSheet, hasAnyToolEnabled } = storeToRefs(
  useToolsSideSheetStore()
);
</script>
<style lang="sass" scoped></style>
