const BASE_ROUTE_SAFETY = '/safety';

const BASE_ROUTE_WORK_ACCIDENT = `${BASE_ROUTE_SAFETY}/work-accidents`;

const BASE_ROUTE_SUMMARY = `${BASE_ROUTE_SAFETY}/summary`;

const SAFETY_ROUTES = {
  ROOT: BASE_ROUTE_SAFETY,
  WORK_ACCIDENT: {
    ROOT: BASE_ROUTE_WORK_ACCIDENT,
    CONTROL: `${BASE_ROUTE_WORK_ACCIDENT}.control`,
    EDIT: `${BASE_ROUTE_WORK_ACCIDENT}.edit`
  },
  SUMMARY: {
    ROOT: BASE_ROUTE_SUMMARY
  }
};

export default SAFETY_ROUTES;
