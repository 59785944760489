<template>
  <FLegacyIframe :iframe-route="$route.fullPath" />
</template>
<script lang="ts" setup>
import EMPLOYEES_ABILITIES from '@/modules/employees/constants/abilities';

definePage({
  meta: {
    ability: EMPLOYEES_ABILITIES.CONFIGURATION.PROFIL_CUSTOMIZATION
  }
});
</script>
