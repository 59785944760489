const SAFETY_ROOT = 'safety';

const WORK_ACCIDENT_ROOT = `${SAFETY_ROOT}:work-accidents`;
const SUMMARY_ROOT = `${WORK_ACCIDENT_ROOT}:summary`;

const SAFETY_ABILITIES = {
  ANY: `${SAFETY_ROOT}`,
  WORK_ACCIDENT: {
    ANY: `${WORK_ACCIDENT_ROOT}:analysis`,
    CONTROL: `${WORK_ACCIDENT_ROOT}:control`
  },
  SUMMARY: {
    ANY: `${SUMMARY_ROOT}`
  }
};

export default SAFETY_ABILITIES;
