<template>
  <div
    v-if="canSeeIframe"
    :class="withBorder ? 'pa-4 pt-0' : ''"
    class="d-flex fill-height w-100 iframe-container"
  >
    <iframe
      :id="iframeId"
      ref="iframeRef"
      v-dynamic-data-attr="{ all: `iframe-legacy` }"
      :class="withBorder ? 'border rounded' : ''"
      :src="iframeSrc"
      class="d-flex fill-height w-100 border-0"
    ></iframe>
  </div>
  <FBypassAbilityContent v-else />
</template>

<script lang="ts" setup>
import { useAuthenticationStore } from '@/modules/authentication/store/authentication';
import useMessageListener from '@/modules/shared/core/composables/PostMessage/useMessageListener';
import useGlobalMessageHandler from '@/modules/shared/core/composables/PostMessage/useGlobalMessageHandler';
import useV2AuthenticationMessageHandler from '@/modules/shared/core/composables/PostMessage/useV2AuthenticationMessageHandler';
import LEGACY_TYPE from '@/modules/shared/core/constants/legacyType';
import {
  getLegacyIframeId,
  getProgramDataByRoute,
  replaceDynamicParamsInString
} from '@/modules/shared/core/utils/mapping';
import {
  createObjectWithKeysAndValues,
  extractKeysFromRoute,
  extractQueryParams,
  extractValuesFromRoute,
  objectToQueryString
} from '@/modules/shared/core/utils/route';
import type { UseMessageListenerParams } from '@/modules/shared/core/composables/PostMessage/useMessageListener.types';
import { usePermissionsStore } from '@/modules/shared/core/stores/permissions';

const props = defineProps({
  iframeRoute: {
    type: String,
    required: true
  },
  iframeRouteAbility: {
    type: String,
    default: ''
  },
  isInModal: {
    type: Boolean,
    default: false
  },
  withBorder: {
    type: Boolean,
    default: false
  }
});

const iframeRef = ref<HTMLIFrameElement | null>(null);
watch(
  () => props.iframeRoute,
  () => {
    if (iframeRef.value) {
      iframeRef.value.src += '';
    }
  }
);

const programData = getProgramDataByRoute(props.iframeRoute);
const extractedParams = extractQueryParams(props.iframeRoute);
const routeKeys = extractKeysFromRoute(programData.routeName);
const routeValues = extractValuesFromRoute(
  programData.routeRegexp,
  props.iframeRoute
);
const paramsObject = createObjectWithKeysAndValues(routeKeys, routeValues);

const { meta } = useRoute();

const { canAccess } = usePermissionsStore();
const canSeeIframe = computed(() => {
  if (props.iframeRouteAbility !== '') {
    return canAccess(props.iframeRouteAbility);
  } else if (meta && meta.ability) {
    //@ts-ignore
    return canAccess(meta.ability);
  }
  return true;
});

const authStore = useAuthenticationStore();
let iframeSrc = '';
const iframeId = getLegacyIframeId(programData);

const messageListenerParams: UseMessageListenerParams = {
  name: iframeId
};
if (programData.isLegacy === LEGACY_TYPE.LEGACY_MONOLITH) {
  messageListenerParams.allowedPageID = programData.programId;
} else if (programData.isLegacy === LEGACY_TYPE.LEGACY_V2) {
  messageListenerParams.allowedPathsRegexp = [programData.iframeRegexp];
}
const { addHandler } = useMessageListener(messageListenerParams);
const { registerGlobalMessageHandler } = useGlobalMessageHandler(addHandler);
const { registerV2AuthenticationMessageHandler } =
  useV2AuthenticationMessageHandler(addHandler);

registerGlobalMessageHandler();
if (programData.isLegacy === LEGACY_TYPE.LEGACY_MONOLITH) {
  if (programData.isLegacyModal) {
    const replacedIframeUrl = replaceDynamicParamsInString(
      `${import.meta.env.VITE_APP_LEGACY_URL.replace('main.php', '')}${programData.legacyModalUrl}`,
      extractedParams
    );
    iframeSrc = `${replacedIframeUrl}&sessionToken=${authStore.authData.session_token}&frameless=true`;
  } else {
    iframeSrc = `${import.meta.env.VITE_APP_LEGACY_URL}?f=${programData.programId}&sessionToken=${authStore.authData.session_token}&frameless=true`;
  }
} else if (programData.isLegacy === LEGACY_TYPE.LEGACY_V2) {
  registerV2AuthenticationMessageHandler();
  const replacedIframeUrl = replaceDynamicParamsInString(
    programData.iframeUrl,
    paramsObject
  );
  iframeSrc = `${import.meta.env.VITE_APP_V2_URL_ORIGIN}${replacedIframeUrl}?frameless=true`;
}

if (props.isInModal) {
  iframeSrc += '&isInModal=true';
}

if (extractedParams && Object.keys(extractedParams).length) {
  delete extractedParams.f;
  if (Object.keys(extractedParams).length) {
    iframeSrc += `&${objectToQueryString(extractedParams)}`;
  }
}
</script>
<style lang="sass" scoped></style>
