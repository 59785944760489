import type { Spread } from '@sdk/modules/core/types/spread';

export class ObjectUtils {
  static mergeDeep<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    T extends { [K in keyof T]: T[K] } & { [P: string | symbol]: any },
    U extends T[]
  >(...sources: [...U]): Spread<U> {
    const target = sources.shift() as T;
    if (!target) {
      return {} as Spread<U>;
    }

    if (!sources.length && target) return target as Spread<U>;

    const source = sources.shift() as T;

    if (source && this.isObject(target) && this.isObject(source)) {
      const objectKeys = [
        ...Object.keys(source),
        ...Object.getOwnPropertySymbols(source)
      ];

      for (const key of objectKeys) {
        if (ObjectUtils.shouldRecursivelyMergeObjects(source[key] as T)) {
          if (!target[key]) {
            Object.assign(target, { [key]: {} });
          }

          this.mergeDeep(target[key], source[key]);

          Object.setPrototypeOf(
            target[key],
            Object.getPrototypeOf(source[key])
          );
        } else if (Array.isArray(source[key]) && Array.isArray(target[key])) {
          Object.assign(target, {
            [key]: [...(target[key] as U), ...(source[key] as U)]
          });
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return ObjectUtils.mergeDeep<T, U>(...([target, ...sources] as U));
  }

  static isObject(value: unknown): boolean {
    return !!value && typeof value === 'object' && !Array.isArray(value);
  }

  static preventCircularDependency<T extends object>(value: T): T {
    Object.defineProperty(value, 'preventCircularDependency', {
      value: true,
      enumerable: false,
      writable: false
    });

    return value;
  }

  static hasPreventCircularDependencyProperty<T extends object>(
    object: T & { preventCircularDependency?: boolean }
  ) {
    return (
      'preventCircularDependency' in object &&
      object.preventCircularDependency === true
    );
  }

  private static shouldRecursivelyMergeObjects<T extends object>(
    sourceObject: T
  ): boolean {
    return (
      ObjectUtils.isObject(sourceObject) &&
      !(sourceObject instanceof Date) &&
      !this.hasPreventCircularDependencyProperty(sourceObject)
    );
  }
}
