<template>
  <FHeaderPrimary
    :back-route="selectedBackUrl"
    :title="t('title')"
  />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import SAFETY_ROUTES from '@/modules/safety/constants/urls';
import SAFETY_ABILITIES from '@/modules/safety/constants/abilities';
import EMPLOYEES_ROUTES from '@/modules/employees/constants/urls';
import useBackPreviousButton from '@/modules/shared/core/composables/useBackPreviousButton';

definePage({
  meta: {
    ability: SAFETY_ABILITIES.WORK_ACCIDENT.CONTROL
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Ajouter un accident de travail'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Add a work accident'
    }
  }
});

const route = useRoute();
const employeeId = route.query.em_id as string;

const { backButtonSelectedName } = useBackPreviousButton([
  SAFETY_ROUTES.WORK_ACCIDENT.ROOT,
  EMPLOYEES_ROUTES.EMPLOYEE.WORK_ACCIDENTS
]);

let selectedBackUrl;

if (backButtonSelectedName === EMPLOYEES_ROUTES.EMPLOYEE.WORK_ACCIDENTS) {
  selectedBackUrl = {
    name: EMPLOYEES_ROUTES.EMPLOYEE.WORK_ACCIDENTS,
    query: { employee_id: employeeId }
  };
} else {
  selectedBackUrl = backButtonSelectedName;
}
</script>
