import type { FolksSDKModuleInterface } from '@sdk/interfaces/FolksSDKModuleInterface';
import { ContainerModule } from 'inversify';
import type StorageStrategyInterface from '@sdk/modules/core/interfaces/StorageStrategyInterface';
import { CORE_TYPES } from '@sdk/modules/core/FolksSDKCoreModuleTypes';
import LocalStorageStrategy from '@sdk/modules/core/strategies/LocalStorageStrategy';
import type CryptographyStrategyInterface from '@sdk/modules/core/interfaces/CryptographyStrategyInterface';
import WindowCryptographyStrategy from '@sdk/modules/core/strategies/WindowCryptographyStrategy';

declare let window: Window & typeof globalThis;

export default class FolksSDKCoreModule implements FolksSDKModuleInterface {
  getContainerModule(): ContainerModule {
    return new ContainerModule((bind) => {
      if (window) {
        if ('localStorage' in window) {
          bind<StorageStrategyInterface>(
            CORE_TYPES.StorageStrategyInterface
          ).toConstantValue(new LocalStorageStrategy(window.localStorage));
        }
        if ('crypto' in window) {
          bind<CryptographyStrategyInterface>(
            CORE_TYPES.CryptographyStrategyInterface
          ).toConstantValue(new WindowCryptographyStrategy(window.crypto));
        }
      }
    });
  }
}
