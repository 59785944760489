<template>
  <FHeaderPrimary
    :configuration-ability="DOCUMENTS_ABILITIES.VAULT.CONFIG.ANY"
    :configuration-route="DOCUMENTS_ROUTES.VAULT.CONFIG.ROOT"
    :title="t('title')"
  />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import DOCUMENTS_ABILITIES from '@/modules/documents/constants/abilities';
import DOCUMENTS_ROUTES from '@/modules/documents/constants/urls';

definePage({
  meta: {
    needAuth: true,
    ability: DOCUMENTS_ABILITIES.VAULT.ANY,
    bypassAbility: [DOCUMENTS_ABILITIES.VAULT.CONFIG.ANY]
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Ressources documentaires'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Documentary Resources'
    }
  }
});
</script>
