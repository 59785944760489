import type SSOAuthenticationServiceInterface from '@sdk/modules/authentication/interfaces/sso/SSOAuthenticationServiceInterface';
import type { SSOInitResponsePayload } from '@sdk/modules/authentication/interfaces/types/responses/sso/SSOInitResponsePayload';
import { inject, injectable } from 'inversify';
import { AUTHENTICATION_BINDING_TYPES } from '@sdk/modules/authentication';
import type AuthenticationCodeFactoryInterface from '@sdk/modules/authentication/interfaces/AuthenticationCodeFactoryInterface';
import type SSOAuthenticationStateRepositoryInterface from '@sdk/modules/authentication/interfaces/sso/SSOAuthenticationStateRepositoryInterface';
import { API_BINDING_TYPES } from '@sdk/modules/API';
import type { APIProxyInterface } from '@sdk/modules/API/Interface/APIProxyInterface';
import { SETTINGS_TYPE } from '@sdk/settings/SettingTypes';
import type { SettingsInterface } from '@sdk/settings/SettingsInterface';
import { InvalidSSOStateException } from '@sdk/modules/authentication/exceptions/sso/InvalidSSOStateException';
import type { AuthenticationSuccessfulResponsePayload } from '@sdk/modules/authentication/interfaces/types/responses/AuthenticationSuccessfulResponsePayload';
import type { AuthenticationErrorResponsePayload } from '@sdk/modules/authentication/interfaces/types/responses/AuthenticationErrorResponsePayload';
import { AuthenticationSuccessfulResponse } from '@sdk/modules/authentication/objects/AuthenticationSuccessfulResponse';
import { AuthenticationErrorResponse } from '@sdk/modules/authentication/objects/AuthenticationErrorResponse';
import type { AuthenticationResponseInterface } from '@sdk/modules/authentication/interfaces/AuthenticationResponseInterface';
import { AxiosError } from 'axios';

@injectable()
export default class SSOAuthenticationService
  implements SSOAuthenticationServiceInterface
{
  constructor(
    @inject(AUTHENTICATION_BINDING_TYPES.AuthenticationCodeFactoryInterface)
    private authenticationCodeFactory: AuthenticationCodeFactoryInterface,
    @inject(
      AUTHENTICATION_BINDING_TYPES.SSOAuthenticationStateRepositoryInterface
    )
    private ssoAuthStateRepository: SSOAuthenticationStateRepositoryInterface,
    @inject(API_BINDING_TYPES.APIProxyInterface)
    private apiProxy: APIProxyInterface,
    @inject(SETTINGS_TYPE.SettingInterface) private settings: SettingsInterface
  ) {}

  async init(code: string): Promise<SSOInitResponsePayload> {
    const { state, codeChallenge } =
      await this.generateAndStoreAuthenticationCodes();

    const response = await this.apiProxy.post<SSOInitResponsePayload>(
      'authorization/sso/initiate',
      {
        sso_code: code,
        state: state,
        code_challenge: codeChallenge,
        client_id: this.settings.sso?.clientId
      },
      {
        withBearerAuth: false
      }
    );

    return response.data;
  }

  async complete(
    urlCode: string,
    urlState: string
  ): Promise<
    AuthenticationResponseInterface<AuthenticationSuccessfulResponsePayload>
  > {
    const { state, codeVerifier } = await this.ssoAuthStateRepository.fetch();

    if (state !== urlState) {
      throw new InvalidSSOStateException();
    }
    try {
      const response = await this.apiProxy.post<
        | AuthenticationSuccessfulResponsePayload
        | AuthenticationErrorResponsePayload
      >(
        'authorization/token',
        {
          code: urlCode,
          code_verifier: codeVerifier,
          client_id: this.settings.sso?.clientId,
          grant_type: 'authorization_code',
          with_legacy_session_token: true,
          redirect_uri: this.settings.sso?.redirectUri
        },
        { withBearerAuth: false }
      );

      return new AuthenticationSuccessfulResponse(
        response.data as AuthenticationSuccessfulResponsePayload
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new AuthenticationErrorResponse(
          error.response?.data as AuthenticationErrorResponsePayload
        );
      }

      throw error;
    }
  }

  private async generateAndStoreAuthenticationCodes(): Promise<{
    state: string;
    codeChallenge: string;
  }> {
    const codeVerifier = this.authenticationCodeFactory.getCodeVerifier();
    const state = this.authenticationCodeFactory.getState();
    const codeChallenge =
      await this.authenticationCodeFactory.getCodeChallenge(codeVerifier);

    await this.ssoAuthStateRepository.save(state, codeVerifier, codeChallenge);

    return { state, codeChallenge };
  }
}
