import ApiFolksEndpointBuilder from '@/modules/shared/core/utils/apiFolks/ApiFolksEndpointBuilder';
import type { APIProxyInterface } from '@sdk/modules/API/Interface/APIProxyInterface';
import { API_BINDING_TYPES } from '@sdk/modules/API';
import type { UserAPI } from '@/modules/shared/core/stores/user.types';
import type { AxiosResponse } from 'axios';

const baseEndpoint = `users/current`;

export async function getCurrentUser(): Promise<
  AxiosResponse<{ data: UserAPI }>
> {
  const { resolve } = useSDKContainer();

  const proxy = resolve<APIProxyInterface>(API_BINDING_TYPES.APIProxyInterface);

  return proxy.get(
    new ApiFolksEndpointBuilder(baseEndpoint)
      .withEmbeddedResources([
        'employee',
        'employee.department',
        'employee.position',
        'employee.province',
        'employee.workingSite',
        'employee.union',
        'employee.norms',
        'company',
        'company.industry',
        'company.companyParameters'
      ])
      .withoutVersion()
      .build()
  );
}
