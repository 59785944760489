import type { PostMessageHandlerInterface } from '@/modules/shared/core/composables/PostMessage/handlers/PostMessageHandlerInterface';
import type { MessageRespondFn } from '@/modules/shared/core/stores/messageHandlersStore';
import { useAuthenticationStore } from '@/modules/authentication/store/authentication';

export class AuthenticatePostMessageHandler
  implements PostMessageHandlerInterface
{
  constructor(
    private readonly authenticationStore: ReturnType<
      typeof useAuthenticationStore
    >
  ) {}

  async handle(respond: MessageRespondFn): Promise<void> {
    respond({ access_token: this.authenticationStore.authData.access_token });
  }
}
