import LEGACY_TYPE from '@/modules/shared/core/constants/legacyType';
import SAFETY_ROUTES from '@/modules/safety/constants/urls';
import SAFETY_ABILITIES from '@/modules/safety/constants/abilities';
import type { Program } from '@/modules/shared/core/utils/mapping/index.types';

const WORK_ACCIDENT_MAPPING = [
  {
    name: SAFETY_ABILITIES.WORK_ACCIDENT.ANY,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 202520,
    module: SAFETY_ABILITIES.WORK_ACCIDENT.ANY,
    routeName: SAFETY_ROUTES.WORK_ACCIDENT.ROOT
  },
  {
    name: SAFETY_ABILITIES.WORK_ACCIDENT.CONTROL,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 202530,
    module: SAFETY_ABILITIES.WORK_ACCIDENT.ANY,
    routeName: SAFETY_ROUTES.WORK_ACCIDENT.EDIT
  },
  {
    name: SAFETY_ABILITIES.WORK_ACCIDENT.CONTROL,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 202530,
    module: SAFETY_ABILITIES.WORK_ACCIDENT.ANY,
    routeName: SAFETY_ROUTES.WORK_ACCIDENT.CONTROL
  }
] as Program[];

const SUMMARY_MAPPING = [
  {
    name: SAFETY_ABILITIES.SUMMARY.ANY,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 202510,
    module: SAFETY_ABILITIES.SUMMARY.ANY,
    routeName: SAFETY_ROUTES.SUMMARY.ROOT
  }
] as Program[];

const SAFETY_MAPPING = [
  ...WORK_ACCIDENT_MAPPING,
  ...SUMMARY_MAPPING
] as Program[];

export default SAFETY_MAPPING;
