<template>
  <VCard
    class="mx-auto sso-card"
    max-width="800"
  >
    <VCardTitle class="pt-0 text-headline-medium">
      {{ t('loginWithSSO') }}
    </VCardTitle>
    <VCardSubtitle
      class="pb-4"
      style="white-space: wrap"
    >
      {{ t('loginWithSSOSubtitle') }}
    </VCardSubtitle>
    <VCardText>
      <VForm
        novalidate
        @submit.prevent="handleSSOSubmit"
      >
        <VContainer class="pa-0">
          <VRow>
            <VCol
              v-if="errorMessage"
              cols="12"
            >
              <FAlertFormError
                v-dynamic-data-attr="{ all: 'alert-error-message-sso' }"
                :is-visible="!!errorMessage"
                :message-text="errorMessage"
              />
            </VCol>
            <VCol cols="12">
              <VTextField
                v-model="companyCode.value.value"
                v-dynamic-data-attr="{ all: 'input-sso-company-code' }"
                :error-messages="companyCode.errorMessage.value"
                :label="t('companyCode')"
                persistent-placeholder
                variant="outlined"
                w-full
              >
              </VTextField>
              <VCheckbox
                v-model="shouldRememberUser.value.value"
                v-dynamic-data-attr="{ all: 'checkbox-sso-shared-computer' }"
                :label="t('rememberMe')"
                hide-details
                density="compact"
                w-full
              />
            </VCol>
          </VRow>
        </VContainer>
        <VCardActions
          class="pa-0"
          style="flex-direction: column"
        >
          <div class="d-flex flex-column w-100 align-center">
            <VBtn
              v-dynamic-data-attr="{ all: 'button-submit-sso-code' }"
              :loading="isSubmitting"
              :text="t('submit')"
              color="primary-variant"
              type="submit"
            />
            <VBtn
              v-dynamic-data-attr="{ all: 'button-sso-back-to-pwd' }"
              variant="text"
              color="primary"
              :text="t('backToCredentials')"
              :to="loginTo"
              class="mt-6"
            />
          </div>
        </VCardActions>
      </VForm>
    </VCardText>
  </VCard>
</template>

<script setup lang="ts">
import { useField, useForm } from 'vee-validate';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import { boolean, string } from 'zod';
import { toTypedSchema } from '@vee-validate/zod';
import { useAuthenticationStore } from '@/modules/authentication/store/authentication';
import AUTHENTICATION_ROUTES from '@/modules/authentication/constants/urls';
import type { RouteLocationRaw } from 'vue-router';

definePage({
  meta: {
    isAuthPages: true
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      loginWithSSO: 'Authentification unique (SSO)',
      loginWithSSOSubtitle:
        'Veuillez entrer votre code de compagnie afin de vous connecter.',
      submit: 'Connexion',
      backToCredentials: 'Retour à la page de connexion',
      companyCode: 'Code de compagnie',
      rememberMe: 'Se souvenir de moi',
      invalidCompanyCode: 'Le code de compagnie est incorrect',
      required: 'Un code de compagnie est requis'
    },
    [SUPPORTED_LANGUAGES.en]: {
      loginWithSSO: 'Single Sign-On (SSO)',
      loginWithSSOSubtitle: 'Please enter your company code to login',
      submit: 'Login',
      backToCredentials: 'Back to login page',
      companyCode: 'Company code',
      rememberMe: 'Remember me',
      invalidCompanyCode: 'Company code is invalid',
      required: 'Company code is required'
    }
  }
});

const store = useAuthenticationStore();

const errorMessage = ref<string | null>(null);

// Form Handling
const { handleSubmit, isSubmitting } = useForm({
  initialValues: {
    companyCode: '',
    shouldRememberUser: false
  }
});

const companyCode = useField(
  'companyCode',
  computed(() => toTypedSchema(string().min(1, t('required'))))
);

const shouldRememberUser = useField(
  'shouldRememberUser',
  toTypedSchema(boolean())
);

const handleSSOSubmit = handleSubmit(
  async ({ companyCode, shouldRememberUser }) => {
    try {
      errorMessage.value = null;
      const redirectUrl = await store.initiateSSO(
        companyCode,
        shouldRememberUser
      );

      window.location.assign(redirectUrl);
    } catch (error: any) {
      errorMessage.value = t('invalidCompanyCode');
    }
  }
);

const loginTo = computed(
  () =>
    ({
      name: AUTHENTICATION_ROUTES.LOGIN
    }) as RouteLocationRaw
);
</script>

<style lang="sass">
.sso-card .v-selection-control__wrapper
  width: 48px
  height: 48px
  margin-top: 4px
.sso-card .v-selection-control__input
  width: 48px
  height: 48px
</style>
