const COMPANY_ROOT = 'company';

const INFORMATIONS_ROOT = `${COMPANY_ROOT}:informations:control`;
const STANDARDS_ROOT = `${COMPANY_ROOT}:norms`;
const STRUCTURE_ROOT = `${COMPANY_ROOT}:structure`;
const SALARY_ROOT = `${COMPANY_ROOT}:salary`;

const COMPANY_ABILITIES = {
  ANY: `${COMPANY_ROOT}`,
  INFORMATIONS: {
    ANY: `${INFORMATIONS_ROOT}`
  },
  STANDARDS: {
    ANY: `${STANDARDS_ROOT}`,
    EMPLOYEES: `${STANDARDS_ROOT}:employees`,
    ORGANISATIONAL: `${STANDARDS_ROOT}:organisational`,
    TRANSFER: `${STANDARDS_ROOT}:bank-transfer`
  },
  STRUCTURE: {
    ANY: `${STRUCTURE_ROOT}`,
    WORKINGSITES: `${STRUCTURE_ROOT}:working-sites`,
    DEPARTMENTS: `${STRUCTURE_ROOT}:departments`,
    POSITIONS: `${STRUCTURE_ROOT}:positions`,
    UNION: `${STRUCTURE_ROOT}:union`,
    CHART: `${STRUCTURE_ROOT}:chart`,
    CHAIRS: `${STRUCTURE_ROOT}:chairs`,
    POSITIONID: `${STRUCTURE_ROOT}:position-id`
  },
  SALARY: {
    ANY: `${SALARY_ROOT}:grid`,
    RANGE: `${SALARY_ROOT}:range`
  }
};

export default COMPANY_ABILITIES;
