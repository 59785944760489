const BASE_ROUTE_AUTHENTICATION = '/auth';

const AUTHENTICATION_ROUTES = {
  ROOT: BASE_ROUTE_AUTHENTICATION,
  LOGIN: `${BASE_ROUTE_AUTHENTICATION}/login`,
  LOGOUT: `${BASE_ROUTE_AUTHENTICATION}/logout`,
  FORGOT_PASSWORD: `${BASE_ROUTE_AUTHENTICATION}/forgot-password`,
  SSO: `${BASE_ROUTE_AUTHENTICATION}/sso/`
};

export default AUTHENTICATION_ROUTES;
