const TIME_MANAGEMENT_ROOT = 'time-management';
const CONFIG_ROOT = 'config';

const ABSENCES_ROOT = `${TIME_MANAGEMENT_ROOT}:absence`;
const VACATIONS_ROOT = `${TIME_MANAGEMENT_ROOT}:vacation`;
const OVERTIMES_ROOT = `${TIME_MANAGEMENT_ROOT}:overtime`;
const CALENDAR_ROOT = `${TIME_MANAGEMENT_ROOT}:calendar`;
const TIMESHEET_ROOT = `${TIME_MANAGEMENT_ROOT}:timesheet`;
const TIMESHEET_CONFIG_ROOT = `${TIMESHEET_ROOT}:${CONFIG_ROOT}`;

const TIME_MANAGEMENT_ABILITIES = {
  ANY: TIME_MANAGEMENT_ROOT,
  ABSENCE: {
    ANY: ABSENCES_ROOT,
    LIST: `${ABSENCES_ROOT}:analysis`,
    CONTROL: `${ABSENCES_ROOT}:control`,
    REQUEST: `${ABSENCES_ROOT}:request`,
    SUMMARY: {
      ANY: `${ABSENCES_ROOT}:summary`,
      ANNUAL: `${ABSENCES_ROOT}:summary:annual`,
      WEEKLY: `${ABSENCES_ROOT}:summary:weekly`
    }
  },
  VACATION: {
    ANY: VACATIONS_ROOT,
    LIST: `${VACATIONS_ROOT}:analysis`,
    CONTROL: `${VACATIONS_ROOT}:control`,
    REQUEST: `${VACATIONS_ROOT}:request`,
    SUMMARY: {
      ANY: `${VACATIONS_ROOT}:summary`
    }
  },
  OVERTIME: {
    ANY: OVERTIMES_ROOT,
    LIST: `${OVERTIMES_ROOT}:analysis`,
    CONTROL: `${OVERTIMES_ROOT}:control`,
    REQUEST: `${OVERTIMES_ROOT}:request`,
    SUMMARY: {
      ANY: `${OVERTIMES_ROOT}:summary`
    }
  },
  CALENDAR: {
    ANY: CALENDAR_ROOT,
    VIEW: `${CALENDAR_ROOT}:index`
  },
  TIMESHEET: {
    ANY: TIMESHEET_ROOT,
    VIEW: `${TIMESHEET_ROOT}:index`,
    SUMMARY: {
      ANY: `${TIMESHEET_ROOT}:summary`
    },
    CONFIG: {
      ANY: TIMESHEET_CONFIG_ROOT,
      CALCULATION: `${TIMESHEET_CONFIG_ROOT}:calculation`,
      LEGEND: `${TIMESHEET_CONFIG_ROOT}:legend`,
      HOLIDAY_SETTINGS: `${TIMESHEET_CONFIG_ROOT}:holiday-settings`,
      PROJECTS: `${TIMESHEET_CONFIG_ROOT}:project-list`,
      PROJECT: `${TIMESHEET_CONFIG_ROOT}:project`,
      PROJECT_TASKS: `${TIMESHEET_CONFIG_ROOT}:tasks`,
      IMPORTATION: `${TIMESHEET_CONFIG_ROOT}:import`
    }
  }
};

export default TIME_MANAGEMENT_ABILITIES;
