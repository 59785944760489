import HTTP_STATUS_CODES from '@/modules/shared/core/constants/httpStatusCodes';
import {
  refreshToken,
  retryCurrentRequest
} from '@/modules/shared/core/utils/apiFolks/refreshToken';
import { AxiosError, type AxiosResponse } from 'axios';

const nonRetryableRoutes = ['/authorization/revoke'];

export const handleErrorCode = (
  status: number,
  message: string,
  axiosResponseOrError: AxiosError | AxiosResponse,
  responseData: any
) => {
  const { config } =
    axiosResponseOrError instanceof AxiosError
      ? (axiosResponseOrError.response ?? {})
      : axiosResponseOrError;

  switch (status) {
    case HTTP_STATUS_CODES.Unauthorized:
      if (
        config &&
        nonRetryableRoutes.some((route) => config.url?.endsWith(route))
      ) {
        return;
      }

      return refreshToken()
        .then(() => {
          return retryCurrentRequest(axiosResponseOrError);
        })
        .catch((err) =>
          Promise.reject('Could not refresh token: ' + err.message)
        );
    case HTTP_STATUS_CODES.Forbidden:
    case HTTP_STATUS_CODES.NotFound:
      return Promise.reject(axiosResponseOrError);
    case HTTP_STATUS_CODES.UnprocessableEntity:
      return Promise.reject(
        Object.values(responseData.errors).map((propertyMessage: any) =>
          propertyMessage.map((item: string) => item).join(', ')
        )
      );
    case HTTP_STATUS_CODES.BadRequest:
      return Promise.reject(responseData.message);
    default:
      if (import.meta.env.DEV) {
        console.log(message || `Unexpected error: ${status}`);
      }
      return Promise.reject(axiosResponseOrError);
  }
};
