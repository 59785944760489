<template>
  <FHeaderPrimary :title="t('title')" />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import useMessageListener from '@/modules/shared/core/composables/PostMessage/useMessageListener';
import type { MessageEventData } from '@/modules/shared/core/objects/MessageEventData';
import { setI18nLanguage } from '@/plugins/vueI18n';

definePage({
  meta: {
    needAuth: true
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Gestion du compte'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Account management'
    }
  }
});

const LANGUAGE_CHANGE_EVENT = 'language-change';
const { addHandler } = useMessageListener({
  name: 'user-params'
});
addHandler(LANGUAGE_CHANGE_EVENT, (event: MessageEventData) => {
  const lang =
    event.data === '1' ? SUPPORTED_LANGUAGES.en : SUPPORTED_LANGUAGES.fr;
  setI18nLanguage(lang);
});
</script>
