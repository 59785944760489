import BASE_ROUTE_PARAMETERS from '@/modules/parameters/constants/urls';

const BASE_ROUTE_IMPORT_EXPORT = `${BASE_ROUTE_PARAMETERS}/import-export`;

const IMPORT_EXPORT_ROUTES = {
  ROOT: BASE_ROUTE_IMPORT_EXPORT,
  IMPORT: `${BASE_ROUTE_IMPORT_EXPORT}/import`,
  EXPORT: `${BASE_ROUTE_IMPORT_EXPORT}/export`
};

export default IMPORT_EXPORT_ROUTES;
