<template>
  <FHeaderPrimary
    :add-ability="EMPLOYEES_ABILITIES.EMPLOYEE.DISCIPLINE.CONTROL"
    title=""
    @add:click="handleAddClick"
  />
  <FLegacyIframe
    :iframe-route="
      $route.fullPath +
      '?' +
      objectToQueryString($route.params) +
      '&isEmployeeProfile=true&code=' +
      employeeData.code
    "
  />
</template>
<script lang="ts" setup>
import EMPLOYEES_ABILITIES from '@/modules/employees/constants/abilities';
import { objectToQueryString } from '@/modules/shared/core/utils/route';
import { useSingleEmployeeStore } from '@/modules/employees/stores/employee';
import useDisciplineList from '@/modules/employees/composables/useDisciplineList';

definePage({
  meta: {
    ability: EMPLOYEES_ABILITIES.EMPLOYEE.DISCIPLINE.ANY,
    bypassAbility: [EMPLOYEES_ABILITIES.EMPLOYEE.DISCIPLINE.CONTROL]
  }
});

const singleEmployeeStore = useSingleEmployeeStore('list');
const { employeeData } = storeToRefs(singleEmployeeStore);
const { handleAddClick } = useDisciplineList();
</script>
