<template>
  <router-view />
</template>
<script lang="ts" setup>
import HIRING_ABILITIES from '@/modules/hiring/constants/abilities';

definePage({
  meta: {
    needAuth: true,
    ability: HIRING_ABILITIES.ANY
  }
});
</script>
<style lang="sass" scoped></style>
