/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */
import 'reflect-metadata';

// Plugins
import { registerPlugins } from '@/plugins';
import { registerAllModules } from '@/modules';
// Components
import App from './App.vue';
// Composables
import { createApp } from 'vue';
import testIdDirective from '@/modules/shared/core/directives/TestIdDirective';
import vDynamicDataAttributeDirective from '@/modules/shared/core/directives/DynamicDataAttributeDirective';
import { FolksSDK } from '@sdk/FolksSDK';
import { AuthenticationStoreCredentialsStorage } from '@/modules/authentication/store/AuthenticationStoreCredentialsStorage';
import { useAuthenticationStore } from '@/modules/authentication/store/authentication';

async function init() {
  // Folks SDK init
  FolksSDK.initialize({
    api: {
      baseUrl: import.meta.env.VITE_APP_API_URL_ORIGIN + '/api/v2/',
      origin: import.meta.env.VITE_APP_API_URL_ORIGIN
    },
    sso: {
      clientId: import.meta.env.VITE_APP_CLIENT_ID,
      redirectUri: import.meta.env.VITE_APP_SPA_URL + '/auth/sso/complete'
    }
  });

  const app = createApp(App);
  await registerPlugins(app);

  const authenticationStore = useAuthenticationStore();
  FolksSDK.setCredentialsStorage(
    new AuthenticationStoreCredentialsStorage(authenticationStore)
  );
  FolksSDK.onRefreshTokenFail(async () => {
    return authenticationStore.logout();
  });

  await registerAllModules();

  app.directive('test-id', testIdDirective);
  app.directive('dynamic-data-attr', vDynamicDataAttributeDirective);
  app.mount('#app');
}

init();
