<template>
  <FHeaderPrimary
    :tabs="tabs"
    :back-route="backButtonSelectedName"
    :title="t('title')"
  />
  <FLayoutContentForTabs />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import EMPLOYEES_ABILITIES from '@/modules/employees/constants/abilities';
import type { Tab } from '@/components/FTabs.types';
import EMPLOYEES_ROUTES from '@/modules/employees/constants/urls';
import REPORTS_ROUTES from '@/modules/reports/constants/urls';
import useBackPreviousButton from '@/modules/shared/core/composables/useBackPreviousButton';

definePage({
  meta: {
    ability: EMPLOYEES_ABILITIES.CLIMATE.ANY
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Sommaires du climat de travail',
      annual: 'Résumé des résultats',
      analysis: 'Analyse détaillée',
      comments: 'Sommaire des commentaires'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Work climate summaries',
      annual: 'Summary of results',
      analysis: 'Detailed analysis',
      comments: 'Summary of comments'
    }
  }
});

const tabs = [
  {
    to: EMPLOYEES_ROUTES.CLIMATE.ANNUAL,
    ability: EMPLOYEES_ABILITIES.CLIMATE.ANNUAL,
    title: t('annual')
  },
  {
    to: EMPLOYEES_ROUTES.CLIMATE.ANALYSIS,
    ability: EMPLOYEES_ABILITIES.CLIMATE.ANALYSIS,
    title: t('analysis')
  },
  {
    to: EMPLOYEES_ROUTES.CLIMATE.COMMENTS,
    ability: EMPLOYEES_ABILITIES.CLIMATE.COMMENTS,
    title: t('comments')
  }
] as Tab[];

const { backButtonSelectedName } = useBackPreviousButton([
  REPORTS_ROUTES.SUMMARY
]);

useFirstTabRedirect(EMPLOYEES_ROUTES.CLIMATE.ROOT, tabs);
</script>
