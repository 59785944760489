import _definePage_default_0 from '/builds/folkshr/hris/spa/src/pages/index.vue?definePage&vue&lang.tsx'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/builds/folkshr/hris/spa/src/pages/[...path].vue?definePage&vue&lang.tsx'
import _definePage_default_3 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/certificates-careers/careers.vue?definePage&vue&lang.tsx'
import _definePage_default_4 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/certificates-careers/certificates.vue?definePage&vue&lang.tsx'
import _definePage_default_5 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/certificates-careers.vue?definePage&vue&lang.tsx'
import _definePage_default_6 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/certificates-careers.control.vue?definePage&vue&lang.tsx'
import _definePage_default_7 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/certificates-careers.control.edit.vue?definePage&vue&lang.tsx'
import _definePage_default_8 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/performances/analysis.vue?definePage&vue&lang.tsx'
import _definePage_default_9 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/performances/summary.vue?definePage&vue&lang.tsx'
import _definePage_default_10 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/performances/tracking.vue?definePage&vue&lang.tsx'
import _definePage_default_11 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/performances.vue?definePage&vue&lang.tsx'
import _definePage_default_12 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/performances.admin.vue?definePage&vue&lang.tsx'
import _definePage_default_13 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/training.vue?definePage&vue&lang.tsx'
import _definePage_default_14 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/training.control.vue?definePage&vue&lang.tsx'
import _definePage_default_15 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/training.control.edit.vue?definePage&vue&lang.tsx'
import _definePage_default_16 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/training.request.vue?definePage&vue&lang.tsx'
import _definePage_default_17 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/training.request.edit.vue?definePage&vue&lang.tsx'
import _definePage_default_18 from '/builds/folkshr/hris/spa/src/pages/advancement.vue?definePage&vue&lang.tsx'
import _definePage_default_19 from '/builds/folkshr/hris/spa/src/modules/authentication/pages/sso/index.vue?definePage&vue&lang.tsx'
import _definePage_default_20 from '/builds/folkshr/hris/spa/src/modules/authentication/pages/sso/complete.vue?definePage&vue&lang.tsx'
import _definePage_default_21 from '/builds/folkshr/hris/spa/src/pages/auth.vue?definePage&vue&lang.tsx'
import _definePage_default_22 from '/builds/folkshr/hris/spa/src/modules/company/pages/informations.vue?definePage&vue&lang.tsx'
import _definePage_default_23 from '/builds/folkshr/hris/spa/src/modules/company/pages/salary.vue?definePage&vue&lang.tsx'
import _definePage_default_24 from '/builds/folkshr/hris/spa/src/modules/company/pages/salary.range.vue?definePage&vue&lang.tsx'
import _definePage_default_25 from '/builds/folkshr/hris/spa/src/modules/company/pages/standards/bank-transfer.vue?definePage&vue&lang.tsx'
import _definePage_default_26 from '/builds/folkshr/hris/spa/src/modules/company/pages/standards/employees.vue?definePage&vue&lang.tsx'
import _definePage_default_27 from '/builds/folkshr/hris/spa/src/modules/company/pages/standards/organisational.vue?definePage&vue&lang.tsx'
import _definePage_default_28 from '/builds/folkshr/hris/spa/src/modules/company/pages/standards.vue?definePage&vue&lang.tsx'
import _definePage_default_29 from '/builds/folkshr/hris/spa/src/modules/company/pages/structure/chairs.vue?definePage&vue&lang.tsx'
import _definePage_default_30 from '/builds/folkshr/hris/spa/src/modules/company/pages/structure/chairs.position-id.vue?definePage&vue&lang.tsx'
import _definePage_default_31 from '/builds/folkshr/hris/spa/src/modules/company/pages/structure/chart.vue?definePage&vue&lang.tsx'
import _definePage_default_32 from '/builds/folkshr/hris/spa/src/modules/company/pages/structure/departments.vue?definePage&vue&lang.tsx'
import _definePage_default_33 from '/builds/folkshr/hris/spa/src/modules/company/pages/structure/positions.vue?definePage&vue&lang.tsx'
import _definePage_default_34 from '/builds/folkshr/hris/spa/src/modules/company/pages/structure/union.vue?definePage&vue&lang.tsx'
import _definePage_default_35 from '/builds/folkshr/hris/spa/src/modules/company/pages/structure/working-sites.vue?definePage&vue&lang.tsx'
import _definePage_default_36 from '/builds/folkshr/hris/spa/src/modules/company/pages/structure.vue?definePage&vue&lang.tsx'
import _definePage_default_37 from '/builds/folkshr/hris/spa/src/pages/company.vue?definePage&vue&lang.tsx'
import _definePage_default_38 from '/builds/folkshr/hris/spa/src/modules/documents/pages/e-sign.vue?definePage&vue&lang.tsx'
import _definePage_default_39 from '/builds/folkshr/hris/spa/src/modules/documents/pages/e-sign.templates.vue?definePage&vue&lang.tsx'
import _definePage_default_40 from '/builds/folkshr/hris/spa/src/modules/documents/pages/vault.vue?definePage&vue&lang.tsx'
import _definePage_default_41 from '/builds/folkshr/hris/spa/src/modules/documents/pages/vault.configurations.vue?definePage&vue&lang.tsx'
import _definePage_default_42 from '/builds/folkshr/hris/spa/src/pages/documents.vue?definePage&vue&lang.tsx'
import _definePage_default_43 from '/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/discipline.vue?definePage&vue&lang.tsx'
import _definePage_default_44 from '/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/documents.vue?definePage&vue&lang.tsx'
import _definePage_default_45 from '/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/expense-account.vue?definePage&vue&lang.tsx'
import _definePage_default_46 from '/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/informations.vue?definePage&vue&lang.tsx'
import _definePage_default_47 from '/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/note.vue?definePage&vue&lang.tsx'
import _definePage_default_48 from '/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/onboarding.vue?definePage&vue&lang.tsx'
import _definePage_default_49 from '/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/performances/analysis.vue?definePage&vue&lang.tsx'
import _definePage_default_50 from '/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/performances/careers.vue?definePage&vue&lang.tsx'
import _definePage_default_51 from '/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/performances/certificates.vue?definePage&vue&lang.tsx'
import _definePage_default_52 from '/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/performances/training.vue?definePage&vue&lang.tsx'
import _definePage_default_53 from '/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/statistics.vue?definePage&vue&lang.tsx'
import _definePage_default_54 from '/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/surveys.vue?definePage&vue&lang.tsx'
import _definePage_default_55 from '/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/time-management/absence.vue?definePage&vue&lang.tsx'
import _definePage_default_56 from '/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/time-management/overtime.vue?definePage&vue&lang.tsx'
import _definePage_default_57 from '/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/time-management/timesheet.vue?definePage&vue&lang.tsx'
import _definePage_default_58 from '/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/time-management/vacation.vue?definePage&vue&lang.tsx'
import _definePage_default_59 from '/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/work-accidents.vue?definePage&vue&lang.tsx'
import _definePage_default_60 from '/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id].vue?definePage&vue&lang.tsx'
import _definePage_default_61 from '/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id].configuration/custom-field-corpo.vue?definePage&vue&lang.tsx'
import _definePage_default_62 from '/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id].configuration/custom-field-perso.vue?definePage&vue&lang.tsx'
import _definePage_default_63 from '/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id].configuration/projects.vue?definePage&vue&lang.tsx'
import _definePage_default_64 from '/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id].configuration.vue?definePage&vue&lang.tsx'
import _definePage_default_65 from '/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id].history/corporate.vue?definePage&vue&lang.tsx'
import _definePage_default_66 from '/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id].history/personal.vue?definePage&vue&lang.tsx'
import _definePage_default_67 from '/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id].history.vue?definePage&vue&lang.tsx'
import _definePage_default_68 from '/builds/folkshr/hris/spa/src/modules/employees/pages/climate/analysis.vue?definePage&vue&lang.tsx'
import _definePage_default_69 from '/builds/folkshr/hris/spa/src/modules/employees/pages/climate/annual.vue?definePage&vue&lang.tsx'
import _definePage_default_70 from '/builds/folkshr/hris/spa/src/modules/employees/pages/climate/comments.vue?definePage&vue&lang.tsx'
import _definePage_default_71 from '/builds/folkshr/hris/spa/src/modules/employees/pages/climate.vue?definePage&vue&lang.tsx'
import _definePage_default_72 from '/builds/folkshr/hris/spa/src/modules/employees/pages/configuration/profil-customization.vue?definePage&vue&lang.tsx'
import _definePage_default_73 from '/builds/folkshr/hris/spa/src/modules/employees/pages/configuration.vue?definePage&vue&lang.tsx'
import _definePage_default_74 from '/builds/folkshr/hris/spa/src/modules/employees/pages/expense-accounts.vue?definePage&vue&lang.tsx'
import _definePage_default_75 from '/builds/folkshr/hris/spa/src/modules/employees/pages/expense-accounts.configurations.vue?definePage&vue&lang.tsx'
import _definePage_default_76 from '/builds/folkshr/hris/spa/src/modules/employees/pages/expense-accounts.create.vue?definePage&vue&lang.tsx'
import _definePage_default_77 from '/builds/folkshr/hris/spa/src/modules/employees/pages/expense-accounts.edit.vue?definePage&vue&lang.tsx'
import _definePage_default_78 from '/builds/folkshr/hris/spa/src/modules/employees/pages/history/corporate.vue?definePage&vue&lang.tsx'
import _definePage_default_79 from '/builds/folkshr/hris/spa/src/modules/employees/pages/history/personal.vue?definePage&vue&lang.tsx'
import _definePage_default_80 from '/builds/folkshr/hris/spa/src/modules/employees/pages/history.vue?definePage&vue&lang.tsx'
import _definePage_default_81 from '/builds/folkshr/hris/spa/src/modules/employees/pages/incidents.vue?definePage&vue&lang.tsx'
import _definePage_default_82 from '/builds/folkshr/hris/spa/src/modules/employees/pages/list.vue?definePage&vue&lang.tsx'
import _definePage_default_83 from '/builds/folkshr/hris/spa/src/modules/employees/pages/summary.vue?definePage&vue&lang.tsx'
import _definePage_default_84 from '/builds/folkshr/hris/spa/src/pages/employees.vue?definePage&vue&lang.tsx'
import _definePage_default_85 from '/builds/folkshr/hris/spa/src/modules/hiring/pages/requisition.vue?definePage&vue&lang.tsx'
import _definePage_default_86 from '/builds/folkshr/hris/spa/src/modules/hiring/pages/requisition.configurations.vue?definePage&vue&lang.tsx'
import _definePage_default_87 from '/builds/folkshr/hris/spa/src/modules/hiring/pages/requisition.configurations.config.vue?definePage&vue&lang.tsx'
import _definePage_default_88 from '/builds/folkshr/hris/spa/src/modules/hiring/pages/requisition.create.vue?definePage&vue&lang.tsx'
import _definePage_default_89 from '/builds/folkshr/hris/spa/src/modules/hiring/pages/requisition.edit.vue?definePage&vue&lang.tsx'
import _definePage_default_90 from '/builds/folkshr/hris/spa/src/pages/hiring.vue?definePage&vue&lang.tsx'
import _definePage_default_91 from '/builds/folkshr/hris/spa/src/modules/onboarding/pages/arrivals.vue?definePage&vue&lang.tsx'
import _definePage_default_92 from '/builds/folkshr/hris/spa/src/modules/onboarding/pages/arrivals.configurations/tasks.vue?definePage&vue&lang.tsx'
import _definePage_default_93 from '/builds/folkshr/hris/spa/src/modules/onboarding/pages/arrivals.configurations/templates.vue?definePage&vue&lang.tsx'
import _definePage_default_94 from '/builds/folkshr/hris/spa/src/modules/onboarding/pages/arrivals.configurations/titles.vue?definePage&vue&lang.tsx'
import _definePage_default_95 from '/builds/folkshr/hris/spa/src/modules/onboarding/pages/arrivals.configurations.vue?definePage&vue&lang.tsx'
import _definePage_default_96 from '/builds/folkshr/hris/spa/src/modules/onboarding/pages/departures.vue?definePage&vue&lang.tsx'
import _definePage_default_97 from '/builds/folkshr/hris/spa/src/modules/onboarding/pages/departures.configurations/tasks.vue?definePage&vue&lang.tsx'
import _definePage_default_98 from '/builds/folkshr/hris/spa/src/modules/onboarding/pages/departures.configurations/templates.vue?definePage&vue&lang.tsx'
import _definePage_default_99 from '/builds/folkshr/hris/spa/src/modules/onboarding/pages/departures.configurations/titles.vue?definePage&vue&lang.tsx'
import _definePage_default_100 from '/builds/folkshr/hris/spa/src/modules/onboarding/pages/departures.configurations.vue?definePage&vue&lang.tsx'
import _definePage_default_101 from '/builds/folkshr/hris/spa/src/modules/onboarding/pages/departures.control.vue?definePage&vue&lang.tsx'
import _definePage_default_102 from '/builds/folkshr/hris/spa/src/pages/onboarding.vue?definePage&vue&lang.tsx'
import _definePage_default_103 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/import-export/pages/export.vue?definePage&vue&lang.tsx'
import _definePage_default_104 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/import-export/pages/import.vue?definePage&vue&lang.tsx'
import _definePage_default_105 from '/builds/folkshr/hris/spa/src/modules/parameters/pages/import-export.vue?definePage&vue&lang.tsx'
import _definePage_default_106 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/integrations/pages/api.vue?definePage&vue&lang.tsx'
import _definePage_default_107 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/integrations/pages/nethris.vue?definePage&vue&lang.tsx'
import _definePage_default_108 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/integrations/pages/payroll.vue?definePage&vue&lang.tsx'
import _definePage_default_109 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/integrations/pages/payworks.vue?definePage&vue&lang.tsx'
import _definePage_default_110 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/integrations/pages/sso.vue?definePage&vue&lang.tsx'
import _definePage_default_111 from '/builds/folkshr/hris/spa/src/modules/parameters/pages/integrations.vue?definePage&vue&lang.tsx'
import _definePage_default_112 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/notifications/pages/index.vue?definePage&vue&lang.tsx'
import _definePage_default_113 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/roles-permissions/pages/index.vue?definePage&vue&lang.tsx'
import _definePage_default_114 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/active-employees/index.vue?definePage&vue&lang.tsx'
import _definePage_default_115 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/companies.vue?definePage&vue&lang.tsx'
import _definePage_default_116 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/companies.config.vue?definePage&vue&lang.tsx'
import _definePage_default_117 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/companies.create.vue?definePage&vue&lang.tsx'
import _definePage_default_118 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/companies.edit.vue?definePage&vue&lang.tsx'
import _definePage_default_119 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/pw-numbers/index.vue?definePage&vue&lang.tsx'
import _definePage_default_120 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/time-management/absences-reasons.vue?definePage&vue&lang.tsx'
import _definePage_default_121 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/time-management/overtime-types.vue?definePage&vue&lang.tsx'
import _definePage_default_122 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/time-management/overtime-values.vue?definePage&vue&lang.tsx'
import _definePage_default_123 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/time-management/vacation-types.vue?definePage&vue&lang.tsx'
import _definePage_default_124 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/time-management.vue?definePage&vue&lang.tsx'
import _definePage_default_125 from '/builds/folkshr/hris/spa/src/modules/parameters/pages/users.vue?definePage&vue&lang.tsx'
import _definePage_default_126 from '/builds/folkshr/hris/spa/src/modules/parameters/pages/users.bulk.vue?definePage&vue&lang.tsx'
import _definePage_default_127 from '/builds/folkshr/hris/spa/src/modules/parameters/pages/users.create.vue?definePage&vue&lang.tsx'
import _definePage_default_128 from '/builds/folkshr/hris/spa/src/modules/parameters/pages/users.edit.vue?definePage&vue&lang.tsx'
import _definePage_default_129 from '/builds/folkshr/hris/spa/src/pages/parameters.vue?definePage&vue&lang.tsx'
import _definePage_default_130 from '/builds/folkshr/hris/spa/src/modules/reports/pages/dashboard/analysis.vue?definePage&vue&lang.tsx'
import _definePage_default_131 from '/builds/folkshr/hris/spa/src/modules/reports/pages/dashboard/summary.vue?definePage&vue&lang.tsx'
import _definePage_default_132 from '/builds/folkshr/hris/spa/src/modules/reports/pages/dashboard.vue?definePage&vue&lang.tsx'
import _definePage_default_133 from '/builds/folkshr/hris/spa/src/modules/reports/pages/discipline.vue?definePage&vue&lang.tsx'
import _definePage_default_134 from '/builds/folkshr/hris/spa/src/modules/reports/pages/kpi.vue?definePage&vue&lang.tsx'
import _definePage_default_135 from '/builds/folkshr/hris/spa/src/modules/reports/pages/summary.vue?definePage&vue&lang.tsx'
import _definePage_default_136 from '/builds/folkshr/hris/spa/src/pages/reports.vue?definePage&vue&lang.tsx'
import _definePage_default_137 from '/builds/folkshr/hris/spa/src/modules/safety/pages/summary.vue?definePage&vue&lang.tsx'
import _definePage_default_138 from '/builds/folkshr/hris/spa/src/modules/safety/pages/work-accidents.vue?definePage&vue&lang.tsx'
import _definePage_default_139 from '/builds/folkshr/hris/spa/src/modules/safety/pages/work-accidents.control.vue?definePage&vue&lang.tsx'
import _definePage_default_140 from '/builds/folkshr/hris/spa/src/modules/safety/pages/work-accidents.edit.vue?definePage&vue&lang.tsx'
import _definePage_default_141 from '/builds/folkshr/hris/spa/src/pages/safety.vue?definePage&vue&lang.tsx'
import _definePage_default_142 from '/builds/folkshr/hris/spa/src/pages/simulator.vue?definePage&vue&lang.tsx'
import _definePage_default_143 from '/builds/folkshr/hris/spa/src/modules/time-management/pages/absences.vue?definePage&vue&lang.tsx'
import _definePage_default_144 from '/builds/folkshr/hris/spa/src/modules/time-management/pages/absences.summary/annual.vue?definePage&vue&lang.tsx'
import _definePage_default_145 from '/builds/folkshr/hris/spa/src/modules/time-management/pages/absences.summary/weekly.vue?definePage&vue&lang.tsx'
import _definePage_default_146 from '/builds/folkshr/hris/spa/src/modules/time-management/pages/absences.summary.vue?definePage&vue&lang.tsx'
import _definePage_default_147 from '/builds/folkshr/hris/spa/src/modules/time-management/pages/calendar.vue?definePage&vue&lang.tsx'
import _definePage_default_148 from '/builds/folkshr/hris/spa/src/modules/time-management/pages/overtimes.vue?definePage&vue&lang.tsx'
import _definePage_default_149 from '/builds/folkshr/hris/spa/src/modules/time-management/pages/overtimes.summary.vue?definePage&vue&lang.tsx'
import _definePage_default_150 from '/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet.vue?definePage&vue&lang.tsx'
import _definePage_default_151 from '/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet.configurations/calculations.vue?definePage&vue&lang.tsx'
import _definePage_default_152 from '/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet.configurations/holidays.vue?definePage&vue&lang.tsx'
import _definePage_default_153 from '/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet.configurations/importation.vue?definePage&vue&lang.tsx'
import _definePage_default_154 from '/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet.configurations/legend.vue?definePage&vue&lang.tsx'
import _definePage_default_155 from '/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet.configurations/projects.vue?definePage&vue&lang.tsx'
import _definePage_default_156 from '/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet.configurations.vue?definePage&vue&lang.tsx'
import _definePage_default_157 from '/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet.summary.vue?definePage&vue&lang.tsx'
import _definePage_default_158 from '/builds/folkshr/hris/spa/src/modules/time-management/pages/vacations.vue?definePage&vue&lang.tsx'
import _definePage_default_159 from '/builds/folkshr/hris/spa/src/modules/time-management/pages/vacations.summary.vue?definePage&vue&lang.tsx'
import _definePage_default_160 from '/builds/folkshr/hris/spa/src/pages/time-management.vue?definePage&vue&lang.tsx'
import _definePage_default_161 from '/builds/folkshr/hris/spa/src/pages/unauthorized.vue?definePage&vue&lang.tsx'
import _definePage_default_162 from '/builds/folkshr/hris/spa/src/pages/user-params.vue?definePage&vue&lang.tsx'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: '/',
    component: () => import('/builds/folkshr/hris/spa/src/pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  _mergeRouteRecord(
  {
    path: '/:path(.*)',
    name: '/[...path]',
    component: () => import('/builds/folkshr/hris/spa/src/pages/[...path].vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  _mergeRouteRecord(
  {
    path: '/advancement',
    name: '/advancement',
    component: () => import('/builds/folkshr/hris/spa/src/pages/advancement.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: 'certificates-careers',
        name: '/advancement/certificates-careers',
        component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/certificates-careers.vue'),
        children: [
  _mergeRouteRecord(
          {
            path: 'careers',
            name: '/advancement/certificates-careers/careers',
            component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/certificates-careers/careers.vue'),
            /* no children */
          },
  _definePage_default_3
  ),
  _mergeRouteRecord(
          {
            path: 'certificates',
            name: '/advancement/certificates-careers/certificates',
            component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/certificates-careers/certificates.vue'),
            /* no children */
          },
  _definePage_default_4
  )
        ],
      },
  _definePage_default_5
  ),
  _mergeRouteRecord(
      {
        path: 'certificates-careers/control',
        name: '/advancement/certificates-careers.control',
        component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/certificates-careers.control.vue'),
        /* no children */
      },
  _definePage_default_6
  ),
  _mergeRouteRecord(
      {
        path: 'certificates-careers/control/edit',
        name: '/advancement/certificates-careers.control.edit',
        component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/certificates-careers.control.edit.vue'),
        /* no children */
      },
  _definePage_default_7
  ),
  _mergeRouteRecord(
      {
        path: 'performances',
        name: '/advancement/performances',
        component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/performances.vue'),
        children: [
  _mergeRouteRecord(
          {
            path: 'analysis',
            name: '/advancement/performances/analysis',
            component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/performances/analysis.vue'),
            /* no children */
          },
  _definePage_default_8
  ),
  _mergeRouteRecord(
          {
            path: 'summary',
            name: '/advancement/performances/summary',
            component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/performances/summary.vue'),
            /* no children */
          },
  _definePage_default_9
  ),
  _mergeRouteRecord(
          {
            path: 'tracking',
            name: '/advancement/performances/tracking',
            component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/performances/tracking.vue'),
            /* no children */
          },
  _definePage_default_10
  )
        ],
      },
  _definePage_default_11
  ),
  _mergeRouteRecord(
      {
        path: 'performances/admin',
        name: '/advancement/performances.admin',
        component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/performances.admin.vue'),
        /* no children */
      },
  _definePage_default_12
  ),
  _mergeRouteRecord(
      {
        path: 'training',
        name: '/advancement/training',
        component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/training.vue'),
        /* no children */
      },
  _definePage_default_13
  ),
  _mergeRouteRecord(
      {
        path: 'training/control',
        name: '/advancement/training.control',
        component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/training.control.vue'),
        /* no children */
      },
  _definePage_default_14
  ),
  _mergeRouteRecord(
      {
        path: 'training/control/edit',
        name: '/advancement/training.control.edit',
        component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/training.control.edit.vue'),
        /* no children */
      },
  _definePage_default_15
  ),
  _mergeRouteRecord(
      {
        path: 'training/request',
        name: '/advancement/training.request',
        component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/training.request.vue'),
        /* no children */
      },
  _definePage_default_16
  ),
  _mergeRouteRecord(
      {
        path: 'training/request/edit',
        name: '/advancement/training.request.edit',
        component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/training.request.edit.vue'),
        /* no children */
      },
  _definePage_default_17
  )
    ],
  },
  _definePage_default_18
  ),
  _mergeRouteRecord(
  {
    path: '/auth',
    name: '/auth',
    component: () => import('/builds/folkshr/hris/spa/src/pages/auth.vue'),
    children: [
      {
        path: 'create-password',
        name: '/auth/create-password',
        component: () => import('/builds/folkshr/hris/spa/src/modules/authentication/pages/create-password.vue'),
        /* no children */
      },
      {
        path: 'forgot-password',
        name: '/auth/forgot-password',
        component: () => import('/builds/folkshr/hris/spa/src/modules/authentication/pages/forgot-password.vue'),
        /* no children */
      },
      {
        path: 'login',
        name: '/auth/login',
        component: () => import('/builds/folkshr/hris/spa/src/modules/authentication/pages/login.vue'),
        /* no children */
      },
      {
        path: 'logout',
        name: '/auth/logout',
        component: () => import('/builds/folkshr/hris/spa/src/modules/authentication/pages/logout.vue'),
        /* no children */
      },
      {
        path: 'reset-password',
        name: '/auth/reset-password',
        component: () => import('/builds/folkshr/hris/spa/src/modules/authentication/pages/reset-password.vue'),
        /* no children */
      },
      {
        path: 'sso',
        /* internal name: '/auth/sso' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/auth/sso/',
            component: () => import('/builds/folkshr/hris/spa/src/modules/authentication/pages/sso/index.vue'),
            /* no children */
          },
  _definePage_default_19
  ),
  _mergeRouteRecord(
          {
            path: 'complete',
            name: '/auth/sso/complete',
            component: () => import('/builds/folkshr/hris/spa/src/modules/authentication/pages/sso/complete.vue'),
            /* no children */
          },
  _definePage_default_20
  )
        ],
      }
    ],
  },
  _definePage_default_21
  ),
  _mergeRouteRecord(
  {
    path: '/company',
    name: '/company',
    component: () => import('/builds/folkshr/hris/spa/src/pages/company.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: 'informations',
        name: '/company/informations',
        component: () => import('/builds/folkshr/hris/spa/src/modules/company/pages/informations.vue'),
        /* no children */
      },
  _definePage_default_22
  ),
  _mergeRouteRecord(
      {
        path: 'salary',
        name: '/company/salary',
        component: () => import('/builds/folkshr/hris/spa/src/modules/company/pages/salary.vue'),
        /* no children */
      },
  _definePage_default_23
  ),
  _mergeRouteRecord(
      {
        path: 'salary/range',
        name: '/company/salary.range',
        component: () => import('/builds/folkshr/hris/spa/src/modules/company/pages/salary.range.vue'),
        /* no children */
      },
  _definePage_default_24
  ),
  _mergeRouteRecord(
      {
        path: 'standards',
        name: '/company/standards',
        component: () => import('/builds/folkshr/hris/spa/src/modules/company/pages/standards.vue'),
        children: [
  _mergeRouteRecord(
          {
            path: 'bank-transfer',
            name: '/company/standards/bank-transfer',
            component: () => import('/builds/folkshr/hris/spa/src/modules/company/pages/standards/bank-transfer.vue'),
            /* no children */
          },
  _definePage_default_25
  ),
  _mergeRouteRecord(
          {
            path: 'employees',
            name: '/company/standards/employees',
            component: () => import('/builds/folkshr/hris/spa/src/modules/company/pages/standards/employees.vue'),
            /* no children */
          },
  _definePage_default_26
  ),
  _mergeRouteRecord(
          {
            path: 'organisational',
            name: '/company/standards/organisational',
            component: () => import('/builds/folkshr/hris/spa/src/modules/company/pages/standards/organisational.vue'),
            /* no children */
          },
  _definePage_default_27
  )
        ],
      },
  _definePage_default_28
  ),
  _mergeRouteRecord(
      {
        path: 'structure',
        name: '/company/structure',
        component: () => import('/builds/folkshr/hris/spa/src/modules/company/pages/structure.vue'),
        children: [
  _mergeRouteRecord(
          {
            path: 'chairs',
            name: '/company/structure/chairs',
            component: () => import('/builds/folkshr/hris/spa/src/modules/company/pages/structure/chairs.vue'),
            /* no children */
          },
  _definePage_default_29
  ),
  _mergeRouteRecord(
          {
            path: 'chairs/position-id',
            name: '/company/structure/chairs.position-id',
            component: () => import('/builds/folkshr/hris/spa/src/modules/company/pages/structure/chairs.position-id.vue'),
            /* no children */
          },
  _definePage_default_30
  ),
  _mergeRouteRecord(
          {
            path: 'chart',
            name: '/company/structure/chart',
            component: () => import('/builds/folkshr/hris/spa/src/modules/company/pages/structure/chart.vue'),
            /* no children */
          },
  _definePage_default_31
  ),
  _mergeRouteRecord(
          {
            path: 'departments',
            name: '/company/structure/departments',
            component: () => import('/builds/folkshr/hris/spa/src/modules/company/pages/structure/departments.vue'),
            /* no children */
          },
  _definePage_default_32
  ),
  _mergeRouteRecord(
          {
            path: 'positions',
            name: '/company/structure/positions',
            component: () => import('/builds/folkshr/hris/spa/src/modules/company/pages/structure/positions.vue'),
            /* no children */
          },
  _definePage_default_33
  ),
  _mergeRouteRecord(
          {
            path: 'union',
            name: '/company/structure/union',
            component: () => import('/builds/folkshr/hris/spa/src/modules/company/pages/structure/union.vue'),
            /* no children */
          },
  _definePage_default_34
  ),
  _mergeRouteRecord(
          {
            path: 'working-sites',
            name: '/company/structure/working-sites',
            component: () => import('/builds/folkshr/hris/spa/src/modules/company/pages/structure/working-sites.vue'),
            /* no children */
          },
  _definePage_default_35
  )
        ],
      },
  _definePage_default_36
  )
    ],
  },
  _definePage_default_37
  ),
  _mergeRouteRecord(
  {
    path: '/documents',
    name: '/documents',
    component: () => import('/builds/folkshr/hris/spa/src/pages/documents.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: 'e-sign',
        name: '/documents/e-sign',
        component: () => import('/builds/folkshr/hris/spa/src/modules/documents/pages/e-sign.vue'),
        /* no children */
      },
  _definePage_default_38
  ),
  _mergeRouteRecord(
      {
        path: 'e-sign/templates',
        name: '/documents/e-sign.templates',
        component: () => import('/builds/folkshr/hris/spa/src/modules/documents/pages/e-sign.templates.vue'),
        /* no children */
      },
  _definePage_default_39
  ),
  _mergeRouteRecord(
      {
        path: 'vault',
        name: '/documents/vault',
        component: () => import('/builds/folkshr/hris/spa/src/modules/documents/pages/vault.vue'),
        /* no children */
      },
  _definePage_default_40
  ),
  _mergeRouteRecord(
      {
        path: 'vault/configurations',
        name: '/documents/vault.configurations',
        component: () => import('/builds/folkshr/hris/spa/src/modules/documents/pages/vault.configurations.vue'),
        /* no children */
      },
  _definePage_default_41
  )
    ],
  },
  _definePage_default_42
  ),
  _mergeRouteRecord(
  {
    path: '/employees',
    name: '/employees',
    component: () => import('/builds/folkshr/hris/spa/src/pages/employees.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: ':employee_id',
        name: '/employees/[employee_id]',
        component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id].vue'),
        children: [
  _mergeRouteRecord(
          {
            path: 'discipline',
            name: '/employees/[employee_id]/discipline',
            component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/discipline.vue'),
            /* no children */
          },
  _definePage_default_43
  ),
  _mergeRouteRecord(
          {
            path: 'documents',
            name: '/employees/[employee_id]/documents',
            component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/documents.vue'),
            /* no children */
          },
  _definePage_default_44
  ),
  _mergeRouteRecord(
          {
            path: 'expense-account',
            name: '/employees/[employee_id]/expense-account',
            component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/expense-account.vue'),
            /* no children */
          },
  _definePage_default_45
  ),
  _mergeRouteRecord(
          {
            path: 'informations',
            name: '/employees/[employee_id]/informations',
            component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/informations.vue'),
            children: [
              {
                path: 'corporate',
                name: '/employees/[employee_id]/informations/corporate',
                component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/informations/corporate.vue'),
                /* no children */
              },
              {
                path: 'personal',
                name: '/employees/[employee_id]/informations/personal',
                component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/informations/personal.vue'),
                /* no children */
              }
            ],
          },
  _definePage_default_46
  ),
  _mergeRouteRecord(
          {
            path: 'note',
            name: '/employees/[employee_id]/note',
            component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/note.vue'),
            /* no children */
          },
  _definePage_default_47
  ),
  _mergeRouteRecord(
          {
            path: 'onboarding',
            name: '/employees/[employee_id]/onboarding',
            component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/onboarding.vue'),
            /* no children */
          },
  _definePage_default_48
  ),
          {
            path: 'performances',
            /* internal name: '/employees/[employee_id]/performances' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: 'analysis',
                name: '/employees/[employee_id]/performances/analysis',
                component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/performances/analysis.vue'),
                /* no children */
              },
  _definePage_default_49
  ),
  _mergeRouteRecord(
              {
                path: 'careers',
                name: '/employees/[employee_id]/performances/careers',
                component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/performances/careers.vue'),
                /* no children */
              },
  _definePage_default_50
  ),
  _mergeRouteRecord(
              {
                path: 'certificates',
                name: '/employees/[employee_id]/performances/certificates',
                component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/performances/certificates.vue'),
                /* no children */
              },
  _definePage_default_51
  ),
  _mergeRouteRecord(
              {
                path: 'training',
                name: '/employees/[employee_id]/performances/training',
                component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/performances/training.vue'),
                /* no children */
              },
  _definePage_default_52
  )
            ],
          },
  _mergeRouteRecord(
          {
            path: 'statistics',
            name: '/employees/[employee_id]/statistics',
            component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/statistics.vue'),
            /* no children */
          },
  _definePage_default_53
  ),
  _mergeRouteRecord(
          {
            path: 'surveys',
            name: '/employees/[employee_id]/surveys',
            component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/surveys.vue'),
            /* no children */
          },
  _definePage_default_54
  ),
          {
            path: 'time-management',
            /* internal name: '/employees/[employee_id]/time-management' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: 'absence',
                name: '/employees/[employee_id]/time-management/absence',
                component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/time-management/absence.vue'),
                /* no children */
              },
  _definePage_default_55
  ),
  _mergeRouteRecord(
              {
                path: 'overtime',
                name: '/employees/[employee_id]/time-management/overtime',
                component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/time-management/overtime.vue'),
                /* no children */
              },
  _definePage_default_56
  ),
  _mergeRouteRecord(
              {
                path: 'timesheet',
                name: '/employees/[employee_id]/time-management/timesheet',
                component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/time-management/timesheet.vue'),
                /* no children */
              },
  _definePage_default_57
  ),
  _mergeRouteRecord(
              {
                path: 'vacation',
                name: '/employees/[employee_id]/time-management/vacation',
                component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/time-management/vacation.vue'),
                /* no children */
              },
  _definePage_default_58
  )
            ],
          },
  _mergeRouteRecord(
          {
            path: 'work-accidents',
            name: '/employees/[employee_id]/work-accidents',
            component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id]/work-accidents.vue'),
            /* no children */
          },
  _definePage_default_59
  )
        ],
      },
  _definePage_default_60
  ),
  _mergeRouteRecord(
      {
        path: ':employee_id/configuration',
        name: '/employees/[employee_id].configuration',
        component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id].configuration.vue'),
        children: [
  _mergeRouteRecord(
          {
            path: 'custom-field-corpo',
            name: '/employees/[employee_id].configuration/custom-field-corpo',
            component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id].configuration/custom-field-corpo.vue'),
            /* no children */
          },
  _definePage_default_61
  ),
  _mergeRouteRecord(
          {
            path: 'custom-field-perso',
            name: '/employees/[employee_id].configuration/custom-field-perso',
            component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id].configuration/custom-field-perso.vue'),
            /* no children */
          },
  _definePage_default_62
  ),
  _mergeRouteRecord(
          {
            path: 'projects',
            name: '/employees/[employee_id].configuration/projects',
            component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id].configuration/projects.vue'),
            /* no children */
          },
  _definePage_default_63
  )
        ],
      },
  _definePage_default_64
  ),
  _mergeRouteRecord(
      {
        path: ':employee_id/history',
        name: '/employees/[employee_id].history',
        component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id].history.vue'),
        children: [
  _mergeRouteRecord(
          {
            path: 'corporate',
            name: '/employees/[employee_id].history/corporate',
            component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id].history/corporate.vue'),
            /* no children */
          },
  _definePage_default_65
  ),
  _mergeRouteRecord(
          {
            path: 'personal',
            name: '/employees/[employee_id].history/personal',
            component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/[employee_id].history/personal.vue'),
            /* no children */
          },
  _definePage_default_66
  )
        ],
      },
  _definePage_default_67
  ),
  _mergeRouteRecord(
      {
        path: 'climate',
        name: '/employees/climate',
        component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/climate.vue'),
        children: [
  _mergeRouteRecord(
          {
            path: 'analysis',
            name: '/employees/climate/analysis',
            component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/climate/analysis.vue'),
            /* no children */
          },
  _definePage_default_68
  ),
  _mergeRouteRecord(
          {
            path: 'annual',
            name: '/employees/climate/annual',
            component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/climate/annual.vue'),
            /* no children */
          },
  _definePage_default_69
  ),
  _mergeRouteRecord(
          {
            path: 'comments',
            name: '/employees/climate/comments',
            component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/climate/comments.vue'),
            /* no children */
          },
  _definePage_default_70
  )
        ],
      },
  _definePage_default_71
  ),
  _mergeRouteRecord(
      {
        path: 'configuration',
        name: '/employees/configuration',
        component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/configuration.vue'),
        children: [
  _mergeRouteRecord(
          {
            path: 'profil-customization',
            name: '/employees/configuration/profil-customization',
            component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/configuration/profil-customization.vue'),
            /* no children */
          },
  _definePage_default_72
  )
        ],
      },
  _definePage_default_73
  ),
  _mergeRouteRecord(
      {
        path: 'expense-accounts',
        name: '/employees/expense-accounts',
        component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/expense-accounts.vue'),
        /* no children */
      },
  _definePage_default_74
  ),
  _mergeRouteRecord(
      {
        path: 'expense-accounts/configurations',
        name: '/employees/expense-accounts.configurations',
        component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/expense-accounts.configurations.vue'),
        /* no children */
      },
  _definePage_default_75
  ),
  _mergeRouteRecord(
      {
        path: 'expense-accounts/create',
        name: '/employees/expense-accounts.create',
        component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/expense-accounts.create.vue'),
        /* no children */
      },
  _definePage_default_76
  ),
  _mergeRouteRecord(
      {
        path: 'expense-accounts/edit',
        name: '/employees/expense-accounts.edit',
        component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/expense-accounts.edit.vue'),
        /* no children */
      },
  _definePage_default_77
  ),
  _mergeRouteRecord(
      {
        path: 'history',
        name: '/employees/history',
        component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/history.vue'),
        children: [
  _mergeRouteRecord(
          {
            path: 'corporate',
            name: '/employees/history/corporate',
            component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/history/corporate.vue'),
            /* no children */
          },
  _definePage_default_78
  ),
  _mergeRouteRecord(
          {
            path: 'personal',
            name: '/employees/history/personal',
            component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/history/personal.vue'),
            /* no children */
          },
  _definePage_default_79
  )
        ],
      },
  _definePage_default_80
  ),
  _mergeRouteRecord(
      {
        path: 'incidents',
        name: '/employees/incidents',
        component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/incidents.vue'),
        /* no children */
      },
  _definePage_default_81
  ),
  _mergeRouteRecord(
      {
        path: 'list',
        name: '/employees/list',
        component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/list.vue'),
        /* no children */
      },
  _definePage_default_82
  ),
  _mergeRouteRecord(
      {
        path: 'summary',
        name: '/employees/summary',
        component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/summary.vue'),
        /* no children */
      },
  _definePage_default_83
  )
    ],
  },
  _definePage_default_84
  ),
  _mergeRouteRecord(
  {
    path: '/hiring',
    name: '/hiring',
    component: () => import('/builds/folkshr/hris/spa/src/pages/hiring.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: 'requisition',
        name: '/hiring/requisition',
        component: () => import('/builds/folkshr/hris/spa/src/modules/hiring/pages/requisition.vue'),
        /* no children */
      },
  _definePage_default_85
  ),
  _mergeRouteRecord(
      {
        path: 'requisition/configurations',
        name: '/hiring/requisition.configurations',
        component: () => import('/builds/folkshr/hris/spa/src/modules/hiring/pages/requisition.configurations.vue'),
        /* no children */
      },
  _definePage_default_86
  ),
  _mergeRouteRecord(
      {
        path: 'requisition/configurations/config',
        name: '/hiring/requisition.configurations.config',
        component: () => import('/builds/folkshr/hris/spa/src/modules/hiring/pages/requisition.configurations.config.vue'),
        /* no children */
      },
  _definePage_default_87
  ),
  _mergeRouteRecord(
      {
        path: 'requisition/create',
        name: '/hiring/requisition.create',
        component: () => import('/builds/folkshr/hris/spa/src/modules/hiring/pages/requisition.create.vue'),
        /* no children */
      },
  _definePage_default_88
  ),
  _mergeRouteRecord(
      {
        path: 'requisition/edit',
        name: '/hiring/requisition.edit',
        component: () => import('/builds/folkshr/hris/spa/src/modules/hiring/pages/requisition.edit.vue'),
        /* no children */
      },
  _definePage_default_89
  )
    ],
  },
  _definePage_default_90
  ),
  _mergeRouteRecord(
  {
    path: '/onboarding',
    name: '/onboarding',
    component: () => import('/builds/folkshr/hris/spa/src/pages/onboarding.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: 'arrivals',
        name: '/onboarding/arrivals',
        component: () => import('/builds/folkshr/hris/spa/src/modules/onboarding/pages/arrivals.vue'),
        /* no children */
      },
  _definePage_default_91
  ),
  _mergeRouteRecord(
      {
        path: 'arrivals/configurations',
        name: '/onboarding/arrivals.configurations',
        component: () => import('/builds/folkshr/hris/spa/src/modules/onboarding/pages/arrivals.configurations.vue'),
        children: [
  _mergeRouteRecord(
          {
            path: 'tasks',
            name: '/onboarding/arrivals.configurations/tasks',
            component: () => import('/builds/folkshr/hris/spa/src/modules/onboarding/pages/arrivals.configurations/tasks.vue'),
            /* no children */
          },
  _definePage_default_92
  ),
  _mergeRouteRecord(
          {
            path: 'templates',
            name: '/onboarding/arrivals.configurations/templates',
            component: () => import('/builds/folkshr/hris/spa/src/modules/onboarding/pages/arrivals.configurations/templates.vue'),
            /* no children */
          },
  _definePage_default_93
  ),
  _mergeRouteRecord(
          {
            path: 'titles',
            name: '/onboarding/arrivals.configurations/titles',
            component: () => import('/builds/folkshr/hris/spa/src/modules/onboarding/pages/arrivals.configurations/titles.vue'),
            /* no children */
          },
  _definePage_default_94
  )
        ],
      },
  _definePage_default_95
  ),
  _mergeRouteRecord(
      {
        path: 'departures',
        name: '/onboarding/departures',
        component: () => import('/builds/folkshr/hris/spa/src/modules/onboarding/pages/departures.vue'),
        /* no children */
      },
  _definePage_default_96
  ),
  _mergeRouteRecord(
      {
        path: 'departures/configurations',
        name: '/onboarding/departures.configurations',
        component: () => import('/builds/folkshr/hris/spa/src/modules/onboarding/pages/departures.configurations.vue'),
        children: [
  _mergeRouteRecord(
          {
            path: 'tasks',
            name: '/onboarding/departures.configurations/tasks',
            component: () => import('/builds/folkshr/hris/spa/src/modules/onboarding/pages/departures.configurations/tasks.vue'),
            /* no children */
          },
  _definePage_default_97
  ),
  _mergeRouteRecord(
          {
            path: 'templates',
            name: '/onboarding/departures.configurations/templates',
            component: () => import('/builds/folkshr/hris/spa/src/modules/onboarding/pages/departures.configurations/templates.vue'),
            /* no children */
          },
  _definePage_default_98
  ),
  _mergeRouteRecord(
          {
            path: 'titles',
            name: '/onboarding/departures.configurations/titles',
            component: () => import('/builds/folkshr/hris/spa/src/modules/onboarding/pages/departures.configurations/titles.vue'),
            /* no children */
          },
  _definePage_default_99
  )
        ],
      },
  _definePage_default_100
  ),
  _mergeRouteRecord(
      {
        path: 'departures/control',
        name: '/onboarding/departures.control',
        component: () => import('/builds/folkshr/hris/spa/src/modules/onboarding/pages/departures.control.vue'),
        /* no children */
      },
  _definePage_default_101
  )
    ],
  },
  _definePage_default_102
  ),
  _mergeRouteRecord(
  {
    path: '/parameters',
    name: '/parameters',
    component: () => import('/builds/folkshr/hris/spa/src/pages/parameters.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: 'import-export',
        name: '/parameters/import-export',
        component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/pages/import-export.vue'),
        children: [
  _mergeRouteRecord(
          {
            path: 'export',
            name: '/parameters/import-export/export',
            component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/import-export/pages/export.vue'),
            /* no children */
          },
  _definePage_default_103
  ),
  _mergeRouteRecord(
          {
            path: 'import',
            name: '/parameters/import-export/import',
            component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/import-export/pages/import.vue'),
            /* no children */
          },
  _definePage_default_104
  )
        ],
      },
  _definePage_default_105
  ),
  _mergeRouteRecord(
      {
        path: 'integrations',
        name: '/parameters/integrations',
        component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/pages/integrations.vue'),
        children: [
  _mergeRouteRecord(
          {
            path: 'api',
            name: '/parameters/integrations/api',
            component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/integrations/pages/api.vue'),
            /* no children */
          },
  _definePage_default_106
  ),
  _mergeRouteRecord(
          {
            path: 'nethris',
            name: '/parameters/integrations/nethris',
            component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/integrations/pages/nethris.vue'),
            /* no children */
          },
  _definePage_default_107
  ),
  _mergeRouteRecord(
          {
            path: 'payroll',
            name: '/parameters/integrations/payroll',
            component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/integrations/pages/payroll.vue'),
            /* no children */
          },
  _definePage_default_108
  ),
  _mergeRouteRecord(
          {
            path: 'payworks',
            name: '/parameters/integrations/payworks',
            component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/integrations/pages/payworks.vue'),
            /* no children */
          },
  _definePage_default_109
  ),
  _mergeRouteRecord(
          {
            path: 'sso',
            name: '/parameters/integrations/sso',
            component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/integrations/pages/sso.vue'),
            /* no children */
          },
  _definePage_default_110
  )
        ],
      },
  _definePage_default_111
  ),
      {
        path: 'notifications',
        /* internal name: '/parameters/notifications' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/parameters/notifications/',
            component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/notifications/pages/index.vue'),
            /* no children */
          },
  _definePage_default_112
  )
        ],
      },
      {
        path: 'roles-permissions',
        /* internal name: '/parameters/roles-permissions' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/parameters/roles-permissions/',
            component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/roles-permissions/pages/index.vue'),
            /* no children */
          },
  _definePage_default_113
  )
        ],
      },
      {
        path: 'system-management',
        /* internal name: '/parameters/system-management' */
        /* no component */
        children: [
          {
            path: 'active-employees',
            /* internal name: '/parameters/system-management/active-employees' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: '/parameters/system-management/active-employees/',
                component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/active-employees/index.vue'),
                /* no children */
              },
  _definePage_default_114
  )
            ],
          },
  _mergeRouteRecord(
          {
            path: 'companies',
            name: '/parameters/system-management/companies',
            component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/companies.vue'),
            /* no children */
          },
  _definePage_default_115
  ),
  _mergeRouteRecord(
          {
            path: 'companies/config',
            name: '/parameters/system-management/companies.config',
            component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/companies.config.vue'),
            /* no children */
          },
  _definePage_default_116
  ),
  _mergeRouteRecord(
          {
            path: 'companies/create',
            name: '/parameters/system-management/companies.create',
            component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/companies.create.vue'),
            /* no children */
          },
  _definePage_default_117
  ),
  _mergeRouteRecord(
          {
            path: 'companies/edit',
            name: '/parameters/system-management/companies.edit',
            component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/companies.edit.vue'),
            /* no children */
          },
  _definePage_default_118
  ),
          {
            path: 'pw-numbers',
            /* internal name: '/parameters/system-management/pw-numbers' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: '/parameters/system-management/pw-numbers/',
                component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/pw-numbers/index.vue'),
                /* no children */
              },
  _definePage_default_119
  )
            ],
          },
  _mergeRouteRecord(
          {
            path: 'time-management',
            name: '/parameters/system-management/time-management',
            component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/time-management.vue'),
            children: [
  _mergeRouteRecord(
              {
                path: 'absences-reasons',
                name: '/parameters/system-management/time-management/absences-reasons',
                component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/time-management/absences-reasons.vue'),
                /* no children */
              },
  _definePage_default_120
  ),
  _mergeRouteRecord(
              {
                path: 'overtime-types',
                name: '/parameters/system-management/time-management/overtime-types',
                component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/time-management/overtime-types.vue'),
                /* no children */
              },
  _definePage_default_121
  ),
  _mergeRouteRecord(
              {
                path: 'overtime-values',
                name: '/parameters/system-management/time-management/overtime-values',
                component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/time-management/overtime-values.vue'),
                /* no children */
              },
  _definePage_default_122
  ),
  _mergeRouteRecord(
              {
                path: 'vacation-types',
                name: '/parameters/system-management/time-management/vacation-types',
                component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/time-management/vacation-types.vue'),
                /* no children */
              },
  _definePage_default_123
  )
            ],
          },
  _definePage_default_124
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'users',
        name: '/parameters/users',
        component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/pages/users.vue'),
        /* no children */
      },
  _definePage_default_125
  ),
  _mergeRouteRecord(
      {
        path: 'users/bulk',
        name: '/parameters/users.bulk',
        component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/pages/users.bulk.vue'),
        /* no children */
      },
  _definePage_default_126
  ),
  _mergeRouteRecord(
      {
        path: 'users/create',
        name: '/parameters/users.create',
        component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/pages/users.create.vue'),
        /* no children */
      },
  _definePage_default_127
  ),
  _mergeRouteRecord(
      {
        path: 'users/edit',
        name: '/parameters/users.edit',
        component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/pages/users.edit.vue'),
        /* no children */
      },
  _definePage_default_128
  )
    ],
  },
  _definePage_default_129
  ),
  _mergeRouteRecord(
  {
    path: '/reports',
    name: '/reports',
    component: () => import('/builds/folkshr/hris/spa/src/pages/reports.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: 'dashboard',
        name: '/reports/dashboard',
        component: () => import('/builds/folkshr/hris/spa/src/modules/reports/pages/dashboard.vue'),
        children: [
  _mergeRouteRecord(
          {
            path: 'analysis',
            name: '/reports/dashboard/analysis',
            component: () => import('/builds/folkshr/hris/spa/src/modules/reports/pages/dashboard/analysis.vue'),
            /* no children */
          },
  _definePage_default_130
  ),
  _mergeRouteRecord(
          {
            path: 'summary',
            name: '/reports/dashboard/summary',
            component: () => import('/builds/folkshr/hris/spa/src/modules/reports/pages/dashboard/summary.vue'),
            /* no children */
          },
  _definePage_default_131
  )
        ],
      },
  _definePage_default_132
  ),
  _mergeRouteRecord(
      {
        path: 'discipline',
        name: '/reports/discipline',
        component: () => import('/builds/folkshr/hris/spa/src/modules/reports/pages/discipline.vue'),
        /* no children */
      },
  _definePage_default_133
  ),
  _mergeRouteRecord(
      {
        path: 'kpi',
        name: '/reports/kpi',
        component: () => import('/builds/folkshr/hris/spa/src/modules/reports/pages/kpi.vue'),
        /* no children */
      },
  _definePage_default_134
  ),
  _mergeRouteRecord(
      {
        path: 'summary',
        name: '/reports/summary',
        component: () => import('/builds/folkshr/hris/spa/src/modules/reports/pages/summary.vue'),
        /* no children */
      },
  _definePage_default_135
  )
    ],
  },
  _definePage_default_136
  ),
  _mergeRouteRecord(
  {
    path: '/safety',
    name: '/safety',
    component: () => import('/builds/folkshr/hris/spa/src/pages/safety.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: 'summary',
        name: '/safety/summary',
        component: () => import('/builds/folkshr/hris/spa/src/modules/safety/pages/summary.vue'),
        /* no children */
      },
  _definePage_default_137
  ),
  _mergeRouteRecord(
      {
        path: 'work-accidents',
        name: '/safety/work-accidents',
        component: () => import('/builds/folkshr/hris/spa/src/modules/safety/pages/work-accidents.vue'),
        /* no children */
      },
  _definePage_default_138
  ),
  _mergeRouteRecord(
      {
        path: 'work-accidents/control',
        name: '/safety/work-accidents.control',
        component: () => import('/builds/folkshr/hris/spa/src/modules/safety/pages/work-accidents.control.vue'),
        /* no children */
      },
  _definePage_default_139
  ),
  _mergeRouteRecord(
      {
        path: 'work-accidents/edit',
        name: '/safety/work-accidents.edit',
        component: () => import('/builds/folkshr/hris/spa/src/modules/safety/pages/work-accidents.edit.vue'),
        /* no children */
      },
  _definePage_default_140
  )
    ],
  },
  _definePage_default_141
  ),
  _mergeRouteRecord(
  {
    path: '/simulator',
    name: '/simulator',
    component: () => import('/builds/folkshr/hris/spa/src/pages/simulator.vue'),
    /* no children */
  },
  _definePage_default_142
  ),
  _mergeRouteRecord(
  {
    path: '/time-management',
    name: '/time-management',
    component: () => import('/builds/folkshr/hris/spa/src/pages/time-management.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: 'absences',
        name: '/time-management/absences',
        component: () => import('/builds/folkshr/hris/spa/src/modules/time-management/pages/absences.vue'),
        /* no children */
      },
  _definePage_default_143
  ),
  _mergeRouteRecord(
      {
        path: 'absences/summary',
        name: '/time-management/absences.summary',
        component: () => import('/builds/folkshr/hris/spa/src/modules/time-management/pages/absences.summary.vue'),
        children: [
  _mergeRouteRecord(
          {
            path: 'annual',
            name: '/time-management/absences.summary/annual',
            component: () => import('/builds/folkshr/hris/spa/src/modules/time-management/pages/absences.summary/annual.vue'),
            /* no children */
          },
  _definePage_default_144
  ),
  _mergeRouteRecord(
          {
            path: 'weekly',
            name: '/time-management/absences.summary/weekly',
            component: () => import('/builds/folkshr/hris/spa/src/modules/time-management/pages/absences.summary/weekly.vue'),
            /* no children */
          },
  _definePage_default_145
  )
        ],
      },
  _definePage_default_146
  ),
  _mergeRouteRecord(
      {
        path: 'calendar',
        name: '/time-management/calendar',
        component: () => import('/builds/folkshr/hris/spa/src/modules/time-management/pages/calendar.vue'),
        /* no children */
      },
  _definePage_default_147
  ),
  _mergeRouteRecord(
      {
        path: 'overtimes',
        name: '/time-management/overtimes',
        component: () => import('/builds/folkshr/hris/spa/src/modules/time-management/pages/overtimes.vue'),
        /* no children */
      },
  _definePage_default_148
  ),
  _mergeRouteRecord(
      {
        path: 'overtimes/summary',
        name: '/time-management/overtimes.summary',
        component: () => import('/builds/folkshr/hris/spa/src/modules/time-management/pages/overtimes.summary.vue'),
        /* no children */
      },
  _definePage_default_149
  ),
  _mergeRouteRecord(
      {
        path: 'timesheet',
        name: '/time-management/timesheet',
        component: () => import('/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet.vue'),
        /* no children */
      },
  _definePage_default_150
  ),
  _mergeRouteRecord(
      {
        path: 'timesheet/configurations',
        name: '/time-management/timesheet.configurations',
        component: () => import('/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet.configurations.vue'),
        children: [
  _mergeRouteRecord(
          {
            path: 'calculations',
            name: '/time-management/timesheet.configurations/calculations',
            component: () => import('/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet.configurations/calculations.vue'),
            /* no children */
          },
  _definePage_default_151
  ),
  _mergeRouteRecord(
          {
            path: 'holidays',
            name: '/time-management/timesheet.configurations/holidays',
            component: () => import('/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet.configurations/holidays.vue'),
            /* no children */
          },
  _definePage_default_152
  ),
  _mergeRouteRecord(
          {
            path: 'importation',
            name: '/time-management/timesheet.configurations/importation',
            component: () => import('/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet.configurations/importation.vue'),
            /* no children */
          },
  _definePage_default_153
  ),
  _mergeRouteRecord(
          {
            path: 'legend',
            name: '/time-management/timesheet.configurations/legend',
            component: () => import('/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet.configurations/legend.vue'),
            /* no children */
          },
  _definePage_default_154
  ),
  _mergeRouteRecord(
          {
            path: 'projects',
            name: '/time-management/timesheet.configurations/projects',
            component: () => import('/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet.configurations/projects.vue'),
            /* no children */
          },
  _definePage_default_155
  )
        ],
      },
  _definePage_default_156
  ),
  _mergeRouteRecord(
      {
        path: 'timesheet/summary',
        name: '/time-management/timesheet.summary',
        component: () => import('/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet.summary.vue'),
        /* no children */
      },
  _definePage_default_157
  ),
  _mergeRouteRecord(
      {
        path: 'vacations',
        name: '/time-management/vacations',
        component: () => import('/builds/folkshr/hris/spa/src/modules/time-management/pages/vacations.vue'),
        /* no children */
      },
  _definePage_default_158
  ),
  _mergeRouteRecord(
      {
        path: 'vacations/summary',
        name: '/time-management/vacations.summary',
        component: () => import('/builds/folkshr/hris/spa/src/modules/time-management/pages/vacations.summary.vue'),
        /* no children */
      },
  _definePage_default_159
  )
    ],
  },
  _definePage_default_160
  ),
  _mergeRouteRecord(
  {
    path: '/unauthorized',
    name: '/unauthorized',
    component: () => import('/builds/folkshr/hris/spa/src/pages/unauthorized.vue'),
    /* no children */
  },
  _definePage_default_161
  ),
  _mergeRouteRecord(
  {
    path: '/user-params',
    name: '/user-params',
    component: () => import('/builds/folkshr/hris/spa/src/pages/user-params.vue'),
    /* no children */
  },
  _definePage_default_162
  )
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

