<template>
  <VDialog
    :height="isIframeContent ? '90%' : undefined"
    :max-width="isIframeContent ? 1080 : 600"
    :model-value="isShowed"
    @update:model-value="$emit('dialog:update', $event)"
  >
    <VCard class="rounded-28 fill-height">
      <VCardItem>
        <VCardTitle class="text-headline-small">
          {{ title }}
        </VCardTitle>
      </VCardItem>
      <VCardText class="text-body-medium overflow-auto">
        <FLegacyIframe
          v-if="isShowed && isIframeContent"
          :iframe-route="iframeRoute"
          :iframe-route-ability="iframeRouteAbility"
          :is-in-modal="true"
        />
      </VCardText>
      <VDivider />
      <VCardActions class="pt-6">
        <VSpacer />
        <slot
          v-if="!isIframeContent || hasIframeActions"
          name="card-actions"
        >
          <VBtn
            v-dynamic-data-attr="{ all: 'f-dialog_cancel-action' }"
            color="primary"
            variant="text"
            @click="$emit('dialog:cancel')"
          >
            {{ cancelComputed }}
          </VBtn>
          <slot name="submit">
            <VBtn
              v-dynamic-data-attr="{ all: 'f-dialog_save-action' }"
              variant="flat"
              @click="$emit('dialog:save')"
            >
              {{ btnText || t('save') }}
            </VBtn>
          </slot>
        </slot>
        <slot
          v-else
          name="card-actions"
        >
          <VBtn
            v-dynamic-data-attr="{ all: 'f-dialog_close-action' }"
            :text="t('close')"
            color="primary"
            variant="text"
            @click="$emit('dialog:cancel')"
          />
        </slot>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
<script lang="ts" setup>
import { usePermissionsStore } from '@/modules/shared/core/stores/permissions';

defineEmits(['dialog:submit', 'dialog:update', 'dialog:save', 'dialog:cancel']);
const props = defineProps({
  title: {
    type: String,
    required: true
  },
  isShowed: {
    type: Boolean,
    required: true
  },
  iframeRoute: {
    type: String,
    default: ''
  },
  iframeRouteAbility: {
    type: String,
    default: ''
  },
  cancelLabel: {
    type: String,
    default: ''
  },
  btnText: {
    type: String,
    default: '',
    required: false
  }
});

console.log('props fIFrame', props);

const { t } = useI18n({
  useScope: 'global'
});

const isIframeContent = computed(() => {
  return props.iframeRoute !== '';
});

const { canAccess } = usePermissionsStore();
const hasIframeActions = computed(() => {
  return isIframeContent && canAccess(props.iframeRouteAbility);
});

const cancelComputed = computed(() => {
  return props.cancelLabel || t('cancel');
});
</script>
<style lang="sass" scoped></style>
