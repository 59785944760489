import type {
  Program,
  ProgramData
} from '@/modules/shared/core/utils/mapping/index.types';
import LEGACY_TYPE from '@/modules/shared/core/constants/legacyType';

import SYSTEM_MANAGEMENT_MAPPING from '@/modules/parameters/submodules/system-management/constants/program';
import ADVANCEMENT_MAPPING from '@/modules/advancement/constants/program';
import BASE_MAPPING from '@/constants/program';
import COMPANY_MAPPING from '@/modules/company/constants/program';
import DOCUMENTS_MAPPING from '@/modules/documents/constants/program';
import EMPLOYEES_MAPPING from '@/modules/employees/constants/program';
import HIRING_MAPPING from '@/modules/hiring/constants/program';
import IMPORT_EXPORT_MAPPING from '@/modules/parameters/submodules/import-export/constants/program';
import INTEGRATIONS_MAPPING from '@/modules/parameters/submodules/integrations/constants/program';
import NOTIFICATIONS_MAPPING from '@/modules/parameters/submodules/notifications/constants/program';
import ONBOARDING_MAPPING from '@/modules/onboarding/constants/program';
import REPORTS_MAPPING from '@/modules/reports/constants/program';
import SAFETY_MAPPING from '@/modules/safety/constants/program';
import ROLES_PERMISSIONS_MAPPING from '@/modules/parameters/submodules/roles-permissions/constants/program';
import TIME_MANAGEMENT_MAPPING from '@/modules/time-management/constants/program';
import USERS_MAPPING from '@/modules/parameters/submodules/users/constants/program';

import {
  extractQueryParams,
  flattenObject
} from '@/modules/shared/core/utils/route';

export function getLegacyRoutesMap(): ProgramData[] {
  return [
    ...ADVANCEMENT_MAPPING,
    ...BASE_MAPPING,
    ...SYSTEM_MANAGEMENT_MAPPING,
    ...COMPANY_MAPPING,
    ...DOCUMENTS_MAPPING,
    ...HIRING_MAPPING,
    ...IMPORT_EXPORT_MAPPING,
    ...INTEGRATIONS_MAPPING,
    ...NOTIFICATIONS_MAPPING,
    ...ONBOARDING_MAPPING,
    ...REPORTS_MAPPING,
    ...SAFETY_MAPPING,
    ...ROLES_PERMISSIONS_MAPPING,
    ...TIME_MANAGEMENT_MAPPING,
    ...USERS_MAPPING,
    ...EMPLOYEES_MAPPING
  ].map(addProgramDataValues);
}

export const replaceDynamicParamsInString = (
  str: string,
  params: Record<any, string>
) => {
  return Object.keys(params).reduce((acc, key) => {
    return acc.replace(`:${key}`, params[key]);
  }, str);
};

const getRegexpForDynamicRoute = (route: string) => {
  const dynamicRouteParamsTransformed = route
    .replace(/\[\w+\]/g, '(\\w+)')
    .replace(/:\w+/g, '(\\w+)');
  return new RegExp(`^${dynamicRouteParamsTransformed}/?$`);
};

function replaceLastCharacterIfSlash(str: string, replacement = ''): string {
  // Check if the last character is '/'
  if (str.endsWith('/') && str.length > 1) {
    // Replace the last character and return the new string
    return str.slice(0, -1) + replacement;
  }
  // Return the original string if no replacement is needed
  return str;
}

export function getProgramDataByID(programID: number) {
  if (!programID) {
    throw new Error('Program ID is required');
  }

  const foundProgramData = getLegacyRoutesMap().find((program) => {
    return program.programId === programID;
  });

  if (!foundProgramData) {
    throw new Error(`ProgramID ${programID} is not valid`);
  }

  return foundProgramData;
}

export function getProgramDataByRoute(
  routeName: string,
  isEmployeeRoute?: boolean
) {
  if (!routeName) {
    throw new Error('Route name is required');
  }

  const splitedRoute = routeName.split('?');
  const routeWithoutQueryParams = splitedRoute[0];

  const extractedParams = extractQueryParams(routeName);
  const flattenedParams = flattenObject(extractedParams);
  const foundProgramData = getLegacyRoutesMap().find((program) => {
    if (program.editKey && !isEmployeeRoute) {
      return (
        routeWithoutQueryParams.match(program.routeRegexp) &&
        flattenedParams[program.editKey]
      );
    }

    return routeWithoutQueryParams.match(program.routeRegexp);
  });

  if (!foundProgramData) {
    throw new Error(`Route name ${routeName} is not valid`);
  }

  return foundProgramData;
}

export function getProgramDataByIframeRoute(routeName: string) {
  if (!routeName) {
    throw new Error('Route name is required');
  }

  const foundProgramData = getLegacyRoutesMap().find((program) => {
    return routeName.match(program.iframeRegexp);
  });

  if (!foundProgramData) {
    throw new Error(`Route name ${routeName} is not valid`);
  }

  return foundProgramData;
}

function addProgramDataValues(programData: Program): ProgramData {
  if (programData.isLegacy === LEGACY_TYPE.LEGACY_MONOLITH) {
    if (programData.isLegacyModal !== undefined && programData.legacyModalUrl) {
      programData.iframeUrl = programData.legacyModalUrl;
    } else {
      programData.iframeUrl = 'legacy/page-id/' + programData.programId;
    }
  }

  return {
    ...programData,
    routeRegexp: getRegexpForDynamicRoute(programData.routeName),
    iframeRegexp: getRegexpForDynamicRoute(
      replaceLastCharacterIfSlash(programData.iframeUrl)
    )
  };
}

export function getLegacyIframeId(programData: ProgramData) {
  return `legacy-frame:${programData.name}`;
}
