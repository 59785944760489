import PARAMETERS_ABILITIES_ROOT from '@/modules/parameters/constants/abilities';

const IMPORT_EXPORT_ROOT = `${PARAMETERS_ABILITIES_ROOT}:import-export`;

const IMPORT_EXPORT_ABILITIES = {
  ANY: IMPORT_EXPORT_ROOT,
  IMPORT: `${IMPORT_EXPORT_ROOT}:import`,
  EXPORT: `${IMPORT_EXPORT_ROOT}:export`
};

export default IMPORT_EXPORT_ABILITIES;
