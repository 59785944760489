<template>
  <FHeaderPrimary
    :add-summary-ability="SAFETY_ABILITIES.SUMMARY.ANY"
    :add-summary-route="SAFETY_ROUTES.SUMMARY.ROOT"
    :add-summary-text="t('summary')"
    :add-ability="SAFETY_ABILITIES.WORK_ACCIDENT.CONTROL"
    :add-route="SAFETY_ROUTES.WORK_ACCIDENT.CONTROL"
    :title="t('title')"
  />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import SAFETY_ROUTES from '@/modules/safety/constants/urls';
import SAFETY_ABILITIES from '@/modules/safety/constants/abilities';

definePage({
  meta: {
    ability: SAFETY_ABILITIES.WORK_ACCIDENT.ANY,
    bypassAbility: [
      SAFETY_ABILITIES.SUMMARY.ANY,
      SAFETY_ABILITIES.WORK_ACCIDENT.CONTROL
    ]
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Accidents de travail',
      summary: 'Voir le sommaire'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Work accidents',
      summary: 'View summary'
    }
  }
});
</script>
