<template>
  <FHeaderPrimary
    :title="t('title')"
    :add-ability="SYSTEM_MANAGEMENT_ABILITIES.COMPANIES.CREATE"
    :add-route="SYSTEM_MANAGEMENT_ROUTES.COMPANIES.CREATE"
    :configuration-ability="SYSTEM_MANAGEMENT_ABILITIES.COMPANIES.CONFIG"
    :configuration-route="SYSTEM_MANAGEMENT_ROUTES.COMPANIES.CONFIG"
  />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import FLayoutContentForIframe from '@/modules/shared/core/layouts/FLayoutContentForIframe.vue';
import SYSTEM_MANAGEMENT_ABILITIES from '@/modules/parameters/submodules/system-management/constants/abilities';
import SYSTEM_MANAGEMENT_ROUTES from '@/modules/parameters/submodules/system-management/constants/urls';

definePage({
  meta: {
    ability: SYSTEM_MANAGEMENT_ABILITIES.COMPANIES.LIST,
    bypassAbility: [
      SYSTEM_MANAGEMENT_ABILITIES.COMPANIES.CREATE,
      SYSTEM_MANAGEMENT_ABILITIES.COMPANIES.CONFIG
    ]
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Liste des compagnies'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Companies list'
    }
  }
});
</script>
