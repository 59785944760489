export default function useHookHandler<T>(items: Ref<T[]>, sortKey: keyof T) {
  const getSortedItems = computed(() => {
    return [...items.value].sort((a: T, b: T) => {
      // Ensure numerical sorting by providing a fallback of 0 if `a[sortKey]` or `b[sortKey]` is undefined
      return (
        ((a[sortKey] as unknown as number) || 0) -
        ((b[sortKey] as unknown as number) || 0)
      );
    });
  });

  const register = (item: T) => {
    items.value.push(item);
  };

  const unregister = (item: T) => {
    const index = items.value.indexOf(item);
    if (index > -1) {
      items.value.splice(index, 1);
    }
  };

  return {
    getSortedItems,
    register,
    unregister
  };
}
