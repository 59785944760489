<template>
  <VExpandTransition>
    <VContainer
      v-if="alertBindings.isVisible"
      class="pb-0"
    >
      <VRow>
        <VCol cols="12">
          <FAlert
            :closable="true"
            :icon="alertBindings.icon"
            :is-visible="alertBindings.isVisible"
            :message-text="alertBindings.messageText"
            :message-title="alertBindings.messageTitle"
            type="error"
            variant="outlined"
            @close="alertDismiss"
          >
            <template
              v-if="alertBindings.isContactSupport"
              #text
            >
              <p class="text-body-medium">
                {{ getText }}
                <a
                  class="text-error"
                  href="mailto:support@folkshr.com"
                  >{{ t('contactSupport') }}</a
                >
              </p>
            </template>
          </FAlert>
        </VCol>
      </VRow>
    </VContainer>
  </VExpandTransition>
</template>

<script lang="ts" setup>
import { useGlobalErrorAlertStore } from '@/modules/shared/core/stores/globalErrorAlert';
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      contactSupport: 'contactez l’équipe support.'
    },
    [SUPPORTED_LANGUAGES.en]: {
      contactSupport: 'contact support team.'
    }
  }
});

const globalErrorAlertStore = useGlobalErrorAlertStore();
const { bindings: alertBindings } = storeToRefs(globalErrorAlertStore);
const { dismiss: alertDismiss } = globalErrorAlertStore;

const getMessage = (
  message: { text: string; keys?: Record<string, unknown> } | string
) => {
  if (!message) return;
  return typeof message === 'string'
    ? message
    : message.keys === undefined
      ? t(message.text)
      : t(message.text, message.keys);
};

const getText = computed(() =>
  alertBindings.value.messageText
    ? getMessage(alertBindings.value.messageText)
    : ''
);
</script>
