<template>
  <FIframeDialog
    :iframe-route="route"
    :iframe-route-ability="routeAbility"
    :is-showed="isShowed"
    :title="t(title)"
    :btn-text="t(btnText)"
    @dialog:save="sendSaveEvent"
    @dialog:cancel="sendCancelEvent"
    @dialog:update="updateDialog"
  />
</template>
<script lang="ts" setup>
import { getProgramDataByRoute } from '@/modules/shared/core/utils/mapping';
import useMessageListener from '@/modules/shared/core/composables/PostMessage/useMessageListener';
import useMessageSender from '@/modules/shared/core/composables/PostMessage/useMessageSender';

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  type: {
    type: String,
    required: true
  },
  isShowed: {
    type: Boolean,
    required: true
  },
  route: {
    type: String,
    required: true
  },
  routeAbility: {
    type: String,
    default: ''
  },
  btnText: {
    type: String,
    default: '',
    required: false
  }
});

const emits = defineEmits(['dialog:update']);

const { t } = useI18n({
  useScope: 'global'
});

const programData = getProgramDataByRoute(props.route);
const { addHandler } = useMessageListener({
  name: `F-Dialog:${props.type}:${props.route}`,
  allowedPathsRegexp: [programData.iframeRegexp]
});

const { sendMessageToProgramFrame, buildMessageEventDataToSend } =
  useMessageSender();

const { path } = useRoute();
const sendSaveEvent = () => {
  sendMessageToProgramFrame(
    buildMessageEventDataToSend(`${props.type}:form:submit`, null, path),
    programData
  );
};

const sendCancelEvent = () => {
  sendMessageToProgramFrame(
    buildMessageEventDataToSend(`${props.type}:form:cancel`, null, path),
    programData
  );
};

const updateDialog = (value: boolean) => {
  emits('dialog:update', value);
};

addHandler('dialog:close', () => {
  updateDialog(false);
});
</script>
<style lang="sass" scoped></style>
