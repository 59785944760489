<template>
  <FHeaderPrimary
    :add-ability="REPORTS_ABILITIES.DISCIPLINE.CONTROL"
    :title="t('title')"
    @add:click="handleAddClick"
  />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import REPORTS_ABILITIES from '@/modules/reports/constants/abilities';
import useReportDisciplineList from '@/modules/reports/composables/useReportDisciplineList';

definePage({
  meta: {
    ability: REPORTS_ABILITIES.DISCIPLINE.ANY,
    bypassAbility: [REPORTS_ABILITIES.DISCIPLINE.CONTROL]
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Discipline'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Discipline'
    }
  }
});

const { handleAddClick } = useReportDisciplineList();
</script>
