<template>
  <FHeaderPrimary :title="t('title')" />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import SYSTEM_MANAGEMENT_ABILITIES from '@/modules/parameters/submodules/system-management/constants/abilities';

definePage({
  meta: {
    ability: SYSTEM_MANAGEMENT_ABILITIES.PW_NUMBERS.ANY
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Numéros de PW'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'PW Numbers'
    }
  }
});
</script>
