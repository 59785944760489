import { defineStore } from 'pinia';
import { useDisplay } from 'vuetify';

export const useNavigationRailStore = defineStore(
  'navigationRail',
  () => {
    const { mobile } = useDisplay();

    const isInRail = ref(true);
    const mobileNavOpen = ref(false);
    const webNavOpenByClick = ref(false);

    const isTooltipDisabled = computed(() => {
      return !isInRail.value || webNavOpenByClick.value;
    });

    const closeMobileNav = () => {
      if (mobile.value && mobileNavOpen.value) {
        mobileNavOpen.value = false;
      }
    };

    return {
      isInRail,
      mobileNavOpen,
      webNavOpenByClick,
      isTooltipDisabled,
      closeMobileNav
    };
  },
  {
    persist: true
  }
);
