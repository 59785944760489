/**
 * A composable function that provides text highlighting functionality based on search value
 * @param searchValue - The string to search for in the text
 * @param htmlWrapper - An object containing HTML tags for wrapping the highlighted text
 * @param htmlWrapper.frontTag - Opening HTML tag for highlighting (e.g., '<mark>', '<span class="highlight">')
 * @param htmlWrapper.backTag - Closing HTML tag for highlighting (e.g., '</mark>', '</span>')
 * @returns An object containing the highlightHTMLString function
 * @example
 * ```ts
 * const { highlightHTMLString } = useSearchHighlither('search', {
 *   frontTag: '<mark>',
 *   backTag: '</mark>'
 * });
 * const result = highlightHTMLString('This is a search text'); // Returns: "This is a <mark>search</mark> text"
 * ```
 */
export default function useSearchHighlither(
  searchValue: string,
  htmlWrapper: { frontTag: string; backTag: string }
) {
  const highlightHTMLString = (text: string) => {
    if (!searchValue) {
      return text;
    }

    const searchValueRegex = new RegExp(searchValue, 'gi');
    const highlightedText = text.replace(
      searchValueRegex,
      `${htmlWrapper.frontTag}$&${htmlWrapper.backTag}`
    );

    return highlightedText;
  };

  return { highlightHTMLString };
}
