<template>
  <router-view />
</template>
<script lang="ts" setup>
import SAFETY_ABILITIES from '@/modules/safety/constants/abilities';

definePage({
  meta: {
    needAuth: true,
    ability: SAFETY_ABILITIES.ANY
  }
});
</script>
<style lang="sass" scoped></style>
