<template>
  <FHeaderPrimary
    :add-ability="ONBOARDING_ABILITIES.DEPARTURES.CONTROL"
    :add-route="ONBOARDING_ROUTES.DEPARTURES.CONTROL"
    :configuration-ability="ONBOARDING_ABILITIES.DEPARTURES.CONFIG.ANY"
    :configuration-route="ONBOARDING_ROUTES.DEPARTURES.CONFIG.ROOT"
    :title="t('title')"
  />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import ONBOARDING_ABILITIES from '@/modules/onboarding/constants/abilities';
import ONBOARDING_ROUTES from '@/modules/onboarding/constants/urls';

definePage({
  meta: {
    ability: ONBOARDING_ABILITIES.DEPARTURES.LIST,
    bypassAbility: [
      ONBOARDING_ABILITIES.DEPARTURES.CONTROL,
      ONBOARDING_ABILITIES.DEPARTURES.CONFIG.ANY
    ]
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Départs'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Departures'
    }
  }
});
</script>
