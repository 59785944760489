export const AUTHENTICATION_BINDING_TYPES = {
  AuthenticationServiceInterface: Symbol.for('AuthenticationServiceInterface'),
  AuthenticationCodeFactoryInterface: Symbol.for(
    'AuthenticationCodeFactoryInterface'
  ),
  // sso specific bindings
  SSOAuthenticationServiceInterface: Symbol.for(
    'SSOAuthenticationServiceInterface'
  ),
  SSOAuthenticationStateRepositoryInterface: Symbol.for(
    'SSOAuthenticationStateRepositoryInterface'
  ),
  SSOConfigurationServiceInterface: Symbol.for(
    'SSOConfigurationServiceInterface'
  ),
  AuthenticationRefreshTokenMutexInterface: Symbol.for(
    'AuthenticationRefreshTokenMutexInterface'
  )
};
