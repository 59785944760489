import { useAuthenticationStore } from '@/modules/authentication/store/authentication';
import { AuthenticatePostMessageHandler } from '@/modules/shared/core/composables/PostMessage/handlers/AuthenticatePostMessageHandler';
import { RefreshTokenPostMessageHandler } from '@/modules/shared/core/composables/PostMessage/handlers/RefreshTokenPostMessageHandler';
import type { AddHandler } from '@/modules/shared/core/composables/PostMessage/useMessageListener.types';

export default function useV2AuthenticationMessageHandler(
  addHandlerMethod: AddHandler
) {
  const authenticationStore = useAuthenticationStore();

  const registerV2AuthenticationMessageHandler = () => {
    addHandlerMethod(
      'authenticate',
      new AuthenticatePostMessageHandler(authenticationStore)
    );
    addHandlerMethod(
      'refreshToken',
      new RefreshTokenPostMessageHandler(authenticationStore)
    );
  };

  return {
    registerV2AuthenticationMessageHandler
  };
}
