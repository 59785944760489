import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import { localize, setLocale } from '@vee-validate/i18n';
import { configure, defineRule } from 'vee-validate';
import { required } from '@vee-validate/rules';
// Define the rule globally
defineRule('required', required);
configure({
  // Generates an English message locale generator
  generateMessage: localize('en', {
    messages: {
      required: 'This field is required'
    }
  })
});

export const setVeeValidateLocale = (locale: SUPPORTED_LANGUAGES) => {
  setLocale(locale);
};
