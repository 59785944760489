<template>
  <FLegacyIframe :iframe-route="$route.fullPath" />
</template>
<script lang="ts" setup>
import INTEGRATIONS_ABILITIES from '@/modules/parameters/submodules/integrations/constants/abilities';

definePage({
  meta: {
    ability: INTEGRATIONS_ABILITIES.NETHRIS
  }
});
</script>
