<template>
  <FHeaderPrimary
    :back-route="backButtonSelectedName"
    :title="t('title')"
  />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import EMPLOYEES_ABILITIES from '@/modules/employees/constants/abilities';
import EMPLOYEES_ROUTES from '@/modules/employees/constants/urls';
import REPORTS_ROUTES from '@/modules/reports/constants/urls';
import FHeaderPrimary from '@/components/FHeaderPrimary.vue';
import useBackPreviousButton from '@/modules/shared/core/composables/useBackPreviousButton';

definePage({
  meta: {
    ability: EMPLOYEES_ABILITIES.LIST.SUMMARY
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Sommaire des employés'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Employees Summary'
    }
  }
});

const { backButtonSelectedName } = useBackPreviousButton([
  EMPLOYEES_ROUTES.LIST,
  REPORTS_ROUTES.SUMMARY
]);
</script>
