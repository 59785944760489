import type { APIProxyInterface } from '@sdk/modules/API/Interface/APIProxyInterface';
import { injectable, inject } from 'inversify';
import {
  AxiosError,
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosResponse,
  type AxiosStatic,
  type CreateAxiosDefaults,
  HttpStatusCode,
  type InternalAxiosRequestConfig
} from 'axios';
import { SETTINGS_TYPE } from '@sdk/settings/SettingTypes';
import type { FolksAxiosConfig } from '@sdk/modules/API/Interface/FolksAxiosConfig';
import type { SettingsInterface } from '@sdk/settings/SettingsInterface';
import { API_BINDING_TYPES } from '@sdk/modules/API';
import type { AuthenticationCredentialsStorageInterface } from '@sdk/modules/API/Interface/AuthenticationCredentialsStorageInterface';
import ValidationError from '@sdk/modules/API/Errors/ValidationError';

export type APIProxyErrorHandler = (error: AxiosError) => Promise<boolean>;

type APIProxyErrorHandlers = {
  unrestricted: APIProxyErrorHandler[];
  [key: number]: APIProxyErrorHandler[];
};

type ValidationErrorPayload = {
  message?: string;
  errors?: { [property: string]: Array<string> };
};

@injectable()
export class APIProxy implements APIProxyInterface {
  private axiosInstance: AxiosInstance;

  private errorHandlersCallbacks: APIProxyErrorHandlers = {
    unrestricted: []
  };

  constructor(
    @inject(SETTINGS_TYPE.SettingInterface) private settings: SettingsInterface,
    @inject(API_BINDING_TYPES.Axios) axios: AxiosStatic,
    @inject(API_BINDING_TYPES.AuthenticationCredentialsStorage)
    private credentialsStorage: AuthenticationCredentialsStorageInterface
  ) {
    this.axiosInstance = axios.create(this.getAxiosConfig());
    this.axiosInstance.interceptors.request.use((config) =>
      this.handleAxiosRequest(config)
    );
    this.axiosInstance.interceptors.response.use(
      async (response) => this.handleAxiosResponse(response),
      async (error) => this.handleAxiosError(error)
    );
  }

  async post<T = unknown, D = unknown>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D> & FolksAxiosConfig
  ): Promise<AxiosResponse<T>> {
    return this.axiosInstance.post<T, AxiosResponse<T>, D>(url, data, config);
  }

  get<T = unknown, P = unknown>(
    url: string,
    params?: P,
    config?: (AxiosRequestConfig<unknown> & FolksAxiosConfig) | undefined
  ): Promise<AxiosResponse<T>> {
    return this.axiosInstance.get<T>(url, {
      ...config,
      params
    });
  }

  delete<P = unknown>(
    url: string,
    params?: P,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return this.axiosInstance.delete(url, {
      params,
      ...config
    });
  }

  private getAxiosConfig(): CreateAxiosDefaults {
    return {
      baseURL: this.settings.api?.baseUrl,
      withCredentials: true,
      headers: {
        Accept: 'application/json;charset=UTF-8',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      timeout: 60000
    };
  }

  addErrorHandler(handler: APIProxyErrorHandler, ...codes: number[]): void {
    if (codes.length) {
      for (const code of codes) {
        if (!(code in this.errorHandlersCallbacks)) {
          this.errorHandlersCallbacks[code] = [];
        }

        this.errorHandlersCallbacks[code].push(handler);
      }
    } else {
      this.errorHandlersCallbacks.unrestricted.push(handler);
    }
  }

  handleAxiosResponse(response: AxiosResponse) {
    return response;
  }

  async handleAxiosRequest(
    config: InternalAxiosRequestConfig & FolksAxiosConfig
  ) {
    if (!('withBearerAuth' in config) || config.withBearerAuth === true) {
      const bearerToken = await this.credentialsStorage.get('accessToken');

      config.headers['Authorization'] = `Bearer ${bearerToken}`;
    }

    return config;
  }

  async handleAxiosError(error: AxiosError) {
    let shouldRetry = false;
    if (this.hasErrorHandlingEnabled(error)) {
      for (const handler of this.errorHandlersCallbacks.unrestricted) {
        shouldRetry = shouldRetry || (await handler(error));
      }

      const errorStatus = error.response?.status;

      if (errorStatus && errorStatus in this.errorHandlersCallbacks) {
        for (const handler of this.errorHandlersCallbacks[errorStatus]) {
          shouldRetry = shouldRetry || (await handler(error));
        }
      }

      if (shouldRetry && error.config) {
        return this.axiosInstance.request(error.config);
      }
    }

    if (
      error.response &&
      error.response.status === HttpStatusCode.UnprocessableEntity
    ) {
      return Promise.reject(
        this.createNewValidationError(
          error as AxiosError<ValidationErrorPayload>
        )
      );
    }

    return Promise.reject(error);
  }

  private hasErrorHandlingEnabled(error: AxiosError) {
    if (!error.config) {
      return true;
    }

    return (
      !('withErrorHandling' in error.config) ||
      error.config.withErrorHandling !== false
    );
  }

  private createNewValidationError(
    error: AxiosError<ValidationErrorPayload>
  ): ValidationError | Error {
    if (!error.response?.data) {
      return error;
    }

    let errorMessage = error.message;
    let validationErrors = {};

    if (
      'message' in (error.response?.data ?? {}) &&
      error.response.data.message
    ) {
      errorMessage = error.response.data.message;
    }

    if (
      'errors' in (error.response?.data ?? {}) &&
      error.response.data.errors
    ) {
      validationErrors = error.response.data.errors;
    }

    return new ValidationError(errorMessage, validationErrors);
  }
}
