<template>
  <FHeaderPrimaryMultiAdd
    :title="t('title')"
    :button-actions="accessibleButtonActions"
  />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import USERS_ABILITIES from '@/modules/parameters/submodules/users/constants/abilities';
import USERS_ROUTES from '@/modules/parameters/submodules/users/constants/urls';
import { usePermissionsStore } from '@/modules/shared/core/stores/permissions';
import type {
  FHeaderPrimaryMultiAddButtonActions,
  FHeaderPrimaryMultiAddButtonActionsWithAbilities
} from '@/modules/parameters/submodules/users/components/FHeaderPrimaryMultiAdd.types';

definePage({
  meta: {
    ability: USERS_ABILITIES.ANY
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Utilisateurs',
      buttons: {
        createUser: 'Ajouter un utilisateur',
        generateUsers: 'Générer des utilisateurs',
        generateUsersSubtitle:
          'Permet de créer plusieurs utilisateurs à la fois'
      }
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Users',
      buttons: {
        createUser: 'Add user',
        generateUsers: 'Generate users',
        generateUsersSubtitle: 'Allows multiple users to be created at once'
      }
    }
  }
});

const buttonActionsWithAbilities: Ref<
  FHeaderPrimaryMultiAddButtonActionsWithAbilities[]
> = ref([
  {
    to: USERS_ROUTES.CREATE,
    ability: USERS_ABILITIES.CREATE,
    title: t('buttons.createUser')
  },
  {
    to: USERS_ROUTES.BULK,
    ability: USERS_ABILITIES.BULK,
    title: t('buttons.generateUsers'),
    subtitle: t('buttons.generateUsersSubtitle')
  }
]);

const { canAccess } = usePermissionsStore();

const accessibleButtonActions: ComputedRef<
  FHeaderPrimaryMultiAddButtonActions[]
> = computed(() => {
  return buttonActionsWithAbilities.value
    .filter(({ ability }) => canAccess(ability))
    .map((buttonAction) => ({ ...buttonAction, ability: undefined }));
});
</script>
