<template>
  <FLegacyIframe :iframe-route="$route.fullPath" />
</template>
<script lang="ts" setup>
definePage({
  meta: {
    needAuth: true
  }
});
</script>
<style lang="sass" scoped></style>
