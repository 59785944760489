<template>
  <VAvatar
    :color="color"
    :size="size"
    :tile="tile"
    :class="[disable ? 'disable' : '', color]"
  >
    <template v-if="src">
      <VImg :src="src" />
    </template>
    <template v-else>
      <span :class="textSize">
        {{ initials }}
      </span>
    </template>
  </VAvatar>
</template>

<script lang="ts" setup>
import { computed, defineProps } from 'vue';
import { FAvatarSizes } from '@/modules/shared/core/constants/FAvatarSizeEnum';
import { type FAvatarTypes } from '@/modules/shared/core/components/FAvatar.types';

const {
  color = 'primary-variant',
  size = FAvatarSizes.medium,
  disable = false,
  fullName
} = defineProps<FAvatarTypes>();

const initials = computed(() => {
  if (!fullName) return '';
  const names = fullName.split(' ');
  const initials = names.length > 1 ? names[0][0] + names[1][0] : names[0][0];
  return initials.toUpperCase();
});

const textSize = computed(() => {
  switch (size) {
    case FAvatarSizes.xxxSmall:
      return 'text-title-xsmall';
    case FAvatarSizes.xxSmall:
      return 'text-title-xsmall';
    case FAvatarSizes.small:
    case FAvatarSizes.medium:
      return 'text-title-medium';
    case FAvatarSizes.large:
    case FAvatarSizes.xLarge:
      return 'text-title-large';
    default:
      return 'text-title-medium';
  }
});
</script>
