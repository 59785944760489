import { NavigateToPostMessageHandler } from '@/modules/shared/core/composables/PostMessage/handlers/NavigateToPostMessageHandler';
import { NavigateToSPAPostMessageHandler } from '@/modules/shared/core/composables/PostMessage/handlers/NavigateToSPAPostMessageHandler';
import { ChangeUrlPostMessageHandler } from '@/modules/shared/core/composables/PostMessage/handlers/ChangeUrlPostMessageHandler';
import { RequestQueryParamsPostMessageHandler } from '@/modules/shared/core/composables/PostMessage/handlers/RequestQueryParamsPostMessageHandler';
import { BackPostMessageHandler } from '@/modules/shared/core/composables/PostMessage/handlers/BackPostMessageHandler';
import { ReloadPostMessageHandler } from '@/modules/shared/core/composables/PostMessage/handlers/ReloadPostMessageHandler';
import type { AddHandler } from '@/modules/shared/core/composables/PostMessage/useMessageListener.types';

export default function useGlobalMessageHandler(addHandlerMethod: AddHandler) {
  const router = useRouter();

  const registerGlobalMessageHandler = () => {
    addHandlerMethod('navigateTo', new NavigateToPostMessageHandler(router));
    addHandlerMethod(
      'navigateToSPA',
      new NavigateToSPAPostMessageHandler(router)
    );
    addHandlerMethod('changeUrl', new ChangeUrlPostMessageHandler(router));
    addHandlerMethod('queryParams', new RequestQueryParamsPostMessageHandler());
    addHandlerMethod('urlBack', new BackPostMessageHandler(router));
    addHandlerMethod('reload', new ReloadPostMessageHandler());
  };

  return {
    registerGlobalMessageHandler
  };
}
