<template>
  <FHeaderPrimary
    :tabs="tabs"
    :back-route="backButtonSelectedName"
    :title="t('title')"
  />
  <FLayoutContentForTabs />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import EMPLOYEES_ABILITIES from '@/modules/employees/constants/abilities';
import type { Tab } from '@/components/FTabs.types';
import EMPLOYEES_ROUTES from '@/modules/employees/constants/urls';
import REPORTS_ROUTES from '@/modules/reports/constants/urls';
import useBackPreviousButton from '@/modules/shared/core/composables/useBackPreviousButton';

definePage({
  meta: {
    ability: EMPLOYEES_ABILITIES.HISTORY.ANY
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: "Historique de l'employé",
      corporate: 'Corporatif',
      personal: 'Personnel'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Employees history',
      corporate: 'Corporate',
      personal: 'Personal'
    }
  }
});

const tabs = [
  {
    to: EMPLOYEES_ROUTES.EMPLOYEE.HISTORY.CORPORATE,
    ability: EMPLOYEES_ABILITIES.HISTORY.CORPORATE,
    title: t('corporate')
  },
  {
    to: EMPLOYEES_ROUTES.EMPLOYEE.HISTORY.PERSONAL,
    ability: EMPLOYEES_ABILITIES.HISTORY.PERSONAL,
    title: t('personal')
  }
] as Tab[];

const { backButtonSelectedName } = useBackPreviousButton([
  EMPLOYEES_ROUTES.EMPLOYEE.ROOT,
  REPORTS_ROUTES.SUMMARY
]);

useFirstTabRedirect(EMPLOYEES_ROUTES.EMPLOYEE.HISTORY.ROOT, tabs);
</script>
