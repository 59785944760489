<template>
  <FHeaderPrimary
    :tabs="tabs"
    :title="t('title')"
  />
  <FLayoutContentForTabs />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import type { Tab } from '@/components/FTabs.types';
import COMPANY_ROUTES from '@/modules/company/constants/urls';
import COMPANY_ABILITIES from '@/modules/company/constants/abilities';

definePage({
  meta: {
    ability: COMPANY_ABILITIES.STANDARDS.ANY
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Normes',
      organizational: 'Organisationnelles',
      employee: 'Employés',
      transfer: 'Transfert des banques'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Standards',
      organizational: 'Organizational',
      employee: 'Employees',
      transfer: 'Transfer of banks'
    }
  }
});

const tabs = [
  {
    to: COMPANY_ROUTES.STANDARDS.ORGANISATIONAL,
    ability: COMPANY_ABILITIES.STANDARDS.ORGANISATIONAL,
    title: t('organizational')
  },
  {
    to: COMPANY_ROUTES.STANDARDS.EMPLOYEES,
    ability: COMPANY_ABILITIES.STANDARDS.EMPLOYEES,
    title: t('employee')
  },
  {
    to: COMPANY_ROUTES.STANDARDS.TRANSFER,
    ability: COMPANY_ABILITIES.STANDARDS.TRANSFER,
    title: t('transfer')
  }
] as Tab[];

useFirstTabRedirect(COMPANY_ROUTES.STANDARDS.ROOT, tabs);
</script>
