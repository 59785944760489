import type { Program } from '@/modules/shared/core/utils/mapping/index.types';
import LEGACY_TYPE from '@/modules/shared/core/constants/legacyType';
import EMPLOYEES_ROUTES from '@/modules/employees/constants/urls';
import EMPLOYEES_ABILITIES from '@/modules/employees/constants/abilities';

const EMPLOYEE_MAPPING = [
  {
    name: EMPLOYEES_ABILITIES.EMPLOYEE.NOTE,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    isLegacyModal: true,
    legacyModalUrl: 'urio-modal.php?dialog=note&em_id=:id',
    programId: 0,
    editKey: 'id',
    module: EMPLOYEES_ABILITIES.EMPLOYEE.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.NOTE
  },
  {
    name: EMPLOYEES_ABILITIES.EMPLOYEE.ONBOARDING,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    isLegacyModal: true,
    legacyModalUrl: 'urio-modal.php?dialog=onboarding&em_id=:id',
    programId: 0,
    editKey: 'id',
    module: EMPLOYEES_ABILITIES.EMPLOYEE.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.ONBOARDING
  },
  {
    name: EMPLOYEES_ABILITIES.LIST.SUMMARY,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 201010,
    module: EMPLOYEES_ABILITIES.LIST.ANY,
    routeName: EMPLOYEES_ROUTES.SUMMARY
  },
  {
    name: EMPLOYEES_ABILITIES.EMPLOYEE.TIME_MANAGEMENT.TIMESHEET,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 250000,
    editKey: 'id',
    module: EMPLOYEES_ABILITIES.EMPLOYEE.TIME_MANAGEMENT.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.TIME_MANAGEMENT.TIMESHEET
  },
  {
    name: EMPLOYEES_ABILITIES.EMPLOYEE.EXPENSE_ACCOUNTS,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 201110,
    module: EMPLOYEES_ABILITIES.EMPLOYEE.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.EXPENSE_ACCOUNTS
  },
  {
    name: EMPLOYEES_ABILITIES.EMPLOYEE.SURVEYS,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 203030,
    editKey: 'id',
    module: EMPLOYEES_ABILITIES.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.SURVEYS
  },
  {
    name: EMPLOYEES_ABILITIES.EMPLOYEE.CONFIGURATION.CUSTOM_FIELD_PERSO,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    isLegacyModal: true,
    legacyModalUrl: 'urio-modal.php?dialog=custom_field_perso&em_id=:id',
    programId: 0,
    editKey: 'id',
    module: EMPLOYEES_ABILITIES.EMPLOYEE.CONFIGURATION.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.CONFIGURATION.CUSTOM_FIELD_PERSO
  },
  {
    name: EMPLOYEES_ABILITIES.EMPLOYEE.CONFIGURATION.CUSTOM_FIELD_CORPO,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    isLegacyModal: true,
    legacyModalUrl: 'urio-modal.php?dialog=custom_field_corpo&em_id=:id',
    programId: 0,
    editKey: 'id',
    module: EMPLOYEES_ABILITIES.EMPLOYEE.CONFIGURATION.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.CONFIGURATION.CUSTOM_FIELD_CORPO
  },
  {
    name: EMPLOYEES_ABILITIES.EMPLOYEE.CONFIGURATION.PROJECTS,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    isLegacyModal: true,
    legacyModalUrl: 'urio-modal.php?dialog=employee_project&em_id=:id',
    programId: 0,
    editKey: 'id',
    module: EMPLOYEES_ABILITIES.EMPLOYEE.CONFIGURATION.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.CONFIGURATION.PROJECTS
  }
] as Program[];

const CONFIGURATION_MAPPING = [
  {
    name: EMPLOYEES_ABILITIES.CONFIGURATION.PROFIL_CUSTOMIZATION,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505023,
    module: EMPLOYEES_ABILITIES.CONFIGURATION.ANY,
    routeName: EMPLOYEES_ROUTES.CONFIGURATION.PROFIL_CUSTOMIZATION
  }
] as Program[];

const ABSENCES_MAPPING = [
  {
    name: EMPLOYEES_ABILITIES.EMPLOYEE.TIME_MANAGEMENT.ABSENCE.ANY,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 201520,
    editKey: 'id',
    module: EMPLOYEES_ABILITIES.EMPLOYEE.TIME_MANAGEMENT.ABSENCE.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.TIME_MANAGEMENT.ABSENCE.ROOT,
    iframeUrl: '/legacy/employee-absence/analysis'
  },
  {
    name: EMPLOYEES_ABILITIES.EMPLOYEE.TIME_MANAGEMENT.ABSENCE.CONTROL,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 201530,
    module: EMPLOYEES_ABILITIES.EMPLOYEE.TIME_MANAGEMENT.ABSENCE.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.TIME_MANAGEMENT.ABSENCE.CONTROL,
    iframeUrl: '/legacy/employee-absence/control'
  },
  {
    name:
      EMPLOYEES_ABILITIES.EMPLOYEE.TIME_MANAGEMENT.ABSENCE.REQUEST + ':edit',
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 201530,
    module: EMPLOYEES_ABILITIES.EMPLOYEE.TIME_MANAGEMENT.ABSENCE.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.TIME_MANAGEMENT.ABSENCE.EDIT,
    iframeUrl: '/legacy/employee-absence/:id/edit'
  }
] as Program[];

const VACATIONS_MAPPING = [
  {
    name: EMPLOYEES_ABILITIES.EMPLOYEE.TIME_MANAGEMENT.VACATION.ANY,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 206025,
    editKey: 'id',
    module: EMPLOYEES_ABILITIES.EMPLOYEE.TIME_MANAGEMENT.VACATION.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.TIME_MANAGEMENT.VACATION.ROOT,
    iframeUrl: '/legacy/employee-vacation/analysis'
  },
  {
    name: EMPLOYEES_ABILITIES.EMPLOYEE.TIME_MANAGEMENT.VACATION.CONTROL,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 206030,
    module: EMPLOYEES_ABILITIES.EMPLOYEE.TIME_MANAGEMENT.VACATION.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.TIME_MANAGEMENT.VACATION.CONTROL,
    iframeUrl: '/legacy/employee-vacation/control'
  },
  {
    name:
      EMPLOYEES_ABILITIES.EMPLOYEE.TIME_MANAGEMENT.VACATION.REQUEST + ':edit',
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 206030,
    module: EMPLOYEES_ABILITIES.EMPLOYEE.TIME_MANAGEMENT.VACATION.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.TIME_MANAGEMENT.VACATION.EDIT,
    iframeUrl: '/legacy/employee-vacation/:id/edit'
  }
] as Program[];

const OVERTIMES_MAPPING = [
  {
    name: EMPLOYEES_ABILITIES.EMPLOYEE.TIME_MANAGEMENT.OVERTIME.ANY,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 207030,
    editKey: 'id',
    module: EMPLOYEES_ABILITIES.EMPLOYEE.TIME_MANAGEMENT.OVERTIME.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.TIME_MANAGEMENT.OVERTIME.ROOT,
    iframeUrl: '/legacy/employee-overtime/analysis'
  },
  {
    name: EMPLOYEES_ABILITIES.EMPLOYEE.TIME_MANAGEMENT.OVERTIME.CONTROL,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 207040,
    module: EMPLOYEES_ABILITIES.EMPLOYEE.TIME_MANAGEMENT.OVERTIME.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.TIME_MANAGEMENT.OVERTIME.CONTROL,
    iframeUrl: '/legacy/employee-overtime/control'
  },
  {
    name:
      EMPLOYEES_ABILITIES.EMPLOYEE.TIME_MANAGEMENT.OVERTIME.REQUEST + ':edit',
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 207040,
    module: EMPLOYEES_ABILITIES.EMPLOYEE.TIME_MANAGEMENT.OVERTIME.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.TIME_MANAGEMENT.OVERTIME.EDIT,
    iframeUrl: '/legacy/employee-overtime/:id/edit'
  }
] as Program[];

const HISTORY_MAPPING = [
  {
    name: EMPLOYEES_ABILITIES.HISTORY.CORPORATE,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 201060,
    module: EMPLOYEES_ABILITIES.HISTORY.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.HISTORY.CORPORATE
  },
  {
    name: EMPLOYEES_ABILITIES.HISTORY.PERSONAL,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 201050,
    module: EMPLOYEES_ABILITIES.HISTORY.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.HISTORY.PERSONAL
  },
  {
    name: EMPLOYEES_ABILITIES.HISTORY.CORPORATE,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 201060,
    module: EMPLOYEES_ABILITIES.HISTORY.ANY,
    routeName: EMPLOYEES_ROUTES.HISTORY.CORPORATE
  },
  {
    name: EMPLOYEES_ABILITIES.HISTORY.PERSONAL,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 201050,
    module: EMPLOYEES_ABILITIES.HISTORY.ANY,
    routeName: EMPLOYEES_ROUTES.HISTORY.PERSONAL
  }
] as Program[];

const EXPENSE_ACCOUNTS_MAPPING = [
  {
    name: EMPLOYEES_ABILITIES.EXPENSE_ACCOUNTS.LIST,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 201110,
    module: EMPLOYEES_ABILITIES.EXPENSE_ACCOUNTS.ANY,
    routeName: EMPLOYEES_ROUTES.EXPENSE_ACCOUNTS.ROOT
  },
  {
    name: EMPLOYEES_ABILITIES.EXPENSE_ACCOUNTS.CONFIG.ANY,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505110,
    module: EMPLOYEES_ABILITIES.EXPENSE_ACCOUNTS.ANY,
    routeName: EMPLOYEES_ROUTES.EXPENSE_ACCOUNTS.CONFIG.ROOT
  },
  {
    name: EMPLOYEES_ABILITIES.EXPENSE_ACCOUNTS.REQUEST + ':edit',
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 405000,
    module: EMPLOYEES_ABILITIES.EXPENSE_ACCOUNTS.ANY,
    routeName: EMPLOYEES_ROUTES.EXPENSE_ACCOUNTS.EDIT
  },
  {
    name: EMPLOYEES_ABILITIES.EXPENSE_ACCOUNTS.REQUEST + ':create',
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 405000,
    module: EMPLOYEES_ABILITIES.EXPENSE_ACCOUNTS.ANY,
    routeName: EMPLOYEES_ROUTES.EXPENSE_ACCOUNTS.CREATE
  }
] as Program[];

const INCIDENTS_MAPPING = [
  {
    name: EMPLOYEES_ABILITIES.INCIDENTS.ANY,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 204010,
    module: EMPLOYEES_ABILITIES.INCIDENTS.ANY,
    routeName: EMPLOYEES_ROUTES.INCIDENTS.ROOT,
    iframeUrl: '/legacy/discipline/summary'
  }
] as Program[];

const CLIMATE_MAPPING = [
  {
    name: EMPLOYEES_ABILITIES.CLIMATE.ANNUAL,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 203010,
    module: EMPLOYEES_ABILITIES.CLIMATE.ANY,
    routeName: EMPLOYEES_ROUTES.CLIMATE.ANNUAL
  },
  {
    name: EMPLOYEES_ABILITIES.CLIMATE.ANALYSIS,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 203020,
    module: EMPLOYEES_ABILITIES.CLIMATE.ANY,
    routeName: EMPLOYEES_ROUTES.CLIMATE.ANALYSIS
  },
  {
    name: EMPLOYEES_ABILITIES.CLIMATE.COMMENTS,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 203040,
    module: EMPLOYEES_ABILITIES.CLIMATE.ANY,
    routeName: EMPLOYEES_ROUTES.CLIMATE.COMMENTS
  }
] as Program[];

const DISCIPLINE_MAPPING = [
  {
    name: EMPLOYEES_ABILITIES.EMPLOYEE.DISCIPLINE.ANY,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 204020,
    module: EMPLOYEES_ABILITIES.EMPLOYEE.DISCIPLINE.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.DISCIPLINE.ROOT,
    iframeUrl: '/legacy/discipline/analysis'
  },
  {
    name: EMPLOYEES_ABILITIES.EMPLOYEE.DISCIPLINE.CONTROL,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 204030,
    module: EMPLOYEES_ABILITIES.EMPLOYEE.DISCIPLINE.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.DISCIPLINE.CONTROL,
    iframeUrl: '/legacy/employee-discipline/:code/control'
  },
  {
    name: EMPLOYEES_ABILITIES.EMPLOYEE.DISCIPLINE.CONTROL + ':edit',
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 204030,
    module: EMPLOYEES_ABILITIES.EMPLOYEE.DISCIPLINE.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.DISCIPLINE.EDIT,
    iframeUrl: '/legacy/discipline/:id/edit'
  }
] as Program[];

const PERFORMANCES_MAPPING = [
  {
    name: EMPLOYEES_ABILITIES.EMPLOYEE.PERFORMANCES.TRAINING,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 202010,
    module: EMPLOYEES_ABILITIES.EMPLOYEE.PERFORMANCES.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.PERFORMANCES.TRAINING
  },
  {
    name: EMPLOYEES_ABILITIES.EMPLOYEE.PERFORMANCES.CAREERS,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 205510,
    module: EMPLOYEES_ABILITIES.EMPLOYEE.PERFORMANCES.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.PERFORMANCES.CAREERS
  },
  {
    name: EMPLOYEES_ABILITIES.EMPLOYEE.PERFORMANCES.CERTIFICATES,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 205530,
    module: EMPLOYEES_ABILITIES.EMPLOYEE.PERFORMANCES.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.PERFORMANCES.CERTIFICATES
  },
  {
    name: EMPLOYEES_ABILITIES.EMPLOYEE.PERFORMANCES.ANALYSIS,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 203540,
    module: EMPLOYEES_ABILITIES.EMPLOYEE.PERFORMANCES.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.PERFORMANCES.ANALYSIS,
    iframeUrl: '/legacy/HrManagement/employee-evaluation/employee/summary'
  }
] as Program[];

const STATISTICS_MAPPING = [
  {
    name: EMPLOYEES_ABILITIES.EMPLOYEE.STATISTICS,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 201020,
    module: EMPLOYEES_ABILITIES.EMPLOYEE.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.STATISTICS
  }
] as Program[];

const WORK_ACCIDENTS_MAPPING = [
  {
    name: EMPLOYEES_ABILITIES.EMPLOYEE.WORK_ACCIDENTS,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 202520,
    module: EMPLOYEES_ABILITIES.EMPLOYEE.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.WORK_ACCIDENTS
  }
] as Program[];

const EMPLOYEES_MAPPING = [
  ...CONFIGURATION_MAPPING,
  ...ABSENCES_MAPPING,
  ...VACATIONS_MAPPING,
  ...OVERTIMES_MAPPING,
  ...HISTORY_MAPPING,
  ...EXPENSE_ACCOUNTS_MAPPING,
  ...INCIDENTS_MAPPING,
  ...CLIMATE_MAPPING,
  ...DISCIPLINE_MAPPING,
  ...PERFORMANCES_MAPPING,
  ...STATISTICS_MAPPING,
  ...WORK_ACCIDENTS_MAPPING,
  ...EMPLOYEE_MAPPING
] as Program[];

export default EMPLOYEES_MAPPING;
