<template>
  <FHeaderPrimary
    :title="t('title')"
    :tabs="tabs"
  />
  <FLayoutContentForTabs />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import type { Tab } from '@/components/FTabs.types';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import IMPORT_EXPORT_ABILITIES from '@/modules/parameters/submodules/import-export/constants/abilities';
import IMPORT_EXPORT_ROUTES from '@/modules/parameters/submodules/import-export/constants/urls';

definePage({
  meta: {
    ability: IMPORT_EXPORT_ABILITIES.ANY
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Importation/Exportation',
      import: 'Import des employés',
      export: 'Export des employés'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Import/Export',
      import: 'Employees Import',
      export: 'Employees Export'
    }
  }
});

const tabs = [
  {
    to: IMPORT_EXPORT_ROUTES.IMPORT,
    ability: IMPORT_EXPORT_ABILITIES.IMPORT,
    title: t('import')
  },
  {
    to: IMPORT_EXPORT_ROUTES.EXPORT,
    ability: IMPORT_EXPORT_ABILITIES.EXPORT,
    title: t('export')
  }
] as Tab[];

useFirstTabRedirect(IMPORT_EXPORT_ROUTES.ROOT, tabs);
</script>
