import type SSOAuthenticationStateRepositoryInterface from '@sdk/modules/authentication/interfaces/sso/SSOAuthenticationStateRepositoryInterface';
import { CORE_TYPES } from '@sdk/modules/core/FolksSDKCoreModuleTypes';
import type StorageStrategyInterface from '@sdk/modules/core/interfaces/StorageStrategyInterface';
import { inject, injectable } from 'inversify';
import { MissingSSOStatePropertyException } from '@sdk/modules/authentication/exceptions/sso/MissingSSOStatePropertyException';

@injectable()
export default class SSOAuthenticationStateRepository
  implements SSOAuthenticationStateRepositoryInterface
{
  public static SSO_AUTH_REPO_PREFIX = 'sso_authentication';

  constructor(
    @inject(CORE_TYPES.StorageStrategyInterface)
    private storageStrategy: StorageStrategyInterface
  ) {}

  async save(
    state: string,
    codeVerifier: string,
    codeChallenge: string
  ): Promise<void> {
    await this.storageStrategy.setItem(this.getKey('state'), state);
    await this.storageStrategy.setItem(this.getKey('verifier'), codeVerifier);
    await this.storageStrategy.setItem(this.getKey('challenge'), codeChallenge);
  }

  async fetch(): Promise<{
    state: string;
    codeVerifier: string;
    codeChallenge: string;
  }> {
    const values = {
      state: (await this.storageStrategy.getItem(
        this.getKey('state')
      )) as string,
      codeVerifier: (await this.storageStrategy.getItem(
        this.getKey('verifier')
      )) as string,
      codeChallenge: (await this.storageStrategy.getItem(
        this.getKey('challenge')
      )) as string
    };

    for (const property in values) {
      if (!values[property as keyof typeof values]) {
        throw new MissingSSOStatePropertyException(property);
      }
    }

    return values;
  }

  private getKey(key: string): string {
    return `${SSOAuthenticationStateRepository.SSO_AUTH_REPO_PREFIX}_${key}`;
  }
}
