<template>
  <FHeaderPrimary
    :add-summary-ability="abilitySummaryWithPrivacy"
    :add-summary-route="EMPLOYEES_ROUTES.EMPLOYEE.HISTORY.ROOT"
    :add-summary-text="t('summary')"
    :back-route="EMPLOYEES_ROUTES.LIST"
    :configuration-ability="abilityConfigurationWithPrivacy"
    :configuration-route="EMPLOYEES_ROUTES.EMPLOYEE.CONFIGURATION.ROOT"
    :tabs="tabsWithPrivacy"
    :title="titleWithName"
  />
  <FLayoutContentForTabs />
</template>
<script lang="ts">
import { defineColadaLoader } from 'unplugin-vue-router/data-loaders/pinia-colada';
import { useSingleEmployeeStore } from '@/modules/employees/stores/employee';
import type { RouteLocationNormalizedLoaded } from 'vue-router';
import type { RouteEmployeeSingleParams } from '@/modules/employees/pages/[employee_id].types';

const prefetchSingleEmployeeStore = useSingleEmployeeStore('list');
export const preloadEmployee = defineColadaLoader({
  async query(to: RouteLocationNormalizedLoaded) {
    const params = to.params as RouteEmployeeSingleParams;
    return prefetchSingleEmployeeStore.getByID(params.employee_id);
  },
  key: (to: RouteLocationNormalizedLoaded) => {
    const params = to.params as RouteEmployeeSingleParams;
    return ['employee', params.employee_id];
  },
  staleTime: 1000 * 60
});
</script>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import type { TypeOfTab } from '@/components/FTabs.types';
import EMPLOYEES_ROUTES from '@/modules/employees/constants/urls';
import EMPLOYEES_ABILITIES from '@/modules/employees/constants/abilities';
import { getFullName } from '@/modules/shared/core/utils/dataFormatters';
import EMPLOYEE_PERMISSIONS from '@/modules/shared/core/enums/employeePermissions';

definePage({
  meta: {
    ability: EMPLOYEES_ABILITIES.EMPLOYEE.ANY
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Profil de',
      note: 'Note au dossier',
      documents: 'Documents',
      summary: 'Voir les sommaires',
      onboarding: 'Accueil et intégration',
      timeManagement: 'Temps',
      absences: 'Absence',
      vacations: 'Vacances',
      overtimes: 'Temps supplémentaire',
      timesheet: 'Feuille de temps',
      expenseAccount: 'Compte de dépenses',
      surveys: 'Sondages',
      performance: 'Évolution et carrière',
      training: 'Formations',
      careers: 'Carrière',
      certificates: 'Certificats',
      analysis: 'Performance',
      informations: 'Informations',
      discipline: 'Disciplines',
      statistics: 'Statistiques',
      workAccidents: 'Accidents de travail'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Profile of',
      documents: 'Documents',
      note: 'File Notes',
      onboarding: 'Onboarding',
      summary: 'View summaries',
      timeManagement: 'Time',
      vacations: 'Vacations',
      overtimes: 'Overtimes',
      timesheet: 'Timesheet',
      expenseAccount: 'Expense Accounts',
      surveys: 'Surveys',
      performance: 'Performance and Career',
      training: 'Training',
      careers: 'Careers',
      certificates: 'Certificates',
      analysis: 'Performance',
      informations: 'Informations',
      discipline: 'Discipline',
      statistics: 'Statistics',
      workAccidents: 'Work Accidents'
    }
  }
});

const singleEmployeeStore = useSingleEmployeeStore('list');
const { employeeData } = storeToRefs(singleEmployeeStore);

const hasAccessToPrivateProfile = computed(() => {
  return EMPLOYEE_PERMISSIONS.can(
    employeeData.value.permissionBit,
    EMPLOYEE_PERMISSIONS.VALUES.READ
  );
});

const titleWithName = computed(() => {
  return `${t('title')} ${getFullName(employeeData.value)}`;
});

const abilitySummaryWithPrivacy = computed(() => {
  return hasAccessToPrivateProfile.value ? EMPLOYEES_ABILITIES.HISTORY.ANY : '';
});

const abilityConfigurationWithPrivacy = computed(() => {
  return hasAccessToPrivateProfile.value
    ? EMPLOYEES_ABILITIES.EMPLOYEE.CONFIGURATION.ANY
    : '';
});

const tabs = [
  {
    to: EMPLOYEES_ROUTES.EMPLOYEE.INFORMATIONS.ROOT,
    ability: EMPLOYEES_ABILITIES.EMPLOYEE.INFORMATIONS,
    title: t('informations')
  },
  {
    to: EMPLOYEES_ROUTES.EMPLOYEE.NOTE,
    ability: EMPLOYEES_ABILITIES.EMPLOYEE.NOTE,
    title: t('note')
  },
  {
    to: EMPLOYEES_ROUTES.EMPLOYEE.DOCUMENTS,
    ability: EMPLOYEES_ABILITIES.EMPLOYEE.DOCUMENTS.ANY,
    title: t('documents')
  },
  {
    ability: EMPLOYEES_ABILITIES.EMPLOYEE.TIME_MANAGEMENT.ANY,
    title: t('timeManagement'),
    type: 'parent',
    children: [
      {
        to: EMPLOYEES_ROUTES.EMPLOYEE.TIME_MANAGEMENT.ABSENCE.ROOT,
        ability: EMPLOYEES_ABILITIES.EMPLOYEE.TIME_MANAGEMENT.ABSENCE.ANY,
        title: t('absences')
      },
      {
        to: EMPLOYEES_ROUTES.EMPLOYEE.TIME_MANAGEMENT.VACATION.ROOT,
        ability: EMPLOYEES_ABILITIES.EMPLOYEE.TIME_MANAGEMENT.VACATION.ANY,
        title: t('vacations')
      },
      {
        to: EMPLOYEES_ROUTES.EMPLOYEE.TIME_MANAGEMENT.OVERTIME.ROOT,
        ability: EMPLOYEES_ABILITIES.EMPLOYEE.TIME_MANAGEMENT.OVERTIME.ANY,
        title: t('overtimes')
      },
      {
        to: EMPLOYEES_ROUTES.EMPLOYEE.TIME_MANAGEMENT.TIMESHEET,
        ability: EMPLOYEES_ABILITIES.EMPLOYEE.TIME_MANAGEMENT.TIMESHEET,
        title: t('timesheet')
      }
    ]
  },

  {
    to: EMPLOYEES_ROUTES.EMPLOYEE.ONBOARDING,
    ability: EMPLOYEES_ABILITIES.EMPLOYEE.ONBOARDING,
    title: t('onboarding')
  },
  {
    ability: EMPLOYEES_ABILITIES.EMPLOYEE.PERFORMANCES.ANY,
    title: t('performance'),
    type: 'parent',
    children: [
      {
        to: EMPLOYEES_ROUTES.EMPLOYEE.PERFORMANCES.TRAINING,
        ability: EMPLOYEES_ABILITIES.EMPLOYEE.PERFORMANCES.TRAINING,
        title: t('training')
      },
      {
        to: EMPLOYEES_ROUTES.EMPLOYEE.PERFORMANCES.CAREERS,
        ability: EMPLOYEES_ABILITIES.EMPLOYEE.PERFORMANCES.CAREERS,
        title: t('careers')
      },
      {
        to: EMPLOYEES_ROUTES.EMPLOYEE.PERFORMANCES.CERTIFICATES,
        ability: EMPLOYEES_ABILITIES.EMPLOYEE.PERFORMANCES.CERTIFICATES,
        title: t('certificates')
      },
      {
        to: EMPLOYEES_ROUTES.EMPLOYEE.PERFORMANCES.ANALYSIS,
        ability: EMPLOYEES_ABILITIES.EMPLOYEE.PERFORMANCES.ANALYSIS,
        title: t('analysis')
      }
    ]
  },
  {
    to: EMPLOYEES_ROUTES.EMPLOYEE.EXPENSE_ACCOUNTS,
    ability: EMPLOYEES_ABILITIES.EMPLOYEE.EXPENSE_ACCOUNTS,
    title: t('expenseAccount')
  },
  {
    to: EMPLOYEES_ROUTES.EMPLOYEE.DISCIPLINE.ROOT,
    ability: EMPLOYEES_ABILITIES.EMPLOYEE.DISCIPLINE.ANY,
    title: t('discipline')
  },
  {
    to: EMPLOYEES_ROUTES.EMPLOYEE.SURVEYS,
    ability: EMPLOYEES_ABILITIES.EMPLOYEE.SURVEYS,
    title: t('surveys')
  },
  {
    to: EMPLOYEES_ROUTES.EMPLOYEE.STATISTICS,
    ability: EMPLOYEES_ABILITIES.EMPLOYEE.STATISTICS,
    title: t('statistics')
  },
  {
    to: EMPLOYEES_ROUTES.EMPLOYEE.WORK_ACCIDENTS,
    ability: EMPLOYEES_ABILITIES.EMPLOYEE.WORK_ACCIDENTS,
    title: t('workAccidents')
  }
] as TypeOfTab[];

const tabsWithPrivacy = computed(() => {
  if (hasAccessToPrivateProfile.value) {
    return tabs;
  } else {
    return [tabs[0]];
  }
});

useFirstTabRedirect(EMPLOYEES_ROUTES.EMPLOYEE.ROOT, tabsWithPrivacy.value);
</script>
