<template>
  <FDatatable
    :headers="getVDatatableHeaders"
    :items="documents"
    :items-length="documentsLength"
    :items-per-page="itemsPerPage"
    :loading="loading"
    :sort-by="sortBy"
    @update:search="search = $event"
    @update:sort-by="sortBy = $event"
    @update:page="page = $event"
    @update:items-per-page="itemsPerPage = $event"
    @click:row="($data) => documentPreviewDialogCtrl.show($data.item)"
  >
    <template #table.prepend>
      <FFileUploadOverlay
        v-if="isUploadButtonVisible"
        @on-drop="handleFilesUpload"
      />
    </template>
    <template #headerActions>
      <div
        v-if="isDownloadAllButtonVisible || isUploadButtonVisible"
        class="d-flex ga-5"
      >
        <VBtn
          v-if="isDownloadAllButtonVisible"
          v-dynamic-data-attr="{ all: 'f-document-file-downloadAll-button' }"
          color="primary"
          variant="text"
          @click="downloadAllDocumentWarningModal = true"
        >
          <template #prepend>
            <FIconMD
              icon="download_2"
              weight="400"
            />
          </template>
          {{ t('downloadAll') }}
        </VBtn>

        <FFileUploadButton
          v-if="isUploadButtonVisible"
          v-dynamic-data-attr="{ all: 'f-document-file-upload-button' }"
          :text="t('add')"
          @upload:files="handleFilesUpload"
        />
      </div>
    </template>
    <template #name="{ item }">
      <div class="d-flex">
        <FIconFiles :file-type="item.fileType" />
        <span class="cell-name pl-2 text-body-medium align-self-center">
          <FEllipsis :text="getFileName(item)" />
        </span>
      </div>
    </template>
    <template #updatedBy="{ item }">
      <FDatatableCellFullNameAndAvatar
        :image="item.updatedBy?.image"
        :full-name="item.updatedBy?.fullName"
        :is-active="true"
      />
    </template>
    <template #extraActions="{ item }">
      <FDatatableExtraActions>
        <VListItem @click="downloadDocument(item)">
          <template #prepend>
            <FIconMD icon="download" />
          </template>
          <VListItemTitle>
            {{ t('table.extraActions.download') }}
          </VListItemTitle>
        </VListItem>
        <VListItem
          v-if="canEdit"
          @click="openRenameDialog(item)"
        >
          <template #prepend>
            <FIconMD icon="edit" />
          </template>
          <VListItemTitle>
            {{ t('table.extraActions.rename') }}
          </VListItemTitle>
        </VListItem>
        <VListItem
          v-dynamic-data-attr="{ all: `button-preview-document-${item.id}` }"
          @click="() => documentPreviewDialogCtrl.show(item)"
        >
          <template #prepend>
            <FIconMD icon="visibility" />
          </template>
          <VListItemTitle>
            {{ t('table.extraActions.preview') }}
          </VListItemTitle>
        </VListItem>
        <VDivider v-if="canEdit" />
        <VListItem
          v-if="canEdit"
          @click="openDeleteDocumentModal(item)"
        >
          <template #prepend>
            <FIconMD icon="delete" />
          </template>
          <VListItemTitle>
            {{ t('table.extraActions.delete') }}
          </VListItemTitle>
        </VListItem>
      </FDatatableExtraActions>
    </template>
  </FDatatable>
  <FDialogFormDocumentRename
    v-if="isRenameDialogVisible && currentDocument"
    :document="currentDocument"
    :employee-id="employeeId"
    @dialog:submit="isRenameDialogVisible = false"
    @dialog:cancel="isRenameDialogVisible = false"
  />
  <FDocumentPreviewDialog
    :document-data="documentPreviewDialogCtrl.data"
    :loading="documentPreviewDialogCtrl.loading"
    :title="documentPreviewDialogCtrl.title"
    :is-visible="documentPreviewDialogCtrl.visible"
    @close="documentPreviewDialogCtrl.close"
    @download-click="documentPreviewDialogCtrl.download"
  />
  <FDocumentUploadConfirmDialog
    :visibility="uploadConfirmDialogsVisibility"
    :files-validation-warning="filesValidationWarning"
    :has-valid-documents="hasValidDocuments"
    @cancel="handleUploadCancel"
    @confirm="handleUploadConfirm()"
    @next-warning="handleUploadNextWarning"
  />
  <FDocumentDrawer
    v-if="isDocumentDrawerVisible"
    class="w-px-103"
    :files="documentsUploading"
    :employee-id="Number(employeeId)"
    @close="isDocumentDrawerVisible = false"
    @preview-document="handleDocumentPreview"
  />

  <FDocumentUploadConfirmCancelDialog
    :is-visible="isConfirmCloseUploadVisible"
    :mode="FDocumentUploadConfirmCancelDialogMode.LEAVE"
    @cancel="isConfirmCloseUploadVisible = false"
    @confirm="handleDismissConfirmClose(true)"
  />
  <FConfirmDialog
    v-if="downloadAllDocumentWarningModal"
    :is-visible="true"
    :title="t('modal.downloadAllDocumentWarning.title')"
    :content="t('modal.downloadAllDocumentWarning.content')"
    :cancel-btn-text="t('modal.downloadAllDocumentWarning.cancel')"
    :confirm-btn-text="t('modal.downloadAllDocumentWarning.confirm')"
    :is-submitting="submittingDownloadAll"
    @cancel="downloadAllDocumentWarningModal = false"
    @confirm="downloadAllDocuments"
  />
  <FConfirmDialog
    v-if="deleteDocumentModal && currentDocument"
    :is-visible="true"
    :title="
      t('modal.deleteDocumentWarning.title', {
        documentName: getFileName(currentDocument)
      })
    "
    :content="
      t('modal.deleteDocumentWarning.content', {
        documentName: getFileName(currentDocument)
      })
    "
    :cancel-btn-text="t('modal.deleteDocumentWarning.cancel')"
    :confirm-btn-text="t('modal.deleteDocumentWarning.confirm')"
    confirm-btn-color="error"
    confirm-btn-variant="outlined"
    @cancel="deleteDocumentModal = false"
    @confirm="deleteDocument(currentDocument)"
  />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useFilesManagerStore } from '@/modules/shared/core/stores/filesManager';
import useFDatatableItems from '@/modules/shared/core/composables/useFDatatableItems';
import useFDatatableColumns from '@/modules/shared/core/composables/useFDatatableColumns';
import EMPLOYEES_ABILITIES from '@/modules/employees/constants/abilities';
import SUPPORTED_LANGUAGES from '@/modules/shared/core/constants/languages';
import type { FileElement } from '@/modules/shared/core/stores/filesManager.types';
import { getName as getFileName } from '@/modules/shared/core/utils/fileUtils';
import { usePermissionsStore } from '@/modules/shared/core/stores/permissions';
import { VBtn } from 'vuetify/components';
import useDocumentValidation from '@/modules/employees/composables/useDocumentValidation';
import { FDocumentUploadConfirmCancelDialogMode } from '@/modules/employees/components/FDocumentUploadConfirmCancelDialog.types';
import { FileUploadingStatus } from '@/modules/shared/core/constants/fileUploadingStatus';

definePage({
  meta: {
    ability: EMPLOYEES_ABILITIES.EMPLOYEE.DOCUMENTS.ANY
  }
});
const { params } = useRoute();
const employeeId = computed(() =>
  Number((params as { employee_id: number }).employee_id)
);
const { canAccess } = usePermissionsStore();
const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      documents: 'Documents',
      downloadAll: 'Tout télécharger',
      add: 'Ajouter',
      table: {
        name: 'Nom',
        type: 'Type',
        createdAt: "Date d'ajout",
        updatedBy: 'Modifié par',
        extraActions: {
          delete: 'Supprimer',
          download: 'Télécharger',
          rename: 'Renommer',
          preview: "Afficher l'aperçu"
        }
      },
      modal: {
        downloadAllDocumentWarning: {
          title: 'Télécharger tous les documents de l’employé',
          content:
            'Le téléchargement de tous les documents du profil peut être volumineux et prendre plusieurs minutes. Assurez-vous d’avoir suffisamment d’espace de stockage et une connexion stable avant de continuer.',
          confirm: 'Lancer le téléchargement',
          cancel: 'Annuler'
        },
        deleteDocumentWarning: {
          title: 'Supprimer « {documentName} »',
          content:
            'L’élément « {documentName} » sera supprimé définitivement. Désirez-vous réellement supprimer le document ?',
          confirm: 'Supprimer le document',
          cancel: 'Annuler'
        }
      }
    },
    [SUPPORTED_LANGUAGES.en]: {
      documents: 'Documents',
      downloadAll: 'Download all',
      add: 'Add',
      table: {
        name: 'Name',
        type: 'Type',
        createdAt: 'Added Date',
        updatedBy: 'Modified By',
        extraActions: {
          delete: 'Delete',
          download: 'Download',
          rename: 'Rename',
          preview: 'Show preview'
        }
      },
      modal: {
        downloadAllDocumentWarning: {
          title: 'Download all employee documents',
          content:
            'Downloading all documents from the profile can be large and take several minutes. Make sure you have enough storage space and a stable connection before continuing.',
          confirm: 'Start download',
          cancel: 'Cancel'
        },
        deleteDocumentWarning: {
          title: 'Delete "{documentName}"',
          content:
            'The item "{documentName}" will be permanently deleted. Do you really want to delete the document?',
          confirm: 'Delete document',
          cancel: 'Cancel'
        }
      }
    }
  }
});

const { getVDatatableHeaders, getVisibleColumns } = useFDatatableColumns([
  {
    title: t('table.name'),
    key: 'name',
    sortable: true,
    mandatory: true
  },
  {
    title: t('table.createdAt'),
    key: 'auditTimestamp.createdAt',
    sortable: true
  },
  { title: t('table.updatedBy'), key: 'updatedBy', sortable: false }
]);

const {
  generateFilesWarningVisibility,
  validateFilesLength,
  getFilesWarning,
  keepFiles,
  keepFilesBasedOnLength,
  formatFilesName
} = useDocumentValidation();

const {
  isVisible: isConfirmCloseUploadVisible,
  show: showConfirmCloseUpload,
  handleDismiss: handleDismissConfirmClose
} = useDialog();

const canEdit = computed(() =>
  canAccess(EMPLOYEES_ABILITIES.EMPLOYEE.DOCUMENTS.WRITE)
);

const isUploadButtonVisible = computed(() => {
  return canAccess(EMPLOYEES_ABILITIES.EMPLOYEE.DOCUMENTS.WRITE);
});

const isDownloadAllButtonVisible = computed(() => {
  return documents.value.length > 0;
});

type ErrorType = 'maxLength' | 'maxSize' | 'fileType';

const uploadConfirmDialogsVisibility = ref<{
  maxLength: boolean;
  maxSize: boolean;
  fileType: boolean;
}>({
  maxLength: false,
  maxSize: false,
  fileType: false
});

const filesManagerStore = useFilesManagerStore('employee', employeeId.value);

const isDocumentDrawerVisible = ref(false);

const {
  files: documents,
  filesLength: documentsLength,
  currentFile: currentDocument,
  currentFilesUploading: documentsUploading
} = storeToRefs(filesManagerStore);

const { itemsPerPage, loading, fetchNewEntries, page, sortBy, search } =
  useFDatatableItems(filesManagerStore.get, getVisibleColumns);

fetchNewEntries();

const hasFilesInUploadingStatus = computed(() => {
  return documentsUploading.value.some(
    (file) => file.status == FileUploadingStatus.UPLOADING
  );
});

window.addEventListener('beforeunload', (event) => {
  if (hasFilesInUploadingStatus.value) {
    event.returnValue = false;
  }
});

onBeforeRouteLeave(async () => {
  let promise;

  if (hasFilesInUploadingStatus.value) {
    promise = showConfirmCloseUpload<boolean>();
  } else {
    promise = Promise.resolve(true);
  }

  const results = await promise;

  if (results) {
    filesManagerStore.abortAllUploads();
    return true;
  }

  return false;
});

const isRenameDialogVisible = ref(false);

const openRenameDialog = (item: FileElement) => {
  currentDocument.value = item;
  isRenameDialogVisible.value = true;
};

const deleteDocumentModal = ref(false);

const openDeleteDocumentModal = (item: FileElement) => {
  currentDocument.value = item;
  deleteDocumentModal.value = true;
};

const deleteDocument = (item: FileElement) => {
  deleteDocumentModal.value = false;
  filesManagerStore.deleteFile(item.id as number, item.randomName);
};

const documentPreviewDialogCtrl = useDocumentPreviewDialog(filesManagerStore);

const downloadDocument = (item: FileElement) => {
  filesManagerStore.downloadFile(item.randomName);
};
const downloadAllDocumentWarningModal = ref(false);
const submittingDownloadAll = ref(false);

const downloadAllDocuments = async () => {
  submittingDownloadAll.value = true;
  await filesManagerStore.downloadAllFile();
  submittingDownloadAll.value = false;
  downloadAllDocumentWarningModal.value = false;
};

const filesUploading = ref();
const filesValidationWarning = ref();
const hasValidDocuments = ref(false);

const handleFilesUpload = (files: FileList | File[]) => {
  if (files.length) {
    const filesArray = files instanceof FileList ? Array.from(files) : files;

    uploadConfirmDialogsVisibility.value =
      generateFilesWarningVisibility(filesArray);

    filesValidationWarning.value = getFilesWarning(filesArray);

    filesUploading.value = keepFiles(filesArray);

    uploadConfirmDialogsVisibility.value.maxLength = !validateFilesLength(
      filesUploading.value
    );

    filesUploading.value = formatFilesName(
      keepFilesBasedOnLength(filesUploading.value)
    );

    hasValidDocuments.value = filesUploading.value.length !== 0;

    if (
      Object.values(uploadConfirmDialogsVisibility.value).every(
        (isVisible) => !isVisible
      )
    ) {
      openDocumentDrawer();
    }
  }
};

const handleUploadConfirm = () => {
  closeUploadConfirmDialogs();
  openDocumentDrawer();
};

const handleUploadNextWarning = (errorType: ErrorType) => {
  uploadConfirmDialogsVisibility.value[errorType] = false;
};

const handleUploadCancel = () => {
  closeUploadConfirmDialogs();
};

const closeUploadConfirmDialogs = () => {
  uploadConfirmDialogsVisibility.value = {
    maxLength: false,
    maxSize: false,
    fileType: false
  };
};

const openDocumentDrawer = () => {
  filesManagerStore.upload(filesUploading.value);
  isDocumentDrawerVisible.value = true;
};

const handleDocumentPreview = async (file: FileElement) => {
  await documentPreviewDialogCtrl.show(file);
};
</script>

<style lang="sass" scoped>
.cell-name
  max-width: 300px
</style>
