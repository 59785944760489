<template>
  <router-view />
</template>
<script lang="ts" setup>
definePage({
  meta: {
    isAuthPages: true
  }
});
</script>
<style lang="sass" scoped></style>
