<template>
  <VSnackbar
    v-model="snackbar"
    location="bottom"
    :vertical="vertical"
    :multi-line="multiline"
    :timeout="timeout"
    @update:model-value="$emit('close')"
  >
    <template #text>
      <slot name="text">
        {{ getMessage(message) }}
      </slot>
    </template>
    <template #actions>
      <slot name="actions"></slot>
      <VBtn
        v-if="closable"
        v-tooltip:top="t('close')"
        v-dynamic-data-attr="{ all: 'f-snackbar-close-btn' }"
        color="inverse-on-surface"
        :icon="true"
        @click="$emit('close')"
      >
        <FIconMD icon="close" />
      </VBtn>
    </template>
  </VSnackbar>
</template>

<script lang="ts" setup>
import SUPPORTED_LANGUAGES from '../constants/languages';
import type { FSnackbarProps } from './FSnackbar.types';

const {
  isVisible = false,
  closable = true,
  timeout = 3500,
  multiline = false,
  vertical = false,
  message
} = defineProps<FSnackbarProps>();

defineEmits(['close']);

const snackbar = computed(() => isVisible);

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      close: 'Fermer'
    },
    [SUPPORTED_LANGUAGES.en]: {
      close: 'Close'
    }
  }
});
const { getMessage } = useGetMessage();
</script>
